import React from 'react';
import styled from 'styled-components/macro';
import PropTypes from 'prop-types';
import {
  Avatar,
} from '@material-ui/core';
import { Typography } from '../../../pages/private/styles';
import {
  BDown,
  CustomDetails,
  CustomAccordion,
  CustomAccordionSummary,
} from '../styles';
import Basics from './Basics';
import Content from './Content';
import Questions from './Questions';
import Audience from './Audience';
import Launch from './Launch';

const NumAvatar = styled(Avatar)`
  background-color: ${props => props.theme.palette.primary.main};
  color: white;
  margin-right: 12px;
  width: 24px;
  height: 24px;
  font-size: 14px;
`;

const QuizBuilder = ({
  isRetail,
}) => (
  <>
    <CustomAccordion>
      <CustomAccordionSummary expandIcon={<BDown />}>
        <NumAvatar>
          1
        </NumAvatar>
        <Typography
          variant="subtitle1"
        >
          <strong>
            Basics
          </strong>
        </Typography>
      </CustomAccordionSummary>
      <CustomDetails>
        <Basics isRetail={isRetail} />
      </CustomDetails>
    </CustomAccordion>
    <CustomAccordion>
      <CustomAccordionSummary expandIcon={<BDown />}>
        <NumAvatar>
          2
        </NumAvatar>
        <Typography
          variant="subtitle1"
        >
          <strong>
            Content
          </strong>
        </Typography>
      </CustomAccordionSummary>
      <CustomDetails>
        <Content />
      </CustomDetails>
    </CustomAccordion>
    <CustomAccordion>
      <CustomAccordionSummary expandIcon={<BDown />}>
        <NumAvatar>
          3
        </NumAvatar>
        <Typography
          variant="subtitle1"
        >
          <strong>
            Questions
          </strong>
        </Typography>
      </CustomAccordionSummary>
      <CustomDetails>
        <Questions />
      </CustomDetails>
    </CustomAccordion>
    <CustomAccordion>
      <CustomAccordionSummary expandIcon={<BDown />}>
        <NumAvatar>
          4
        </NumAvatar>
        <Typography
          variant="subtitle1"
        >
          <strong>
            Audience
          </strong>
        </Typography>
      </CustomAccordionSummary>
      <CustomDetails>
        <Audience isRetail={isRetail} />
      </CustomDetails>
    </CustomAccordion>
    <CustomAccordion>
      <CustomAccordionSummary expandIcon={<BDown />}>
        <NumAvatar>
          5
        </NumAvatar>
        <Typography
          variant="subtitle1"
        >
          <strong>
            Launch
          </strong>
        </Typography>
      </CustomAccordionSummary>
      <CustomDetails>
        <Launch isRetail={isRetail} />
      </CustomDetails>
    </CustomAccordion>
  </>
);

export default QuizBuilder;

QuizBuilder.propTypes = {
  isRetail: PropTypes.bool.isRequired,
};
