/* eslint-disable */
import React, { useState } from "react";
import styled from "styled-components/macro";
import { DropzoneArea } from "material-ui-dropzone";
import { Button, CircularProgress } from "@material-ui/core";
import API from "../../../../axios/instances/API";
import { parse } from "papaparse";

const Wrapper = styled.div`
  width: 100%;
`;

const parseTemplateErrors = (error, formattedData) => {
  const errorObj = error?.response?.data?.error;
  const rowsWithErrors = errorObj?.context?.row_errors;
  const generalErrors = errorObj?.context?.general_errors;
  const cleanRowsWithErrors = !Boolean(rowsWithErrors)
    ? null
    : Object.keys(rowsWithErrors).map((key) => {
        return {
          key,
          row: formattedData[key - 1],
          msg: rowsWithErrors[key],
        };
      });

  return {
    title: errorObj?.title,
    body: errorObj?.details,
    rowErrors: cleanRowsWithErrors || null,
    generalErrors: generalErrors || null,
    status: error?.response?.status,
    result: "Failed",
  };
};

const UploadTemplate = ({ setInviteResults, setSnackbarText }) => {
  const [uploadKey, setUploadKey] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const [file, setFile] = useState([]);

  const uploadCsvInvites = async (formattedData) => {
    setIsUploading(true);
    try {
      await API.post("/dashboard/teams/invitations/bulk/", {
        invitations: formattedData,
      });
      setUploadKey((p) => !p);
      setSnackbarText("Csv invites uploaded to server success");
      setIsUploading(false);
      setInviteResults({
        result: "Success",
        numberOfInvitesSent: formattedData.length,
      });
    } catch (error) {
      setUploadKey((p) => !p);
      const errorObj = parseTemplateErrors(error, formattedData);
      setIsUploading(false);
      setInviteResults(errorObj);
    }
  };

  const handleCsvUpload = async () => {
    try {
      const [targetFile] = file;
      parse(targetFile, {
        skipFirstNLines: 1,

        complete: async (results) => {
          const formattedData = results.data
            .map((row, i) => {
              if (i === 0) return; // removes the headers
              if (row.length < 2) return; // if an empty row is accidentally added, this will skip it

              return {
                row: i,
                email: row[2],
                first_name: row[0],
                last_name: row[1],
                locations: [row[3]],
                roles: [row[4], row[5], row[6]].filter(Boolean),
              };
            })
            .filter(Boolean);

          await uploadCsvInvites(formattedData);
        },
      });
    } catch (error) {
      setSnackbarText("Error uploading the csv error");
    }
  };

  return (
    <Wrapper>
      <div style={{ height: 6 }} />
      <DropzoneArea
        showAlerts={false}
        acceptedFiles={["text/csv", ".csv"]}
        useChipsForPreview
        // onDropRejected={(e) => console.log(e)}
        showFileNamesInPreview
        showFileNames
        filesLimit={1}
        onChange={(f) => setFile(f)}
        key={uploadKey}
        dropzoneText={
          <div
            style={{
              height: 80,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <span style={{ fontSize: 15 }}>Upload completed template</span>
            <span style={{ fontSize: 12, color: "darkgrey" }}>
              File types accepted: .csv
            </span>
          </div>
        }
      />
      <div style={{ height: 8 }} />
      <Button
        mt={4}
        p={2}
        fullWidth
        color="secondary"
        variant="contained"
        disabled={file.length === 0 || isUploading}
        onClick={handleCsvUpload}
        startIcon={isUploading && <CircularProgress size={20} />}
      >
        Upload
      </Button>
    </Wrapper>
  );
};

export default UploadTemplate;
