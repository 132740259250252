/* eslint-disable */
import React from "react";
import { TextField, Button } from "@material-ui/core";
import { X } from "react-feather";
import styled from "styled-components/macro";

const CloseButton = styled(Button)`
  background: #eeeeee;
  height: 32px;
  max-height: 32px;
  min-height: 32px;
  min-width: 32px;
  max-width: 32px;
  width: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;

  svg {
    color: slategrey;
  }

  &:hover {
    svg {
      color: black;
    }
  }
`;

const CustomSearchBarForTables = ({ searchText, onSearch, onHide }) => {
  const handleTextChange = (event) => {
    onSearch(event.target.value);
  };

  return (
    <div
      style={{
        width: 300,
        gap: 6,
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-start",
      }}
    >
      <TextField
        size="small"
        variant="outlined"
        value={searchText || ""}
        onChange={handleTextChange}
        fullWidth
        color="secondary"
        placeholder="Search table.."
        autoFocus
      />
      <CloseButton onClick={onHide}>
        <X height={22} width={22} />
      </CloseButton>
    </div>
  );
};

export default CustomSearchBarForTables;
