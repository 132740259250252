/* eslint-disable */
import React from "react";
import { AUDIENCETYPES, USER_TYPES } from "../../../../storage/constants";
import { TABLE_STATES, getComboIds } from "../helpers";
import FunLoader from "../../../Loaders/FunLoader";
import CurrentAudience from "../../currentAudience";
import useAudienceToolStore from "../../../../store/useAudienceToolStore";
import SupervisorModifyToggle from "../SupervisorModifyToggle";
import { Button } from "@material-ui/core";

const SupervisorBox = () => {
  const tableState = useAudienceToolStore((state) => state.tableState);

  const currentAudienceType = useAudienceToolStore(
    (state) => state.currentAudienceType
  );

  const currentAudience = useAudienceToolStore(
    (state) => state.currentAudience
  );

  const canManagersModify = useAudienceToolStore(
    (state) => state.canManagersModify
  );

  const userIsManager = useAudienceToolStore(
    (state) => state.userType === USER_TYPES.RETAIL_MANAGER_CAN_DEPLOY
  );
  const updateTableState = useAudienceToolStore(
    (state) => state.updateTableState
  );
  const moduleId = useAudienceToolStore((state) => state.moduleId);

  const locations = useAudienceToolStore((state) => state.locations);
  const roles = useAudienceToolStore((state) => state.roles);
  const comboIds = getComboIds(currentAudienceType, currentAudience);

  return (
    <div
      style={{
        position: "absolute",
        height: "100%",
        width: "100%",
        top: 0,
        right: 0,
        zIndex: 100,
        background: "#eeeeee94",

        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        borderRadius: 4,
      }}
    >
      <div
        style={{
          display: "flex",
          minHeight: 256,
          minWidth: 400,
          width: "100%",
          maxWidth: 460,
          background: "white",
          flexDirection: "column",
          alignItems: "flex-start",
          justifyContent: "flex-start",
          borderRadius: 4,
          padding: 12,
          border: "2px solid black",
        }}
      >
        <div
          style={{
            width: "fit-content",
            display: "flex",
            alignItems: "center",
            flexDirection: "row",

            justifyContent: "flex-start",
            gap: 3,
          }}
        >
          <p
            style={{
              fontWeight: "600",
              fontSize: 14,
              textTransform: "uppercase",
              padding: 2,
              color: "black",
            }}
          >
            {currentAudienceType === AUDIENCETYPES.NONE
              ? "No Audience"
              : "Saved Audience"}
          </p>
        </div>
        <div style={{ height: 10 }} />
        {tableState === TABLE_STATES.SAVING ? (
          <div
            style={{
              height: 200,
              width: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              border: "1px solid slategrey",
              borderRadius: 4,
              background: "#eeeeee34",
            }}
          >
            <FunLoader />
          </div>
        ) : (
          <CurrentAudience
            moduleId={moduleId}
            options={{ locations, roles }}
            comboValue={comboIds}
            shouldTableBeViewable={true}
            selectedAudience={
              userIsManager ? AUDIENCETYPES.MANAGER : currentAudienceType
            }
            audience={currentAudience}
          />
        )}
        <div
          style={{
            padding: "0px",
            width: "100%",
            marginTop: 12,
            marginBottom: 12,
          }}
        >
          <SupervisorModifyToggle />
        </div>
        <Button
          style={{
            background: canManagersModify ? "black" : "lightgray",
            border: "none",
            marginTop: "auto",
            color: "white",
          }}
          fullWidth
          onClick={() => updateTableState(TABLE_STATES.UNLOCKED)}
          disabled={!canManagersModify} // disable this button if doesnt have required permissions
        >
          Edit
        </Button>
      </div>
    </div>
  );
};

export default SupervisorBox;
