/* eslint-disable */
import React from "react";
import { Checkbox } from "@material-ui/core";
import styled from "styled-components/macro";
import { Check, MapPin } from "react-feather";
import Cell from "./Cell";
import { useShallow } from "zustand/react/shallow";
import { TABLE_STATES } from "./helpers";
import useAudienceToolStore from "../../../store/useAudienceToolStore";

const FirstCell = styled.td`
  padding-left: 12px;
  font-weight: 500;
  position: -webkit-sticky;
  position: sticky;
  left: 0px;
  z-index: 2;
`;

const StyledCheckbox = styled(Checkbox)`
  background-color: transparent;
  color: darkgrey;
  height: 28px;
  width: 28px;

  &.Mui-checked {
    color: black;
    background-color: transparent;
  }
`;

const CheckedIcon2 = () => {
  const isDisplayOnly = useAudienceToolStore((state) => state.isDisplayOnly);
  const tableState = useAudienceToolStore((state) => state.tableState);

  return (
    <div
      style={{
        height: 14,
        width: 14,
        minHeight: 14,
        minWidth: 14,
        borderRadius: 4,
        background:
          isDisplayOnly ||
          [TABLE_STATES.SAVING, TABLE_STATES.LOCKED].includes(tableState)
            ? "slategrey"
            : "black",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Check height={10} width={10} style={{ color: "white" }} />
    </div>
  );
};

const FirstCellComp = ({ checked, id }) => {
  const isDisplayOnly = useAudienceToolStore((state) => state.isDisplayOnly);
  const tableState = useAudienceToolStore((state) => state.tableState);
  const toggleRowCheck = useAudienceToolStore((state) => state.toggleRowCheck);
  const name = useAudienceToolStore(
    (state) => state.locations.find((loc) => loc.id === Number(id)).name
  );

  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-start",
        position: "relative",
      }}
    >
      <StyledCheckbox
        size="small"
        checked={checked}
        disabled={
          [
            TABLE_STATES.LOCKED,
            TABLE_STATES.LOADING,
            TABLE_STATES.SAVING,
          ].includes(tableState) || isDisplayOnly
        }
        icon={
          <div
            style={{
              height: 14,
              width: 14,
              minWidth: 14,
              minHeight: 14,
              borderRadius: 4,
              background: "lightgray",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          />
        }
        checkedIcon={<CheckedIcon2 />}
        onChange={(e) => toggleRowCheck(e, id)}
      />
      <MapPin
        height={11}
        width={11}
        style={{
          marginRight: 2,
          color: "black",
          color:
            isDisplayOnly ||
            [TABLE_STATES.SAVING, TABLE_STATES.LOCKED].includes(tableState)
              ? "darkgrey"
              : "black",
        }}
      />
      <span
        style={{
          fontSize: 10,
          fontWeight: "500",
          textTransform: "capitalize",
          color: "black",
          color:
            isDisplayOnly ||
            [TABLE_STATES.SAVING, TABLE_STATES.LOCKED].includes(tableState)
              ? "darkgrey"
              : "black",
        }}
      >
        {name}
      </span>
    </div>
  );
};

const Row = ({ id }) => {
  const targetRow = useAudienceToolStore(useShallow((state) => state.rows[id]));
  const target = useAudienceToolStore((state) =>
    state.locations.find((loc) => loc.id === Number(id))
  );
  const allChecked = useAudienceToolStore((state) =>
    state.rows[id].every((c) => c.checked)
  );

  return (
    <tr
      style={{
        minHeight: 30,
      }}
    >
      <FirstCell
        style={{
          backgroundColor: allChecked ? "#F8F8F8" : "#F0F0F0",
        }}
      >
        <FirstCellComp checked={allChecked} id={id} name={target.name} />
      </FirstCell>
      {targetRow.map((_, i) => (
        <Cell id={id} index={i} key={i} />
      ))}
    </tr>
  );
};

export default Row;
