/* eslint-disable */
import React from 'react';
import PropTypes from 'prop-types';
import { LinearProgress } from '@material-ui/core';
import styled, { keyframes } from 'styled-components/macro';

const fadeInOut = keyframes`
  0% {
    opacity: 0.2;
  }
  50% {
    opacity: 0.6;
  }
  100%{
    opacity: 0.2;
  }
`;

const LoadingText = styled.span`
  letter-spacing: 1px;
  font-weight: 300;
  font-size: 10px;
  opacity: 0;
  animation: 1.8s ${fadeInOut} ease-out infinite;
`;

const ErrorText = styled.span`
  letter-spacing: 1px;
  font-weight: 300;
  font-size: 10px;
`;

const BarWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  width: fit-content;
`;

const PercentageText = styled.span`
  font-size: 10px;
  font-weight: 500;
  color: ${props => props.theme.palette.grey[700]};
`;

const CustomLinearProgress = styled(LinearProgress)`
  background-color: white;
  background-color: ${props => props.theme.palette.grey[100]};
  border-radius: 8px;
  min-height: 10px;
  width: 150px;
  margin-right: 8px;
  border: 1px solid #eeeeee;

  .MuiLinearProgress-barColorPrimary {
    background-color: ${props => {
      if (props.value > 80) return props.theme.palette.success.main;
      if (props.value > 60) return props.theme.palette.success.light;
      if (props.value > 40) return props.theme.palette.warning.light;
      if (props.value > 20) return props.theme.palette.warning.main;
      if (props.value > 0) return props.theme.palette.error.main;
      return props.theme.palette.primary.main;
    }};
  }
`;

const ProgressBar = ({ progress, id, isLoading, isError }) => {
  if (!progress || isLoading) {
    return (
      <BarWrapper>
        <LoadingText>Loading..</LoadingText>
      </BarWrapper>
    );
  }

  if (progress === 'ERROR' || isError) {
    return (
      <BarWrapper>
        <ErrorText>Error Loading Progress</ErrorText>
      </BarWrapper>
    );
  }
  const filtered = progress[id];

  if (filtered === undefined) {
    return (
      <BarWrapper>
        <CustomLinearProgress
          variant="determinate"
          value={0}
        />
        <PercentageText>
          (0/0) <strong>0%</strong>
        </PercentageText>
      </BarWrapper>
    );
  }

  const value =
    filtered.complete === 0 ? 0 : Math.floor((filtered.complete / filtered.total) * 100);

  return (
    <BarWrapper>
      <CustomLinearProgress
        variant="determinate"
        value={value}
      />
      <PercentageText>
        (
        <small>
          {value === 0 ? `0/${filtered.total}` : `${filtered.complete}/${filtered.total}`}
        </small>
        ) <strong>{value}%</strong>
      </PercentageText>
    </BarWrapper>
  );
};

export default ProgressBar;

ProgressBar.propTypes = {
  id: PropTypes.number.isRequired,
  isLoading: PropTypes.bool.isRequired,
  isError: PropTypes.bool.isRequired,
  progress: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
};
