/* eslint-disable */
import { Tooltip, Button } from '@material-ui/core';
import styled from 'styled-components/macro';

const StyledButton = styled(Button)`
  height: 22px;
  width: 22px;
  min-height: 22px;
  max-height: 22px;
  max-width: 22px;
  min-width: 22px;
  background-color: #eeeeee;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0px;
  transition: background-color 200ms ease-in;

  svg {
    color: slategrey;
  }
  &:hover {
    background-color: slategray;

    svg {
      color: #eeeeee !important;
    }
  }
`;

const ActionButtonForTables = ({ handleClick, disabled = false, title = 'title', Icon }) => {
  return (
    <div style={{ width: 'fit-content' }}>
      <Tooltip
        arrow
        placement="right"
        title={title}
      >
        <span>
          <StyledButton
            onClick={handleClick}
            disabled={disabled}
          >
            <Icon
              height={14}
              width={14}
              style={{ color: disabled ? 'lightgray' : 'slategray' }}
            />
          </StyledButton>
        </span>
      </Tooltip>
    </div>
  );
};

export default ActionButtonForTables;
