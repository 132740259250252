/* eslint-disable */
import useSWR from 'swr';
import API from '../../../axios/instances/API';

const fetcher = async (url) => {
  const { data } = await API.get(url);
  return data.verticals;
};

const useAllVerticals = () => {
  const { data, error, mutate } = useSWR('dashboard/signup/company/verticals/', fetcher, {
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
    revalideIfStale: false,
  });

  return {
    data,
    isLoading: !error && !data,
    isError: error,
    mutate,
  };
};

export default useAllVerticals;
