/* eslint-disable */
import useSWR from 'swr';
import API from '../../../axios/instances/API';

const fetcher = async (url, countryCode) => {
  const { data } = await API.get(`${url}${countryCode}`);
  return data.states;
};

const useStatesByCountry = countryCode => {
  const { data, error, mutate } = useSWR(countryCode ? ['dashboard/address/states/?country_code=', countryCode] : null, fetcher, {
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
    revalideIfStale: false,
  });

  return {
    data,
    isLoading: !error && !data,
    isError: error,
    mutate,
  };
};

export default useStatesByCountry;
