/* eslint-disable */
import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components/macro";
import { LinearProgress } from "@material-ui/core";
import { Typography } from "../../styles";

const CustomLinearProgress = styled(LinearProgress)`

  background-color: ${(props) => props.theme.palette.grey[300]};
  height: 14px;
  border-radius: 4px;
  width: 280px;

  .MuiLinearProgress-barColorPrimary {
    background-color: ${(props) => props.theme.palette.primary.dark};
    };
  } 
`;

const AverageScoreContainer = styled.div`
  padding: 8px 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

const AverageScoreBar = ({ value, title }) => (
  <AverageScoreContainer>
    <Typography variant="subtitle2">{title}</Typography>
    <Typography variant="h2" mb={2}>
      {Math.floor(value * 100)}%
    </Typography>
    <CustomLinearProgress variant="determinate" value={value * 100} />
  </AverageScoreContainer>
);

export default AverageScoreBar;

AverageScoreBar.propTypes = {
  title: PropTypes.string.isRequired,
  value: PropTypes.number.isRequired,
};
