/* eslint-disable */
import React, { useState, useRef } from "react";
import PropTypes from "prop-types";
import { Tag } from "react-feather";
import styled from "styled-components/macro";
import { Chip, Popper } from "@material-ui/core";
import Inner from "./Inner";

const RoleChip = styled(Chip)`
  padding: 0px 5px;
  margin-right: 2px;
  border-radius: 4px;
  margin: 2px;
  max-width: 220px;
  color: black;
  background: #eeeeee;

  border: 1px solid
    ${(props) => {
      if (props.isactive === "Yes") {
        return "lightgray";
      }
      return props.theme.palette.grey[300];
    }};

  .MuiChip-deleteIconSmall {
    color: rgba(0, 0, 0, 0.4);
    height: 14px;
    width: 14px;

    &:hover {
      color: black;
    }
  }

  svg {
    color: black;
  }
`;

const ShowRoleChip = ({ name, id, shouldLinkToRolePage = true }) => {
  const [open, setOpen] = useState(false);
  const anchorRef = useRef(null);

  return (
    <>
      <RoleChip
        ref={anchorRef}
        isactive={open ? "Yes" : "No"}
        icon={<Tag style={{ height: 10, width: 10 }} />}
        label={name}
        size="small"
        onClick={() => setOpen((p) => !p)}
      />
      <Popper
        open={open}
        anchorEl={anchorRef.current}
        placement="bottom-end"
        style={{ zIndex: "10000000000" }}
      >
        <Inner
          id={id}
          name={name}
          setOpen={setOpen}
          shouldLinkToRolePage={shouldLinkToRolePage}
        />
      </Popper>
    </>
  );
};

export default ShowRoleChip;

ShowRoleChip.propTypes = {
  name: PropTypes.string.isRequired,
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
};

ShowRoleChip.defaultProps = {
  canDelete: false,
  deleteFunc: null,
  isRemoving: false,
};
