/* eslint-disable */
import React from "react";
import PropTypes from "prop-types";
import { User } from "react-feather";
import {
  CenterWithColor,
  StyledLink,
  CustomDatatableV2,
} from "../../../styles";
import { makeDefaultTableOptions } from "../../../../../storage/helpers";
import {
  retCompsByEngagementType,
  retSubsByEngagementType,
  retPassRateByEngagementType,
} from "../../utils/utils";
import {
  DEFINITIONS,
  returnDefinitionByEngagementType,
} from "../../../../../storage/constants";
import TableTitle from "../../../../../components/Table/TableTitle";
import SubmissionTypeChip from "../../../home/retail/SubmissionTypeChip";
import { Users } from "react-feather";
import CustomDataTableIcons from "../../../../../components/Table/CustomDataTableIcons";
import { useMediaQuery } from "@material-ui/core";

const UsersTable = ({ users, engagementType }) => {
  const isUnder600Px = useMediaQuery((theme) => theme.breakpoints.down("xs"));

  const options = makeDefaultTableOptions("users-at-location");
  options.rowsPerPage = 20;
  options.sortOrder = {
    name: "completions",
    direction: "desc",
  };
  if (isUnder600Px) {
    options.rowsPerPageOptions = [];
    options.jumpToPage = false;
  }

  const tableData = users.map((user) => ({
    id: user.user_id,
    name: `${user.first_name} ${user.last_initial}`,
    isFollower: user.is_follower,
    location: user.locations_info.map((loc) => loc.location).join(", "),
    completions: retCompsByEngagementType(user, engagementType),
    submissions: retSubsByEngagementType(user, engagementType),
    passRate: retPassRateByEngagementType(user, engagementType),
  }));

  const columns = [
    {
      name: "id",
      label: " ",
      options: {
        filter: false,
        sort: false,
        display: false,
        print: false,
        download: false,
      },
    },
    {
      name: "name",
      label: "User",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta) => (
          <StyledLink
            value={value}
            to={`/vendor-reports/user/${tableMeta.rowData[0]}`}
            CustomIcon={User}
          />
        ),
      },
    },
    {
      name: "location",
      label: "Location",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "isFollower",
      label: "Is Follower",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (val) => (
          <div style={{ display: "flex", alignItems: "center" }}>
            <div
              style={{
                height: 8,
                width: 8,
                borderRadius: 4,
                backgroundColor: val ? "#64d377" : "tomato",
                marginRight: 6,
              }}
            />
            <span>{val ? "Yes" : "No"}</span>
          </div>
        ),
      },
    },
    {
      name: "submissions",
      label: "Submissions",
      options: {
        filter: true,
        sort: true,
        hint: returnDefinitionByEngagementType("submissions", engagementType),
        customBodyRender: (val) => (
          <CenterWithColor engagementType={engagementType}>
            <span>{val}</span>
          </CenterWithColor>
        ),
      },
    },
    {
      name: "completions",
      label: "Completions",
      options: {
        filter: true,
        sort: true,
        hint: returnDefinitionByEngagementType("completions", engagementType),
        customBodyRender: (val) => (
          <CenterWithColor engagementType={engagementType}>
            <span>{val}</span>
          </CenterWithColor>
        ),
      },
    },
    {
      name: "passRate",
      label: "Pass Rate",
      options: {
        sort: true,
        filter: true,
        hint: DEFINITIONS.passRate,
        customBodyRender: (val) => (
          <CenterWithColor engagementType={engagementType}>
            <span>{val}%</span>
          </CenterWithColor>
        ),
      },
    },
  ];

  return (
    <CustomDatatableV2
      title={
        <TableTitle
          title="Users at this company"
          Icon={Users}
          isVendor
          submissionType={engagementType}
        />
      }
      options={options}
      columns={columns}
      data={tableData}
      components={{
        icons: CustomDataTableIcons,
      }}
    />
  );
};

export default UsersTable;

UsersTable.propTypes = {
  users: PropTypes.array.isRequired,
  engagementType: PropTypes.string.isRequired,
};
