/* eslint-disable */
import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components/macro";
import AnswerDistributionByEngagementType from "../../reuseableComponents/AnswerDistributionByEngagementType";

const AnswersDistributionWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-wrap: wrap;
  width: 100%;
`;

const AnswerBreakdown = ({ questions, engagementType }) => {
  return (
    <>
      <AnswersDistributionWrapper>
        {questions.map((question) => (
          <AnswerDistributionByEngagementType
            question={question}
            key={question.id}
            engagementType={engagementType}
          />
        ))}
      </AnswersDistributionWrapper>
    </>
  );
};

export default AnswerBreakdown;

AnswerBreakdown.propTypes = {
  questions: PropTypes.array.isRequired,
};
