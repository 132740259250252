/* eslint-disable */
import React, { useRef, useEffect, useState } from 'react';
import styled, { keyframes } from 'styled-components/macro';
import Vimeo from '@vimeo/player';
import { VIMEO_BASE_URL } from '../../storage/constants';


const fadeInOut = keyframes`
  0% {
    opacity: 0.2;
  }
  50% {
    opacity: 0.6;
  }
  100%{
    opacity: 0.2;
  }
`;

const HoldVideo = styled.div`
  border: 1px solid #EEEEEE;
  height: 320px;
  width: 480px;
  background-color: black;
  position: relative;
`;

const LoadingSpan = styled.span`
  color: lightgrey;
  position: absolute;
  top: 50%;
  left: 50%;
  font-size: 12px;
  transform: translate(-50%, -50%);
  animation: 1.4s ${fadeInOut} ease-out infinite;
`



const ShowVideo = ({ videoUrl, setSnackbarText }) => {

  const videoRef = useRef(null);
  const [showLoading, setShowLoading] = useState(true)

  useEffect(() => {
    const videoUrlFull = `${VIMEO_BASE_URL}${videoUrl}`;
    const vimeoOptions = {
      url: videoUrlFull,
      width: '478px',
      height: '318px',
      autoplay: true,
      loop: false,
    };

    const player = new Vimeo(videoRef.current, vimeoOptions);

    player.on('bufferend', () => setShowLoading(false) )
    player.on('error', () => setSnackbarText('Error Playing This Video'));
    
    return () => player.destroy()

  }, [])

  return (
    <HoldVideo>
      {
        showLoading && <LoadingSpan>Loading....</LoadingSpan>
      }
  
      <>
        <div ref={videoRef} id="video-player" />
      </>
    </HoldVideo>
  )
}

export default ShowVideo;
