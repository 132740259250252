/* eslint-disable */
import React, { useState, useMemo } from "react";
import PropTypes from "prop-types";
import styled from "styled-components/macro";
import { Modal, IconButton } from "@material-ui/core";
import {
  Maximize2,
  ChevronLeft,
  ChevronRight,
  DownloadCloud,
} from "react-feather";
import { Document, Page, pdfjs } from "react-pdf/dist/esm/entry.webpack";
import { Button, Typography } from "../../styles";
import PdfModal from "./PdfModal";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const PdfDocumentHolder = styled(Document)`
  border: 2px solid #eeeeee;
  position: relative;
`;

const PDFWrapper = styled.div`
  position: relative;
`;

const MaximizeTopRight = styled(IconButton)`
  position: absolute;
  top: 2px;
  right: 2px;
  z-index: 100;
  color: slategrey;

  svg {
    height: 16px;
    width: 16px;
  }

  :hover {
    cursor: pointer;

    svg {
      color: #337ab7;
    }
  }
`;

const PdfOutline = styled.div`
  height: 240px;
  width: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: white;
`;

const Download = styled.a`
  font-size: 10px;
  text-decoration: none;
  color: slategrey;
  margin-top: 2px;
  border: 1px solid #eeeeee;
  background-color: #eeeeee50;
  padding: 3px;
  border-radius: 3px;

  &:hover {
    text-decoration: underline;
    color: #337ab7;
  }
`;

const PdfLoadError = () => (
  <PdfOutline>
    <em>
      <strong>
        <p>Error Loading PDF</p>
      </strong>
    </em>
  </PdfOutline>
);

const PdfLoading = () => (
  <PdfOutline>
    <em>
      <strong>
        <p>Loading...</p>
      </strong>
    </em>
  </PdfOutline>
);

const PdfDocument = ({ media, allowDownload = false }) => {
  const { content } = media;
  const [pageNumber, setPageNumber] = useState(1);
  const [numberPages, setNumberPages] = useState(null);
  const [pdfExpanded, setPdfExpanded] = useState(false);

  return (
    <>
      <div>
        <PDFWrapper>
          <MaximizeTopRight onClick={() => setPdfExpanded(true)}>
            <Maximize2 />
          </MaximizeTopRight>
          <PdfDocumentHolder
            disableAnnotationLayer
            file={content}
            onLoadSuccess={({ numPages }) => setNumberPages(numPages)}
            noData="No Data"
            error={<PdfLoadError />}
            loading={<PdfLoading />}
          >
            <Page
              renderAnnotationLayer={false}
              pageNumber={pageNumber}
              height={300}
            />
          </PdfDocumentHolder>
          {allowDownload && (
            <>
              <div style={{ height: 4 }} />
              <Download href={content} download target="_blank">
                Download Pdf
              </Download>
            </>
          )}
        </PDFWrapper>
        {numberPages && (
          <>
            <Typography variant="body1">
              Page <strong>{pageNumber}</strong> of {numberPages}
            </Typography>
            <Button
              onClick={() => setPageNumber((n) => n - 1)}
              disabled={pageNumber === 1}
              color="secondary"
            >
              <ChevronLeft />
            </Button>
            <Button
              onClick={() => setPageNumber((n) => n + 1)}
              disabled={pageNumber === numberPages}
              color="secondary"
            >
              <ChevronRight />
            </Button>
          </>
        )}
      </div>
      <Modal
        open={pdfExpanded}
        onClose={() => setPdfExpanded(false)}
        aria-labelledby="Expand the Pdf"
        aria-describedby="Expand a Pdf"
      >
        <div>
          <PdfModal setPdfExpanded={setPdfExpanded} media={media} />
        </div>
      </Modal>
    </>
  );
};

export default PdfDocument;

PdfDocument.propTypes = {
  media: PropTypes.object.isRequired,
  allowDownload: PropTypes.bool,
};

PdfDocument.defaultProps = {
  allowDownload: null,
};
