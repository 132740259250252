import React from "react";
import PropTypes from "prop-types";
import ExplainStates from "./ExplainStates";
import ExplainActions from "./ExplainActions";
import { IntroText } from "./styles";
import FreqAskedModuleQuestions from "./FreqAskedModuleQuestions";

const Quizzes = ({ isRetail }) => (
  <>
    {isRetail && (
      <IntroText mb={4}>
        The Library is the hub where all your modules can be managed. If you
        create a module it will appear in the Custom Modules table. If you add a
        Module from the Marketplace, it will be added to the Vendor Modules
        table. If a vendor shares a module with you, it will appear in the
        Suggested Modules table. Modules that have been closed comprise the
        closed modules table.
      </IntroText>
    )}
    {!isRetail && (
      <IntroText mb={4}>
        The Library is the hub where all your modules can be managed. You can
        create modules for the marketplace or to suggest here. All your current
        modules are listed in the Custom Modules table. Modules that have been
        closed comprise the closed modules table.
      </IntroText>
    )}
    <ExplainStates isRetail={isRetail} />
    <ExplainActions isRetail={isRetail} />
    {isRetail && <FreqAskedModuleQuestions isRetail={isRetail} />}
  </>
);

export default Quizzes;

Quizzes.propTypes = {
  isRetail: PropTypes.bool.isRequired,
};
