/* eslint-disable */
import React, { useState } from "react";
import { Repeat } from "react-feather";
import API from "../../../../../axios/instances/API";
import { StyledItem } from "../styles";
import { QUIZSTATES, USER_TYPES } from "../../../../../storage/constants";

const Reopen = ({
  id,
  mutate,
  setSnackbarText,
  userType,
  moduleState,
  isRetailerModuleFromMarketplace,
  handleClose,
}) => {
  const [reopening, setReopening] = useState(false);

  if (moduleState !== QUIZSTATES.CLOSED) return null;

  if (![USER_TYPES.RETAIL_ADMIN, USER_TYPES.VENDOR].includes(userType)) {
    return null;
  }

  if (isRetailerModuleFromMarketplace) return null;

  const handleReopenErrorMsg = (error) => {
    if (error?.response?.data?.error?.details) {
      return `${error?.response?.data?.error?.details} error`;
    }

    return "Unable to reopen this module error";
  };

  const handleReopen = async () => {
    setReopening(true);
    try {
      await API.post("/dashboard/quiz/redeploy/", {
        id,
      });
      await mutate();
      setReopening(false);
      setSnackbarText("Successfully reopened module success");
      handleClose();
    } catch (error) {
      const msg = handleReopenErrorMsg(error);
      setReopening(false);
      setSnackbarText(msg);
      handleClose();
    }
  };

  return (
    <StyledItem onClick={handleReopen} disabled={reopening}>
      <Repeat />
      <span>{reopening ? "..." : "Reopen Module"}</span>
    </StyledItem>
  );
};

export default Reopen;
