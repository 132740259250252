/* eslint-disable */
import React, { useState, useContext } from "react";
import PropTypes from "prop-types";
import { Modal, Tooltip, useMediaQuery } from "@material-ui/core";
import { TrendingUp, List } from "react-feather";
import styled from "styled-components/macro";
import { StyledLink, CustomDatatableV2 } from "../styles";
import ReorderStream from "./ReorderStream";
import DeleteStreamModal from "./DeleteStreamModal";
import {
  fixIncomingDate,
  makeDefaultTableOptions,
} from "../../../storage/helpers";
import { QUIZSTATES } from "../../../storage/constants";
import DeleteButtonForTables from "../../../components/Table/DeleteButtonForTables";
import useQuizReview from "../../../swr/hooks/Library/useQuizReview";
import { GlobalState } from "../../../store/GlobalState";
import LibraryShowModule from "../library/components/LibraryShowModule";
import { addDays } from "date-fns";
import { makeStyles } from "@material-ui/core";
import TableTitle from "../../../components/Table/TableTitle";
import CustomDataTableIcons from "../../../components/Table/CustomDataTableIcons";
import DateTooltip from "../../../components/Table/DateTooltip";

const FlexDiv = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 2px;
`;

const InnerContainer = styled.div`
  width: 100%;
  background-color: white;
  border-radius: 4px;
  min-height: 100px;
  display: flex;
  padding: 4px;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
  border: 1px solid slategrey;
  min-height: 156px;
  height: 180px;

  span {
    color: black;
  }
`;

const MiniModWrapper = styled.div`
  height: 32px;
  width: 32px;
  border-radius: 2px;
  margin-right: 4px;
  border: 1px solid #eeeeee;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    cursor: pointer;
  }
`;

const CustomBadge = styled.div`
  position: absolute;
  top: 0px;
  right: 4px;
  background-color: ${(props) => {
    if (props.state === QUIZSTATES.LIVE)
      return props.theme.palette.success.light;
    if (props.state === QUIZSTATES.CONVERSION)
      return props.theme.palette.warning.dark;
    if (props.state === QUIZSTATES.CLOSED) return "tomato";
    if (props.state === QUIZSTATES.IN_DEVELOPMENT)
      return props.theme.palette.info.light;
    if (props.state === QUIZSTATES.PENDING)
      return props.theme.palette.warning.light;
    return "white";
  }};
  z-index: 100;
  height: 6px;
  width: 6px;
  border-radius: 2px;
`;

const useStyles = makeStyles(() => ({
  tooltip: {
    maxWidth: "none",
    minWidth: 300,
    maxWidth: 400,
    minHeight: "none",
    maxHeight: "none",
    borderRadius: 8,
    padding: 0,
  },
}));

const Inner = ({ id }) => {
  const { details: data, isLoading } = useQuizReview(id);
  const { state } = useContext(GlobalState);
  const { config } = state;

  if (isLoading) {
    return (
      <InnerContainer>
        <div
          style={{
            width: "100%",
            minWidth: 300,
            minHeight: 160,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <span style={{ color: "darkgrey" }}>loading...</span>
        </div>
      </InnerContainer>
    );
  }

  return (
    <InnerContainer>
      <LibraryShowModule
        srcString={data?.image}
        value={data?.name}
        marketplaceParentInfo={
          Object.values(data?.marketPlaceParent).length > 0
            ? { companyName: data?.marketPlaceParent?.company?.name }
            : null
        }
        config={config}
      />
      <FlexDiv>
        <span style={{ color: "black", fontWeight: "400", fontSize: 11 }}>
          Start date:
        </span>
        <span style={{ color: "black", fontSize: 11, fontWeight: "400" }}>
          {addDays(new Date(data?.startDate), 1).toDateString()}
        </span>
      </FlexDiv>
      <FlexDiv>
        <span style={{ color: "black", fontWeight: "400", fontSize: 11 }}>
          End date:
        </span>
        <span style={{ color: "black", fontSize: 11, fontWeight: "400" }}>
          {data.dueDate
            ? addDays(new Date(data?.dueDate), 1).toDateString()
            : "---/---"}
        </span>
      </FlexDiv>
      <FlexDiv>
        <span style={{ color: "black", fontWeight: "400", fontSize: 11 }}>
          Time to complete:
        </span>
        <span style={{ color: "black", fontSize: 11, fontWeight: "400" }}>
          {data?.estimatedTime} minutes
        </span>
      </FlexDiv>
      <FlexDiv>
        <span style={{ color: "black", fontWeight: "400", fontSize: 11 }}>
          Score to pass:
        </span>
        <span style={{ color: "black", fontSize: 11, fontWeight: "400" }}>
          {data?.passScore}/{data?.questions?.questions?.length}{" "}
        </span>
      </FlexDiv>
      <FlexDiv>
        <span style={{ color: "black", fontWeight: "400", fontSize: 11 }}>
          Has Description:
        </span>
        <span style={{ color: "black", fontSize: 11, fontWeight: "400" }}>
          {data?.description ? "Yes" : "No"}
        </span>
      </FlexDiv>

      <FlexDiv>
        <span style={{ color: "black", fontWeight: "400", fontSize: 11 }}>
          Has Disclaimer:
        </span>
        <span style={{ color: "black", fontSize: 11, fontWeight: "400" }}>
          {data?.disclaimer ? "Yes" : "No"}
        </span>
      </FlexDiv>
      <FlexDiv>
        <span style={{ color: "black", fontWeight: "400", fontSize: 11 }}>
          Answer Key Enabled:
        </span>
        <span style={{ color: "black", fontSize: 11, fontWeight: "400" }}>
          {data?.displayAnswers ? "Yes" : "No"}
        </span>
      </FlexDiv>
    </InnerContainer>
  );
};

const MiniModule = ({ mod }) => {
  const classes = useStyles();
  return (
    <>
      <Tooltip
        classes={{ tooltip: classes.tooltip }}
        title={<Inner id={mod.id} />}
        disabled={mod.state === "Closed"}
        arrow
        placement="top"
      >
        <div style={{ position: "relative" }}>
          <CustomBadge state={mod.state} />
          <MiniModWrapper
            style={{
              height: 38,
              width: 38,
              borderRadius: 4,
              marginRight: 4,
              border: "1px solid #EEEEEE",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              position: "relative",
              backgroundColor: "white",
            }}
          >
            <img
              style={{
                height: 36,
                width: 36,
                borderRadius: 4,
                objectFit: "contain",
                backgroundColor: "white",
              }}
              src={mod.imageUrl}
              alt="module"
            />
          </MiniModWrapper>
        </div>
      </Tooltip>
    </>
  );
};

MiniModule.propTypes = {
  mod: PropTypes.object.isRequired,
};

const StreamsTable = ({
  streams,
  setSnackbarText,
  mutate,
  readOnly,
  isRetail,
}) => {
  const [deleteInfo, setDeleteInfo] = useState(null);
  const isUnder600Px = useMediaQuery((theme) => theme.breakpoints.down("xs"));
  const numStreams = streams.length;

  const currentStreamOrder = streams
    .sort((first, second) => {
      return first.order - second.order;
    })
    .map((stream) => stream.id);

  const getStreamModulesById = (id) => {
    const stream = streams.filter((s) => s.id === id);
    return stream[0].quizzes;
  };

  const options = makeDefaultTableOptions("streams");
  options.sortOrder = {
    name: "created",
    direction: "desc",
  };
  options.rowsPerPage = 10;
  options.textLabels = {
    body: {
      noMatch:
        'No streams created for your team yet. Click on "Create New Stream" to get started.',
    },
  };

  if (isUnder600Px) {
    options.rowsPerPageOptions = [];
    options.jumpToPage = false;
  }

  const tableData = streams.map((stream) => ({
    id: stream.id,
    name: stream.name,
    created: new Date(stream.created).toISOString(),
    quizzes: stream.quizzes,
    order: stream.order,
  }));

  const columns = [
    {
      name: "id",
      label: "ID",
      options: {
        filter: false,
        sort: false,
        display: false,
      },
    },
    {
      name: "name",
      label: "Stream",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta) => {
          return (
            <StyledLink
              to={`/streams/details/${tableMeta.rowData[0]}`}
              value={value}
              CustomIcon={TrendingUp}
            />
          );
        },
      },
    },
    {
      name: "created",
      label: "Created",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value) => (
          <DateTooltip date={value} shouldLocalize={isRetail} />
        ),
      },
    },
    {
      name: "quizzes",
      label: "Modules",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value) => {
          const filteredMods = value.filter(
            (mod) => mod.state !== QUIZSTATES.CLOSED
          );

          if (!filteredMods || filteredMods.length === 0) {
            return (
              <span style={{ fontSize: 12, color: "darkgrey" }}>
                No modules
              </span>
            );
          }
          return (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-start",
                flexDirection: "row",
                gap: 4,
              }}
            >
              <div
                style={{
                  height: 42,
                  width: 220,
                  display: "flex",
                  alignItems: "flex-start",
                  justifyContent: "flex-start",
                  overflowY: "scroll",
                  flexDirection: "row",
                }}
              >
                {filteredMods.map((mod) => (
                  <MiniModule mod={mod} key={mod.id} />
                ))}
              </div>
            </div>
          );
        },
      },
    },

    {
      name: "order",
      label: "Feed Position",
      options: {
        filter: true,
        sort: true,
        hint: "Determines which streams show up first in users feed",
        customBodyRender: (value, tableMeta) => (
          <ReorderStream
            value={value}
            numStreams={numStreams}
            id={tableMeta.rowData[0]}
            currentStreamOrder={currentStreamOrder}
            readOnly={readOnly}
            setSnackbarText={setSnackbarText}
            mutate={mutate}
          />
        ),
      },
    },
    {
      name: "delete",
      label: "Delete",
      options: {
        sort: false,
        filter: false,
        empty: true,
        customBodyRender: (_, tableMeta) => (
          <DeleteButtonForTables
            disabled={readOnly}
            handleClick={(e) => {
              e.stopPropagation();
              const { rowData } = tableMeta;
              setDeleteInfo({
                id: rowData[0],
                name: rowData[1],
                modules: getStreamModulesById(rowData[0]),
              });
            }}
          />
        ),
      },
    },
  ];

  return (
    <>
      <CustomDatatableV2
        title={<TableTitle title="Streams List" Icon={List} />}
        data={tableData}
        options={options}
        columns={columns}
        components={{
          icons: CustomDataTableIcons,
        }}
      />
      <Modal
        open={Boolean(deleteInfo)}
        onClose={() => setDeleteInfo(null)}
        aria-labelledby="Delete Stream Modal"
        aria-describedby="Modal to Delete Stream"
      >
        <div>
          <DeleteStreamModal
            deleteInfo={deleteInfo}
            setDeleteInfo={setDeleteInfo}
            setSnackbarText={setSnackbarText}
            mutate={mutate}
            isRetail={isRetail}
          />
        </div>
      </Modal>
    </>
  );
};

export default StreamsTable;

StreamsTable.propTypes = {
  streams: PropTypes.array.isRequired,
  setSnackbarText: PropTypes.func.isRequired,
  mutate: PropTypes.func.isRequired,
  readOnly: PropTypes.bool.isRequired,
  isRetail: PropTypes.bool.isRequired,
};
