/* eslint-disable */
import { Button, Modal, Tooltip } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { ArrowRight } from 'react-feather';
import styled from 'styled-components/macro';
import '../../../../components/Company/devices.css';
import useQuizReview from '../../../../swr/hooks/Library/useQuizReview';
import BasicsComponent from './Basics';
import ContentComponent from './Content';
import MockHeader from './Mockheader';
import QuestionsComponent from './Questions';
import { Smartphone } from 'react-feather';

const NextButton = styled(Button)`
  width: 38px;
  height: 38px;
  max-height: 38px;
  max-width: 38px;
  min-height: 38px;
  min-width: 38px;
  background: #337ab7;
  position: absolute;
  bottom: 18px;
  right: 8px;
  border: 1px solid #eeeeee;

  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  cursor: pointer;

  &:hover {
    background-color: #156cb5;
  }
`;

const PreviewButton = styled(Button)`
  height: 34px;
  width: 34px;
  min-height: 34px;
  min-width: 34px;
  max-height: 34px;
  min-width: 34px;
  border-radius: 6px;
  background: white;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid lightgray;

  svg {
    color: slategrey;
    transition: color 200ms ease-in;
  }

  &:hover {
    svg {
      color: black;
    }
  }
`;

const steps = {
  0: {
    num: 0,
    name: 'Overview',
    Component: BasicsComponent,
  },
  1: {
    num: 1,
    name: 'Content',
    Component: ContentComponent,
  },
  2: {
    num: 2,
    name: 'Questions',
    Component: QuestionsComponent,
  },
};

const Preview = ({ id }) => {
  const [open, setOpen] = useState(false);
  const [step, setStep] = useState(0);
  const { details: data, isLoading, isError, mutate } = useQuizReview(id);
  const [questionIndex, setQuestionIndex] = useState(0);
  const [contentIndex, setContentIndex] = useState(0);

  useEffect(() => {
    if (isLoading) return null;

    if (open) {
      mutate();
    }
  }, [open, isLoading]);

  if (isLoading) return null;

  const Component = steps[step].Component;

  const numQuestions = data?.questions?.questions.length;

  const hasMultipleContentPeices = Boolean(data?.media && data?.video);

  const handleGoForward = () => {
    if (step === 2) {
      if (numQuestions === 0) return null;

      if (questionIndex !== numQuestions - 1) {
        return setQuestionIndex(p => p + 1);
      }

      if (questionIndex === numQuestions - 1) {
        return null;
      }
    }

    if (step === 1 && hasMultipleContentPeices && contentIndex === 0) {
      return setContentIndex(1);
    }

    setStep(p => p + 1);
  };

  const handleGoBack = () => {
    if (step === 1 && hasMultipleContentPeices && contentIndex === 1) {
      return setContentIndex(0);
    }

    if (step === 2 && questionIndex !== 0) {
      return setQuestionIndex(p => p - 1);
    }

    setStep(p => p - 1);
  };

  return (
    <>
      <Tooltip
        title="Preview what this module will look like in the app"
        arrow
      >
        <PreviewButton onClick={() => setOpen(true)}>
          <Smartphone
            height={14}
            width={14}
          />
        </PreviewButton>
      </Tooltip>
      <Modal
        open={open}
        onClose={() => setOpen(false)}
      >
        <div
          style={{
            height: 690,
            width: 340,
            background: 'white',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            borderRadius: 12,
            border: '1px solid black',
            flexDirection: 'column',
            position: 'absolute',
            left: '50%',
            top: '50%',
            transform: 'translate(-50%, -50%)',
          }}
        >
          <div className="md-iphone-5 md-black-device">
            <div className="md-body">
              <div className="md-buttons" />
              <div className="md-front-camera" />
              <div className="md-screen">
                <div
                  style={{
                    height: '100%',
                    width: '100%',
                    background: 'white',
                    display: 'flex',
                    alignItems: 'flex-start',
                    flexDirection: 'column',
                    justifyContent: 'flex-start',
                  }}
                >
                  <MockHeader
                    step={step}
                    data={data}
                    handleGoBack={handleGoBack}
                  />
                  <Component
                    data={data}
                    questionIndex={questionIndex}
                    setQuestionIndex={setQuestionIndex}
                    contentIndex={contentIndex}
                    hasMultipleContentPeices={hasMultipleContentPeices}
                  />
                  <NextButton onClick={handleGoForward}>
                    <ArrowRight
                      height={18}
                      width={18}
                      color="white"
                    />
                  </NextButton>
                  <div style={{ height: 10, width: '100%', background: '#eeeeee' }} />
                </div>
              </div>
            </div>
          </div>
          {/* <div
            style={{
              width: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              gap: 18,
              height: 40,
              marginTop: 12,
              marginBottomn: 8,
            }}
          >
            <CustomButton
              onClick={() => setStep((p) => p - 1)}
              disabled={step === 0}
            >
              <ChevronLeft />
            </CustomButton>
            <span
              style={{ fontWeight: "500", width: 100, textAlign: "center" }}
            >
              {steps[step]?.name}
            </span>
            <CustomButton
              onClick={() => setStep((p) => p + 1)}
              disabled={step === 2}
            >
              <ChevronRight />
            </CustomButton>
          </div> */}
        </div>
      </Modal>
    </>
  );
};

export default Preview;
