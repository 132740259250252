import React from 'react';
import styled from 'styled-components/macro';
import { Typography } from '../../../pages/private/styles';
import { IntroText } from '../styles';

const Wrapper = styled.div`
  margin-left: 12px;
`;

const Questions = () => (
  <Wrapper>
    <Typography variant="subtitle2">
      How many questions should I include?
    </Typography>
    <IntroText
      mt={1}
      mb={6}
    >
      While there is a minimum of one question, there is no cap on the number of questions.
      Keep in mind we at Spiffy believe microlearning to be the highest impact style of learning,
      and usually 3-5 questions will suffice.
    </IntroText>
    <Typography variant="subtitle2">
      Can i edit my questions later?
    </Typography>
    <IntroText
      mt={1}
      mb={6}
    >
      If your module is not yet live, you can edit or delete any question.
      If the module is already live, you can only edit the text of the question options,
      you cannot change the answer key, delete questions or change the actual question itself.
    </IntroText>
  </Wrapper>
);

export default Questions;
