/* eslint-disable */
import React from "react";
import { BrowserRouter as Router } from "react-router-dom";
import ReactDOM from "react-dom";
import * as Sentry from "@sentry/react";
import { HelmetProvider, Helmet } from "react-helmet-async";
import { SWRConfig } from "swr";
import { ThemeProvider } from "styled-components/macro";
import "./css/index.css";
import {
  ThemeProvider as MuiThemeProvider,
  StylesProvider,
  jssPreset,
} from "@material-ui/core/styles";
import { create } from "jss";
import createTheme from "./theme";
import App from "./App";
import { THEMES } from "./storage/constants";
import GlobalStateProvider from "./store/GlobalState";

const jss = create({
  ...jssPreset(),
  insertionPoint: document.getElementById("jss-insertion-point"),
}); // TODO -> Investigate this a bit, do i need jss

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
});

ReactDOM.render(
  <React.StrictMode>
    <HelmetProvider>
      <Helmet defaultTitle="Spiffy" />
      <GlobalStateProvider>
        <StylesProvider jss={jss}>
          <MuiThemeProvider theme={createTheme(THEMES.DEFAULT)}>
            <ThemeProvider theme={createTheme(THEMES.DEFAULT)}>
              <SWRConfig value={{ errorRetryCount: 2 }}>
                <Router>
                  <App />
                </Router>
              </SWRConfig>
            </ThemeProvider>
          </MuiThemeProvider>
        </StylesProvider>
      </GlobalStateProvider>
    </HelmetProvider>
  </React.StrictMode>,
  document.getElementById("root")
);
