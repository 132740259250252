/* eslint-disable */
import React from "react";
import { Cast } from "react-feather";
import { StyledItem } from "../styles";
import {
  USER_TYPES,
  QUIZSTATES,
  LIBRARY_MODAL_KEYS,
} from "../../../../../storage/constants";

const EditAudience = ({
  userType,
  moduleState,
  isRetailerModuleFromMarketplace,
  id,
  setModalState,
  handleClose,
  isUserAManagerWhoCreatedModule,
  moduleName,
}) => {
  if (
    ![QUIZSTATES.LIVE, QUIZSTATES.PENDING, QUIZSTATES.IN_DEVELOPMENT].includes(
      moduleState
    )
  ) {
    return null;
  }

  if (
    isRetailerModuleFromMarketplace &&
    moduleState === QUIZSTATES.IN_DEVELOPMENT &&
    // userType !== USER_TYPES.RETAIL_MANAGER_CAN_DEPLOY &&
    !isUserAManagerWhoCreatedModule
  ) {
    return null;
  }

  if (userType === USER_TYPES.RETAIL_MANAGER_CANT_DEPLOY) return null;

  if (
    !isRetailerModuleFromMarketplace &&
    userType !== USER_TYPES.RETAIL_MANAGER_CAN_DEPLOY
  ) {
    /// Managers with deploy access can edit a live custom audience.
    /// Admins will not, as they already have  the Edit Module capability available
    return null;
  }

  if (isUserAManagerWhoCreatedModule && !isRetailerModuleFromMarketplace) {
    return null;
  }

  if (
    isRetailerModuleFromMarketplace &&
    userType === USER_TYPES.RETAIL_MANAGER_CAN_DEPLOY &&
    moduleState === QUIZSTATES.IN_DEVELOPMENT
  ) {
    return null;
  }

  return (
    <StyledItem
      onClick={() => {
        setModalState({
          key: LIBRARY_MODAL_KEYS.EDIT_AUDIENCE,
          id,
          moduleName,
        });
        handleClose();
      }}
    >
      <Cast />
      <span>Edit Audience</span>
    </StyledItem>
  );
};

export default EditAudience;
