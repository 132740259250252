/* eslint-disable  */
import React from "react";
import SubmissionTypeChip from "../pages/private/home/retail/SubmissionTypeChip";
import styled, { keyframes } from "styled-components/macro";
import { Paper } from "@material-ui/core";
import ExtraInfo from "./ExtraInfo";

const fadeInRight = keyframes`
  0% {
    opacity: 0;
    transform: translateX(20px) scale(.85);
  }
  100% {
    opacity: 1;
    transform: translateX(0px) scale(1);
  }
`;

const SmallBlockWideBox = styled(Paper)`
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  border-radius: 4px;
  flex-basis: 32%;
  height: 80px;
  flex-grow: 1;
  min-width: 260px;
  padding: 16px 24px;

  p {
    font-size: 15px;
    color: darkgrey;
    font-weight: 500;
    animation: 300ms ${fadeInRight} ease-out;
    margin-right: 4px;
  }
`;

const StatSpan = styled.span`
  font-size: 36px;
  font-weight: 800;
  color: rgb(51, 122, 183);
  animation: 300ms ${fadeInRight} ease-out;
`;

const SmallBlockWide = ({
  title,
  stat,
  Icon,
  engagementType,
  definition = null,
}) => (
  <SmallBlockWideBox>
    <div>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-start",
        }}
      >
        <Icon
          style={{
            color: "black",
            height: 24,
            width: 24,
            marginRight: 10,
            marginBottom: 2,
          }}
        />
        {engagementType ? (
          <SubmissionTypeChip submissionType={engagementType} small isVendor />
        ) : (
          <div />
        )}
      </div>
      <div
        style={{
          display: "flex",
          alignItems: "flex-start",
          justifyContent: "center",
        }}
      >
        <p>{title}</p>
        {definition ? <ExtraInfo text={definition} /> : <div />}
      </div>
    </div>
    <StatSpan>{stat}</StatSpan>
  </SmallBlockWideBox>
);

export default SmallBlockWide;
