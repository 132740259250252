/* eslint-disable */
import React from 'react';
import { DownloadCloud, Filter, Printer, Search } from 'react-feather';
import styled from 'styled-components/macro';

const IconContainer = styled.div`
  height: 28px;
  width: 28px;
  min-width: 28px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #eeeeee;

  svg {
    height: 14px;
    width: 14px;
    color: black;
  }

  &:hover {
    border: 1px solid lightgray;
  }

  .MuiIconButton-root {
    padding: 0px;
  }
`;

const CustomDataTableIcons = {
  SearchIcon: () => (
    <IconContainer>
      <Search />
    </IconContainer>
  ),
  DownloadIcon: () => (
    <IconContainer>
      <DownloadCloud />
    </IconContainer>
  ),
  PrintIcon: () => (
    <IconContainer>
      <Printer />
    </IconContainer>
  ),
  FilterIcon: () => (
    <IconContainer>
      <Filter />
    </IconContainer>
  ),
};

export default CustomDataTableIcons;
