/* eslint-disable */
import React from "react";
import { Checkbox } from "@material-ui/core";
import styled from "styled-components/macro";
import useAudienceToolStore from "../../../store/useAudienceToolStore";
import LockIndicator from "./LockIndicator";
import CheckedIcon from "./CheckedIcon";
import { TABLE_STATES } from "./helpers";

const StyledCheckbox = styled(Checkbox)`
  background-color: transparent;
  color: slategrey;
  height: 28px;
  width: 28px;

  &.Mui-checked {
    color: #2c61ac;
    background-color: transparent;
  }
`;

const CellContainer = styled.td`
  width: 60px;
  max-width: 120px;
  position: relative;
`;

const UncheckedIcon = () => (
  <div
    style={{
      height: 14,
      width: 14,
      minWidth: 14,
      minHeight: 14,
      borderRadius: 4,
      background: "lightgray",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    }}
  />
);

const CellCheckbox = ({ checked, index, id }) => {
  const tableState = useAudienceToolStore((state) => state.tableState);
  const isDisplayOnly = useAudienceToolStore((state) => state.isDisplayOnly);
  const toggleCellCheck = useAudienceToolStore(
    (state) => state.toggleCellCheck
  );
  const cellLocationId = useAudienceToolStore(
    (state) => state.rows[id][index].locationId
  );

  return (
    <StyledCheckbox
      size="small"
      checked={checked}
      disabled={
        [TABLE_STATES.LOCKED, TABLE_STATES.SAVING].includes(tableState) ||
        isDisplayOnly
      }
      icon={<UncheckedIcon />}
      checkedIcon={<CheckedIcon />}
      onChange={(e) => toggleCellCheck(e, cellLocationId, index)}
    />
  );
};

const Cell = ({ id, index }) => {
  const isCellChecked = useAudienceToolStore(
    (state) => state.rows[id][index].checked
  );

  return (
    <CellContainer>
      <LockIndicator />
      <div
        style={{
          backgroundColor: isCellChecked ? "#eeeeee34" : "#eeeeee10",
          display: "flex",
          borderBottom: "1px solid #eeeeee",
          borderLeft: `1px solid ${index === 0 ? "lightgray" : "#eeeeee"}`,
          padding: 2,
          alignItems: "center",
          justifyContent: "center",
          flexDirection: "column",
          position: "relative",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          height: 34,
          width: "100%",
        }}
      >
        <CellCheckbox id={id} checked={isCellChecked} index={index} />
      </div>
    </CellContainer>
  );
};

export default Cell;
