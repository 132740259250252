/* eslint-disable */
import React from "react";
import PropTypes from "prop-types";
import { Wrapper } from "../auth/styles";
import CreateCompanyForm from "./CreateCompanyForm";
import DashboardSignupHeader from "./DashboardSignupHeader";
import SearchForCompanyForm from "./SearchForCompanyForm";

const SelectWorkplace = ({ dispatch, ACTIONS, signupState }) => {
  const { cantFindCompany } = signupState;
  return (
    <Wrapper>
      <DashboardSignupHeader
        handleBack={() =>
          cantFindCompany
            ? dispatch({ type: ACTIONS.RETURN_TO_FIND_COMPANY })
            : dispatch({ type: ACTIONS.RETURN_TO_BASICS_FORM })
        }
        title={cantFindCompany ? "Create A Company" : "Where Do You Work"}
        subtitle={
          cantFindCompany
            ? "If you can't find your company, create one"
            : "Search for the company you work at"
        }
      />
      {cantFindCompany ? (
        <CreateCompanyForm
          dispatch={dispatch}
          ACTIONS={ACTIONS}
          signupState={signupState}
        />
      ) : (
        <SearchForCompanyForm
          dispatch={dispatch}
          ACTIONS={ACTIONS}
          signupState={signupState}
        />
      )}
    </Wrapper>
  );
};

export default SelectWorkplace;

SelectWorkplace.propTypes = {
  dispatch: PropTypes.func.isRequired,
  ACTIONS: PropTypes.object.isRequired,
  signupState: PropTypes.object.isRequired,
};
