/* eslint-disable */
import React from "react";
import { StyledItem } from "../styles";
import { QUIZSTATES, USER_TYPES } from "../../../../../storage/constants";
import { BarChart } from "react-feather";
import { useHistory } from "react-router-dom";

const ViewProgress = ({ id, userType, moduleState }) => {
  const history = useHistory();

  if (![QUIZSTATES.LIVE, QUIZSTATES.CLOSED].includes(moduleState)) {
    return null;
  }

  if (
    [
      USER_TYPES.RETAIL_MANAGER_CANT_DEPLOY,
      USER_TYPES.RETAIL_MANAGER_CAN_DEPLOY,
    ].includes(userType)
  ) {
    return null;
  }

  return (
    <StyledItem onClick={() => history.push(`/modules/progress/${id}`)}>
      <BarChart />
      <span>
        {moduleState === QUIZSTATES.LIVE
          ? "View Progress"
          : "Review Completions"}
      </span>
    </StyledItem>
  );
};

export default ViewProgress;
