/* eslint-disable */
import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components/macro";
import { Avatar, Typography } from "@material-ui/core";

const ListWrapper = styled.div`
  width: 100%;
`;

const NumAvatar = styled(Avatar)`
  background-color: ${(props) => props.theme.palette.primary.main};

  color: white;
  margin-right: 8px;
  width: 22px;
  height: 22px;
  font-size: 13px;
  font-weight: 500;
  border-radius: 4px;
`;

const ItemHolder = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  padding: 8px 0px;
`;

const FlexDiv = styled.div`
  padding: 10px 0px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
`;

const AboutList = ({ listItems }) => (
  <ListWrapper>
    {listItems.map((item, index) => (
      <ItemHolder key={item.title}>
        <FlexDiv>
          <NumAvatar size="small">{index + 1}</NumAvatar>
          <Typography variant="h6">
            {item.title} {""}
          </Typography>
        </FlexDiv>
        <Typography variant="body1" color="textSecondary">
          {item.body}
        </Typography>
      </ItemHolder>
    ))}
  </ListWrapper>
);

export default AboutList;

AboutList.propTypes = {
  listItems: PropTypes.array.isRequired,
};
