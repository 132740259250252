/* eslint-disable */
import { Button, MenuItem, TextField } from "@material-ui/core";
import React, { useState } from "react";
import { DownloadCloud } from "react-feather";
import styled from "styled-components/macro";
import useAllCountries from "../../../../swr/hooks/Register/useAllCountries";
import useCitiesByState from "../../../../swr/hooks/Register/useCitiesByState";
import useStatesByCountry from "../../../../swr/hooks/Register/useStatesByCountry";
import { createWorkbookForCsvLocations } from "../../teamMembers/InviteTeamMembersViaCsv/utils";

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 6px;
  padding-top: 8px;
`;

const CountryAndStateFields = () => {
  const [selectedCountry, setSelectedCountry] = useState("");
  const [selectedState, setSelectedState] = useState("");

  const { data, isLoading } = useAllCountries();

  const { data: stateData, isLoading: stateLoading } =
    useStatesByCountry(selectedCountry);

  const { data: citiesData, isLoading: citiesLoading } =
    useCitiesByState(selectedState);

  return (
    <>
      <Wrapper>
        <TextField
          select
          label="Country"
          fullWidth
          variant="outlined"
          disabled={isLoading}
          size="small"
          color="secondary"
          value={isLoading ? "" : selectedCountry}
          onChange={(e) => {
            setSelectedState("");
            setSelectedCountry(e.target.value);
          }}
        >
          {!isLoading &&
            data?.map((country) => (
              <MenuItem key={country?.name} value={country?.code}>
                {country?.name}
              </MenuItem>
            ))}
          <MenuItem></MenuItem>
        </TextField>
        <TextField
          select
          disabled={stateLoading}
          label="State/Province"
          fullWidth
          value={stateLoading ? "" : selectedState}
          variant="outlined"
          size="small"
          color="secondary"
          onChange={(e) => setSelectedState(e.target.value)}
        >
          {!stateLoading &&
            stateData?.map((state) => (
              <MenuItem key={state.id} value={state.id}>
                {state.name}
              </MenuItem>
            ))}
          {!stateLoading && stateData?.length === 0 && (
            <MenuItem disabled value={null}>
              -- No states available --
            </MenuItem>
          )}
        </TextField>
      </Wrapper>
      <div style={{ height: 6 }} />
      <Button
        variant="outlined"
        fullWidth
        disabled={!selectedCountry || !selectedState || citiesLoading}
        startIcon={<DownloadCloud height={12} width={12} />}
        onClick={() =>
          createWorkbookForCsvLocations(
            data.find((country) => country.code === selectedCountry)?.name,
            stateData.find((state) => state.id === selectedState)?.name,
            citiesData
          )
        }
      >
        Download Template
      </Button>
    </>
  );
};

export default CountryAndStateFields;
