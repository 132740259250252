/* eslint-disable */
/* eslint-disable no-nested-ternary */
/* eslint-disable max-len */
/* eslint-disable arrow-body-style */
import React from "react";
import PropTypes from "prop-types";
import { User, Download } from "react-feather";
import {
  CustomDatatableV2,
  SubmissionChipAssignedTooltip,
  SubmissionChipExtraCreditTooltip,
  StyledLink,
} from "../../../styles";
import { makeDefaultTableOptions } from "../../../../../storage/helpers";
import useSingleLocationSubmissions from "../../../../../swr/hooks/VendorReports/useSingleLocationSubmissions";
import { ENGAGEMENT_TYPES } from "../../../../../storage/constants";
import SubmissionResultIndicator from "../../../../../components/Table/SubmissionResultIndicator";
import TableTitle from "../../../../../components/Table/TableTitle";
import CustomDataTableIcons from "../../../../../components/Table/CustomDataTableIcons";
import { useMediaQuery } from "@material-ui/core";
import DateTooltip from "../../../../../components/Table/DateTooltip";
import DisplayScore from "../../../../../components/UI/DisplayScore";

const getTableData = (submissions, engagementType) => {
  const filteredSubmissions =
    engagementType === ENGAGEMENT_TYPES.ASSIGNED
      ? submissions.filter((sub) => !sub.is_follower_submission)
      : engagementType === ENGAGEMENT_TYPES.EXTRACREDIT
      ? submissions.filter((sub) => sub.is_follower_submission)
      : submissions;

  return filteredSubmissions.map((submission) => {
    return {
      id: submission.id,
      date: submission.created,
      userId: submission.user_id,
      name: submission?.users_name,
      module: submission.quiz_name,
      type: submission.is_follower_submission
        ? ENGAGEMENT_TYPES.EXTRACREDIT
        : ENGAGEMENT_TYPES.ASSIGNED,
      score: `${submission.score}/${submission.questions}`,
      passed: submission.passed ? "Yes" : "No",
    };
  });
};

const SubmissionsTable = ({ locationId, locationName, engagementType }) => {
  const isUnder600Px = useMediaQuery((theme) => theme.breakpoints.down("xs"));

  const { isLoading, isError, data } = useSingleLocationSubmissions(locationId);

  if (isLoading) return <span>loading...</span>;

  if (isError) return null;

  const options = makeDefaultTableOptions(`${locationName}-submissions`);
  options.sortOrder = {
    name: "date",
    direction: "desc",
  };
  options.rowsPerPage = 10;
  if (isUnder600Px) {
    options.rowsPerPageOptions = [];
    options.jumpToPage = false;
  }

  const tableData = getTableData(data.submissions, engagementType);

  const columns = [
    {
      name: "id",
      label: " ",
      options: {
        filter: false,
        sort: false,
        display: false,
        print: false,
        download: false,
      },
    },
    {
      name: "userId",
      label: " ",
      options: {
        filter: false,
        sort: false,
        display: false,
        print: false,
        download: false,
      },
    },
    {
      name: "date",
      label: "Date",
      options: {
        filter: true,
        customBodyRender: (val) => (
          <DateTooltip date={val} />
          // <span>{formatDateWithTime(new Date(val))}</span>
        ),
      },
    },
    {
      name: "name",
      label: "User",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta) => (
          <StyledLink
            value={value}
            to={`/vendor-reports/user/${tableMeta.rowData[1]}`}
            CustomIcon={User}
          />
        ),
      },
    },
    {
      name: "module",
      label: "Module",
    },

    {
      name: "type",
      label: "Submission Type",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (val) =>
          val === ENGAGEMENT_TYPES.ASSIGNED ? (
            <SubmissionChipAssignedTooltip />
          ) : (
            <SubmissionChipExtraCreditTooltip isVendor />
          ),
      },
    },
    {
      name: "score",
      label: "Score",
      options: {
        customBodyRender: (value) => <DisplayScore value={value} />,
      },
    },
    {
      name: "passed",
      label: "Passed",
      options: {
        display: true,
        download: true,
        print: true,
        customBodyRender: (value) => (
          <SubmissionResultIndicator value={value} />
        ),
      },
    },
  ];

  return (
    <CustomDatatableV2
      title={
        <TableTitle
          Icon={Download}
          title="Submissions at this location"
          isVendor
          submissionType={engagementType}
        />
      }
      options={options}
      columns={columns}
      data={tableData}
      components={{
        icons: CustomDataTableIcons,
      }}
    />
  );
};

export default SubmissionsTable;

SubmissionsTable.propTypes = {
  locationId: PropTypes.number.isRequired,
  locationName: PropTypes.string.isRequired,
  engagementType: PropTypes.string.isRequired,
};
