/* eslint-disable */
import React from 'react';
import PropTypes from 'prop-types';

const TabPanel = ({ children, value, index, ...other }) => (
  <div
    role="tabpanel"
    hidden={value !== index}
    {...other}
    style={{ width: '100%' }}
  >
    {value === index && <div>{children}</div>}
  </div>
);

export default TabPanel;

TabPanel.propTypes = {
  children: PropTypes.object.isRequired,
  other: PropTypes.any,
  value: PropTypes.number.isRequired,
  index: PropTypes.number.isRequired,
};

TabPanel.defaultProps = {
  other: null,
};
