/* eslint-disable */
import React from "react";

const SubmissionResultIndicator = ({ value }) => {
  if (value === "Yes") {
    return (
      <div
        style={{
          backgroundColor: "mediumseagreen",
          borderRadius: 4,
          padding: "0px 4px",
          width: "fit-content",
          width: 38,
          height: 20,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <span style={{ color: "white", fontSize: 9, fontWeight: "600" }}>
          PASS
        </span>
      </div>
    );
  }

  return (
    <div
      style={{
        backgroundColor: "lightgray",
        borderRadius: 4,
        padding: "0px 4px",
        width: "fit-content",
        height: 20,
        width: 38,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <span style={{ color: "black", fontSize: 9, fontWeight: "600" }}>
        FAIL
      </span>
    </div>
  );
};

export default SubmissionResultIndicator;
