/* eslint-disable */
import useSWR from "swr";
import API from "../../../axios/instances/API";

const fetcher = async (url) => {
  const { data } = await API.get(url);
  return data;
};

const useInactiveTeamMembers = (page, pageSize, orderBy, search) => {
  const searchActive = search && search.length > 2;

  let URL = `/dashboard/teams/inactive/?page=${page}&page_size=${pageSize}&order_by=${orderBy}`;

  if (searchActive) {
    URL = URL + `&search=${search}`;
  }

  const { data, error, mutate } = useSWR(URL, fetcher, {
    errorRetryCount: 1,
  });

  return {
    data,
    isLoading: !error && !data,
    isError: error,
    mutate,
  };
};

export default useInactiveTeamMembers;
