/* eslint-disable */
import useSWR from 'swr';
import API from '../../../axios/instances/API';

const fetcher = async (url, stateId) => {
  const { data } = await API.get(`${url}${stateId}`);
  return data.cities;
};

const useCitiesByState = stateId => {
  const { data, error, mutate } = useSWR(stateId ? ['dashboard/address/cities/?state_id=', stateId] : null, fetcher, {
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
    revalideIfStale: false,
  });

  return {
    data,
    isLoading: !error && !data,
    isError: error,
    mutate,
  };
};

export default useCitiesByState;