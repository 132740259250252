/* eslint-disable */
import React from "react";
import useAudienceToolStore from "../../../store/useAudienceToolStore";
import { Lock } from "react-feather";
import { TABLE_STATES } from "./helpers";

const LockIndicator = () => {
  const tableState = useAudienceToolStore((state) => state.tableState);
  const isDisplayOnly = useAudienceToolStore((state) => state.isDisplayOnly);

  if (isDisplayOnly || tableState === TABLE_STATES.LOCKED) {
    return (
      <Lock
        style={{ position: "absolute", top: 4, left: 4, color: "lightgray" }}
        height={8}
        width={8}
      />
    );
  }

  return null;
};

export default LockIndicator;
