/* eslint-disable */
import React, { useContext, useEffect } from "react";
import styled from "styled-components/macro";
import useAudienceToolStore from "../../../store/useAudienceToolStore";
import TableModals from "./TableModals";
import { getUserType } from "../../../storage/helpers";
import { GlobalState } from "../../../store/GlobalState";
import ATT from "./ATT";
import SnackTest from "./AudienceSnack";
import AudienceSelector from "./AudienceSelector";

const Wrapper = styled.div`
  min-height: 340px;
  height: 100%;
  width: 100%;
  display: flex;
  padding: 4px;
  margin-top: 4px;
  align-items: center;
  justify-content: flex-start;
`;

const AudienceTool = ({
  moduleId,
  isTemplate = false,
  templateId = null,
  templateAudience = null,
}) => {
  const { state } = useContext(GlobalState);
  const { config } = state;
  const initialize = useAudienceToolStore((state) => state.initialize);
  const userType = getUserType(config);

  const initialLoad = useAudienceToolStore((state) => state.initialLoad);
  const displayAudienceSelect = useAudienceToolStore(
    (state) => state.displayAudienceSelect
  );

  const resetStateToInitial = useAudienceToolStore(
    (state) => state.resetStateToInitial
  );

  useEffect(() => {
    initialize(moduleId, userType, isTemplate, templateId, templateAudience);

    return () => resetStateToInitial();
  }, [moduleId, userType, templateId]);

  if (initialLoad)
    return (
      <>
        <Wrapper>
          <div
            style={{
              height: "100%",
              width: "100%",
              minWidth: 580,
              background: "#eeeeee64",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              minHeight: 340,
              borderRadius: 8,
            }}
          >
            <span style={{ color: "lightgray" }}>loading..</span>
          </div>
        </Wrapper>
      </>
    );

  return (
    <>
      {displayAudienceSelect ? <AudienceSelector /> : <ATT />}
      <div style={{ height: 12 }} />
      <TableModals />
      <SnackTest />
    </>
  );
};

export default AudienceTool;
