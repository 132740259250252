/* eslint-disable */
import React, { useState, useContext } from "react";
import { Modal, useMediaQuery } from "@material-ui/core";
import { CustomDatatableV2 } from "../styles";
import {
  makeDefaultTableOptions,
  fixIncomingDate,
  getConfig,
} from "../../../storage/helpers";
import useAccessRequestLists from "../../../swr/hooks/DashboardAcceessRequest/useAccessRequestLists";
import TableLoader from "../../../components/Loaders/TableLoader";
import API from "../../../axios/instances/API";
import { CheckCircle, Feather } from "react-feather";
import ConfirmModalSmall from "../../../components/Modals/ConfirmModalSmall";
import AcceptDarModal from "../../../components/Modals/AcceptDarModal";
import { GlobalState } from "../../../store/GlobalState";
import ACTIONS from "../../../store/actions";
import DeleteButtonForTables from "../../../components/Table/DeleteButtonForTables";
import ActionButtonForTables from "../../../components/Table/ActionButtonForTables";
import TableTitle from "../../../components/Table/TableTitle";
import CustomDataTableIcons from "../../../components/Table/CustomDataTableIcons";
import DateTooltip from "../../../components/Table/DateTooltip";

const ACCESS_LEVELS = {
  READ_ONLY: "Read Only",
  ADMIN: "Admin",
  READ_ONLY_ORGANZINATION: "Read Only Organization Admin",
  ORGANIZATION: "Organization Admin",
  SUPERVISOR: "Supervisor",
};

const AccessRequestsTable = ({ setSnackbarText }) => {
  const { dispatch } = useContext(GlobalState);

  const [confirmInfo, setConfirmInfo] = useState(null);
  const [acceptInfo, setAcceptInfo] = useState(null);
  const { data, isLoading, mutate } = useAccessRequestLists("M");
  const isUnder600Px = useMediaQuery((theme) => theme.breakpoints.down("xs"));

  if (isLoading) return <TableLoader />;

  const options = makeDefaultTableOptions("pending-supervisors");
  options.sortOrder = {
    name: "created",
    direction: "desc",
  };
  options.textLabels = {
    body: {
      noMatch:
        "There are no pending requests for supervisor access. You can promote existing users from the team members page as supervisors or invite new ones from the button above!",
    },
  };
  options.rowsPerPage = 50;

  if (isUnder600Px) {
    options.rowsPerPageOptions = [];
    options.jumpToPage = false;
  }

  const handleRejectRequest = async () => {
    try {
      await API.delete("/dashboard/dashboard-access-request/details/", {
        data: {
          id: confirmInfo?.id,
        },
      });
      await mutate();
      const config = await getConfig();
      dispatch({ type: ACTIONS.UPDATE_CONFIG, payload: config });
      setSnackbarText(
        `Declined access request from ${confirmInfo.name} warning`
      );
      setConfirmInfo(null);
    } catch (error) {
      setSnackbarText("Failed to decline access request error");
      setConfirmInfo(null);
    }
  };

  const { access_requests } = data;

  const tableData = access_requests.map((req) => ({
    id: req.id,
    userId: req.user_id,
    created: new Date(req.created).toISOString(),
    name: `${req.first_name} ${req.last_name}`,
    email: req.email,
    locationToManage: req.requested_locations,
  }));

  const columns = [
    {
      name: "id",
      label: "ID",
      options: {
        filter: false,
        sort: false,
        display: false,
        download: false,
        print: false,
      },
    },
    {
      name: "userId",
      label: "userId",
      options: {
        filter: false,
        sort: false,
        display: false,
        download: false,
        print: false,
      },
    },
    {
      name: "name",
      label: "Name",
      options: {
        filter: true,
        sort: true,
        display: true,
      },
    },
    {
      name: "email",
      label: "Email",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "created",
      label: "Created",
      options: {
        filter: true,
        sort: true,
        display: true,
        customBodyRender: (value) => (
          <DateTooltip date={value} shouldLocalize />
        ),
      },
    },
    {
      name: "locationToManage",
      label: "Location Requested",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value) => {
          if (value.length > 1) {
            return "Multiple Locations";
          }
          return value[0].name;
        },
      },
    },
    {
      name: "accept",
      label: "Approve",
      options: {
        sort: false,
        filter: false,
        empty: true,
        download: false,
        print: false,
        customBodyRender: (_, tableMeta) => (
          <ActionButtonForTables
            title="approve"
            Icon={CheckCircle}
            handleClick={() => {
              return setAcceptInfo({
                accessLevel: ACCESS_LEVELS.ADMIN,
                id: tableMeta.rowData[0],
                name: tableMeta.rowData[2],
                initialLocation: tableMeta.rowData[5].map((v) => v.id) || [],
              });
            }}
          />
        ),
      },
    },
    {
      name: "delete",
      label: "Decline",
      options: {
        sort: false,
        filter: false,
        empty: true,
        download: false,
        print: false,
        customBodyRender: (_, tableMeta) => (
          <DeleteButtonForTables
            handleClick={() =>
              setConfirmInfo({
                id: tableMeta.rowData[0],
                name: tableMeta.rowData[2],
                type: "delete",
              })
            }
          />
        ),
      },
    },
  ];

  return (
    <>
      <CustomDatatableV2
        title={<TableTitle title="Supervisor Access Requests" Icon={Feather} />}
        data={tableData}
        columns={columns}
        options={options}
        components={{
          icons: CustomDataTableIcons,
        }}
      />
      <Modal open={Boolean(confirmInfo)} onClose={() => setConfirmInfo(null)}>
        <div>
          <ConfirmModalSmall
            title="Decline Access Request"
            name={confirmInfo?.name}
            handleConfirm={handleRejectRequest}
          />
        </div>
      </Modal>
      <Modal open={Boolean(acceptInfo)} onClose={() => setAcceptInfo(null)}>
        <div>
          <AcceptDarModal
            name={acceptInfo?.name}
            id={acceptInfo?.id}
            accessLevel={ACCESS_LEVELS.SUPERVISOR}
            initialLocation={acceptInfo?.initialLocation}
            setSnackbarText={setSnackbarText}
            handleClose={() => setAcceptInfo(null)}
            mutate={mutate}
          />
        </div>
      </Modal>
    </>
  );
};

export default AccessRequestsTable;
