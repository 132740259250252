/* eslint-disable */
import React from "react";
import PropTypes from "prop-types";
import styled, { keyframes } from "styled-components/macro";

const ErrorText = styled.span`
  letter-spacing: 1px;
  font-weight: 300;
  font-size: 10px;
`;

const fadeInOut = keyframes`
  0% {
    opacity: 0.2;
  }
  50% {
    opacity: 0.6;
  }
  100%{
    opacity: 0.2;
  }
`;

const LoadingText = styled.span`
  letter-spacing: 1px;
  font-weight: 300;
  font-size: 10px;
  opacity: 0;
  animation: 1.8s ${fadeInOut} ease-out infinite;
`;

const OutstandingModules = ({ progress, id, isError, isLoading }) => {
  if (!progress || isLoading) {
    return <LoadingText>Loading...</LoadingText>;
  }

  if (progress === "ERROR" || isError) {
    return <ErrorText>Error Loading</ErrorText>;
  }

  const filtered = progress[id];

  if (filtered === undefined) {
    return <span>0</span>;
  }

  const value = filtered.total - filtered.complete;

  return <span>{value}</span>;
};

export default OutstandingModules;

OutstandingModules.propTypes = {
  id: PropTypes.string.isRequired,
  progress: PropTypes.object.isRequired,
  isError: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool.isRequired,
};
