/* eslint-disable */
import React from "react";
import { Divider as MuiDivider } from "@material-ui/core";
import { Home } from "react-feather";
import { spacing } from "@material-ui/system";
import PropTypes from "prop-types";
import styled from "styled-components/macro";

const Divider = styled(MuiDivider)(spacing);

const SpacingBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 8px;

  @media (max-width: 600px) {
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 12px;
  }
`;

const MoveRight = styled.div`
  margin-left: auto;

  @media (max-width: 600px) {
    margin-left: 0px;
  }
`;

const Wrapper = styled.div`
  padding: 10px 16px 0px 16px;

  @media (max-width: 1280px) {
    margin-top: -8px;
  }

  @media (max-width: 440px) {
    h4 {
      font-size: 14px;
    }

    h2 {
      font-size: 14px;
    }

    h3 {
      font-size: 14px;
    }

    a {
      font-size: 14px;
    }

    svg {
      height: 18px;
      width: 18px;
    }
  }
`;

const PrimaryTitle = styled.p`
  font-size: 22px;
  font-weight: 700;
  color: black;
`;

const PrivateHeader = ({ children, Icon = Home }) => {
  return (
    <Wrapper>
      <SpacingBox>
        <div
          style={{
            height: 40,
            width: 40,
            minWidth: 40,
            borderRadius: 4,
            background: "#eeeeee",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Icon height={16} width={16} />
        </div>
        <PrimaryTitle>Dashboard</PrimaryTitle>
        {children && <MoveRight>{children}</MoveRight>}
      </SpacingBox>
      <Divider mb={6} mt={2} />
    </Wrapper>
  );
};

export default PrivateHeader;

PrivateHeader.propTypes = {
  header: PropTypes.string.isRequired,
  secondary: PropTypes.string,
  destination: PropTypes.string,
  small: PropTypes.bool,
  children: PropTypes.node,
};

PrivateHeader.defaultProps = {
  secondary: null,
  destination: null,
  small: null,
  children: null,
};
