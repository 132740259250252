/* eslint-disable */
import React, { useState, useEffect } from "react";
import styled from "styled-components/macro";
import { Paper } from "@material-ui/core";
import { Button } from "../../../pages/private/styles";
import { Check, Tag, AlertTriangle } from "react-feather";
import useAudienceToolStore from "../../../store/useAudienceToolStore";

const Wrapper = styled(Paper)`
  padding: 10px;
  width: 340px;
  height: 400px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  text-align: left;
  max-width: 520px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  border: 2px solid black;
`;

const Item = styled.div`
  width: 100%;
  padding: 8px;
  margin-bottom: 4px;
  border-radius: 6px;
  background: #eeeeee77;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  cursor: pointer;

  &:hover {
    transform: scale(1.003);
  }
`;

const List = styled.div`
  flex: 1;
  width: 100%;
  margin-bottom: 8px;
  padding-top: 8px;
  padding-bottom: 8px;
  overflow-y: scroll;
`;

const RoleSelectionModal = ({ roles, handleClose, handleApply }) => {
  const [selectedRoles, setSelectedRoles] = useState([]);

  const currentAudienceType = useAudienceToolStore(
    (state) => state.currentAudienceType
  );
  const currentAudience = useAudienceToolStore(
    (state) => state.currentAudience
  );

  useEffect(() => {
    if (currentAudienceType === "R") {
      const targetRoles = Object.values(currentAudience)[0];
      if (targetRoles.length === 0) return null;

      const initialRoleIds = targetRoles.map((role) => role.id);
      setSelectedRoles(initialRoleIds);
    }
  }, []);

  const isDisabled = selectedRoles.length < 1;

  if (roles.length === 0) {
    return (
      <Wrapper>
        <div
          style={{
            height: "100%",
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column",
          }}
        >
          <AlertTriangle color="darkorange" />
          <div style={{ height: 16 }} />
          <span style={{ color: "black", fontWeight: "600" }}>
            No roles created for this company.
          </span>

          <span
            style={{
              textAlign: "center",
              color: "darkgrey",
              fontSize: 12,
              maxWidth: "80%",
            }}
          >
            Company must have at least 1 role created before launching modules.
          </span>
          {/* <span style={{ color: 'darkgrey' }}>No roles created for this company</span> */}
        </div>
      </Wrapper>
    );
  }

  return (
    <Wrapper>
      <span style={{ fontSize: 16, fontWeight: "600" }}>Select Roles</span>
      <List>
        {roles.map((role) => (
          <Item
            key={role.id}
            onClick={() =>
              setSelectedRoles((p) =>
                p.includes(role.id)
                  ? p.filter((id) => id !== role.id)
                  : [...p, role.id]
              )
            }
          >
            <Tag height={12} width={12} style={{ marginRight: 6 }} />
            <span
              style={{
                fontSize: 12,
                fontWeight: selectedRoles.includes(role.id) ? 500 : 400,
              }}
            >
              {role.name}
            </span>
            <div style={{ marginLeft: "auto" }}>
              {selectedRoles.includes(role.id) ? (
                <div
                  style={{
                    height: 14,
                    width: 14,
                    borderRadius: 4,
                    background: "#337ab7",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Check height={10} width={10} style={{ color: "white" }} />
                </div>
              ) : (
                <div
                  style={{
                    height: 14,
                    width: 14,
                    borderRadius: 4,
                    background: "lightgray",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                />
              )}
            </div>
          </Item>
        ))}
      </List>
      <Button
        style={{
          alignSelf: "flex-end",
          marginTop: "auto",
          background: isDisabled ? "lightgray" : "#337ab7",
          height: 32,
          minWidth: 60,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          padding: "0px 8px",
        }}
        variant="contained"
        color="primary"
        disabled={isDisabled}
        onClick={() => {
          handleApply(selectedRoles);
          handleClose();
        }}
      >
        Confirm & Save
      </Button>
    </Wrapper>
  );
};

export default RoleSelectionModal;
