import { QUESTION_TYPES } from "../../../../storage/constants";

const getChipLabel = (question) => {
  switch (question.type) {
    case QUESTION_TYPES.TRUE_FALSE:
      return "True/False";
    case QUESTION_TYPES.MULTIPLE_CHOICE:
      return "Multiple Choice";
    case QUESTION_TYPES.SELECT_ALL:
      return "Select All";
    default:
      return "Unknown";
  }
};

export default getChipLabel;
