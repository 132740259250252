/* eslint-disable */
import useSWR from "swr";
import API from "../../../axios/instances/API";

const BASE_URL = "/dashboard/analytics/report/?report=quiz_view&quiz_id=";

const fetcher = async (url) => {
  const { data } = await API.get(url);
  return data;
};

const useSingleModuleStats = (id) => {
  const { data, error, mutate } = useSWR(`${BASE_URL}${id}`, fetcher);

  return {
    data,
    isLoading: !error && !data,
    isError: error,
    mutate,
  };
};

export default useSingleModuleStats;
