/* eslint-disable */
import React from "react";
import PropTypes from "prop-types";
import { User, Users } from "react-feather";
import { CenterWithColor, StyledLink } from "../../../styles";
import { makeDefaultTableOptions } from "../../../../../storage/helpers";
import {
  DEFINITIONS,
  returnDefinitionByEngagementType,
} from "../../../../../storage/constants";
import {
  retCompsByEngagementType,
  retSubsByEngagementType,
  retPassRateByEngagementType,
} from "../../utils/utils";
import styled from "styled-components/macro";
import { LinearProgress } from "@material-ui/core";
import { CustomDatatableV2 } from "../../../styles";
import TableTitle from "../../../../../components/Table/TableTitle";
import SubmissionTypeChip from "../../../home/retail/SubmissionTypeChip";
import CustomDataTableIcons from "../../../../../components/Table/CustomDataTableIcons";
import { useMediaQuery } from "@material-ui/core";

const BarWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
`;

const CustomLinearProgress = styled(LinearProgress)`
  background-color: white;
  background-color: ${(props) => props.theme.palette.grey[100]};
  border-radius: 8px;
  min-height: 10px;
  width: 174px;
  margin-right: 8px;
  border: 1px solid #eeeeee;

  .MuiLinearProgress-barColorPrimary {
    background-color: ${(props) => {
      if (props.value > 80) return props.theme.palette.success.main;
      if (props.value > 60) return props.theme.palette.success.light;
      if (props.value > 40) return props.theme.palette.warning.light;
      if (props.value > 20) return props.theme.palette.warning.main;
      if (props.value > 0) return props.theme.palette.error.main;
      return props.theme.palette.primary.main;
    }};
  }
`;

const PercentageText = styled.span`
  font-size: 10px;
  font-weight: 500;
  color: ${(props) => props.theme.palette.grey[700]};
`;

const retProgressPercentage = (progress) => {
  try {
    const { completed, outstanding } = progress;

    if (completed === 0)
      return {
        percentage: 0,
        stringified: `${completed}/${completed + outstanding}`,
      };

    return {
      percentage: Math.floor((completed / (completed + outstanding)) * 100),
      stringified: `${completed}/${completed + outstanding}`,
    };
  } catch (error) {
    return {
      percentage: 0,
      stringified: "0/0",
    };
  }
};

const ProgressBar = ({ percentage, stringified }) => {
  return (
    <BarWrapper>
      <CustomLinearProgress variant="determinate" value={percentage} />
      <PercentageText>
        (<small>{stringified}</small>) <strong>{percentage}%</strong>
      </PercentageText>
    </BarWrapper>
  );
};

const TeamMembersTable = ({ members, engagementType }) => {
  const isUnder600Px = useMediaQuery((theme) => theme.breakpoints.down("xs"));

  const options = makeDefaultTableOptions("show-team-members");
  options.sortOrder = {
    name: "completions",
    direction: "desc",
  };
  options.rowsPerPage = 10;

  if (isUnder600Px) {
    options.rowsPerPageOptions = [];
    options.jumpToPage = false;
  }

  const columns = [
    {
      name: "id",
      label: "ID",
      options: {
        filter: false,
        sort: false,
        display: false,
      },
    },
    {
      name: "name",
      label: "Name",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta) => (
          <StyledLink
            to={`/vendor-reports/user/${tableMeta.rowData[0]}`}
            value={value}
            CustomIcon={User}
          />
        ),
      },
    },
    {
      name: "submissions",
      label: "Submissions",
      options: {
        filter: true,
        sort: true,
        hint: returnDefinitionByEngagementType("submissions", engagementType),
        customBodyRender: (val) => (
          <CenterWithColor engagementType={engagementType}>
            <span>{val}</span>
          </CenterWithColor>
        ),
      },
    },
    {
      name: "completions",
      label: "Completions",
      options: {
        filter: true,
        sort: true,
        hint: returnDefinitionByEngagementType("completions", engagementType),
        customBodyRender: (val) => (
          <CenterWithColor engagementType={engagementType}>
            <span>{val}</span>
          </CenterWithColor>
        ),
      },
    },
    {
      name: "passRate",
      label: "Pass Rate",
      options: {
        sort: true,
        filter: true,
        hint: DEFINITIONS.passRate,
        customBodyRender: (val) => (
          <CenterWithColor engagementType={engagementType}>
            <span>{val}%</span>
          </CenterWithColor>
        ),
      },
    },
    {
      name: "progress",
      label: "Progress",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value) => {
          const { percentage, stringified } = value;
          return (
            <ProgressBar percentage={percentage} stringified={stringified} />
          );
        },
      },
    },
  ];

  const tableData = members.map((member) => ({
    id: member.user_id,
    name: `${member.first_name} ${member.last_initial}`,
    completions: retCompsByEngagementType(member, engagementType),
    submissions: retSubsByEngagementType(member, engagementType),
    passRate: retPassRateByEngagementType(member, engagementType),
    progress: retProgressPercentage(member?.progress),
  }));

  return (
    <CustomDatatableV2
      title={
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-start",
            gap: 8,
          }}
        >
          <TableTitle
            title="Team Members at this location"
            isVendor
            Icon={Users}
            submissionType={engagementType}
          />
        </div>
      }
      options={options}
      columns={columns}
      data={tableData}
      components={{
        icons: CustomDataTableIcons,
      }}
    />
  );
};

export default TeamMembersTable;

TeamMembersTable.propTypes = {
  members: PropTypes.array.isRequired,
  engagementType: PropTypes.string.isRequired,
};
