import useSWR from 'swr';
import API from '../../../axios/instances/API';

const fetcher = async (url) => {
  const { data } = await API.get(url);
  return data.members;
};

const useRoleTeamMembers = (id) => {
  const { data, error, mutate } = useSWR(`/dashboard/teams/by-role/?role_id=${id}`, fetcher, {
    dedupingInterval: 30000,
    revalidateOnFocus: false,
  });

  return {
    teamMembers: data,
    isLoading: !error && !data,
    isError: error,
    mutate,
  };
};

export default useRoleTeamMembers;
