/* eslint-disable */
import React from 'react';
import styled from 'styled-components/macro';

const Wrapper = styled.div`
  max-height: 440px;
  flex: 1;

  background-color: white;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  width: fit-content;
  min-width: 100%;
  max-width: 100%;
  position: relative;
  align-self: flex-start;
  z-index: 1;
  border: 1px solid lightgray;
  margin-right: auto;
  overflow: auto;
  border-radius: 4px;

  ::-webkit-scrollbar {
    // display: none;
  }

  table {
    align-self: flex-start;
    margin-right: auto;
    border-collapse: separate;
    border-spacing: 0;
    width: 100%;
  }

  th:first-child {
    position: -webkit-sticky;
    position: sticky;
    left: 0;
    z-index: 2;
    background: #ccc;
  }

  thead {
    th {
      color: black;
      position: -webkit-sticky;
      position: sticky;
      top: 0;
      z-index: 4;
      min-width: 180px;
    }
  }
`;

const TableWrapper = ({ children }) => <Wrapper>{children}</Wrapper>;

export default TableWrapper;
