/* eslint-disable */
import useSWR from "swr";
import API from "../../../axios/instances/API";

const fetcher = async (url) => {
  const { data } = await API.get(url);
  return data.limits;
};

const useCompanyLimits = (limitType = "custom_modules") => {
  const { data, error, mutate } = useSWR(
    `dashboard/company/limits/?limit_type=${limitType}`,
    fetcher,
    {
      revalidateOnFocus: false,
    }
  );

  return {
    data,
    isLoading: !error && !data,
    isError: error,
    mutate,
  };
};

export default useCompanyLimits;
