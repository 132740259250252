/* eslint-disable */
import React from 'react';
import { Alert, AlertTitle } from '@material-ui/lab';

const MembersInMultipleCompaniesWarning = ({ num, isSingleCompanyBreakdown = false }) => (
  <Alert
    severity="warning"
    style={{
      borderRadius: 4,
      boxShadow: 'none',
      boxShadow: '0px 2px 1px 0px rgba(0, 0, 0, .2',
    }}
  >
    <AlertTitle>
      {isSingleCompanyBreakdown
        ? `There ${num > 1 ? 'are' : 'is'} ${num} team ${
            num > 1 ? 'members' : 'member'
          } with multiple locations within this company.`
        : `There ${num > 1 ? 'are' : 'is'} ${num} ${
            num > 1 ? 'users that have' : 'user that has'
          } team ${num > 1 ? 'members' : 'members'} in multiple companies.`}
    </AlertTitle>
    {isSingleCompanyBreakdown
      ? 'Their stats will be counted in each location where they work.'
      : 'Their stats will be counted in each company where they work.'}
  </Alert>
);

export default MembersInMultipleCompaniesWarning;
