/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import styled from 'styled-components/macro';
import PropTypes from 'prop-types';
import { Typography } from '../../../pages/private/styles';
import { IntroText } from '../styles';

const Wrapper = styled.div`
  margin-left: 12px;
`;

const Subtitle = styled.p`
  font-size: 12px;
  font-weight: 500;
  margin-bottom: 4px;
`;

const IndentWrapper = styled.div`
  width: 100%;
  padding-left: 12px;
`;

const Launch = ({
  isRetail,
}) => (
  <Wrapper>
    <Typography
      variant="subtitle2"
    >
      What happens to my module if i don't launch?
    </Typography>
    <IntroText
      mt={1}
      mb={6}
    >
      Not ready to launch? No problem. If you don't launch your quiz,
      it will be stored for you with an "In Development" state.
      You can come back and finish making your module anytime, so there is no rush to launch.
    </IntroText>
    {
      isRetail && (
        <>
          <Typography
            variant="subtitle2"
          >
            What will happen when i launch?
          </Typography>
          <IntroText
            mt={1}
            mb={6}
          >
            Once you launch your module, you will be redirected back to the module library page.
            If the start date of your module is today, it will have a state of "Live", and will be
            available in the app to everyone included in your chosen audience.
            If the start date is in the future, your module will be
            have a state of "Pending", which will change to "Live", on the start date.
            You can still edit every part of your module until it turns "Live".
          </IntroText>
        </>
      )
    }
    {
      !isRetail && (
        <>
          <Typography
            variant="subtitle2"
            mb={4}
          >
            What are the different launch options?
          </Typography>
          <IndentWrapper>
            <Subtitle>
              Launch to Marketplace
            </Subtitle>
            <IntroText mb={4}>
              This option publishes your content to the marketplace.
              Other companies with access to the marketplace will be
              able to review the content and schedule it for their team
              members. Also, users that follow your brand will get access to
              the module right away.
            </IntroText>
            <Subtitle>
              Suggest Content
            </Subtitle>
            <IntroText mb={4}>
              Allows you to deploy content to a custom audience without
              publishing it to the marketplace.
            </IntroText>
            <Subtitle>
              Suggest content and launch to marketplace.
            </Subtitle>
            <IntroText mb={4}>
              The option will suggest your module to the chosen custom audience,
              deploy it to your followers, and publish it to the marketplace.
            </IntroText>
          </IndentWrapper>
        </>
      )
    }
  </Wrapper>
);

export default Launch;

Launch.propTypes = {
  isRetail: PropTypes.bool.isRequired,
};
