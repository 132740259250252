/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import { IntroText } from './styles';
import { Typography } from '../../pages/private/styles';

const Followers = () => (
  <>
    <Typography variant="subtitle2" mb={2}>
      Followers is Spiffy's newest feature!
    </Typography>
    <IntroText>
      The followers functionality allows team members across the Spiffy
      universe to access vendor content,
      without it needing to be deployed to
      them directly by a supervisor. Every
      user of the Spiffy App will
      have the ability to follow their
      favourite brands, and will receive content
      deployed by those brands to
      the marketplace. This page is a simple report detailing
      how you are doing building and engaging followers.
    </IntroText>
  </>

);

export default Followers;
