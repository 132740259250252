/* eslint-disable */
import useSWR from "swr";
import API from "../../../axios/instances/API";

const fetcher = async (url) => {
  const { data } = await API.get(url);
  return data;
};

const useLocationDetailV2 = (id) => {
  const { data, error, mutate } = useSWR(
    `dashboard/analytics/retail-reports/location-view/?location_id=${id}`,
    fetcher,
    {
      revalidateOnFocus: false,
    }
  );

  return {
    data,
    isLoading: !error && !data,
    isError: error,
    mutate,
  };
};

export default useLocationDetailV2;
