import useSWR from 'swr';
import API from '../../../axios/instances/API';

const fetcher = async (url) => {
  const { data } = await API.get(url);
  return data.roles;
};

const useRoles = () => {
  const { data, error, mutate } = useSWR('dashboard/roles/', fetcher, {
    revalidateOnFocus: false,
  });

  return {
    roles: data,
    rolesLoading: !error && !data,
    isError: error,
    mutate,
  };
};

export default useRoles;
