import useSWR from 'swr';
import API from '../../../axios/instances/API';

const fetcher = async (url) => {
  const { data } = await API.get(url);

  return data;
};

const useAccessRequestLists = jobType => {
  const { data, error, mutate } = useSWR(`/dashboard/dashboard-access-request/?job=${jobType}`, fetcher, {
    revalidateOnFocus: false,
  });

  return {
    data,
    isLoading: !error && !data,
    isError: error,
    mutate,
  };
};

export default useAccessRequestLists;
