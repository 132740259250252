/* eslint-disable */
import React from 'react';
import { Alert } from '@material-ui/lab';

const PermissionsWarning = () => {
  return (
    <Alert
      severity="warning"
      style={{ marginBottom: 16 }}
    >
      <p style={{ fontWeight: '600' }}>You have limited permissions</p>

      <p>
        The admins at your company have not allowed you to edit modules, deploy content, or view the
        marketplace.
      </p>
    </Alert>
  );
};

export default PermissionsWarning;
