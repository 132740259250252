/* eslint-disable */
import React from "react";
import PropTypes from "prop-types";
import { Users } from "react-feather";
import CustomDataTableIcons from "../../../../../components/Table/CustomDataTableIcons";
import TableTitle from "../../../../../components/Table/TableTitle";
import { makeDefaultTableOptions } from "../../../../../storage/helpers";
import { CustomDatatableV2 } from "../../../styles";

const columns = [
  {
    name: "id",
    label: "ID",
    options: {
      filter: false,
      sort: false,
      display: false,
      download: false,
    },
  },
  {
    name: "name",
    label: "Team Member Name",
    options: {
      filter: true,
      sort: true,
    },
  },
  {
    name: "location",
    label: "Location",
    options: {
      filter: true,
      sort: true,
    },
  },
  {
    name: "passed",
    label: "Completed Module",
    options: {
      filter: true,
      sort: true,
    },
  },
];

const OutstandingTable = ({ selectedQuiz, assignedUsers }) => {
  const joinedName = selectedQuiz.quiz.name.split(" ").join("_");
  const options = makeDefaultTableOptions(`${joinedName}-progress`);

  const audienceData = assignedUsers.map((val) => ({
    id: val.id,
    name: val.name ? val.name : `${val.first_name} ${val.last_name}`,
    location:
      val?.locations.length === 0
        ? "*none"
        : val.locations.length > 1
        ? val.locations.map((l) => l?.name).join(", ")
        : val.locations[0]?.name,
    passed: val.completed ? "Yes" : "No",
  }));

  return (
    <CustomDatatableV2
      title={<TableTitle Icon={Users} title="Assigned Team Members" />}
      options={options}
      columns={columns}
      data={audienceData}
      components={{
        icons: CustomDataTableIcons,
      }}
    />
  );
};

export default OutstandingTable;

OutstandingTable.propTypes = {
  selectedQuiz: PropTypes.object.isRequired,
};
