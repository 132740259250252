/* eslint-disable */
import * as excelJs from "exceljs";

const downloadWorkbook = async (
  workbook,
  templateName = "Spiffy-Email-Invite-Template.xlsx"
) => {
  const excelBlob = await workbook.xlsx.writeBuffer();
  const excelUrl = URL.createObjectURL(
    new Blob([excelBlob], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    })
  );

  const link = document.createElement("a");
  link.href = excelUrl;
  link.download = templateName;
  document.body.appendChild(link);
  link.click();

  URL.revokeObjectURL(excelUrl);
  document.body.removeChild(link);
};

export const createWorkbookForCsvLocations = async (country, state, cities) => {
  const workbook = new excelJs.Workbook();
  const cityValues = cities.map((city) => city.name);

  const sheet = workbook.addWorksheet("LocationInvitations", {
    properties: {
      defaultColWidth: 24,
      defaultRowHeight: 24,
    },
    views: [{ state: "frozen", ySplit: 1 }],
    pageSetup: {},
  });

  sheet.columns = [
    {
      header: "Location Name",
      id: "LocationName",
      key: "LocationName",
    },
    {
      header: "Address Line 1",
      id: "AddressLine1",
      key: "AddressLine1",
    },
    {
      header: "Address Line 2",
      id: "AddressLine2",
      key: "AddressLine2",
    },
    {
      header: "Country",
      id: "Country",
      key: "Country",
    },
    {
      header: "State/Province",
      id: "State/Province",
      key: "State/Province",
    },
    {
      header: "City",
      id: "city",
      key: "city",
    },
    {
      header: "Postal Code",
      id: "PostalCode",
      key: "PostalCode",
    },
    {
      header: "Supervisor Deploy Permissions",
      id: "SupervisorDeployPermissions",
      key: "SupervisorDeployPermissions",
    },
  ].map((column) => {
    if (!column.width) {
      column.width = 34;
    }

    column.style = {
      alignment: { vertical: "middle", horizontal: "center" },
    };
    return column;
  });

  sheet.getRow(1).font = { bold: true, size: 12 };

  /// Header Notes
  sheet.getCell("A1").note =
    "Choose the name of the location in this column. [MANDATORY]";
  sheet.getCell("B1").note = "Address line 1 of the location. [MANDATORY]";
  sheet.getCell("C1").note = "Address line 2 of the loaction. [OPTIONAL]";
  sheet.getCell("D1").note =
    "Country of location. Selected when downloading the workbook. [MANDATORY]";
  sheet.getCell("E1").note =
    "State/Provice of location. Selected when downloading the workbook. [MANDATORY]";
  sheet.getCell("F1").note =
    "City of location. All available cities in the selected state should be available in the dropdown. [MANDATORY]";
  sheet.getCell("G1").note = "Post code of location. [MANDATORY]";
  sheet.getCell("H1").note =
    "Allow supervisors to deploy content at this location? [MANDATORY]";
  // Header Fills
  sheet.getCell("A1").fill = {
    type: "pattern",
    pattern: "solid",
    fgColor: { argb: "eeeeee" },
  };

  sheet.getCell("B1").fill = {
    type: "pattern",
    pattern: "solid",
    fgColor: { argb: "eeeeee" },
  };
  sheet.getCell("C1").fill = {
    type: "pattern",
    pattern: "solid",
    fgColor: { argb: "eeeeee" },
  };
  sheet.getCell("D1").fill = {
    type: "pattern",
    pattern: "solid",
    fgColor: { argb: "eeeeee" },
  };
  sheet.getCell("E1").fill = {
    type: "pattern",
    pattern: "solid",
    fgColor: { argb: "eeeeee" },
  };
  sheet.getCell("F1").fill = {
    type: "pattern",
    pattern: "solid",
    fgColor: { argb: "eeeeee" },
  };
  sheet.getCell("G1").fill = {
    type: "pattern",
    pattern: "solid",
    fgColor: { argb: "eeeeee" },
  };
  sheet.getCell("H1").fill = {
    type: "pattern",
    pattern: "solid",
    fgColor: { argb: "eeeeee" },
  };
  /// city validation
  sheet.dataValidations.add("F2:F251", {
    type: "list",
    allowBlank: false,
    showErrorMessage: true,
    errorStyle: "error",
    error: "Please select a valid city from the list",
    formulae: [`Validations!$A$1:$A$${cityValues.length}`],
  });

  // // super permission validation
  sheet.dataValidations.add("H2:H251", {
    type: "list",
    allowBlank: false,
    showErrorMessage: true,
    errorStyle: "error",
    error: "Please select Yes or No",
    formulae: [`Validations!$B$1:$B$2`],
  });

  // /// country validation
  sheet.dataValidations.add("D2:D251", {
    type: "list",
    allowBlank: false,
    showErrorMessage: true,
    errorStyle: "error",
    error: "Invalid",
    formulae: [`Validations!$C$1`],
  });

  /// state validation
  sheet.dataValidations.add("E2:E251", {
    type: "list",
    allowBlank: false,
    showErrorMessage: true,
    errorStyle: "error",
    error: "Must be state selected on download",
    formulae: [`Validations!$D$1`],
  });

  sheet.dataValidations.add("A252:G252", {
    type: "textLength",
    operator: "equal",
    showErrorMessage: true,
    error: "250 rows maxiumum please",
    allowBlank: true,
    formulae: [0],
  });

  sheet.dataValidations.add("I1:I251", {
    type: "textLength",
    operator: "equal",
    showErrorMessage: true,
    error: "No added columns please",
    allowBlank: true,
    formulae: [0],
  });

  /////---- validation sheet ------ ////
  const validationSheet = workbook.addWorksheet("Validations");
  validationSheet.columns = [
    { header: "City Options", id: "city-options", key: "city-options" },
    {
      header: "Supervisor Deploy Permissions",
      id: "SupervisorDeployPermissions",
      key: "SupervisorDeployPermissions",
    },
    {
      header: "Country Options",
      id: "CountryOptions",
      key: "CountryOptions",
    },
    {
      header: "State Options",
      id: "StateOptions",
      key: "StateOptions",
    },
  ];
  const cityOptions = validationSheet.getColumn("city-options");
  const superDeployOptions = validationSheet.getColumn(
    "SupervisorDeployPermissions"
  );
  const countryOptions = validationSheet.getColumn("CountryOptions");
  const stateOptions = validationSheet.getColumn("StateOptions");

  cityOptions.values = cityValues;
  superDeployOptions.values = ["Yes", "No"];
  stateOptions.values = [state];
  countryOptions.values = [country];

  validationSheet.state = "hidden";
  downloadWorkbook(workbook, "spiffy-location-template.xlsx");
};

export const createWorkbook = async (roles, selectedLocations = []) => {
  const workbook = new excelJs.Workbook();

  const sheet = workbook.addWorksheet("Invitations", {
    properties: {
      defaultColWidth: 24,
      defaultRowHeight: 24,
    },
    views: [{ state: "frozen", ySplit: 1 }],
    pageSetup: {},
  });

  const validationSheet = workbook.addWorksheet("Validations");
  validationSheet.columns = [
    {
      header: "location-options",
      id: "location-options",
      key: "location-options",
    },
    { header: "role-options", id: "role-options", key: "role-options" },
  ];

  const roleValues = roles.map((r) => r.name);

  const locationOptions = validationSheet.getColumn("location-options");
  const roleOptions = validationSheet.getColumn("role-options");
  locationOptions.values = selectedLocations;
  roleOptions.values = roleValues;
  validationSheet.state = "hidden";

  sheet.columns = [
    {
      header: "First name",
      id: "firstName",
      key: "firstName",
    },
    { header: "Last name", id: "lastName", key: "lastName" },
    { header: "Email", id: "email", key: "email" },
    { header: "Location", id: "location", key: "location" },
    { header: "Role 1", id: "role1", key: "role1" },
    { header: "Role 2", id: "role2", key: "role2" },
    { header: "Role 3", id: "role3", key: "role3" },
  ].map((column) => {
    if (!column.width) {
      column.width = 24;
    }

    column.style = {
      alignment: { vertical: "middle", horizontal: "center" },
    };

    return column;
  });

  sheet.eachRow((row) => {
    row.eachCell((cell) => {
      cell.font = {
        name: "Arial",
        size: 10,
      };
    });
  });

  sheet.getRow(1).font = { bold: true, size: 12 };

  sheet.dataValidations.add("D2:D101", {
    type: "list",
    allowBlank: false,
    showErrorMessage: true,
    errorStyle: "error",
    error: "Please select a valid value from the list",
    formulae: [`Validations!$A$1:$A$${selectedLocations.length}`],
  });

  sheet.dataValidations.add("E2:E101", {
    type: "list",
    allowBlank: true,
    showErrorMessage: true,
    error: "Please select a valid value from the list",
    errorStyle: "error",
    formulae: [`Validations!$B$1:$B$${roleValues.length}`],
    prompt: "Optional",
  });

  sheet.dataValidations.add("F2:F101", {
    type: "list",
    allowBlank: true,
    showErrorMessage: true,
    error: "Please select a valid value from the list",

    errorStyle: "error",
    formulae: [`Validations!$B$1:$B$${roleValues.length}`],
    prompt: "Optional",
  });

  sheet.dataValidations.add("G2:G101", {
    type: "list",
    allowBlank: true,
    showErrorMessage: true,
    error: "Please select a valid value from the list",

    errorStyle: "error",
    formulae: [`Validations!$B$1:$B$${roleValues.length}`],
    prompt: "Optional",
  });

  sheet.dataValidations.add("A102:G102", {
    type: "textLength",
    operator: "equal",
    showErrorMessage: true,
    error: "100 rows maxiumum please",
    allowBlank: true,
    formulae: [0],
  });

  sheet.getCell("D1").note =
    "Which location does this employee work at (Required)";
  sheet.getCell("E1").note =
    "What job function does this employee perform? (Optional)";
  sheet.getCell("F1").note =
    "What job function does this employee perform? (Optional)";
  sheet.getCell("G1").note =
    "What job function does this employee perform? (Optional)";

  sheet.getCell("A1").fill = {
    type: "pattern",
    pattern: "solid",
    fgColor: { argb: "eeeeee" },
  };

  sheet.getCell("A1").dataValidation = {
    type: "textLength",
    operator: "equal",
    formulae: [10],
    showErrorMessage: true,
    error: "Please do not change column names",
  };

  sheet.getCell("B1").fill = {
    type: "pattern",
    pattern: "solid",
    fgColor: { argb: "eeeeee" },
  };

  sheet.getCell("B1").dataValidation = {
    type: "textLength",
    operator: "equal",
    formulae: [9],
    showErrorMessage: true,
    error: "Please do not change column names",
  };

  sheet.getCell("C1").fill = {
    type: "pattern",
    pattern: "solid",
    fgColor: { argb: "eeeeee" },
  };

  sheet.getCell("C1").dataValidation = {
    type: "textLength",
    operator: "equal",
    formulae: [5],
    showErrorMessage: true,
    error: "Please do not change column names",
  };

  sheet.getCell("D1").fill = {
    type: "pattern",
    pattern: "solid",
    fgColor: { argb: "eeeeee" },
  };

  sheet.getCell("D1").dataValidation = {
    type: "textLength",
    operator: "equal",
    formulae: [8],
    showErrorMessage: true,
    error: "Please do not change column names",
  };

  sheet.getCell("E1").fill = {
    type: "pattern",
    pattern: "solid",
    fgColor: { argb: "eeeeee" },
  };

  sheet.getCell("E1").dataValidation = {
    type: "textLength",
    operator: "equal",
    formulae: [6],
    showErrorMessage: true,
    error: "Please do not change column names",
  };

  sheet.getCell("F1").fill = {
    type: "pattern",
    pattern: "solid",
    fgColor: { argb: "eeeeee" },
  };

  sheet.getCell("F1").dataValidation = {
    type: "textLength",
    operator: "equal",
    formulae: [6],
    showErrorMessage: true,
    error: "Please do not change column names",
  };

  sheet.getCell("G1").fill = {
    type: "pattern",
    pattern: "solid",
    fgColor: { argb: "eeeeee" },
  };

  sheet.getCell("G1").dataValidation = {
    type: "textLength",
    operator: "equal",
    formulae: [6],
    showErrorMessage: true,
    error: "Please do not change column names",
  };

  sheet.dataValidations.add("H1:H102", {
    type: "textLength",
    operator: "equal",
    showErrorMessage: true,
    error: "No added columns please",
    allowBlank: true,
    formulae: [0],
  });

  downloadWorkbook(workbook);
};
