/* eslint-disable */
import React from 'react';
import PropTypes from 'prop-types';
import { WEB_APP_BASE_URL } from '../../../storage/constants';


const Referral = ({
  match,
}) => {
  const referralCode = match?.params?.ref;

  window.location.replace(`${WEB_APP_BASE_URL}referral/${referralCode}`)

  return <span />;
};

export default Referral;

Referral.propTypes = {
  match: PropTypes.object.isRequired,
};
