/* eslint-disable */
import React, { useState } from "react";
import styled from "styled-components/macro";
import { Paper, MenuItem, CircularProgress } from "@material-ui/core";
import { TextField, Typography, Button } from "../styles";
import API from "../../../axios/instances/API";

const Wrapper = styled(Paper)`
  padding: 20px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  border: 2px solid black;
  width: 580px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
`;
const ADMIN_ACCESS_LEVELS = {
  READ_ONLY: "Read-Only",
  ADMIN: "Admin",
  READ_ONLY_ORGANZINATION: "Read-Only Organization Admin",
  ORGANIZATION: "Organization Access",
};

const ModifyAdminTypeModal = ({
  modifyAdminInfo,
  setModifyAdminInfo,
  setSnackbarText,
  mutate,
  userHasOrgAccess,
}) => {
  const { currentPrivelege, name } = modifyAdminInfo;
  const [selectedAdminLevel, setSelectedAdminLevel] =
    useState(currentPrivelege);
  const [isUpdating, setIsUpdating] = useState(false);

  const handleUpdate = async () => {
    try {
      setIsUpdating(true);
      await API.put("dashboard/admin/details/", {
        id: modifyAdminInfo.id,
        read_only:
          selectedAdminLevel === ADMIN_ACCESS_LEVELS.READ_ONLY ||
          selectedAdminLevel === ADMIN_ACCESS_LEVELS.READ_ONLY_ORGANZINATION,
        organization_access:
          selectedAdminLevel === ADMIN_ACCESS_LEVELS.ORGANIZATION ||
          selectedAdminLevel === ADMIN_ACCESS_LEVELS.READ_ONLY_ORGANZINATION,
      });
      await mutate();
      setIsUpdating(false);
      setSnackbarText("Successfully updated admin privileges success");
      setModifyAdminInfo(null);
    } catch (error) {
      setIsUpdating(false);
      setSnackbarText("Failed to update admin privileges error");
    }
  };

  return (
    <Wrapper>
      <Typography style={{ fontSize: 16, fontWeight: "500" }}>
        Modify Admins Permissions Level
      </Typography>
      <Typography variant="subtitle1">{name}</Typography>
      <TextField
        fullWidth
        select
        variant="outlined"
        mt={8}
        size="small"
        value={selectedAdminLevel}
        onChange={(e) => setSelectedAdminLevel(e.target.value)}
      >
        <MenuItem value={ADMIN_ACCESS_LEVELS.ADMIN}>Admin Access</MenuItem>
        <MenuItem value={ADMIN_ACCESS_LEVELS.READ_ONLY}>Read-Only</MenuItem>
        {userHasOrgAccess && (
          <MenuItem value={ADMIN_ACCESS_LEVELS.READ_ONLY_ORGANZINATION}>
            Read-Only Organization Access
          </MenuItem>
        )}
        {userHasOrgAccess && (
          <MenuItem value={ADMIN_ACCESS_LEVELS.ORGANIZATION}>
            Organization Access
          </MenuItem>
        )}
      </TextField>
      <Button
        disabled={selectedAdminLevel === currentPrivelege || isUpdating}
        variant="contained"
        color="secondary"
        fullWidth
        mt={2}
        onClick={handleUpdate}
        endIcon={
          isUpdating ? (
            <CircularProgress size={14} style={{ color: "white" }} />
          ) : null
        }
      >
        {isUpdating ? "Saving" : "Save"}
      </Button>
    </Wrapper>
  );
};

export default ModifyAdminTypeModal;
