/* eslint-disable */
import React, { useState } from "react";
import { Copy } from "react-feather";
import API from "../../../../../axios/instances/API";
import { StyledItem } from "../styles";
import { QUIZSTATES, USER_TYPES } from "../../../../../storage/constants";

const Clone = ({
  id,
  mutate,
  setSnackbarText,
  userType,
  moduleState,
  handleClose,
  isRetailerModuleFromMarketplace,
  currentCompanyId,
}) => {
  const [isCloning, setCloning] = useState(false);

  if (
    [
      USER_TYPES.READ_ONLY,
      USER_TYPES.RETAIL_MANAGER_CANT_DEPLOY,
      USER_TYPES.RETAIL_MANAGER_CAN_DEPLOY,
    ].includes(userType)
  ) {
    return null;
  }

  if (isRetailerModuleFromMarketplace) {
    return null;
  }

  if (
    ![
      QUIZSTATES.LIVE,
      QUIZSTATES.CLOSED,
      QUIZSTATES.IN_DEVELOPMENT,
      QUIZSTATES.IN_REVIEW,
    ].includes(moduleState)
  ) {
    return null;
  }

  const handleClone = async () => {
    setCloning(true);
    try {
      await API.post("/dashboard/quiz/copy/", {
        quiz_id: id,
        target_company_ids: [currentCompanyId],
      });
      await mutate();
      setCloning(false);
      setSnackbarText("Successfully cloned module success");
      handleClose();
    } catch (error) {
      setCloning(false);
      setSnackbarText("Failed to clone module error");
      handleClose();
    }
  };

  return (
    <StyledItem onClick={handleClone} disabled={isCloning}>
      <Copy />
      <span>{isCloning ? "Cloning" : "Clone Module"}</span>
    </StyledItem>
  );
};

export default Clone;
