/* eslint-disable */
import React from 'react';
import useKeyCompanyStats from '../../../../../swr/hooks/RetailReports/useKeyCompanyStats';
import styled, { keyframes } from 'styled-components/macro';
import { Paper } from '@material-ui/core';
import { Book, Users, MapPin } from 'react-feather';
import ExtraInfo from '../../../../../components/ExtraInfo';
import { Typography } from '../../../styles';
import { DEFINITIONS } from '../../../../../storage/constants';

const fadeInRight = keyframes`
  0% {
    opacity: 0;
    transform: translateX(20px) scale(.85);
  }
  100% {
    opacity: 1;
    transform: translateX(0px) scale(1);
  }
`;

const Box = styled(Paper)`
  flex: 0.25;
  background-color: white;
  height: 100%;
  border-radius: 6px;
  padding: 16px;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  height: 128px;

  p {
    margin-top: 4px;
    font-size: 13px;
    font-weight: 500;
    color: darkgrey;
  }
`;

const BoldText = styled.span`
  font-size: 40px;
  font-weight: 800;
  margin-top: 0;
  color: black;
  animation: 300ms ${fadeInRight} ease-out;

  @media (max-width: 1100px) {
    font-size: 32px;
  }
`;

const UpperContainer = styled.div`
  display: grid;
  width: 100%;
  align-items: center;
  justify-content: flex-start;
  gap: 12px;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: repeat(1, 1fr);
  grid-template-areas: 'box1 box2 box3 box4';

  @media (max-width: 800px) {
    height: 360px;
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: repeat(2, 1fr);
    grid-template-areas:
      'box1 box1 box2 box2'
      'box3 box3 box4 box4';
  }
`;

const InnerContainer = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  width: 100%;
`;

const ContainerTwo = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: center;
  // border: 1px solid red;
`;

const StatBoxes = ({ data }) => {
  return (
    <div
      style={{
        width: '100%',
        padding: 0,
        borderRadius: 4,
        background: 'transparent',
      }}
    >
      <UpperContainer>
        <Box style={{ gridArea: 'box1' }}>
          <InnerContainer>
            <MapPin
              style={{
                height: 20,
                width: 20,
                color: 'black',
                marginBottom: 4,
              }}
            />
            <ContainerTwo>
              <p>Locations Count</p>
              <ExtraInfo text={DEFINITIONS.locationCount} />
            </ContainerTwo>
            <BoldText>{data.locations_count}</BoldText>
          </InnerContainer>
        </Box>
        <Box style={{ gridArea: 'box2' }}>
          <InnerContainer>
            <Users
              style={{
                height: 20,
                width: 20,
                color: 'black',
                marginBottom: 4,
              }}
            />
            <ContainerTwo>
              <p>Team Members Count</p>
              <ExtraInfo text={DEFINITIONS.teamMemberCount} />
            </ContainerTwo>
            <BoldText>{data.employees}</BoldText>
          </InnerContainer>
        </Box>
        <Box style={{ gridArea: 'box3' }}>
          <InnerContainer>
            <Book
              style={{
                height: 20,
                width: 20,
                color: 'black',
                marginBottom: 4,
              }}
            />
            <ContainerTwo>
              <p>Custom Modules Live</p>
              <ExtraInfo text={DEFINITIONS.customModsCount} />
            </ContainerTwo>
            <BoldText>{data.live_custom_quizzes}</BoldText>
          </InnerContainer>
        </Box>
        <Box style={{ gridArea: 'box4' }}>
          <InnerContainer>
            <Book
              style={{
                height: 20,
                width: 20,
                color: 'black',
                marginBottom: 4,
              }}
            />
            <ContainerTwo>
              <p>Marketplace Modules Live</p>
              <ExtraInfo text={DEFINITIONS.marketplaceModsCount} />
            </ContainerTwo>
            <BoldText>{data.live_marketplace_quizzes}</BoldText>
          </InnerContainer>
        </Box>
      </UpperContainer>
    </div>
  );
};

export default StatBoxes;
