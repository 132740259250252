/* eslint-disable */
import React from "react";
import styled from "styled-components/macro";
import AudienceOptions from "./AudienceOptions";
import SavedAudienceBox from "./SavedAudienceBox";
import ErrorScreen from "./ErrorScreen";

const ActiveDiv = styled.div`
  height: 46px;
  width: 46px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: eeeeee34;
  align-self: center;
`;

const Wrapper = styled.div`
  min-height: 340px;
  width: 100%;
  height: 100%;
  display: flex;
  padding: 4px;
  margin-top: 12px;
  align-items: flex-start;
  justify-content: flex-start;

  @media (max-width: 960px) {
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
`;

const AudienceSelector = () => (
  <Wrapper>
    <AudienceOptions />
    <ActiveDiv />
    <SavedAudienceBox />
    <ErrorScreen />
  </Wrapper>
);

export default AudienceSelector;
