/* eslint-disable */
/* eslint-disable max-len */
import React, { useContext, lazy, Suspense } from "react";
import { Helmet } from "react-helmet-async";
import { GlobalState } from "../../../store/GlobalState";
import RetailHome from "./retail";
import Guides from "../../../components/Guides";

const ManagerHome = lazy(() => import("./manager/index.js"));
const VendorHome = lazy(() => import("./vendor/index.js"));

const Home = () => {
  const { state } = useContext(GlobalState);
  const { config, isRetail } = state;
  const { user } = config;
  const { job } = user;

  // ------ 3 Versions Of Home Page ------- //

  // Retail Admin //
  if (job === "C" && isRetail) {
    return (
      <>
        <Helmet title="Spiffy | Home" />

        <RetailHome config={config} />
        <Guides />
      </>
    );
  }

  // Retail Manager //
  if (job === "M" && isRetail) {
    return (
      <>
        <Helmet title="Spiffy | Home" />
        <Suspense fallback={<span />}>
          <ManagerHome />
        </Suspense>
      </>
    );
  }

  // Vendor //
  return (
    <>
      <Helmet title="Spiffy | Home" />
      <Suspense fallback={<span />}>
        <VendorHome />
      </Suspense>
      <Guides />
    </>
  );
};

export default Home;
