/* eslint-disable */
import React, { useEffect, useContext, useState } from "react";
import { Redirect, Route, useHistory } from "react-router-dom";
import styled from "styled-components/macro";
import PropTypes from "prop-types";
import { getToken, clearTokens } from "../../storage/helpers";
import Dashboard from "../../layouts/Dashboard";
import API from "../../axios/instances/API";
import { GlobalState } from "../../store/GlobalState";
import ACTIONS from "../../store/actions";
import LoaderWrapper from "../Loaders/LoaderWrapper";

const CenterLoaderWrapper = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const SecondProtected = ({ component: Component, ...rest }) => {
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);
  const token = getToken();
  const { state, dispatch } = useContext(GlobalState);
  const { config, initialLog, refreshConfig } = state;
  useEffect(() => {
    if (!rest.needsAuth || initialLog) {
      // Doesn't run if already signed in or in public pages //
      return;
    }
    const setConfig = async () => {
      try {
        setIsLoading(true);
        const res = await API.get("dashboard/account/config/");
        dispatch({ type: ACTIONS.ADD_CONFIG, payload: res.data });
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
        clearTokens();
        history.push({
          pathname: "/",
          state: {
            fromConfig: true,
            search: history.location.search,
            pathName: history.location.pathname,
          },
        });
      }
    };
    setConfig();
  }, [refreshConfig]);

  if ((isLoading || Object.keys(config).length === 0) && rest.needsAuth) {
    return (
      <CenterLoaderWrapper>
        <LoaderWrapper text="User Data" />
      </CenterLoaderWrapper>
    );
  }

  const { current_company: currentCompany, user } = config || {};

  const { isRetail } = state;
  const job = config?.user?.job;
  // const job = "C";

  if (!token && rest.needsAuth) {
    // If You dont have a token and try to get to a page
    // that needs auth, you get redirected to the home page
    return <Redirect to="/" />;
  }

  if (rest.path === "/" && token) {
    // If you have a token already, bounces you to dashboard home
    return <Redirect to={{ pathname: "/home/" }} />;
  }

  // If they are a Vendor.. don't let them go to non-vendor Pages
  if (!rest.allowVendor && !isRetail) {
    return <Redirect to={{ pathname: "/home/" }} />;
  }

  // If you only have Manager Status.. dont let them go to Non Manager Pages
  if (!rest.allowManager && job === "M") {
    return <Redirect to={{ pathname: "/home/" }} />;
  }

  // If you are retail, you do not have a following page
  if (isRetail && !rest.allowRetail) {
    return <Redirect to={{ pathname: "/home/" }} />;
  }

  // Wrap in Dashboard if you are in the dashboard
  if (rest.needsAuth) {
    // eslint-disable-next-line no-unused-vars
    return (
      <Dashboard>
        <Route
          {...rest}
          render={(props) => <Component {...rest} {...props} />}
        />
      </Dashboard>
    );
  }

  return (
    <Route {...rest} render={(props) => <Component {...rest} {...props} />} />
  );
};

export default SecondProtected;

SecondProtected.propTypes = {
  component: PropTypes.elementType.isRequired,
};
