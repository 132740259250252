/* eslint-disable */
// TODO -> move to components
import React from "react";
import PropTypes from "prop-types";
import { Tooltip } from "@material-ui/core";
import styled from "styled-components/macro";
import { fadeIn } from "../../marketplace/marketplaceStyles";
import { ENGAGEMENT_TYPES } from "../../../../storage/constants";

const SubmissionChipAll = styled.div`
  padding: 4px 6px;
  border-radius: 4px;
  background-color: transparent;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${(props) => {
    if (props.small === "yes") {
      return 24;
    }
    return 40;
  }}px;
  border: 1px solid #eeeeee;

  animation: 600ms ${fadeIn} ease-out;
  height: 22px;

  span {
    font-weight: 600;
    font-size: 12px;
    color: rgba(0, 0, 0, 0.8);
  }
`;

const SubmissionChipExtraCredit = styled(SubmissionChipAll)`
  background-color: #ffeeb091;
  border: none;

  width: ${(props) => {
    if (props.small === "yes") {
      return 24;
    }
    return 80;
  }}px;

  span {
    color: #aa9700;
  }
`;

const SubmissionChipAssigned = styled(SubmissionChipAll)`
  background-color: rgba(0, 0, 250, 0.1);
  width: ${(props) => {
    if (props.small === "yes") {
      return 24;
    }
    return 80;
  }}px;

  height: 22px;

  span {
    color: #587dea;
  }
`;

const SubmissionTypeChip = ({ submissionType, small = false, isVendor }) => {
  if (!submissionType) return null;

  if (submissionType === ENGAGEMENT_TYPES.ALL) {
    if (small) return null;

    // return <span style={{ fontWeight: "600", marginLeft: 4 }}>All</span>;
    return (
      <SubmissionChipAll>
        <span>All</span>
      </SubmissionChipAll>
    );
  }

  if (submissionType === ENGAGEMENT_TYPES.ASSIGNED) {
    if (small) {
      return (
        <Tooltip title="Showing Assigned Only" arrow placement="right">
          <SubmissionChipAssigned small="yes" style={{ cursor: "pointer" }}>
            <span>A</span>
          </SubmissionChipAssigned>
        </Tooltip>
      );
    }
    return (
      <Tooltip title="Showing Assigned Only" arrow placement="right">
        <SubmissionChipAssigned small="no">
          <span>Assigned</span>
        </SubmissionChipAssigned>
      </Tooltip>
    );
  }

  if (submissionType === ENGAGEMENT_TYPES.EXTRACREDIT) {
    if (small) {
      return (
        <Tooltip
          title={
            isVendor ? "Showing Follower Only" : "Showing Extra Credit Only"
          }
          arrow
        >
          <SubmissionChipExtraCredit small="yes" style={{ cursor: "pointer" }}>
            <span>{isVendor ? "F" : "EC"}</span>
          </SubmissionChipExtraCredit>
        </Tooltip>
      );
    }

    return (
      <Tooltip
        title={isVendor ? "Showing Follower Only" : "Showing Extra Credit Only"}
        arrow
      >
        <SubmissionChipExtraCredit>
          <span>{isVendor ? "Follower" : "Extra Credit"}</span>
        </SubmissionChipExtraCredit>
      </Tooltip>
    );
  }

  return null;
};

export default SubmissionTypeChip;

SubmissionTypeChip.propTypes = {
  submissionType: PropTypes.string.isRequired,
  small: PropTypes.bool,
};

SubmissionTypeChip.defaultProps = {
  small: false,
};
