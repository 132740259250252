/* eslint-disable */
import { Paper } from "@material-ui/core";
import PropTypes from "prop-types";
import React, { useState } from "react";
import styled from "styled-components/macro";
import LoaderWrapper from "../../../../../components/Loaders/LoaderWrapper";
import PrivateHeader from "../../../../../components/PrivateHeader";
import ErrorBlockWithPermissions from "../../../../../components/UI/ErrorBlockWithPermissions";
import { ENGAGEMENT_TYPES } from "../../../../../storage/constants";
import { getSrcTwo } from "../../../../../storage/helpers";
import useVendorModuleReport from "../../../../../swr/hooks/VendorReports/useVendorModuleReport";
import EngagementTypeSelect from "../../reuseableComponents/EngagementTypeSelect";
import MembersInMultipleCompaniesWarning from "../../reuseableComponents/MembersInMultipleCompaniesWarning";
import AnswerBreakdown from "./AnswerBreakdown";
import CompanyTable from "./CompanyTable";
import EngagementByWeekBarChart from "./EngagementByWeekBarChart";
import FeedbackTable from "./FeedbackTable";
import ModuleDetails from "./ModuleDetails";
import SubmissionsTable from "./SubmissionsTable";

const ModuleImg = styled.img`
  height: 60px;
  width: 60px;
  background-color: white;
  border-radius: 2px;
  object-fit: cover;
`;

const Title = styled.p`
  font-size: 16px;
  font-weight: 600;
  color: black;
  margin-right: 8px;
`;
const FlexTitle = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-left: 8px;
  margin-top: 24px;
  margin-bottom: 8px;
`;

const VendorProgress = ({ id }) => {
  const { isError, isLoading, data } = useVendorModuleReport(id);
  const [engagementType, setEngagementType] = useState(ENGAGEMENT_TYPES.ALL);

  if (isLoading) {
    return <LoaderWrapper text="Module Progress" />;
  }

  if (isError) {
    return (
      <>
        <PrivateHeader header="Reports" secondary="Module" />
        <ErrorBlockWithPermissions errorObj={isError} />
      </>
    );
  }

  const { quiz_info } = data;

  return (
    <>
      <PrivateHeader header="Reports" secondary="Module" />
      <Paper
        style={{
          width: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          padding: 10,
          backgroundColor: "white",
          borderRadius: 4,
        }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-start",
          }}
        >
          <ModuleImg
            src={getSrcTwo(quiz_info.image)}
            alt="module"
            style={{ border: "1px solid #eeeeee", borderRadius: 4 }}
          />
          <div
            style={{
              padding: "0px 12px",
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              justifyContent: "center",
            }}
          >
            <span style={{ fontSize: 20, fontWeight: 600, marginBottom: -2 }}>
              {quiz_info.name}
            </span>
            <span style={{ fontSize: 11, color: "darkgrey" }}>
              {quiz_info.description}
            </span>
          </div>
        </div>
        <EngagementTypeSelect
          engagementType={engagementType}
          setEngagementType={setEngagementType}
          isVendor
        />
      </Paper>
      <ModuleDetails data={data} engagementType={engagementType} />
      {data.users_with_team_members_in_multiple_companies > 0 && (
        <>
          <div style={{ height: 18 }} />
          <MembersInMultipleCompaniesWarning
            num={data.users_with_team_members_in_multiple_companies}
          />
        </>
      )}
      <div style={{ height: 16 }} />
      <CompanyTable
        companies={data.engaged_companies}
        engagementType={engagementType}
      />
      {data.all_submissions.length > 1 && (
        <>
          <div style={{ height: 16 }} />

          <SubmissionsTable
            submissions={data.all_submissions}
            users={data.users_info}
            engagementType={engagementType}
            moduleName={quiz_info.name}
          />
          <div style={{ height: 16 }} />
          <EngagementByWeekBarChart
            data={data}
            engagementType={engagementType}
          />
        </>
      )}
      {data.feedback.length > 0 && (
        <>
          <div style={{ height: 16 }} />

          <FeedbackTable feedback={data.feedback} usersInfo={data.users_info} />
        </>
      )}
      {data.all_submissions.length > 1 && (
        <>
          <FlexTitle>
            <Title>Questions Breakdown</Title>
            <div />
          </FlexTitle>
          <AnswerBreakdown
            questions={data.questions_breakdown}
            engagementType={engagementType}
          />
        </>
      )}
    </>
  );
};

export default VendorProgress;

VendorProgress.propTypes = {
  id: PropTypes.string.isRequired,
};
