/* eslint-disable */
import React from "react";
import { Tooltip } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import styled from "styled-components/macro";
import { X, Check } from "react-feather";

const useStyles = makeStyles(() => ({
  tooltip: {
    borderRadius: 4,
    padding: 0,
    maxWidth: "none",
    height: 30,
    background: "white",
    border: "1px solid slategray",
    padding: 1,
  },
}));

const Container = styled.div`
  cursor: pointer;
  position relative;
  padding: 4px;
  border-radius: 6px;
  max-width: 70px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4px;

  &:hover {
    background-color: #eeeeee;
  }
`;

const InnerNo = () => (
  <div
    style={{
      height: "100%",
      width: "100%",
      padding: 4,
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-start",
      gap: 4,
    }}
  >
    <X height={10} width={10} color="firebrick" />

    <span style={{ fontWeight: "500", color: "black" }}>
      Supervisors at this location do not have deploy permissions.
    </span>
  </div>
);

const InnerYes = () => (
  <div
    style={{
      height: "100%",
      width: "100%",
      padding: 4,
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-start",
      gap: 4,
    }}
  >
    <Check height={10} width={10} color="mediumseagreen" />

    <span style={{ fontWeight: "500", color: "black" }}>
      Supervisors at this location have deploy permissions.
    </span>
  </div>
);

const SuperDeloyTooltip = ({ value }) => {
  const classes = useStyles();

  if (value === "Yes") {
    return (
      <Tooltip
        classes={{ tooltip: classes.tooltip }}
        title={<InnerYes />}
        arrow
        placement="top"
      >
        <Container>
          <Check height={10} width={10} color="mediumseagreen" />
          <span style={{ color: "darkgrey", fontSize: 10 }}>Deploy</span>
        </Container>
      </Tooltip>
    );
  }

  if (value === "No") {
    return (
      <Tooltip
        classes={{ tooltip: classes.tooltip }}
        title={<InnerNo />}
        arrow
        placement="top"
      >
        <Container>
          <X height={10} width={10} color="firebrick" />
          <span style={{ color: "darkgrey", fontSize: 10 }}>Deploy</span>
        </Container>
      </Tooltip>
    );
  }

  return null;
};

export default SuperDeloyTooltip;
