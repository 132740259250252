/* eslint-disable */
import React, { useState } from "react";
import API from "../../axios/instances/API";
import ExtraInfo from "../ExtraInfo";
import { CircularProgress, Switch, Typography } from "@material-ui/core";
import styled from "styled-components/macro";

const ProgressMarginLeft = styled(CircularProgress)`
  margin-left: 2px;
`;

const SwitchContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const Question = styled(Typography)`
  color: ${(props) => props.theme.palette.text.primary};
  font-weight: 400;
  font-size: 12px;
`;

const FlexDiv = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
`;

const CompanySwitches = ({ company, mutate, isRetail }) => {
  const [autoIsUpdating, setAutoIsUpdating] = useState(false);
  const [autoAcceptUpdating, setAutoAcceptUpdating] = useState(false);
  const [displayIncorrectUpdating, setDisplayIncorrectUpdating] =
    useState(false);

  const handleAutoAcceptChange = async (e) => {
    setAutoIsUpdating(true);
    try {
      await API.put("/dashboard/company/details/", {
        auto_accept_users: e.target.checked,
      });
      await mutate();
      setAutoIsUpdating(false);
    } catch (error) {
      setAutoIsUpdating(false);
    }
  };

  const handleDisplayIncorrectChange = async (e) => {
    setDisplayIncorrectUpdating(true);
    try {
      await API.put("/dashboard/company/details/", {
        display_answers: e.target.checked,
      });
      await mutate();
      setDisplayIncorrectUpdating(false);
    } catch (error) {
      setDisplayIncorrectUpdating(false);
    }
  };

  const handleAutoAcceptModulesChange = async (e) => {
    setAutoAcceptUpdating(true);
    try {
      await API.put("/dashboard/company/details/", {
        auto_accept_modules: e.target.checked,
      });
      await mutate();
      setAutoAcceptUpdating(false);
    } catch (error) {
      setAutoAcceptUpdating(false);
    }
  };

  return (
    <>
      <Typography
        style={{ fontSize: 11, color: "darkgrey", fontWeight: "600" }}
      >
        COMPANY OPTIONS
      </Typography>
      <div style={{ height: 4 }} />
      {isRetail && (
        <SwitchContainer>
          <FlexDiv>
            <Question>Auto Accept Users?</Question>
            {autoIsUpdating ? (
              <ProgressMarginLeft size={12} ml={1} />
            ) : (
              <ExtraInfo
                text="Currently you do not automatically accept users. You will have to approve everyone who tries to join your team."
                saved={company.autoAcceptUsers}
                savedText="You automatically accept users who try to join your team"
              />
            )}
          </FlexDiv>
          <Switch
            checked={company.autoAcceptUsers}
            onChange={handleAutoAcceptChange}
            disabled={autoIsUpdating}
            size="small"
          />
        </SwitchContainer>
      )}
      <SwitchContainer>
        <FlexDiv>
          <Question>Display Incorrect Answers?</Question>
          {displayIncorrectUpdating ? (
            <ProgressMarginLeft size={12} ml={1} />
          ) : (
            <ExtraInfo
              // text="Currently you do not automatically display incorrect answers. You will not show team members which questions they got wrong."
              text="You can configure whether users can see incorrect responses after the submission when creating a module. Turning this setting ON will automatically activate this setting for newly created modules."
              saved={company.displayAnswers}
              savedText="You allow users to see which answers they got wrong after submission. This setting can be overridden in the module builder basics screen."
            />
          )}
        </FlexDiv>
        <Switch
          checked={company.displayAnswers}
          onChange={handleDisplayIncorrectChange}
          disabled={displayIncorrectUpdating}
          size="small"
        />
      </SwitchContainer>
      {isRetail && (
        <SwitchContainer>
          <FlexDiv>
            <Question>Auto Accept Modules?</Question>
            {autoAcceptUpdating ? (
              <ProgressMarginLeft size={12} ml={1} />
            ) : (
              <ExtraInfo
                text="You do not automatically accept suggested modules"
                saved={company.autoAcceptModules}
                savedText="Suggested modules are automatically accepted and deployed"
              />
            )}
          </FlexDiv>
          <Switch
            size="small"
            checked={company.autoAcceptModules}
            onChange={handleAutoAcceptModulesChange}
            disabled={autoAcceptUpdating}
          />
        </SwitchContainer>
      )}
    </>
  );
};

export default CompanySwitches;
