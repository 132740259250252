/* eslint-disable */
import { Paper } from "@material-ui/core";
import PropTypes from "prop-types";
import React, { useState } from "react";
import { BarChart2, PieChart, Users } from "react-feather";
import styled from "styled-components/macro";
import LoaderWrapper from "../../../../../components/Loaders/LoaderWrapper";
import PrivateHeader from "../../../../../components/PrivateHeader";
import SmallBlockWide from "../../../../../components/SmallBlockWide";
import ErrorBlockWithPermissions from "../../../../../components/UI/ErrorBlockWithPermissions";
import {
  ENGAGEMENT_TYPES,
  returnDefinitionByEngagementType,
} from "../../../../../storage/constants";
import useSingleCompanyBreakdown from "../../../../../swr/hooks/VendorReports/useSingleCompanyBreakdown";
import EngagementTypeSelect from "../../reuseableComponents/EngagementTypeSelect";
import MembersInMultipleCompaniesWarning from "../../reuseableComponents/MembersInMultipleCompaniesWarning";
import {
  retCompsByEngagementType,
  retSubsByEngagementType,
} from "../../utils/utils";
import LocationsTable from "./LocationsTable";
import SubmissionsTable from "./SubmissionsTable";
import UsersTable from "./UsersTable";

const Container = styled.div`
  display: flex;
  width: 100%;
  padding: 10px 0px;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
  gap: 12px;
`;

const StyledImage = styled.img`
  object-fit: contain;
  height: 65px;
  width 88px;
  border-radius: 4px;
  border: 1px solid #EEEEEE;
  padding: 4px;
`;

const SingleCompanyBreakdown = ({ match }) => {
  const { id } = match.params;

  const [engagementType, setEngagementType] = useState(ENGAGEMENT_TYPES.ALL);
  const { data, isError, isLoading } = useSingleCompanyBreakdown(id);
  if (isLoading) {
    return (
      <>
        <PrivateHeader header="Company Report" secondary />
        <LoaderWrapper text="Company Report" />
      </>
    );
  }

  if (isError) {
    return (
      <>
        <PrivateHeader header="Company Report" secondary />
        <ErrorBlockWithPermissions errorObj={isError} />
      </>
    );
  }

  const subs = retSubsByEngagementType(data.totals, engagementType);
  const comps = retCompsByEngagementType(data.totals, engagementType);

  return (
    <>
      <PrivateHeader header="Company Report" secondary={data?.company_name} />
      <Paper
        style={{
          width: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          padding: 10,
          backgroundColor: "white",
          borderRadius: 4,
        }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-start",
          }}
        >
          <StyledImage src={data.company_image} />
          <div
            style={{
              display: "flex",
              alignItems: "flex-start",
              justifyContent: "center",
              flexDirection: "column",
              paddingLeft: 12,
            }}
          >
            <span style={{ fontSize: 20, fontWeight: 600, marginBottom: -2 }}>
              {data.company_name}
            </span>
            <span style={{ color: "darkgrey", fontSize: 12, fontWeight: 300 }}>
              {data.locations_breakdown.length}{" "}
              {data.locations_breakdown.length > 1 ? "locations" : "location"}{" "}
              at this company
            </span>
          </div>
        </div>
        <EngagementTypeSelect
          engagementType={engagementType}
          setEngagementType={setEngagementType}
          isVendor
        />
      </Paper>
      <Container engagementType={engagementType}>
        <SmallBlockWide
          title="Completions"
          Icon={PieChart}
          stat={comps}
          engagementType={engagementType}
          definition={returnDefinitionByEngagementType(
            "completions",
            engagementType
          )}
        />
        <SmallBlockWide
          title="Submissions"
          Icon={BarChart2}
          stat={subs}
          engagementType={engagementType}
          definition={returnDefinitionByEngagementType(
            "submissions",
            engagementType
          )}
        />
        <SmallBlockWide
          title="Engaged Team Members"
          Icon={Users}
          stat={data.totals.active_employees}
          engagementType={null}
        />
      </Container>
      {data?.users_with_team_members_in_multiple_locations >= 1 && (
        <>
          <div style={{ height: 12 }} />
          <MembersInMultipleCompaniesWarning
            num={data.users_with_team_members_in_multiple_locations}
            isSingleCompanyBreakdown
          />
        </>
      )}
      <div style={{ height: 12 }} />

      <LocationsTable
        locations={data.locations_breakdown}
        engagementType={engagementType}
      />
      <div style={{ height: 12 }} />
      <UsersTable users={data.employees_info} engagementType={engagementType} />
      <div style={{ height: 12 }} />

      <SubmissionsTable
        companyId={id}
        companyName={data.company_name}
        engagementType={engagementType}
      />
    </>
  );
};

export default SingleCompanyBreakdown;

SingleCompanyBreakdown.propTypes = {
  match: PropTypes.object.isRequired,
};
