import useSWR from 'swr';
import API from '../../../axios/instances/API';

const fetcher = async (url) => {
  const { data } = await API.get(url);
  return data.users;
};

const useTeamMembers = () => {
  const { data, error, mutate } = useSWR('/dashboard/teams/', fetcher, {
    dedupingInterval: 4000,
    revalidateOnFocus: false,
    revalidateIfStale: false,
    revalidateOnReconnect: false,
  });

  return {
    team: data,
    isLoading: !error && !data,
    isError: error,
    mutate,
  };
};

export default useTeamMembers;
