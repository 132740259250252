/* eslint-disable */
import React from "react";
import styled from "styled-components/macro";

const Wrapper = styled.div`
  height: 22px;
  width: fit-content;
  padding: 0px 6px;

  background: #eeeeee44;

  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #eeeeee;

  span {
    color: black;
    font-size: 10px;
    font-weight: 600;
    letter-spacing: 2px;
  }
`;

const DisplayScore = ({ value }) => (
  <Wrapper>
    <span>{value}</span>
  </Wrapper>
);

export default DisplayScore;
