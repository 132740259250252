/* eslint-disable */
/* eslint-disable no-return-assign */
import React from "react";
import PropTypes from "prop-types";
import { LazyLoadImage } from "react-lazy-load-image-component";
import styled from "styled-components/macro";
import NoImage from "../assets/images/empty-small.jpg";

const FlexDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;

  img {
    margin-right: 4px;
    height: 36px;
    width: 36px;
    object-fit: contain;
    border-radius: 18px;
    border: 1px solid #eeeeee;
  }
`;

const ShowCompanyWithImage = ({ value, logo, isWide }) => {
  if (logo) {
    return (
      <FlexDiv isWide={isWide ? "yes" : "no"}>
        <LazyLoadImage
          src={logo}
          effect="opacity"
          // eslint-disable-next-line no-param-reassign
          onError={(event) => (event.target.src = NoImage)}
        />
        <span style={{ color: "darkgray" }}>{value}</span>
      </FlexDiv>
    );
  }

  return <span>{value}</span>;
};

export default ShowCompanyWithImage;

ShowCompanyWithImage.propTypes = {
  value: PropTypes.string.isRequired,
  logo: PropTypes.string.isRequired,
  isWide: PropTypes.bool,
};

ShowCompanyWithImage.defaultProps = {
  isWide: null,
};
