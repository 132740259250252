/* eslint-disable */
import React, { useState } from "react";
import PropTypes from "prop-types";
import styled from "styled-components/macro";
import { AlertTitle, Alert } from "@material-ui/lab";
import { DeleteWrapper, DeleteButton, Typography } from "../styles";
import API from "../../../axios/instances/API";

const ButtonHolder = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const DeleteAdminModal = ({
  deleteInfo,
  setSnackbarText,
  setDeleteInfo,
  mutate,
}) => {
  const [isdeleting, setIsDeleting] = useState(false);
  const [selectKeepTms, setSelectedKeepTms] = useState(
    deleteInfo.activeTeamMembers.length > 0
  );

  const handleDelete = async (e) => {
    e.preventDefault();
    setIsDeleting(true);
    try {
      await API.delete("dashboard/admin/details/", {
        data: {
          id: deleteInfo.id,
        },
      });

      await mutate();
      setIsDeleting(false);
      setSnackbarText(deleteInfo.snack);
      return setDeleteInfo(null);
    } catch (error) {
      setSnackbarText("Failed to delete admin error");
      return setIsDeleting(false);
    }
  };

  const handleDeleteWithTeamMember = async (selected) => {
    try {
      await API.delete("dashboard/admin/details/", {
        data: {
          id: deleteInfo.id,
          keep_team_members: selected,
        },
      });

      await mutate();
      setIsDeleting(false);
      setSnackbarText(deleteInfo.snack);
      setDeleteInfo(null);
    } catch (error) {
      setSnackbarText("Failed to delete admin error");
      setIsDeleting(false);
    }
  };

  return (
    <DeleteWrapper style={{ border: "1px solid tomato", width: 460 }}>
      <form id="deleteForm" onSubmit={handleDelete}>
        <Typography variant="h5" component="h5">
          {deleteInfo.header}?
        </Typography>

        <Typography
          mb={selectKeepTms ? 2 : 8}
          mt={1}
          style={{ fontWeight: "400", fontSize: 14 }}
        >
          {deleteInfo.name}
        </Typography>
        {selectKeepTms && (
          <>
            <Alert
              severity="warning"
              mt={1}
              mb={4}
              style={{ textAlign: "left" }}
            >
              <AlertTitle>
                This admin also has an active team member account.
              </AlertTitle>
              Would you like to delete the team member account for{" "}
              {deleteInfo.name} as well?
            </Alert>
            <div style={{ height: 8 }} />
          </>
        )}
        {!selectKeepTms ? (
          <DeleteButton
            type="submit"
            form="deleteForm"
            fullWidth
            disabled={isdeleting}
            p={2}
          >
            {isdeleting ? "..." : "Confirm"}
          </DeleteButton>
        ) : (
          <ButtonHolder>
            <DeleteButton
              style={{ flex: 1 }}
              mr={1}
              onClick={() => handleDeleteWithTeamMember(true)}
            >
              Delete Admin
            </DeleteButton>
            <DeleteButton
              style={{ flex: 1 }}
              ml={1}
              onClick={() => handleDeleteWithTeamMember(false)}
            >
              Delete Both
            </DeleteButton>
          </ButtonHolder>
        )}
      </form>
    </DeleteWrapper>
  );
};

export default DeleteAdminModal;

DeleteAdminModal.propTypes = {
  deleteInfo: PropTypes.object,
  setSnackbarText: PropTypes.func.isRequired,
  setDeleteInfo: PropTypes.func.isRequired,
  mutate: PropTypes.func.isRequired,
};

DeleteAdminModal.defaultProps = {
  deleteInfo: null,
};
