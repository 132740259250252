/* eslint-disable */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Paper, MenuItem } from '@material-ui/core';
import styled from 'styled-components/macro';
import { Typography, Divider, TextField } from '../../styles';
import { getTimeStats } from './utils';
import { OverSizedText } from '../../home/styles';
import { ENGAGEMENT_TYPES } from '../../../../storage/constants';

const Separate = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  margin-bottom: 4px;
`;

const CustomTF = styled(TextField)`
  transform: translateY(-4px);
`;

const OverSizedSmaller = styled(OverSizedText)`
  font-size: 18px;
`;

const OverSizedVerySmall = styled(OverSizedText)`
  font-size: 16px;
  color: black;
  font-weight: 500;
`;

const OverSizeContainer = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
`;

const Wrapper = styled(Paper)`
  padding: 20px;
  flex: 0.5;
  height: 100%;
  border-radius: 6px;
`;

const AttemptsSpan = styled.span`
  font-size: 12px;
  text-align: center;
  color: ${props => props.theme.palette.grey[500]};
  font-weight: 600;
  display: block;
  margin-top: 2px;
`;

const TimeStats = ({ submissions, daysSinceLast, mostAttempts, engagementType }) => {
  const [timePeriod, setTimePeriod] = useState('Month');
  const {
    totalUniqueModules,
    submissionsLastTimePeriod,
    passedLastTimePeriod,
    passedFirstTry,
    firstTryPercentage,
    averageScore,
  } = getTimeStats(submissions, timePeriod);

  if (submissions.length < 10) {
    return (
      <Wrapper>
        <Typography variant="subtitle2">
          Submission stats will show up when employee has 10 submissions.
        </Typography>
      </Wrapper>
    );
  }

  return (
    <Wrapper
      style={{
        boxShadow:
          engagementType === ENGAGEMENT_TYPES.ASSIGNED
            ? '0px 0px 2px 2px rgba(0, 0, 250, .4)'
            : engagementType === ENGAGEMENT_TYPES.EXTRACREDIT
            ? '0px 0px 1px 2px gold'
            : '',
      }}
    >
      <Separate>
        <Typography
          variant="h5"
          mb={1}
          mt={1}
        >
          Submission Stats
        </Typography>
        <CustomTF
          select
          value={timePeriod}
          onChange={e => setTimePeriod(e.target.value)}
          variant="outlined"
          size="small"
          mt={0}
        >
          <MenuItem value="Week">Week</MenuItem>
          <MenuItem value="Month">Month</MenuItem>
          <MenuItem value="Quarter">Quarter</MenuItem>
          <MenuItem value="Year">Year</MenuItem>
        </CustomTF>
      </Separate>
      <Divider mb={3} />

      <div style={{ height: 12 }} />
      <Separate>
        <Typography variant="subtitle2">
          {`Unique modules attempted in the last ${timePeriod}`}
        </Typography>
        <OverSizedVerySmall>{totalUniqueModules}</OverSizedVerySmall>
      </Separate>
      <Separate>
        <Typography variant="subtitle2">{`Submissions in the last ${timePeriod}`}</Typography>
        <OverSizedVerySmall>{submissionsLastTimePeriod}</OverSizedVerySmall>
      </Separate>
      <Separate>
        <Typography variant="subtitle2">{`Completions in the last ${timePeriod}`}</Typography>
        <OverSizedVerySmall>{passedLastTimePeriod}</OverSizedVerySmall>
      </Separate>
      <Separate>
        <Typography variant="subtitle2">{`Average Score in the last ${timePeriod}`}</Typography>
        <OverSizedVerySmall>{averageScore.averageScore}%</OverSizedVerySmall>
      </Separate>
      <Separate>
        <Typography variant="subtitle2">{`Modules passed first try in the last ${timePeriod}`}</Typography>
        <OverSizedVerySmall>{passedFirstTry}</OverSizedVerySmall>
      </Separate>
      <Separate>
        <Typography variant="subtitle2">{`First try percentage in the last ${timePeriod}`}</Typography>
        <OverSizedVerySmall>{firstTryPercentage}%</OverSizedVerySmall>
      </Separate>

      <Divider
        mt={4}
        mb={4}
      />
      <Separate>
        <OverSizeContainer>
          <Typography variant="subtitle2">Most Attempted Module (All Time)</Typography>
          <OverSizedSmaller>{mostAttempts.name}</OverSizedSmaller>
          <AttemptsSpan>{`(${mostAttempts.times} attempts)`}</AttemptsSpan>
        </OverSizeContainer>
        <OverSizeContainer>
          <Typography variant="subtitle2">Time Since Last Submission</Typography>
          <OverSizedSmaller>{daysSinceLast}</OverSizedSmaller>
        </OverSizeContainer>
      </Separate>
    </Wrapper>
  );
};

export default TimeStats;

TimeStats.propTypes = {
  submissions: PropTypes.array.isRequired,
  daysSinceLast: PropTypes.string.isRequired,
  mostAttempts: PropTypes.object.isRequired,
};
