/* eslint-disable */
import { Tooltip } from "@material-ui/core";
import React from "react";
import styled from "styled-components/macro";
import { Info } from "react-feather";

const Wrapper = styled.div`
  border: 1px solid #eeeeee;
  padding: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  margin-right: 4px;
  gap: 8px;
  border-radius: 6px;
  background: #eeeeee50;
`;

const TooltipContainer = styled.div`
  height: 20px;
  width: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border-radius: 4px;
  background: transparent;

  &:hover {
    background: #eeeeee;
  }
`;

const LimitsMsg = ({
  title = "title",
  current = 0,
  limit = 0,
  explanationText = "explanation text",
}) => {
  if (limit === 0) {
    return (
      <Wrapper>
        <p style={{ fontWeight: "600", color: "slategrey", fontSize: 11 }}>
          {title} Limit: <b style={{ color: "firebrick" }}>0</b>
        </p>
      </Wrapper>
    );
  }

  if (limit === 32767) {
    return (
      <Wrapper>
        <p style={{ fontWeight: "600", color: "slategrey", fontSize: 11 }}>
          {title} Limit: <b style={{ color: "mediumseagreen" }}>Unlimited</b>
        </p>
      </Wrapper>
    );
  }

  const isOverLimit = current >= limit;

  return (
    <Wrapper isoverlimit={isOverLimit ? "Y" : "N"}>
      <p
        style={{
          fontWeight: "600",
          color: isOverLimit ? "firebrick" : "slategrey",
          fontSize: 11,
        }}
      >
        {title} limit: <b>{current}</b> / <b>{limit}</b>
      </p>
      <Tooltip arrow title={explanationText}>
        <TooltipContainer>
          <Info
            height={13}
            width={13}
            color={isOverLimit ? "firebrick" : "slategrey"}
          />
        </TooltipContainer>
      </Tooltip>
    </Wrapper>
  );
};

export default LimitsMsg;
