/* eslint-disable */
import React, { useState } from "react";
import styled from "styled-components/macro";
import { Button, Paper } from "@material-ui/core";
import { Check } from "react-feather";

const Wrapper = styled(Paper)`
  padding: 10px;
  width: 340px;
  height: 400px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  text-align: left;
  max-width: 520px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
`;

const Item = styled.div`
  width: 100%;
  padding: 8px;
  margin-bottom: 4px;
  border-radius: 6px;
  background: #eeeeee77;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;

  &:hover {
    transform: scale(1.003);
  }
`;

const List = styled.div`
  flex: 1;
  width: 100%;
  margin-bottom: 8px;
  padding-top: 8px;
  overflow-y: scroll;
`;

const TemplatesModal = ({ handleApply, handleClose, audienceList }) => {
  const [selected, setSelected] = useState(null);
  const isDisabled = !selected;

  return (
    <Wrapper>
      <span style={{ fontSize: 16, fontWeight: "600" }}>Select Template</span>
      <List>
        {audienceList.length === 0 ? (
          <div
            style={{
              width: "100%",
              height: "100%",

              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              flexDirection: "column",
            }}
          >
            <span style={{ fontSize: 12, fontWeight: "500" }}>
              No audience templates created yet
            </span>
            <span style={{ color: "darkgrey", fontSize: 11 }}>
              Create audience templates to use this feature
            </span>
          </div>
        ) : (
          audienceList.map((audience) => (
            <Item key={audience.id} onClick={() => setSelected(audience)}>
              <span
                style={{
                  fontWeight: selected?.id === audience?.id ? 500 : 400,
                }}
              >
                {audience.name}
              </span>
              {selected?.id === audience?.id ? (
                <div
                  style={{
                    height: 14,
                    width: 14,
                    minHeight: 14,
                    minWidth: 14,
                    borderRadius: 4,
                    background: "#337ab7",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Check height={10} width={10} style={{ color: "white" }} />
                </div>
              ) : (
                <div
                  style={{
                    height: 14,
                    width: 14,
                    minWidth: 14,
                    minHeight: 14,
                    borderRadius: 4,
                    background: "lightgray",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                />
              )}
            </Item>
          ))
        )}
      </List>
      <Button
        style={{
          alignSelf: "flex-end",
          marginTop: "auto",
          background: isDisabled ? "lightgray" : "#337ab7",
          height: 32,
          width: 60,
          minWidth: 60,
          maxWidth: 60,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          padding: 0,
        }}
        variant="contained"
        color="primary"
        disabled={isDisabled}
        onClick={() => {
          handleApply(selected.id);
          handleClose();
        }}
      >
        Apply
      </Button>
    </Wrapper>
  );
};

export default TemplatesModal;
