/* eslint-disable */
import React, { useContext } from "react";
import styled from "styled-components/macro";
import { GlobalState } from "../../../../store/GlobalState";
const Item = styled.div`
  height: 35px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #eeeeee82;
  padding: 0px 4px;
  span {
    color: darkgrey;
    font-weight: 400;
    font-size: 11px;
  }

  p {
    color: black;
    font-weight: 500;
    font-size: 11px;
  }
`;

const ModuleImage = styled.img`
  height: 150px;
  width: 150px;
  max-width: 150px;
  border-radius: 16px;
  border: 1px solid #eeeeee;
  object-fit: contain;
`;

const Wrapper = styled.div`
  flex: 1;
  width: 100%;
  padding: 8px;
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  padding-bottom: 80px;

  ::-webkit-scrollbar {
    width: 0;
    background: transparent;
  }
`;

const Basics = ({ data }) => {
  const { state } = useContext(GlobalState);
  const { config } = state;

  const companyName = config?.current_company?.name || "unknown";
  const hasDisclaimer = data?.disclaimer;

  return (
    <Wrapper>
      <div
        style={{
          width: "auto",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          textAlign: "center",
        }}
      >
        <div style={{ height: 4 }} />
        <ModuleImage src={data?.image} />
        <span
          style={{
            fontSize: 15,
            fontWeight: "600",
            marginTop: 8,
            textAlign: "center",
            lineHeight: 1.2,
          }}
        >
          {data.name}
        </span>
        <div style={{ height: 4 }} />

        <span style={{ fontSize: 11, marginTop: 2, lineHeight: 1.4 }}>
          {data.description}
        </span>
        <div style={{ height: 4 }} />

        {hasDisclaimer && data?.disclaimerText && (
          <>
            <div
              style={{
                textAlign: "center",
                width: "100%",
                background: "#eeeeee",
                height: 20,
                marginTop: 6,
              }}
            >
              <span style={{ fontSize: 10, fontWeight: "500" }}>
                Disclaimer
              </span>
            </div>
            <div
              style={{
                minHeight: 28,
                padding: "8px 4px",

                width: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <div
                style={{
                  border: "1px solid darkgrey",
                  height: 14,
                  width: 14,
                  borderRadius: 9,
                  minHeight: 14,
                  minWidth: 14,
                  marginRight: 6,
                  marginLeft: 2,
                }}
              />
              <span
                style={{
                  fontSize: 9,
                  fontStyle: "italic",
                  textAlign: "center",
                  lineHeight: 1.1,
                }}
              >
                {data?.disclaimerText}
              </span>
            </div>
          </>
        )}
      </div>
      <div
        style={{
          textAlign: "center",
          width: "100%",
          background: "#eeeeee",
          height: 20,
          marginTop: 6,
        }}
      >
        <span style={{ fontSize: 10, fontWeight: "500" }}>Module Info</span>
      </div>
      <div style={{ width: "100%", flex: 1 }}>
        <Item>
          <span>Company</span>
          <p>{companyName}</p>
        </Item>
        <Item>
          <span># Of Questions</span>
          <p>{data?.questions?.questions.length}</p>
        </Item>
        <Item>
          <span>Score To Pass</span>
          <p>{data?.passScore}</p>
        </Item>
        <Item>
          <span>Estimate Time</span>
          <p>{data?.estimatedTime} mins</p>
        </Item>
        <Item>
          <span>Answer Key</span>
          <p>{data?.displayAnswers ? "On" : "Off"}</p>
        </Item>
        <Item>
          <span>Media Type</span>
          <p>{data?.media ? "PDF" : data?.video ? "Video" : "None"}</p>
        </Item>
      </div>
    </Wrapper>
  );
};

export default Basics;
