/* eslint-disable */
/* eslint-disable no-nested-ternary */
import React from "react";
import PropTypes from "prop-types";
import { Avatar } from "@material-ui/core";
import styled from "styled-components/macro";
import { Typography } from "../../../styles";
import { QuestionAvatar } from "../../../moduleBuilder/styles";

const Wrapper = styled.div`
  padding: 0px 20px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
`;

const SmallText = styled.span`
  font-size: 10px;
`;

const AnswerHeader = styled.div`
  display: flex;
  width: 100%;
  margin-bottom: 8px;
`;

const AnswerAvatar = styled(Avatar)`
  height: 18px;
  width: 18px;
  font-size: 11px;
  font-weigth: 600;
  margin-right: 12px;
  background-color: ${(props) => {
    if (props.activecolor === "correct") return "mediumseagreen";
    if (props.activecolor === "failed") return "tomato";
    return props.theme.palette.grey[400];
  }};
`;

const AnswerBody = styled.div`
  display: flex;
  width: 100%;
  border-bottom: 1px solid #eee;
  margin-bottom: 2px;
  padding: 2px 0px;
`;

const AnswerContainerLarge = styled.div`
  flex: 0.7;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-evenly;
`;

const AnswerContainerSmall = styled.div`
  flex: 0.3;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const SAEval = ({ answer }) => {
  const formatAnswer = (ans) => {
    const split = ans.split("");
    const RE = new RegExp("[a-zA-Z]");
    const filteredArr = split.filter((letter) => RE.test(letter));
    const retArr = filteredArr.map((letter) => letter.toUpperCase());
    return retArr;
  };
  const correctAnswerArr = formatAnswer(answer.question.correctAnswer);
  const answerArr = formatAnswer(answer.answer);

  return (
    <Wrapper>
      <AnswerHeader>
        <AnswerContainerLarge>
          <SmallText>Options</SmallText>
        </AnswerContainerLarge>
        <AnswerContainerSmall>
          <SmallText>Correct {`(${correctAnswerArr.join(",")})`}</SmallText>
        </AnswerContainerSmall>
        <AnswerContainerSmall>
          <SmallText>Submitted {`(${answerArr.join(",")})`}</SmallText>
        </AnswerContainerSmall>
      </AnswerHeader>
      {Object.entries(answer.question.possibleAnswers).map(([key, text]) => (
        <AnswerBody variant="subtitle2" key={text}>
          <AnswerContainerLarge>
            <Typography variant="body2">{text}</Typography>
          </AnswerContainerLarge>
          <AnswerContainerSmall>
            <QuestionAvatar
              activecolor={
                correctAnswerArr.includes(key.toUpperCase()) ? "Yes" : "No"
              }
            >
              {key.toUpperCase()}
            </QuestionAvatar>
          </AnswerContainerSmall>
          <AnswerContainerSmall>
            <AnswerAvatar
              activecolor={
                answerArr.includes(key.toUpperCase())
                  ? answer.correct
                    ? "correct"
                    : "failed"
                  : "No"
              }
            >
              {key.toUpperCase()}
            </AnswerAvatar>
          </AnswerContainerSmall>
        </AnswerBody>
      ))}
    </Wrapper>
  );
};

export default SAEval;

SAEval.propTypes = {
  answer: PropTypes.object.isRequired,
};
