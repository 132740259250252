/* eslint-disable */
import useSWR from 'swr';
import API from '../../../axios/instances/API';

const fetcher = async url => {
  const { data } = await API.get(url);
  return data.roles;
};

const useTeamMembersRoles = id => {
  const { data, error, mutate } = useSWR(`/dashboard/teams/roles/?id=${id}`, fetcher, {
    dedupingInterval: 30000,
    revalidateOnFocus: false,
  });

  return {
    data,
    isLoading: !error && !data,
    isError: error,
    mutate,
  };
};

export default useTeamMembersRoles;
