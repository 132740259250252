import React, { createContext, useReducer } from 'react';
import PropTypes from 'prop-types';
import reducer from './reducer';

export const GlobalState = createContext();

const initialState = {
  config: {},
  initialLog: false,
  refreshConfig: false,
  transcoding: [],
};

const GlobalStateProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  return (
    <GlobalState.Provider value={{ state, dispatch }}>
      {children}
    </GlobalState.Provider>
  );
};

export default GlobalStateProvider;

GlobalStateProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
