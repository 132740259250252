/* eslint-disable */

import React from "react";
import styled from "styled-components/macro";
import {
  Select,
  MenuItem,
  Paper,
  Button,
  InputLabel,
  FormControl,
  CircularProgress,
} from "@material-ui/core";
import { Download } from "react-feather";
import CheckboxBrandBasic from "../../../../components/CheckboxBrandBasic";

const StyledButton = styled(Button)`
  height: 28px;
  min-height: 28px;
  border-radius: 6px;
  background-color: white;
  color: black;
  transition: background-color 200ms ease-in;

  &:hover {
    background-color: #eeeeee;
  }
`;

const Wrapper = styled.div`
  width: 100%;
  min-height: 80px;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
`;

const StyledMenuItem = styled(MenuItem)`
  border-bottom: 1px solid #eeeeee;
  height: 32px;
`;

const LocationSelection = ({
  locations,
  setSelectedLocation,
  selectedLocation,
  handleClick,
  isLoading,
}) => {
  return (
    <Wrapper>
      <div style={{ height: 4 }} />
      <FormControl size="small" fullWidth variant="outlined">
        <InputLabel id="multi-location-select">Locations</InputLabel>
        <Select
          multiple
          size="small"
          labelId="multi-location-select"
          value={selectedLocation}
          disabled={isLoading}
          renderValue={(selected) => {
            return selected.join(", ");
          }}
          label="locations"
          onChange={(e) => {
            setSelectedLocation(e.target.value);
          }}
          MenuProps={{
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "left",
            },
            transformOrigin: {
              vertical: "top",
              horizontal: "left",
            },
            getContentAnchorEl: null,
          }}
        >
          <div
            style={{
              width: "100%",
              height: 40,
              background: "white",
              padding: "2px 12px",
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-start",
              gap: 8,
              borderTop: "1px solid lightgray",
              borderBottom: "1px solid lightgray",
            }}
          >
            <StyledButton
              size="small"
              variant="outlined"
              onClick={(e) => {
                e.stopPropagation();
                setSelectedLocation(locations.map((l) => l.name));
              }}
            >
              Select All
            </StyledButton>
            <StyledButton
              size="small"
              variant="outlined"
              onClick={(e) => {
                e.stopPropagation();
                setSelectedLocation([]);
              }}
            >
              Unselect All
            </StyledButton>
          </div>
          {locations?.map((location) => (
            <StyledMenuItem value={location?.name} key={location?.id}>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "flex-start",
                  gap: 4,
                }}
              >
                <CheckboxBrandBasic
                  checked={selectedLocation.includes(location?.name)}
                  handleChange={() => {}}
                />
                <span>{location?.name}</span>
              </div>
            </StyledMenuItem>
          ))}
        </Select>
      </FormControl>
      <div style={{ height: 6 }} />
      <Button
        startIcon={
          <Download
            height={13}
            width={13}
            color={selectedLocation?.length === 0 ? "lightgray" : "black"}
          />
        }
        fullWidth
        variant="outlined"
        disabled={selectedLocation?.length === 0 || isLoading}
        onClick={handleClick}
      >
        Download Template
      </Button>
    </Wrapper>
  );
};

export default LocationSelection;
