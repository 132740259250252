/* eslint-disable */
import React from "react";
import styled from "styled-components/macro";
import { Paper } from "@material-ui/core";

const Wrapper = styled(Paper)`
  padding: 0px;
  width: calc(min(1100px, 96vw));
  max-height: 90vh;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  overflow-y: scroll;
  border: 2px solid black;
`;

const ModalWrapper = ({ children }) => {
  return <Wrapper>{children}</Wrapper>;
};

export default ModalWrapper;
