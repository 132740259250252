/* eslint-disable */
import React, { useContext } from "react";
import styled from "styled-components/macro";
import { darken } from "polished";
import { NavLink, withRouter, useHistory } from "react-router-dom";
import PerfectScrollbar from "react-perfect-scrollbar";
import PropTypes from "prop-types";
import "../../css/perfect-scrollbar.css";
import {
  Grid,
  Drawer as MuiDrawer,
  List as MuiList,
  ListItem,
  Typography,
} from "@material-ui/core";
import SidebarItems from "./SidebarItems";
import { clearTokens, clearSWRKeys } from "../../storage/helpers";
import ACTIONS from "../../store/actions";
import { GlobalState } from "../../store/GlobalState";

import sidebarRoutes from "../../routes/sidebarRoutes";

import SpiffyLogo from "../../assets/images/newspiffy.avif";

const Drawer = styled(MuiDrawer)`
  border-right: 0;

  > div {
    border-right: 0;
  }
`;

const Scrollbar = styled(PerfectScrollbar)`
  background-color: ${(props) => props.theme.sidebar.background};
  border-right: 1px solid rgba(0, 0, 0, 0.12);
`;

const List = styled(MuiList)`
  background-color: ${(props) => props.theme.sidebar.background};
`;

const Brand = styled(ListItem)`
  font-size: ${(props) => props.theme.typography.h5.fontSize};
  font-weight: ${(props) => props.theme.typography.fontWeightMedium};
  color: ${(props) => props.theme.sidebar.header.color};
  background-color: ${(props) => props.theme.sidebar.header.background};
  font-family: ${(props) => props.theme.typography.fontFamily};
  min-height: 60px;
  padding-left: ${(props) => props.theme.spacing(6)}px;
  padding-right: ${(props) => props.theme.spacing(6)}px;
  justify-content: center;
  cursor: pointer;

  ${(props) => props.theme.breakpoints.up("sm")} {
    min-height: 70px;
  }

  &:hover {
    background-color: ${(props) => props.theme.sidebar.header.background};
  }
`;

const BrandIcon = styled.img`
  // margin-right: ${(props) => props.theme.spacing(2)}px;
  color: ${(props) => props.theme.sidebar.header.brand.color};
  fill: ${(props) => props.theme.sidebar.header.brand.color};
  width: 86px;
  height: auto;
`;

const SidebarSection = styled(Typography)`
  color: ${(props) => props.theme.sidebar.color};
  padding: ${(props) => props.theme.spacing(2)}px
    ${(props) => props.theme.spacing(7)}px
    ${(props) => props.theme.spacing(2)}px;
  opacity: 0.9;
  display: block;
  &:hover {
    background-color: ${(props) =>
      darken(0.05, props.theme.sidebar.background)};
  }
`;

const SidebarFooter = styled.div`
  background-color: ${(props) =>
    props.theme.sidebar.footer.background} !important;
  padding: ${(props) => props.theme.spacing(2.75)}px
    ${(props) => props.theme.spacing(4)}px;
  border-right: 1px solid rgba(0, 0, 0, 0.12);
  cursor: pointer;
`;

const SidebarFooterText = styled(Typography)`
  color: ${(props) => props.theme.sidebar.footer.color};
  font-size: 16px;
`;

const Sidebar = ({ classes, staticContext, location, ...rest }) => {
  const { dispatch } = useContext(GlobalState);
  const router = useHistory();

  const handleLogout = () => {
    clearTokens();
    clearSWRKeys();
    dispatch({ type: ACTIONS.LOGOUT });
    router.push("/");
  };

  return (
    <Drawer variant="permanent" {...rest}>
      <Brand component={NavLink} to="/" button>
        <BrandIcon src={SpiffyLogo} alt="The Logo" />
      </Brand>
      <Scrollbar>
        <List disablePadding>
          <SidebarItems sidebarRoutes={sidebarRoutes} />
        </List>
      </Scrollbar>
      <SidebarFooter>
        <Grid container align="center" justifyContent="center">
          <Grid item>
            <SidebarSection onClick={handleLogout}>
              <SidebarFooterText variant="body1" as="span">
                Logout
              </SidebarFooterText>
            </SidebarSection>
          </Grid>
        </Grid>
      </SidebarFooter>
    </Drawer>
  );
};

export default withRouter(Sidebar);

Sidebar.propTypes = {
  classes: PropTypes.node,
  staticContext: PropTypes.node,
  location: PropTypes.object,
};

Sidebar.defaultProps = {
  staticContext: null,
  classes: null,
  location: null,
};
