/* eslint-disable */
import React from "react";
import { Alert } from "@material-ui/lab";

const ErrorAlertInvite = ({ errorMsg, handleClose }) => {
  // this works, can map over to show messages, imo takes too much space tho.

  return (
    <Alert
      severity="error"
      onClose={handleClose}
      style={{ textAlign: "start", overflowY: "scroll", width: "100%" }}
    >
      <p style={{ fontWeight: "600" }}>{errorMsg?.title}</p>
      <p>{errorMsg?.details}</p>
      <pre
        style={{
          fontFamily: "inherit",
          textAlign: "start",
          overflowX: "auto",
          whiteSpace: "pre-wrap",
        }}
      >
        {errorMsg?.context}
      </pre>
    </Alert>
  );
};

export default ErrorAlertInvite;
