/* eslint-disable */
import React from "react";
import PropTypes from "prop-types";
import { PieChart, Percent, BarChart2 } from "react-feather";
import styled from "styled-components/macro";
import SmallBlockWide from "../../../../../components/SmallBlockWide";
import {
  returnDefinitionByEngagementType,
  DEFINITIONS,
} from "../../../../../storage/constants";
import {
  retPassRateByEngagementType,
  retCompsByEngagementType,
  retSubsByEngagementType,
} from "../../utils/utils";

const Container = styled.div`
  display: flex;
  width: 100%;
  padding-top: 10px;
  align-items: center;
  justify-content: flex-start;
  gap: 12px;
`;

const ModuleDetails = ({ data, engagementType }) => {
  const { totals } = data;

  const subs = retSubsByEngagementType(totals, engagementType);
  const comps = retCompsByEngagementType(totals, engagementType);
  const passRate = retPassRateByEngagementType(totals, engagementType);

  return (
    <Container>
      <SmallBlockWide
        title="Completions"
        Icon={PieChart}
        stat={comps}
        engagementType={engagementType}
        definition={returnDefinitionByEngagementType(
          "completions",
          engagementType
        )}
      />
      <SmallBlockWide
        title="Submissions"
        Icon={BarChart2}
        stat={subs}
        engagementType={engagementType}
        definition={returnDefinitionByEngagementType(
          "submissions",
          engagementType
        )}
      />
      <SmallBlockWide
        title="Pass Rate"
        Icon={Percent}
        stat={`${passRate}%`}
        engagementType={engagementType}
        definition={DEFINITIONS.passRate}
      />
    </Container>
  );
};

export default ModuleDetails;

ModuleDetails.propTypes = {
  data: PropTypes.object.isRequired,
  engagementType: PropTypes.string.isRequired,
};
