/* eslint-disable */
import React, { useState } from "react";
import { Dialog, Paper } from "@material-ui/core";
import styled from "styled-components/macro";
import { Button } from "../styles";
import { AlertTriangle } from "react-feather";

const Wrapper = styled(Paper)`
  width: 400px;
  min-height: 240px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
  padding: 10px;
`;

const PreInfoDialog = ({ open, info, handleSubmitFromDialog, handleClose }) => {
  const [submitting, setSubmitting] = useState(false);

  return (
    <Dialog open={open} onClose={() => {}}>
      <Wrapper>
        <div
          style={{
            padding: 4,
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column",
          }}
        >
          <AlertTriangle
            style={{ height: 26, width: 26, color: "darkorange" }}
          />
          <span
            style={{
              fontSize: 16,
              fontWeight: 600,
            }}
          >
            {info?.title}
          </span>
        </div>
        <div
          style={{
            width: "100%",
            textAlign: "center",
          }}
        >
          <span
            style={{
              fontSize: 12,
              fontWeight: "500",
              color: "darkgrey",
              lineHeight: 1.2,
            }}
          >
            {info?.details}
          </span>
        </div>
        <div style={{ height: 4 }} />
        <div style={{ width: "100%", textAlign: "center" }}>
          <span style={{ fontWeight: "400", lineHeight: 1.2, fontSize: 12 }}>
            {info?.context}
          </span>
        </div>
        <div
          style={{
            padding: 12,
            marginTop: "auto",
            gap: 12,
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Button
            size="small"
            variant="text"
            color="secondary"
            style={{
              color: "white",
              background: "tomato",
            }}
            onClick={() => handleClose()}
          >
            Cancel
          </Button>
          <Button
            size="small"
            variant="contained"
            style={{
              color: "white",
              background: "mediumseagreen",
            }}
            disabled={submitting}
            onClick={async () => {
              try {
                setSubmitting(true);
                handleSubmitFromDialog(info.body);
                setSubmitting(false);
                handleClose();
              } catch (error) {
                setSubmitting(false);
              }
            }}
          >
            Confirm
          </Button>
        </div>
      </Wrapper>
    </Dialog>
  );
};

export default PreInfoDialog;
