/* eslint-disable */
import React from "react";
import useCompanyLimits from "../../../swr/hooks/Library/useCompanyLimits";
import LimitsMsg from "../../../components/LimitsMsg";
const MpLimits = () => {
  const { data, isLoading, isError } = useCompanyLimits("marketplace_modules");

  if (isLoading || isError) return null;

  return (
    <LimitsMsg
      title="Marketplace Modules"
      limit={data?.marketplace_modules.limit}
      current={data?.marketplace_modules?.used}
      explanationText={
        "Your account allows access to a limited number of marketplace modules. To add more, close existing marketplace modules or upgrade your plan."
      }
    />
  );
};

export default MpLimits;
