import useSWR from 'swr';
import API from '../../../axios/instances/API';

const fetcher = async url => {
  const { data } = await API.get(url);
  return data;
};

const useSingleCompanyBreakdown = id => {
  const { data, error, mutate } = useSWR(`/dashboard/analytics/report/?report=vendor_company_locations_breakdown&company_id=${id}`, fetcher, {
    revalidateOnFocus: false,
  });

  return {
    data,
    isLoading: !error && !data,
    isError: error,
    mutate,
  };
};

export default useSingleCompanyBreakdown;
