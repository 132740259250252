/* eslint-disable */
import useSWR from "swr";
import API from "../../axios/instances/API";

const fetcher = async (url) => {
  const { data } = await API.get(url);
  return data;
};

const userGuideDetails = (code) => {
  const { data, error, mutate } = useSWR(
    `/dashboard/guides/?code=${code}`,
    fetcher,
    {
      revalidateOnReconnect: false,
    }
  );

  return {
    data,
    isLoading: !error && !data,
    isError: error,
    mutate,
  };
};

export default userGuideDetails;
