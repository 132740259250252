/* eslint-disable */
import React from "react";
import PropTypes from "prop-types";
import { User, UserPlus } from "react-feather";
import {
  makeDefaultTableOptions,
  fixIncomingDate,
} from "../../../storage/helpers";
import { returnDefinitionByEngagementType } from "../../../storage/constants";
import { CustomDatatableV2, StyledLink, CenterWithColor } from "../styles";
import VendorShowTeams from "../../../components/VendorShowTeams";
import {
  retCompsByEngagementType,
  retSubsByEngagementType,
} from "../reports/utils/utils";
import CustomDataTableIcons from "../../../components/Table/CustomDataTableIcons";
import TableTitle from "../../../components/Table/TableTitle";
import { useMediaQuery } from "@material-ui/core";

const UsersTable = ({ data, engagementType }) => {
  const options = makeDefaultTableOptions("current-followers");
  const isUnder600Px = useMediaQuery((theme) => theme.breakpoints.down("xs"));

  options.sortOrder = {
    name: "completions",
    direction: "desc",
  };
  options.rowsPerPage = 10;
  options.textLabels = {
    body: {
      noMatch:
        "No users have engaged with your content! Create new modules and deploy it to the marketplace to engage new users!",
    },
  };

  if (isUnder600Px) {
    options.rowsPerPageOptions = [];
    options.jumpToPage = false;
  }

  const columns = [
    {
      name: "id",
      label: "ID",
      options: {
        filter: false,
        sort: false,
        display: false,
        download: false,
      },
    },
    {
      name: "name",
      label: "Name",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta) => (
          <StyledLink
            value={value}
            to={`/vendor-reports/user/${tableMeta.rowData[0]}`}
            CustomIcon={User}
          />
        ),
      },
    },

    {
      name: "submissions",
      label: "Submissions",
      options: {
        filter: true,
        sort: true,
        hint: returnDefinitionByEngagementType("submissions", engagementType),
        customBodyRender: (val) => (
          <CenterWithColor engagementType={engagementType}>
            <span>{val}</span>
          </CenterWithColor>
        ),
      },
    },
    {
      name: "completions",
      label: "Completions",
      options: {
        filter: true,
        sort: true,
        hint: returnDefinitionByEngagementType("completions", engagementType),
        customBodyRender: (val) => (
          <CenterWithColor engagementType={engagementType}>
            <span>{val}</span>
          </CenterWithColor>
        ),
      },
    },

    {
      name: "isCurrentFollower",
      label: "Currently Following",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (val) => (
          <div style={{ display: "flex", alignItems: "center" }}>
            <div
              style={{
                height: 8,
                width: 8,
                borderRadius: 4,
                backgroundColor: val ? "mediumseagreen" : "tomato",
                marginRight: 6,
              }}
            />
            <span>{val ? "Yes" : "No"}</span>
          </div>
        ),
      },
    },
    {
      name: "date",
      label: "Last Submission",
      options: {
        sort: true,
        customBodyRender: (value) =>
          value ? (
            <span>{fixIncomingDate(value.split("T")[0]).toDateString()}</span>
          ) : (
            <div />
          ),
      },
    },
    {
      name: "teamsSearchable",
      label: " ",
      options: {
        filter: true,
        sort: false,
        download: true,
        customBodyRender: () => <div />,
      },
    },
    {
      name: "locationsSearchable",
      label: " ",
      options: {
        filter: true,
        sort: false,
        download: false,
        customBodyRender: () => <div />,
      },
    },
    {
      name: "team",
      label: "Team",
      options: {
        filter: false,
        sort: false,
        download: false,
        display: !isUnder600Px,
        customBodyRender: (value) => <VendorShowTeams teamsArray={value} />,
      },
    },
  ];

  // eslint-disable-next-line arrow-body-style
  const tableData = data.map((user) => {
    return {
      id: user.user_id,
      date: user.last_submission_date
        ? new Date(user.last_submission_date).toISOString()
        : null,
      name: `${user.first_name} ${user.last_initial}`,
      completions: retCompsByEngagementType(user, engagementType),
      submissions: retSubsByEngagementType(user, engagementType),
      isCurrentFollower: user.is_follower,
      team: user.active_team_members,
      teamsSearchable: user.active_team_members
        ? user.active_team_members.map((m) => m.company).join(" ")
        : "",
      locationsSearchable: user.active_team_members
        ? user.active_team_members.map((m) => m.location).join(" ")
        : "",
    };
  });

  return (
    <>
      <CustomDatatableV2
        title={
          <TableTitle
            title="Engaged Users"
            Icon={UserPlus}
            isVendor
            submissionType={engagementType}
          />
        }
        data={tableData}
        columns={columns}
        options={options}
        components={{
          icons: CustomDataTableIcons,
        }}
      />
    </>
  );
};

export default UsersTable;

UsersTable.propTypes = {
  data: PropTypes.array.isRequired,
  engagementType: PropTypes.string.isRequired,
};
