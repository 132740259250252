/* eslint-disable */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import { CircularProgress, Paper } from '@material-ui/core';
import { Typography } from '../../styles';
import API from '../../../../axios/instances/API';

const ModalWrapper = styled(Paper)`
  padding: 16px;
  min-width: min(640px, 100vw);
  max-width: 640px;
  text-align: left;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  border-radius: 6px;
  border: 2px solid black;
`;

const ScrollableWrapper = styled.div`
  height: 320px;
  overflow-y: scroll;
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const Center = styled.div`
  width: 100%;
  flex-grow: 1;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

const HoldLog = styled.div`
  width: 100%;
  padding: 8px 0px;
  display: flex;
  align-items: center;
  // background-color: #eeeeee;
  justify-content: flex-start;
  border-bottom: 1px solid #eeeeee84;
`;

const FlexDiv = styled.div`
  display: flex;
  gap: 4, 
  align-items: center;
  justify-content: flex-start;
  flex: 0.5;

  text-align: left;

  svg {
    height: 12px;
    width: 12px; 
    margin-right: 4px;
    color: darkgray;
  }
`;

const ActivityLogModal = ({ changeLogInfo, handleClose, setSnackbarText }) => {
  const { id, name } = changeLogInfo;
  const [log, setLog] = useState(null);

  useEffect(() => {
    const getLog = async () => {
      try {
        const { data } = await API.get(`/dashboard/quiz/edit_log/?id=${id}`);
        return setLog(data.edit_logs.reverse());
      } catch (error) {
        setSnackbarText('Failed to get changelog data, please try again error');
        return handleClose();
      }
    };

    return getLog();
  }, []);

  if (!log) {
    return (
      <ModalWrapper>
        <ScrollableWrapper>
          <Center>
            <span style={{ marginBottom: 8, color: 'darkgrey' }}>loading...</span>
            <CircularProgress
              size={16}
              style={{ color: 'black' }}
            />
          </Center>
        </ScrollableWrapper>
      </ModalWrapper>
    );
  }

  return (
    <ModalWrapper>
      <div
        style={{
          display: 'flex',
          alignItems: 'flex-start',
          justifyContent: 'flex-start',
          marginBottom: 8,
          gap: 4,
        }}
      >
        <p style={{ fontSize: 13, fontWeight: '600', color: 'black' }}>Activity Log</p>
        <span style={{ color: 'lightgray' }}>/</span>
        <Typography
          style={{ marginBottom: 8, fontSize: 13, fontWeight: '600', color: 'slategray' }}
        >
          {name}
        </Typography>
      </div>

      <ScrollableWrapper>
        {log.length === 0 && (
          <Center>
            <Typography
              variant="subtitle1"
              style={{ color: 'darkgrey' }}
            >
              No activity for this module yet
            </Typography>
          </Center>
        )}
        {log.length > 0 &&
          log.map((l, i) => (
            <HoldLog key={l.date}>
              <div
                style={{
                  height: 18,
                  width: 18,
                  borderRadius: 4,
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  background: '#eeeeee',
                }}
              >
                <span style={{ fontWeight: '600', fontSize: 10 }}>{i + 1}</span>
              </div>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  flex: 0.5,
                  alignItems: 'flex-start',
                  justifyContent: 'center',
                  paddingLeft: 10,
                }}
              >
                <span style={{ fontWeight: '500', fontSize: 12, textAlign: 'left' }}>
                  {l.action}
                </span>
                <p style={{ fontSize: 10, fontWeight: '400', lineHeight: 1.1 }}>{`${new Date(
                  l.date
                ).toDateString()} ${new Date(l.date).toLocaleTimeString()}`}</p>
              </div>
              <FlexDiv style={{ justifyContent: 'flex-end' }}>
                <p style={{ fontSize: 12, fontWeight: '400', textAlign: 'right', lineHeight: 1.1 }}>
                  {l.user}
                </p>
              </FlexDiv>
            </HoldLog>
          ))}
      </ScrollableWrapper>
    </ModalWrapper>
  );
};

export default ActivityLogModal;

ActivityLogModal.propTypes = {
  changeLogInfo: PropTypes.object,
  setChangeLogInfo: PropTypes.func.isRequired,
  setSnackbarText: PropTypes.func.isRequired,
};

ActivityLogModal.defaultProps = {
  changeLogInfo: null,
};
