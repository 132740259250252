/* eslint-disable */
import React, { useState } from "react";
import PropTypes from "prop-types";
import { CircularProgress, MenuItem, Paper } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import { AlertTitle } from "@material-ui/lab";
import API from "../../../../axios/instances/API";
import { FEEDBACK_MESSAGES } from "../../../../storage/constants";
import { Typography, Button, Alert, TextField } from "../../styles";
import styled from "styled-components/macro";

const ModalWrapper = styled(Paper)`
  padding: 20px;
  min-width: min(540px, 100vw);
  max-width: 600px;
  text-align: center;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  border: 2px solid black;
`;

const PromoteModal = ({ promoteModalInfo, managerInMultipleCompanies }) => {
  const history = useHistory();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [errorAlert, setErrorAlert] = useState(false);
  const [adminLevel, setAdminLevel] = useState("adminAccess");
  const [successAlert, setSuccessAlert] = useState(false);

  const handlePromote = async () => {
    try {
      setIsSubmitting(true);
      setErrorAlert(false);

      const sendObj = {
        id: promoteModalInfo.id,
        read_only: adminLevel === "readOnly",
        org_access: adminLevel === "organization_access",
      };

      if (managerInMultipleCompanies) {
        sendObj.proceed_despite_multiple_managed_companies = true;
      }
      await API.put("dashboard/managers/promote/", sendObj);
      setIsSubmitting(false);
      setSuccessAlert({
        title: `Promoted ${promoteModalInfo.name} to Admin`,
        body: "You will be redirected back to the Supervisor Table.",
      });

      setTimeout(() => {
        history.push("/supervisors/");
      }, 2000);
    } catch (error) {
      setErrorAlert(true);
      setIsSubmitting(false);
    }
  };

  const handleChange = (e) => {
    setAdminLevel(e.target.value);
  };

  return (
    <ModalWrapper>
      {successAlert ? (
        <Alert severity="info">
          <AlertTitle>{successAlert.title}</AlertTitle>
          {successAlert.body}
        </Alert>
      ) : (
        <>
          <Typography variant="h4">
            {`Promote ${promoteModalInfo.name} to admin`} ?
          </Typography>
          <Typography variant="body1" mb={6}>
            {`This will give ${promoteModalInfo.name} admin privileges`}
          </Typography>
          {managerInMultipleCompanies && (
            <Alert
              severity="warning"
              mb={6}
              mt={2}
              style={{ textAlign: "left" }}
            >
              <AlertTitle>This supervisor is in multiple companies.</AlertTitle>
              <span>
                If promoted to admin, {promoteModalInfo.name} will have
                supervisor access removed at all companies.
              </span>
            </Alert>
          )}
          <Typography variant="subtitle2" mt={2} mb={2}>
            {`Select Privilege Level for ${promoteModalInfo.name}`}
          </Typography>
          <TextField
            select
            fullWidth
            size="small"
            variant="outlined"
            value={adminLevel}
            onChange={handleChange}
            name="level"
            mb={2}
          >
            <MenuItem value="adminAccess">Admin Access</MenuItem>
            <MenuItem value="readOnly">Read-Only Admin</MenuItem>
            <MenuItem value="orgAccess">
              Admin With Organization Access
            </MenuItem>
          </TextField>
          <Button
            variant="contained"
            mb={1}
            color="primary"
            fullWidth
            disabled={isSubmitting}
            onClick={handlePromote}
            startIcon={isSubmitting ? <CircularProgress size={20} /> : null}
          >
            {isSubmitting ? "Promoting..." : "Promote To Admin"}
          </Button>
          {errorAlert && (
            <Alert mt={3} severity="error" onClose={() => setErrorAlert("")}>
              {FEEDBACK_MESSAGES.GENERIC_ERROR}
            </Alert>
          )}
        </>
      )}
    </ModalWrapper>
  );
};

export default PromoteModal;

PromoteModal.propTypes = {
  promoteModalInfo: PropTypes.oneOfType([PropTypes.bool, PropTypes.object])
    .isRequired,
  setPromoteModalInfo: PropTypes.func.isRequired,
};
