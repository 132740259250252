/* eslint-disable */
import React from "react";
import styled from "styled-components/macro";

const Wrapper = styled.div`
  flex: 1;
  height 100%;
  min-height: 700px;
  padding: 10px;
  display: flex;
  background-color: #eeeeee80;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  min-width: 360px;
  max-width: 360px;
  overflow-y: scroll;
  position: relative;

  @media(max-width: 1500px){
    display: none;
  }

  @media(max-height: 780px) {
    display: none;
  }

  ::-webkit-scrollbar {
    width: 0px;
    background: transparent;
  }
  
  -ms-overflow-style: none; 
  scrollbar-width: none; 
`;

const RightConsoleLayout = ({ children }) => {
  return <Wrapper>{children}</Wrapper>;
};

export default RightConsoleLayout;
