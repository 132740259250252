/* eslint-disable */
import React, { useState } from "react";
import styled from "styled-components/macro";
import { Button, TextField } from "@material-ui/core";
import ACTIONS from "../state/actions";

const Wrapper = styled.div`
  width: 320px;
  height: 120px;
  background: white;
  display: flex;
  padding: 10px;
  flex-direction: column;
  border: 1px solid lightgray;
  border-radius: 4px;
`;

const SearchFilter = ({ dispatch, handleClose, state }) => {
  const [searchText, setSearchText] = useState("");

  const handleSearch = () => {
    if (searchText === state?.searchName) return handleClose();

    dispatch({ type: ACTIONS.SEARCH_BY_NAME, payload: searchText });
    handleClose();
  };

  return (
    <Wrapper>
      <span style={{ fontSize: 13, color: "black", fontWeight: "500" }}>
        Search by module name
      </span>
      <div style={{ height: 8 }} />
      <TextField
        size="small"
        variant="outlined"
        color="secondary"
        fullWidth
        placeholder="Search..."
        autoFocus
        value={searchText}
        onChange={(e) => setSearchText(e.target.value)}
      />
      <div
        style={{
          marginTop: "auto",
          width: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        {state?.searchName ? (
          <Button
            variant="outlined"
            // color="secondary"
            size="small"
            onClick={() => {
              dispatch({ type: ACTIONS.CLEAR_SEARCH });
              return handleClose();
            }}
          >
            Clear
          </Button>
        ) : (
          <div />
        )}
        <Button
          variant="contained"
          color="secondary"
          disabled={searchText.length < 2}
          size="small"
          onClick={handleSearch}
        >
          Search
        </Button>
      </div>
    </Wrapper>
  );
};

export default SearchFilter;
