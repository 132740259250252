import { unstable_createMuiStrictModeTheme as createMuiTheme } from '@material-ui/core/styles';
// This fixed react.forwardRef on transition erros that keep popping up
import variants from './variants';
import typography from './typography';
import overrides from './overrides';
import breakpoints from './breakpoints';
import props from './props';
// import shadows from './shadows';

const createTheme = (name) => {
  let themeConfig = variants.find((variant) => variant.name === name);

  if (!themeConfig) {
    const { defaultVariant } = variants;
    themeConfig = defaultVariant;
  }

  return createMuiTheme(
    {
      spacing: 4,
      breakpoints,
      overrides,
      props,
      typography,
      palette: themeConfig.palette,
    },
    {
      name: themeConfig.name,
      header: themeConfig.header,
      footer: themeConfig.footer,
      sidebar: themeConfig.sidebar,
    },
  );
};

export default createTheme;
