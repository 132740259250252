/* eslint-disable */
import React, { useState, useContext, useEffect } from "react";
import { Helmet } from "react-helmet-async";
import PropTypes from "prop-types";
import { Grid, Modal, Button, Tab } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import PrivateHeader from "../../../components/PrivateHeader";
import OnLoadError from "../../../components/UI/OnLoadError";
import SnackbarWrapper from "../../../components/SnackbarWrapper";
import useLibrary from "../../../swr/hooks/Library/useLibrary";
import SuggestedQuizzesTable from "./SuggestedQuizzesTable";
import CreateQuizModal from "./components/CreateQuizModal";
import { GlobalState } from "../../../store/GlobalState";
import LoaderWrapper from "../../../components/Loaders/LoaderWrapper";
import LibraryTable from "./LibraryTable";
import AutoAcceptSwitch from "./components/AutoAcceptSwitch";
import TabPanel from "../../../components/TabPanel";
import { TableTabs, TableTabBar } from "../styles";
import { Book, Plus } from "react-feather";
import CreateButton from "../../../components/UI/CreateButton";
import ModalWrapper from "./components/ModalWrapper";
import { getUserType } from "../../../storage/helpers";
import { USER_TYPES } from "../../../storage/constants";
import PermissionsWarning from "./PermissionsWarning";
import styled from "styled-components/macro";
import LimitsCheck from "../moduleBuilder/UI/LimitsCheck";

const AddFromMpButton = styled(Button)`
  white-space: nowrap;
  width: fit-content;

  @media (max-width: 600px) {
    display: none;
  }
`;

const Library = ({ location }) => {
  const [snackbarText, setSnackbarText] = useState("");
  const [modalOpen, setModalOpen] = useState(false);
  const [tabValue, setTabValue] = useState(0);
  const [suggestedModalOpen, setSuggestedModalOpen] = useState(false);

  const { state } = useContext(GlobalState);
  const { config, isRetail, job } = state;
  const { user } = config;
  const { read_only: readOnly } = user;

  const isAdmin = job === "C";
  const userType = getUserType(config);

  const history = useHistory();
  const hasPermissionsToCreateModule = isAdmin
    ? true
    : user?.can_deploy_vendor_content
    ? true
    : false;

  const { data, isLoading, mutate, isError } = useLibrary();

  const handleTabChange = (_, newValue) => {
    setTabValue(newValue);
  };

  useEffect(() => {
    if (!location?.state) return;

    if (location?.state?.fromHome && location?.state?.createNew) {
      return setModalOpen(true);
    }
  }, []);

  if (isLoading) {
    return (
      <>
        <PrivateHeader header="Library" Icon={Book} />
        <LoaderWrapper text="Modules" />
      </>
    );
  }

  if (isError) {
    return <OnLoadError />;
  }

  const numLiveModules = isRetail
    ? data.quizzes.filter(
        (quiz) => quiz.state === "3" && quiz.fromMarketplace == 0
      ).length
    : data.quizzes.filter((quiz) => quiz.state === "3").length;

  return (
    <>
      <Helmet title="Spiffy | Library" />
      <PrivateHeader header="Library" Icon={Book}>
        <LimitsCheck />
      </PrivateHeader>
      {!hasPermissionsToCreateModule && <PermissionsWarning />}
      <Grid container spacing={6}>
        <Grid item xs={12}>
          <div style={{ display: "flex", gap: 8 }}>
            <CreateButton
              handleClick={() => {
                if (hasPermissionsToCreateModule) {
                  setModalOpen(true);
                } else {
                  setSnackbarText(
                    "You do not have the permissions to create a new module error"
                  );
                }
              }}
              disabled={
                readOnly || userType === USER_TYPES.RETAIL_MANAGER_CANT_DEPLOY
              }
              title="Create Module"
            />
            {isRetail && userType !== USER_TYPES.RETAIL_MANAGER_CANT_DEPLOY && (
              <AddFromMpButton
                startIcon={
                  <Plus
                    height={15}
                    width={15}
                    color={readOnly ? "lightgray" : "black"}
                  />
                }
                variant="outlined"
                color="inherit"
                disabled={readOnly}
                ml={2}
                onClick={() => history.push("/marketplace/")}
                style={{ whiteSpace: "nowrap", width: "fit-content" }}
              >
                Add from marketplace
              </AddFromMpButton>
            )}
          </div>
        </Grid>
        {userType === USER_TYPES.RETAIL_ADMIN && (
          <Grid item xs={12}>
            <AutoAcceptSwitch setSnackbarText={setSnackbarText} />
          </Grid>
        )}
      </Grid>
      <div style={{ height: 18 }} />
      <Grid spacing={0} container>
        <Grid item xs={12}>
          <TableTabBar position="relative" elevation={0}>
            <TableTabs
              value={tabValue}
              onChange={handleTabChange}
              scrollButtons="off"
            >
              <Tab label={`Module Library (${data.quizzes.length})`} />
              {isRetail && (
                <Tab label={`Suggested Modules (${data.suggestions.length})`} />
              )}
            </TableTabs>
          </TableTabBar>
        </Grid>
        <Grid item xs={12}>
          <TabPanel index={0} value={tabValue}>
            <LibraryTable
              modules={data.quizzes}
              setSnackbarText={setSnackbarText}
              mutate={mutate}
            />
          </TabPanel>
        </Grid>
      </Grid>
      {isRetail && (
        <Grid item xs={12}>
          <TabPanel value={tabValue} index={1}>
            <SuggestedQuizzesTable
              suggestedQuizzes={data.suggestions}
              setSnackbarText={setSnackbarText}
              mutate={mutate}
              hasPermissionsToCreateModule={hasPermissionsToCreateModule}
            />
          </TabPanel>
        </Grid>
      )}

      <SnackbarWrapper
        snackbarText={snackbarText}
        setSnackbarText={setSnackbarText}
      />
      <Modal
        open={modalOpen}
        onClose={() => setModalOpen(false)}
        aria-labelledby="Create a Module"
        aria-describedby="Modal to create a Module"
      >
        <div>
          <CreateQuizModal setModalOpen={setModalOpen} />
        </div>
      </Modal>
      <Modal
        open={suggestedModalOpen}
        onClose={() => setSuggestedModalOpen(false)}
        aria-labelledby="View suggested modules"
        aria-describedby="Modal to view suggested modules"
      >
        <div>
          <ModalWrapper>
            <SuggestedQuizzesTable
              suggestedQuizzes={data.suggestions}
              setSnackbarText={setSnackbarText}
              mutate={mutate}
            />
          </ModalWrapper>
        </div>
      </Modal>
    </>
  );
};

export default Library;

Library.propTypes = {
  location: PropTypes.object.isRequired,
};
