/* eslint-disable */
import useSWR from "swr";
import API from "../../../../axios/instances/API";

const BASE_ENDPOINT = "/dashboard/teams/details/?id=";

const fetcher = async (url) => {
  const { data } = await API.get(url);
  return data;
};

const useTeamMemberGeneralInfo = (id) => {
  const { data, error, mutate } = useSWR(`${BASE_ENDPOINT}${id}`, fetcher, {
    revalidateOnFocus: false,
  });
  return {
    data,
    isLoading: !error && !data,
    isError: error,
    mutate,
  };
};

export default useTeamMemberGeneralInfo;
