/* eslint-disable */
import React from "react";
import PropTypes from "prop-types";
import { Typography } from "../../pages/private/styles";
import AboutList from "./AboutList";
import { IntroText } from "./styles";

const adminList = [
  {
    title: "Read Only Admin",
    body: 'Users with read-only access can only view all the tabs an "Admin" has access to but not edit or create anything.',
  },
  {
    title: "Admin",
    body: "Users with this level of access enjoy the full functionality of the dashboard. They can edit team members, use the content library and marketplace, and view engagement reports.",
  },
  {
    title: "Organization Access Admin",
    body: 'Users with this access level have the same dashboard access as "Admins" but can also make changes to other companies within the organization. For example, if you have an Organization, “Cannabis LTD.”, with two companies, Cannabis A and Cannbis B. A user with organization access can make changes in both Cannabis A and Cannabis B’s dashboards.',
  },
  {
    title: "Read Only Organization Admin",
    body: "Users with read-only organization admin access can view dashboards for all other companies within your organization. For example, if you have an Organization, “Cannabis LTD.”, with two companies, Cannabis A and Cannbis B. A user with organization access can view both Cannabis A and Cannabis B dashboards.",
  },
];

const adminListVendor = [
  {
    title: "Read Only Admin",
    body: 'As the name suggest "read only" admins will be able to view all the tabs an admin has access to, but will not be able to change/edit anything.',
  },
  {
    title: "Admin",
    body: "Users with this level of Access can create/edit content, publish content to the marketplace, create audiences, view reports and more.",
  },
  {
    title: "Organization Access Admin",
    body: 'At this level, users have the same dashboard access as "Admins", but they are also able to make changes to other companies in the organization. For example, pretend you have an organization Cannabis ltd, with two companies Cannabis A and Cannbis B. A user with organization access will be able to make changes to both Cannabis A and Cannabis B.',
  },
];

const Admin = ({ isRetail }) => (
  <>
    {isRetail && (
      <>
        <IntroText variant="body1" mb={6}>
          <em>
            Admins have access to all functionalities of the Spiffy Dashboard, which includes
            inviting/deleting team members within the company, creating custom modules or deploying
            modules from the marketplace, and analyzing location and module-specific engagement
            reports.
          </em>
        </IntroText>
        <Typography variant="subtitle1" mb={-1}>
          <strong>
            <em>There are four different levels of admin.</em>
          </strong>
        </Typography>
        <AboutList listItems={adminList} />
      </>
    )}
    {!isRetail && (
      <>
        <IntroText variant="body1" mb={6}>
          <em>
            Admins have the ability to make changes to their company on the dashboard, which
            includes creating content, publishing it to the marketplace and more.
          </em>
        </IntroText>
        <Typography variant="subtitle1" mb={-1}>
          <strong>
            <em>There are three different levels of admin.</em>
          </strong>
        </Typography>
        <AboutList listItems={adminListVendor} />
      </>
    )}
  </>
);

export default Admin;

Admin.propTypes = {
  isRetail: PropTypes.bool.isRequired,
};
