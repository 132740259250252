/* eslint-disable */
import React from "react";
import styled from "styled-components/macro";
import { CheckCircle, AlertTriangle } from "react-feather";

const IndicatorWrapper = styled.div`
  width: fit-content;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 4px;
`;

const RequiredFieldsIndicator = ({
  title,
  required,
  completed,
  isModuleLive,
  isModuleConvertingNextStateLive,
  isRetail,
}) => {
  return (
    <IndicatorWrapper>
      {isRetail && isModuleLive && (
        <span style={{ color: "darkgrey" }}>Module has launched 🚀</span>
      )}
      {isModuleConvertingNextStateLive && (
        <span style={{ color: "darkgrey" }}>
          Module will be live when video finishes transcoding
        </span>
      )}
      {!isModuleLive && !isModuleConvertingNextStateLive && (
        <>
          <span style={{ fontWeight: "600" }}>{title}:</span>
          <span
            style={{
              fontSize: 12,
              color: required === completed ? "seagreen" : "darkgrey",
            }}
          >
            {`(${completed}/${required}) `}
          </span>

          <span
            style={{
              fontSize: 12,
              color: required === completed ? "seagreen" : "darkgrey",
            }}
          >
            Required Actions complete
          </span>
          {completed === required ? (
            <div
              style={{
                heigth: 20,
                padding: 2,
                width: 20,
                minWidth: 20,

                minHeight: 20,
                borderRadius: 3,
                background: "white",

                border: "1px solid lightgray",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <CheckCircle height={12} width={12} color="seagreen" />
            </div>
          ) : (
            <div
              style={{
                heigth: 20,
                width: 20,
                minWidth: 20,

                minHeight: 20,
                borderRadius: 3,
                padding: 2,
                background: "white",
                border: "1px solid lightgray",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <AlertTriangle height={12} width={12} color="darkorange" />
            </div>
          )}
        </>
      )}
    </IndicatorWrapper>
  );
};

export default RequiredFieldsIndicator;
