/* eslint-disable */
import React, { useState } from "react";
import PropTypes from "prop-types";
import { Modal } from "@material-ui/core";
import { CustomDatatableV2 } from "../../styles";
import { makeDefaultTableOptions } from "../../../../storage/helpers";
import ConfirmRemoveLocation from "../../supervisors/detail/ConfirmRemoveLocation";
import DeleteButtonForTables from "../../../../components/Table/DeleteButtonForTables";
import TableTitle from "../../../../components/Table/TableTitle";
import { Monitor } from "react-feather";
import CustomDataTableIcons from "../../../../components/Table/CustomDataTableIcons";
import { useMediaQuery } from "@material-ui/core";

const ManagersDataTable = ({
  data,
  readOnly,
  isAdmin,
  mutate,
  setSnackbarText,
}) => {
  const [deleteInfo, setDeleteInfo] = useState(null);
  const isUnder600Px = useMediaQuery((theme) => theme.breakpoints.down("xs"));

  const options = makeDefaultTableOptions("supervisors");
  options.rowsPerPage = 10;
  options.textLabels = {
    body: {
      noMatch: "There are no supervisors at this location",
    },
  };

  if (isUnder600Px) {
    options.rowsPerPageOptions = [];
    options.jumpToPage = false;
  }

  const tableData = data.location.managers.map((manager) => ({
    id: manager.id,
    name: `${manager.firstName} ${manager.lastName}`,
    email: manager.email,
    phoneNumber: manager.phoneNumber || "---/---",
  }));

  const columns = [
    {
      name: "id",
      label: "ID",
      options: {
        filter: false,
        sort: false,
        display: false,
      },
    },
    {
      name: "name",
      label: "Name",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "email",
      label: "Email",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "phoneNumber",
      label: "Phone Number",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "delete",
      label: "Delete",
      options: {
        sort: false,
        filter: false,
        empty: true,
        download: false,
        display: true,
        customBodyRender: (_, tableMeta) => (
          <DeleteButtonForTables
            disabled={readOnly || !isAdmin}
            handleClick={(e) => {
              e.stopPropagation();
              const { rowData } = tableMeta;
              setDeleteInfo({
                managerId: rowData[0],
                name: rowData[1],
                locationId: data.location.id,
                locationName: data.location.name,
              });
            }}
          />
        ),
      },
    },
  ];

  return (
    <>
      <CustomDatatableV2
        title={
          <TableTitle
            title={`Supervisors at ${data.location.name}`}
            Icon={Monitor}
          />
        }
        data={tableData}
        columns={columns}
        options={options}
        components={{
          icons: CustomDataTableIcons,
        }}
      />
      <Modal
        open={Boolean(deleteInfo)}
        onClose={() => setDeleteInfo(null)}
        aria-labelledby="Delete a location"
        aria-describedby="modal to delete a location"
      >
        <div>
          <ConfirmRemoveLocation
            setSnackbarText={setSnackbarText}
            deleteInfo={deleteInfo}
            managerId={deleteInfo ? deleteInfo.managerId : null}
            setDeleteInfo={setDeleteInfo}
            mutate={mutate}
            fromLocationPage
          />
        </div>
      </Modal>
    </>
  );
};

export default ManagersDataTable;

ManagersDataTable.propTypes = {
  data: PropTypes.object.isRequired,
  readOnly: PropTypes.bool.isRequired,
  isAdmin: PropTypes.bool.isRequired,
  mutate: PropTypes.func.isRequired,
  setSnackbarText: PropTypes.func.isRequired,
};
