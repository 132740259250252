/* eslint-disable */
import React from 'react';
import { Tooltip, Typography } from '@material-ui/core';
import styled from 'styled-components/macro';
import { makeStyles } from '@material-ui/core/styles';
import { List } from 'react-feather';
import useQuizReview from '../../../../swr/hooks/Library/useQuizReview';
import { addDays } from 'date-fns';

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: fit-content;
  padding: 1px 4px;
  border-radius: 4px;

  &:hover {
    cursor: pointer;
    background-color: #eeeeee;
  }
`;

const InnerContainer = styled.div`
  width: 100%;
  background-color: white;
  border-radius: 4px;
  display: flex;
  padding: 4px;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
  border: 1px solid slategrey;
  min-height: 156px;
  height: 156px;
`;

const FlexDiv = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 2px;
`;

const Inner = ({ id }) => {
  const { isLoading, details } = useQuizReview(id);

  if (isLoading) {
    return (
      <InnerContainer>
        <span>loading...</span>
      </InnerContainer>
    );
  }

  return (
    <InnerContainer>
      <div
        style={{
          padding: 2,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'flex-start',
          gap: 6,

          width: '100%',
        }}
      >
        <Typography
          style={{ color: 'black' }}
          variant="subtitle2"
        >
          Module Info
        </Typography>
      </div>
      <FlexDiv>
        <span style={{ color: 'black', fontWeight: '400', fontSize: 11 }}>Start date:</span>
        <span style={{ color: 'black', fontSize: 11, fontWeight: '400' }}>
          {details?.startDate ? addDays(new Date(details.startDate), 1).toDateString() : '---/---'}
        </span>
      </FlexDiv>
      <FlexDiv>
        <span style={{ color: 'black', fontWeight: '400', fontSize: 11 }}>End date:</span>
        <span style={{ color: 'black', fontSize: 11, fontWeight: '400' }}>
          {details?.dueDate ? addDays(new Date(details.dueDate), 1).toDateString() : '---/---'}
        </span>
      </FlexDiv>
      <FlexDiv>
        <span style={{ color: 'black', fontWeight: '400', fontSize: 11 }}>Time to complete:</span>
        <span style={{ color: 'black', fontSize: 11, fontWeight: '400' }}>
          {details?.estimatedTime} minutes
        </span>
      </FlexDiv>
      <FlexDiv>
        <span style={{ color: 'black', fontWeight: '400', fontSize: 11 }}>Score to pass:</span>
        <span style={{ color: 'black', fontSize: 11, fontWeight: '400' }}>
          {details?.passScore}/{details?.questions?.questions?.length}{' '}
        </span>
      </FlexDiv>
      <FlexDiv>
        <span style={{ color: 'black', fontWeight: '400', fontSize: 11 }}>Has Description:</span>
        <span style={{ color: 'black', fontSize: 11, fontWeight: '400' }}>
          {details.description ? 'Yes' : 'No'}
        </span>
      </FlexDiv>

      <FlexDiv>
        <span style={{ color: 'black', fontWeight: '400', fontSize: 11 }}>Has Disclaimer:</span>
        <span style={{ color: 'black', fontSize: 11, fontWeight: '400' }}>
          {details?.disclaimer ? 'Yes' : 'No'}
        </span>
      </FlexDiv>
      <FlexDiv>
        <span style={{ color: 'black', fontWeight: '400', fontSize: 11 }}>Answer Key Enabled:</span>
        <span style={{ color: 'black', fontSize: 11, fontWeight: '400' }}>
          {details?.displayAnswers ? 'Yes' : 'No'}
        </span>
      </FlexDiv>
    </InnerContainer>
  );
};

const useStyles = makeStyles(() => ({
  noMaxWidth: {
    maxWidth: 'none',
    minWidth: 300,
    maxWidth: 400,
    minHeight: 'none',
    maxHeight: 'none',
    borderRadius: 8,
    padding: 0,
    // TODO -> used in many components
  },
}));

const BasicsTooltip = ({ data }) => {
  const classes = useStyles();
  const { id } = data;

  return (
    <Tooltip
      classes={{ tooltip: classes.noMaxWidth }}
      title={<Inner id={id} />}
      arrow
      placement="top"
    >
      <Container>
        {' '}
        <List
          height={14}
          width={14}
          color="darkgrey"
        />{' '}
      </Container>
    </Tooltip>
  );
};

export default BasicsTooltip;
