/* eslint-disable */
import React from 'react';
import { List } from 'react-feather';
import { StyledItem } from '../styles';
import { USER_TYPES, LIBRARY_MODAL_KEYS } from '../../../../../storage/constants';

const ViewActivityLog = ({ id, userType, moduleName, setModalState, handleClose }) => {
  if (
    [USER_TYPES.RETAIL_MANAGER_CANT_DEPLOY, USER_TYPES.RETAIL_MANAGER_CAN_DEPLOY].includes(userType)
  ) {
    return null;
  }

  return (
    <StyledItem
      onClick={() => {
        setModalState({ key: LIBRARY_MODAL_KEYS.ACTIVITY, id, name: moduleName });
        handleClose();
      }}
    >
      <List />
      <span>View Activity Log</span>
    </StyledItem>
  );
};

export default ViewActivityLog;
