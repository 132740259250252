/* eslint-disable */
import { Paper, Modal } from "@material-ui/core";
import PropTypes from "prop-types";
import React, { useState } from "react";
import styled from "styled-components/macro";
import { Typography } from "../../styles";
import ExcelTemplateCreator from "./ExcelTemplateCreator";
import UploadTemplate from "./UploadTemplate";
import ShowResults from "./ShowResults";

const Wrapper = styled(Paper)`
  padding: 20px;
  min-height: 300px;
  width: min(440px, 90vw);
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  text-align: left;
  border: 2px solid black;
  max-height: 96vh;
  overflow-y: scroll;
`;

const Subtitle = styled.p`
  fontsize: 12px;
  font-weight: 600;
  color: darkgrey;
`;

const Number = styled.span`
  font-size: 12px;
  font-weight: 800;
  color: black;
`;

const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
`;

const InviteTeamMemberCsv = ({
  setInviteCsvModal,
  setSnackbarText,
  mutate = () => {},
}) => {
  const [inviteResults, setInviteResults] = useState(null);

  return (
    <Wrapper>
      <Typography style={{ fontSize: 16, fontWeight: "600" }}>
        Invite Team Members via CSV
      </Typography>
      <div style={{ height: 12 }} />
      <TitleContainer>
        <Number>Step 1:</Number>
        <Subtitle>
          Select the locations to invite team members and download the template.
        </Subtitle>
      </TitleContainer>
      <div style={{ height: 6 }} />
      <ExcelTemplateCreator />
      <div style={{ height: 12 }} />
      <TitleContainer>
        <Number>Step 2:</Number>
        <Subtitle>
          Fill out the downloaded template in excel/sheets/numbers and save the
          file in a CSV format.
        </Subtitle>
      </TitleContainer>
      <div style={{ height: 6 }} />
      <div
        style={{
          background: "#eeeeee60",
          border: "1px solid lightgray",
          padding: "8px 10px",
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
          justifyContent: "flex-start",
          borderRadius: 4,
        }}
      >
        <p
          style={{
            fontWeight: "600",
            fontSize: 13,
            marginLeft: 4,
            color: "#233044",
            marginTop: -1,
          }}
        >
          Notes
        </p>

        <ul style={{ padding: "0px 16px" }}>
          <li style={{ color: "#233044", fontSize: 12, fontWeight: "500" }}>
            Maximum 100 rows per upload.
          </li>
          <li style={{ color: "#233044", fontSize: 12, fontWeight: "500" }}>
            Do not change column names or add columns.
          </li>
          <li style={{ color: "#233044", fontSize: 12, fontWeight: "500" }}>
            First name, last name, email & location are required fields.
          </li>
          <li style={{ color: "#233044", fontSize: 12, fontWeight: "500" }}>
            Role fields are optional.
          </li>
        </ul>
      </div>
      <div style={{ height: 18 }} />
      <TitleContainer>
        <Number>Step 3:</Number>
        <Subtitle>Upload completed template in CSV format.</Subtitle>
      </TitleContainer>
      <UploadTemplate
        setSnackbarText={setSnackbarText}
        setInviteResults={setInviteResults}
      />
      <Modal
        open={Boolean(inviteResults)}
        onClose={() => setInviteResults(null)}
      >
        <ShowResults
          handleExit={() => {
            mutate();
            setInviteResults(null);
            setInviteCsvModal(null);
          }}
          handleUploadMore={() => {
            mutate();
            setInviteResults(null);
          }}
          results={inviteResults}
        />
      </Modal>
    </Wrapper>
  );
};

export default InviteTeamMemberCsv;

InviteTeamMemberCsv.propTypes = {
  setInviteCsvModal: PropTypes.func.isRequired,
  setSnackbarText: PropTypes.func.isRequired,
  mutate: PropTypes.func.isRequired,
};
