/* eslint-disable */
import React, { useEffect, useState } from "react";
import { CircularProgress, Paper } from "@material-ui/core";
import { Formik } from "formik";
import PropTypes from "prop-types";
import { AlertTriangle, CheckCircle } from "react-feather";
import { Helmet } from "react-helmet-async";
import { useHistory } from "react-router-dom";
import styled from "styled-components/macro";
import API from "../../../axios/instances/API";
import FunLoader from "../../../components/Loaders/FunLoader";
import AuthLayout from "../../../layouts/Auth";
import { WEB_APP_BASE_URL } from "../../../storage/constants";
import { Button, TextField, Typography } from "../../private/styles";
import ConfirmPasswordField from "../Inputs/ConfirmPasswordField";
import CountryAndStateFields from "../Inputs/CountryAndStateFields";
import DobField from "../Inputs/DobField";
import EmailField from "../Inputs/EmailField";
import FirstNameField from "../Inputs/FirstNameField";
import LastNameField from "../Inputs/LastNameField";
import LocationSelect from "../Inputs/LocationSelect";
import PasswordField from "../Inputs/PasswordField";
import RoleSelect from "../Inputs/RoleSelect";
import { LoadingWrapper, WideAlert, Wrapper } from "./styles";
import { clearTokens } from "../../../storage/helpers";
import { Alert } from "@material-ui/lab";
import {
  CREATE_TEAM_MEMBER_INVITATION_TYPES,
  INVITATION_TYPES,
  getInitialValues,
  getInviteDataFailedErrorString,
  getInviteType,
  getSubmitBody,
  getValidationSchema,
  shouldFormBeDisabled,
} from "./helpers";

const ColumnInputsWrapper = styled.div`
  margin-top: 12px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
`;

const Subtitle = styled(Typography)`
  letter-spacing: 1px;
  font-weight: 300;
  font-size: 12px;
  text-align: center;
  padding-bottom: 6px;
  padding-top: 12px;
  text-transform: uppercase;
`;

const Spacer4px = styled.div`
  height: 4px;
`;

const Spacer8px = styled.div`
  height: 8px;
`;

const DisabledTextFieldWithValue = styled(TextField)`
  .MuiInputBase-root.Mui-disabled {
    font-size: 12px;
    font-weight: 500;
    color: darkgrey;
  }
`;

const ErrorWrapper = styled(Paper)`
  min-height: 160px;
  width: 400px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  padding: 20px;
`;

const AcceptInvite = ({ match }) => {
  const { token } = match.params;
  const [inviteType, setInviteType] = useState(null);
  const [data, setData] = useState(null);
  const [errorAlert, setErrorAlert] = useState(null);
  const [noFormLoading, setNoFormLoading] = useState(null);
  const history = useHistory();

  useEffect(() => {
    const getInviteDetails = async () => {
      try {
        const { data: apiData } = await API.get(
          `/common/invitation/details/?token=${token}`
        );

        if (apiData?.job === "A") {
          return (window.location = `${WEB_APP_BASE_URL}invitation/${token}`);
        }
        const typeOfInvite = getInviteType(apiData);
        setInviteType(typeOfInvite);
        setData(apiData);
      } catch (error) {
        const errorAlert = getInviteDataFailedErrorString(error);
        setErrorAlert(errorAlert);
        setData("ERROR");
      }
    };

    getInviteDetails();
  }, []);

  const handleSubmitInviteNoForm = async () => {
    try {
      setNoFormLoading(true);
      await API.post("/common/invitation/details/", {
        token,
      });
      clearTokens();
      setNoFormLoading(false);
      setData("SUCCESS");
      setTimeout(() => {
        history.push({
          pathname: "/",
          state: {
            fromInvite: true,
            fromConfig: false,
          },
        });
      }, [3000]);
    } catch (error) {
      const errorMsg = getInviteDataFailedErrorString(error);
      setData("ERROR");
      setErrorAlert(errorMsg);
    }
  };

  const handleFormSubmit = async (values, { setSubmitting }) => {
    setErrorAlert(null);
    setSubmitting(true);
    let sendBody = getSubmitBody(token, values, inviteType);

    try {
      await API.post("/common/invitation/details/", sendBody);
      setSubmitting(false);
      setData("SUCCESS");
      clearTokens();

      setTimeout(() => {
        history.push({
          pathname: "/",
          state: {
            fromInvite: true,
            fromConfig: false,
            email: values.email,
          },
        });
      }, [3000]);
    } catch (error) {
      setSubmitting(false);
      const errorMsg = getInviteDataFailedErrorString(error);
      setErrorAlert(errorMsg);
    }
  };

  if (!data) {
    return (
      <AuthLayout>
        <Helmet title="Spiffy | Accept Invite" />
        <Wrapper>
          <Typography variant="h3" align="center">
            Accept Invite
          </Typography>
          <LoadingWrapper>
            <FunLoader />
          </LoadingWrapper>
        </Wrapper>
      </AuthLayout>
    );
  }

  if (data === "ERROR") {
    return (
      <AuthLayout>
        <Helmet title="Spiffy | Accept Invite" />
        <ErrorWrapper>
          <AlertTriangle
            style={{ height: 32, width: 32, color: "tomato", marginTop: 12 }}
          />
          <div style={{ height: 6 }} />
          <p
            style={{
              fontSize: 18,
              fontWeight: "700",
              textTransform: "uppercase",
            }}
          >
            {errorAlert?.title}
          </p>
          <span
            style={{
              fontSize: 13,
              fontWeight: "400",
              marginTop: 16,
              textAlign: "center",
            }}
          >
            {errorAlert?.body}
          </span>
          <span>{errorAlert?.context}</span>
        </ErrorWrapper>
      </AuthLayout>
    );
  }

  if (data === "SUCCESS") {
    return (
      <AuthLayout>
        <Helmet title="Spiffy | Accept Invite" />
        <ErrorWrapper>
          <CheckCircle
            style={{
              height: 32,
              width: 32,
              color: "mediumseagreen",
              marginTop: 12,
            }}
          />
          <p
            style={{
              fontSize: 22,
              fontWeight: "700",
              textTransform: "uppercase",
            }}
          >
            Invite Accepted
          </p>
          <span
            style={{
              fontSize: 13,
              fontWeight: "300",
              marginTop: 16,
              textAlign: "center",
            }}
          >
            You will be redirected to log in
          </span>
        </ErrorWrapper>
      </AuthLayout>
    );
  }

  const hasUser = data.user_exists;
  const initialValues = getInitialValues(inviteType, data);
  const validationSchema = getValidationSchema(inviteType);

  const hasInfoMessages = data?.info_messages?.length > 0;

  if (
    [
      INVITATION_TYPES.ADMIN_EXISTING_USER,
      INVITATION_TYPES.MANAGER_EXISTING_USER,
    ].includes(inviteType)
  ) {
    return (
      <AuthLayout>
        <Helmet title="Spiffy | Accept Invite" />
        <ErrorWrapper>
          <Typography
            align="center"
            style={{
              textTransform: "uppercase",
              fontSize: 22,
              fontWeight: "700",
            }}
          >
            {data.company}
          </Typography>
          <Typography
            variant="subtitle1"
            mb={1}
            align="center"
            style={{
              fontSize: 13,
              fontWeight: 400,
            }}
          >
            Has invited you to join them on Spiffy
          </Typography>
          {data?.info_messages?.length > 0 && (
            <Alert severity="info">
              <p style={{ fontWeight: 600 }}>Alert</p>
              {data?.info_messages?.map((msg) => (
                <p style={{ fontSize: 12 }} key={msg}>
                  {msg}
                </p>
              ))}
            </Alert>
          )}
          <Button
            fullWidth
            variant="contained"
            size="small"
            color="secondary"
            disabled={noFormLoading}
            onClick={handleSubmitInviteNoForm}
            style={{ marginTop: 26 }}
          >
            Accept Invite
          </Button>
        </ErrorWrapper>
      </AuthLayout>
    );
  }

  return (
    <AuthLayout>
      <Helmet title="Spiffy | Accept Invite" />
      <Wrapper>
        <Typography
          align="center"
          style={{
            textTransform: "uppercase",
            fontSize: 22,
            fontWeight: "700",
          }}
        >
          {data.company}
        </Typography>
        <Typography
          variant="subtitle1"
          mb={1}
          align="center"
          style={{
            fontSize: 13,
            fontWeight: 400,
          }}
        >
          Has invited you to join them on Spiffy
        </Typography>
        {hasInfoMessages && (
          <Alert severity="info">
            <p style={{ fontWeight: 600 }}>Alert</p>
            {data?.info_messages?.map((msg) => (
              <p style={{ fontSize: 12 }} key={msg}>
                {msg}
              </p>
            ))}
          </Alert>
        )}
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleFormSubmit}
        >
          {({
            values,
            handleChange,
            handleBlur,
            handleSubmit,
            setFieldValue,
            isSubmitting,
            errors,
            isValid,
            touched,
          }) => (
            <form noValidate onSubmit={handleSubmit}>
              {!hasUser && (
                <>
                  <Subtitle>--- Account Information ---</Subtitle>
                  <EmailField
                    touched={touched}
                    errors={errors}
                    values={values}
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                    disabled
                    // disabled={inviteType === INVITATION_TYPES.MANAGER_EXISTING_USER}
                  />
                  <ColumnInputsWrapper>
                    <PasswordField
                      touched={touched}
                      errors={errors}
                      values={values}
                      handleChange={handleChange}
                      handleBlur={handleBlur}
                    />
                    <ConfirmPasswordField
                      touched={touched}
                      errors={errors}
                      values={values}
                      handleChange={handleChange}
                      handleBlur={handleBlur}
                    />
                  </ColumnInputsWrapper>
                  <Spacer8px />
                </>
              )}
              {CREATE_TEAM_MEMBER_INVITATION_TYPES.includes(inviteType) && (
                <>
                  <Subtitle>--- Company Information ---</Subtitle>
                  <Spacer8px />
                  <DisabledTextFieldWithValue
                    disabled
                    value={data.company}
                    size="small"
                    fullWidth
                    variant="outlined"
                    label="Company"
                  />
                  <Spacer4px />
                  <Spacer8px />
                  <LocationSelect
                    locations={data.available_locations}
                    touched={touched}
                    errors={errors}
                    values={values}
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                    disabled={data?.available_locations?.length < 2}
                  />
                  <Spacer8px />
                  <Spacer4px />
                  <RoleSelect
                    setFieldValue={setFieldValue}
                    roles={data.available_roles}
                    touched={touched}
                    errors={errors}
                    values={values}
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                  />
                </>
              )}
              {!hasUser && (
                <>
                  <Subtitle>--- User Information ---</Subtitle>
                  <ColumnInputsWrapper>
                    <FirstNameField
                      touched={touched}
                      errors={errors}
                      values={values}
                      handleChange={handleChange}
                      handleBlur={handleBlur}
                    />
                    <LastNameField
                      touched={touched}
                      errors={errors}
                      values={values}
                      handleChange={handleChange}
                      handleBlur={handleBlur}
                    />
                  </ColumnInputsWrapper>
                  <Spacer4px />
                  <Spacer8px />
                  <CountryAndStateFields
                    handleBlur={handleBlur}
                    columns
                    handleChange={handleChange}
                    values={values}
                    setFieldValue={setFieldValue}
                  />
                  <Spacer4px />
                  <Spacer4px />
                  <Spacer4px />
                  <DobField setFieldValue={setFieldValue} values={values} />
                </>
              )}
              <Button
                variant="contained"
                color="secondary"
                fullWidth
                mt={6}
                type="submit"
                disabled={shouldFormBeDisabled(
                  values,
                  isValid,
                  isSubmitting,
                  CREATE_TEAM_MEMBER_INVITATION_TYPES.includes(inviteType)
                )}
                startIcon={isSubmitting ? <CircularProgress size={20} /> : null}
              >
                Accept Invite
              </Button>
            </form>
          )}
        </Formik>
        {errorAlert && (
          <>
            <WideAlert
              severity="error"
              mt={2}
              onClose={() => {
                setErrorAlert(null);
              }}
            >
              <p style={{ fontWeight: "600" }}>{errorAlert?.title}</p>
              <p>{errorAlert?.body}</p>
              <p>{errorAlert?.context}</p>
            </WideAlert>
          </>
        )}
      </Wrapper>
    </AuthLayout>
  );
};

export default AcceptInvite;

AcceptInvite.propTypes = {
  match: PropTypes.object.isRequired,
};
