/* eslint-disable */
import React, { useState } from 'react';
import {
  TextField,
} from '../../private/styles';
import { 
  Mail,
  CheckCircle, 
  XCircle,
  AlertTriangle,
  FilePlus,
} from 'react-feather';
import {
  InputAdornment,
  Tooltip,
} from '@material-ui/core';
import API from '../../../axios/instances/API';

const STATUS_OPTIONS = {
  CONFIRMED: 'CONFIRMED',
  DENIED: 'DENIED',
  UNKNOWN: 'UNKNOWN',
}

const ReferralCodeField = ({
  touched, 
  errors, 
  values, 
  handleChange, 
  handleBlur,
  verifyCode = false,
  
  referralStatus, 
  setReferralStatus, 
}) => {

  const [referralCheckLoading, setReferralCheckLoading] = useState(false);

  const checkForReferralCode = async referralCode => {
    try {
      setReferralCheckLoading(true)
      const { data } = await API.get(`/common/referral/verify/?code=${referralCode}`)
      const { exists } = data;

      setReferralCheckLoading(false)
      setReferralStatus(exists ? STATUS_OPTIONS.CONFIRMED : STATUS_OPTIONS.DENIED)
    } catch (error) {
      setReferralCheckLoading(false)      
    }
  }

  return (
    <TextField
    type="text"
    name="ref"
    label="Referral Code"
    value={values.ref}
    fullWidth
    variant='outlined'
    size="small"
    error={Boolean(touched.ref && errors.ref)}
    onChange={handleChange}
    onBlur={(e) => {
      if (values.ref.length < 1) {                    
        return setReferralStatus(STATUS_OPTIONS.UNKNOWN);
      }
      if (!errors.ref && verifyCode) {
        checkForReferralCode(values.ref)
      }
      handleBlur(e)
    }}
    helperText="*optional"
    autoComplete="new-password"
    InputProps={{
      startAdornment: (
        <InputAdornment position="start">
          <FilePlus style={{ height: 14, width: 14, color: 'darkgrey' }} />
        </InputAdornment>
      ),      
      endAdornment:(
        verifyCode ? (
        <InputAdornment position='end' style={{ cursor: 'pointer'}}>
          {(!errors.ref && values.ref.length > 0 && !referralCheckLoading && referralStatus !== STATUS_OPTIONS.UNKNOWN) && (
              <Tooltip 
                title={
                  referralStatus === STATUS_OPTIONS.CONFIRMED 
                  ? 'Referall code verified' 
                  : 'Referral code does not exist in the database'
                }
                arrow
              >
                <div 
                  style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: 14, width: 14, borderRadius: 10}}
                >                                                            
                  {
                    referralStatus === STATUS_OPTIONS.CONFIRMED ? 
                    <CheckCircle style={{ color: 'mediumseagreen'}} /> 
                    : <AlertTriangle style={{ color: 'darkorange' }} />
                  }
                </div>        
              </Tooltip>
          )}
          </InputAdornment>           
        ) : null 
      )}}
  />
  )
}

export default ReferralCodeField;