/* eslint-disable */
import React from "react";
import useAudienceToolStore from "../../../store/useAudienceToolStore";
import { Check } from "react-feather";
import { TABLE_STATES } from "./helpers";

const CheckedIcon = () => {
  const isDisplayOnly = useAudienceToolStore((state) => state.isDisplayOnly);
  const tableState = useAudienceToolStore((state) => state.tableState);

  return (
    <div
      style={{
        height: 14,
        width: 14,
        minHeight: 14,
        minWidth: 14,
        borderRadius: 4,
        background:
          isDisplayOnly ||
          [TABLE_STATES.SAVING, TABLE_STATES.LOCKED].includes(tableState)
            ? "#337ab79c"
            : "#337ab7",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Check height={10} width={10} style={{ color: "white" }} />
    </div>
  );
};

export default CheckedIcon;
