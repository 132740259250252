/* eslint-disable import/no-duplicates */
/* eslint-disable */
import React from "react";
import FunLoader from "../../../../../components/Loaders/FunLoader";
import ErrorBlockWithPermissions from "../../../../../components/UI/ErrorBlockWithPermissions";
import useKeyCompanyStats from "../../../../../swr/hooks/RetailReports/useKeyCompanyStats";
import EngagementByDateSelect from "./EngagementByDateSelect";
import EngagementChart from "./EngagementChart";
import StatBoxes from "./StatBoxes";

const OverallReport = () => {
  const { data, isLoading, isError } = useKeyCompanyStats();

  if (isLoading) {
    return (
      <div
        style={{
          width: "100%",
          height: 300,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <FunLoader />
        <span style={{ color: "darkgrey" }}>loading report...</span>
      </div>
    );
  }

  if (isError) {
    return <ErrorBlockWithPermissions errorObj={isError} />;
  }

  return (
    <>
      <div style={{ height: 16 }} />
      <StatBoxes data={data} />
      <div style={{ height: 20 }} />

      <EngagementByDateSelect />
      <div style={{ height: 20 }} />

      <EngagementChart />
      <div style={{ height: 20 }} />
    </>
  );
};

export default OverallReport;
