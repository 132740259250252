/* eslint-disable */
import React from "react";
import {
  SubmissionChipExtraCreditTooltip,
  SubmissionChipAssignedTooltip,
  StyledLink,
  CustomDatatableV2,
} from "../../../styles";
import { makeDefaultTableOptions } from "../../../../../storage/helpers";
import { ENGAGEMENT_TYPES } from "../../../../../storage/constants";
import { Download, User } from "react-feather";
import SubmissionResultIndicator from "../../../../../components/Table/SubmissionResultIndicator";
import SubmissionTypeChip from "../../../home/retail/SubmissionTypeChip";
import TableTitle from "../../../../../components/Table/TableTitle";
import CustomDataTableIcons from "../../../../../components/Table/CustomDataTableIcons";
import DateTooltip from "../../../../../components/Table/DateTooltip";
import { useMediaQuery } from "@material-ui/core";

const getTableData = (submissions, users, moduleName, engagementType) => {
  const filteredSubmissions =
    engagementType === ENGAGEMENT_TYPES.ASSIGNED
      ? submissions.filter((sub) => !sub.is_follower_submission)
      : engagementType === ENGAGEMENT_TYPES.EXTRACREDIT
      ? submissions.filter((sub) => sub.is_follower_submission)
      : submissions;

  return filteredSubmissions.map((submission) => {
    const filteredUser = users[submission.user_id];
    return {
      id: submission.id,
      userId: filteredUser.user_id,
      name: `${filteredUser.first_name} ${filteredUser.last_initial}`,
      module: moduleName,
      date: submission.created,
      type: submission.is_follower_submission
        ? ENGAGEMENT_TYPES.EXTRACREDIT
        : ENGAGEMENT_TYPES.ASSIGNED,
      score: `${submission.score}/${submission.questions}`,
      passed: submission.passed ? "Yes" : "No",
    };
  });
};

const SubmissionsTable = ({
  submissions,
  engagementType,
  users,
  moduleName,
}) => {
  const isUnder600Px = useMediaQuery((theme) => theme.breakpoints.down("xs"));

  const options = makeDefaultTableOptions("submissions");
  options.sortOrder = {
    name: "date",
    direction: "desc",
  };
  options.rowsPerPage = 10;

  if (isUnder600Px) {
    options.rowsPerPageOptions = [];
    options.jumpToPage = false;
  }

  const tableData = getTableData(
    submissions,
    users,
    moduleName,
    engagementType
  );

  const columns = [
    {
      name: "id",
      label: " ",
      options: {
        filter: false,
        sort: false,
        display: false,
        print: false,
        download: false,
      },
    },
    {
      name: "userId",
      label: " ",
      options: {
        filter: false,
        sort: false,
        display: false,
        print: false,
        download: false,
      },
    },
    {
      name: "name",
      label: "User",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta) => (
          <StyledLink
            value={value}
            to={`/vendor-reports/user/${tableMeta.rowData[1]}`}
            CustomIcon={User}
          />
        ),
      },
    },
    {
      name: "date",
      label: "Submission Date",
      options: {
        filter: true,
        customBodyRender: (val) => (
          // <span>{formatDateWithTime(new Date(val))}</span>
          <DateTooltip date={val} />
        ),
      },
    },
    {
      name: "module",
      label: "Module",
    },
    {
      name: "type",
      label: "Submission Type",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (val) => {
          if (val === ENGAGEMENT_TYPES.ASSIGNED) {
            return <SubmissionChipAssignedTooltip />;
          } else {
            return <SubmissionChipExtraCreditTooltip isVendor />;
          }
        },
      },
    },
    {
      name: "score",
      label: "Score",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "passed",
      label: "Result",
      options: {
        display: true,
        download: true,
        print: true,
        customBodyRender: (value) => (
          <SubmissionResultIndicator value={value} />
        ),
      },
    },
  ];

  return (
    <>
      <CustomDatatableV2
        title={
          <div style={{ display: "flex", gap: 6 }}>
            <TableTitle Icon={Download} title="Submissions" />
            <SubmissionTypeChip
              isVendor
              small
              submissionType={engagementType}
            />
          </div>
        }
        options={options}
        data={tableData}
        columns={columns}
        components={{
          icons: CustomDataTableIcons,
        }}
      />
    </>
  );
};

export default SubmissionsTable;
