/* eslint-disable */
import React, { useContext, useMemo } from "react";
import PropTypes from "prop-types";
import styled from "styled-components/macro";
import { NavLink } from "react-router-dom";
import { darken } from "polished";
import { Collapse, Typography } from "@material-ui/core";
import SidebarLink from "./SidebarLink";
import SidebarCategory from "./SidebarCategory";
import { GlobalState } from "../../store/GlobalState";

const SidebarSection = styled(Typography)`
  color: ${(props) => props.theme.sidebar.color};
  padding: ${(props) => props.theme.spacing(2)}px
    ${(props) => props.theme.spacing(7)}px
    ${(props) => props.theme.spacing(2)}px;
  opacity: 0.9;
  display: block;
  &:hover {
    background-color: ${(props) =>
      darken(0.05, props.theme.sidebar.background)};
  }
`;

const SidebarItems = ({ sidebarRoutes }) => {
  const { state } = useContext(GlobalState);
  const { config } = state;
  const { isRetail } = state;
  const { user } = config;
  const { job } = user;

  const { current_company: company } = config || {};

  const getSidebarRoutes = (allSidebarRoutes) => {
    // return routes depending on access level
    if (job === "M" && isRetail) {
      const filtered = allSidebarRoutes.filter((route) => route.allowManager);

      if (user.can_deploy_vendor_content) return filtered;

      return filtered.filter((route) => route.id !== "Marketplace");
    }

    if (isRetail) {
      return allSidebarRoutes.filter((route) => route.allowRetail);
    }

    if (!isRetail) {
      return allSidebarRoutes.filter((route) => route.allowVendor);
    }

    return allSidebarRoutes;
  };

  const addBadgesToRoutes = (routes) => {
    try {
      const { pending_access_requests } = company;
      const { team_members, admins, managers } = pending_access_requests || {};

      const routesWithBadges = routes.map((r) => {
        if (r.id === "Admins") {
          if (admins > 0) {
            r.badge = admins;
          } else {
            r.badge = null;
          }
        }
        if (r.id === "Supervisors") {
          if (managers > 0) {
            r.badge = managers;
          } else {
            r.badge = null;
          }
        }
        if (r.id === "Team Members") {
          if (team_members > 0) {
            r.badge = team_members;
          } else {
            r.badge = null;
          }
        }

        return r;
      });

      return routesWithBadges;
    } catch (error) {
      return routes;
    }
  };

  const useRoutes = getSidebarRoutes(sidebarRoutes);

  const useRoutesWithBadges = addBadgesToRoutes(useRoutes, company);

  return (
    <>
      {useRoutesWithBadges.map((category, index) => (
        <React.Fragment key={index}>
          {category.header ? (
            <SidebarSection>{category.header}</SidebarSection>
          ) : null}

          {category.children && category.icon ? (
            <React.Fragment key={index}>
              <SidebarCategory
                isOpen={!openRoutes[index]}
                isCollapsable
                name={category.id}
                icon={category.icon}
                button
                onClick={() => toggle(index)}
              />

              <Collapse in={openRoutes[index]} timeout="auto" unmountOnExit>
                {category.children.map((route, index) => (
                  <SidebarLink
                    key={index}
                    name={route.name}
                    to={route.path}
                    icon={route.icon}
                    badge={route.badge}
                  />
                ))}
              </Collapse>
            </React.Fragment>
          ) : category.icon ? (
            <SidebarCategory
              isCollapsable={false}
              name={category.id}
              to={category.path}
              activeClassName="active"
              component={NavLink}
              icon={category.icon}
              exact
              button
              badge={category.badge}
            />
          ) : null}
        </React.Fragment>
      ))}
    </>
  );
};

export default SidebarItems;

SidebarItems.propTypes = {
  sidebarRoutes: PropTypes.array.isRequired,
};
