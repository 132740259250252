/* eslint-disable */
import React from "react";
import styled from "styled-components/macro";

const Wrapper = styled.div`
  border-radius: 6px;
  min-height: 34px;
  width: 100%;
  padding: 8px;
  background: #eeeeee64;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 4px;

  span {
    font-size: 11px;
  }

  p {
    font-size: 11px;
    line-height: 1.3;
  }
`;

const OptionItem = ({ letter, text, isSelectAll = false }) => {
  // if isSelectAll -> will need fake checkboxes

  return (
    <Wrapper>
      <p>{letter})</p>
      <p>{text}</p>

      {isSelectAll && (
        <div
          style={{
            height: 10,
            borderRadius: 3,
            width: 10,
            minWidth: 10,
            minHeight: 10,
            background: "lightgray",
            marginLeft: "auto",
          }}
        />
      )}
    </Wrapper>
  );
};

export default OptionItem;
