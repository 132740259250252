/* eslint-disable */
import React, { useContext, useState } from "react";
import { Modal, useMediaQuery } from "@material-ui/core";
import { AlertCircle, CheckCircle, XCircle } from "react-feather";
import styled from "styled-components/macro";
import API from "../../../../../axios/instances/API";
import FunLoader from "../../../../../components/Loaders/FunLoader";
import AcceptDarModal from "../../../../../components/Modals/AcceptDarModal";
import ConfirmModalSmall from "../../../../../components/Modals/ConfirmModalSmall";
import SnackbarWrapper from "../../../../../components/SnackbarWrapper";
import ActionButtonForTables from "../../../../../components/Table/ActionButtonForTables";
import DateTooltip from "../../../../../components/Table/DateTooltip";
import {
  getConfig,
  makeDefaultTableOptions,
} from "../../../../../storage/helpers";
import { GlobalState } from "../../../../../store/GlobalState";
import ACTIONS from "../../../../../store/actions";
import useAccessRequestLists from "../../../../../swr/hooks/DashboardAcceessRequest/useAccessRequestLists";
import usePendingTeamInvitations from "../../../../../swr/hooks/Team/usePendingTeamInvitations";
import { Alert, CustomDatatableV2 } from "../../../styles";
import ExplanationTooltip from "./ExplanationTooltip";

const LowerWrapper = styled.div`
  width: 100%;
  min-height: 100px;
  background: white;
  padding: 12px;
  display: flex;
  flex-direction: column;
  border-radius: 4px;
`;

const formatTeamRequests = (arr) => {
  const newArr = arr.map((req) => ({
    ...req,
    first_name: req.user.firstName,
    last_name: req.user.lastName,
    job: "A",
    email: req.user.email,
  }));

  return newArr;
};

const Lower = ({ snackbarText, setSnackbarText }) => {
  const {
    data: adminRequests,
    isLoading: adminReqIsLoading,
    mutate: mutateAdminRequests,
  } = useAccessRequestLists("C");
  const {
    data: managerRequests,
    isLoading: managerReqIsLoading,
    mutate: mutateManagerRequests,
  } = useAccessRequestLists("M");
  const {
    pending: teamRequests,
    isLoading: teamReqIsLoading,
    mutate: mutateTeamMemberRequests,
  } = usePendingTeamInvitations();

  const [acceptInfo, setAcceptInfo] = useState(null);
  const [declineInfo, setDeclineInfo] = useState(null);
  const isUnder600Px = useMediaQuery((theme) => theme.breakpoints.down("xs"));

  const { dispatch } = useContext(GlobalState);

  if (adminReqIsLoading || managerReqIsLoading || teamReqIsLoading) {
    return (
      <LowerWrapper>
        <div
          style={{
            width: "100%",
            height: "100%",
            display: "flex",
            flex: 1,
            minHeight: 240,
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column",
          }}
        >
          <FunLoader />
          <span style={{ color: "lightgray" }}>loading...</span>
        </div>
      </LowerWrapper>
    );
  }

  const options = makeDefaultTableOptions("alerts");
  options.rowsPerPage = 10;
  options.sortOrder = {
    name: "created",
    direction: "desc",
  };

  if (isUnder600Px) {
    options.rowsPerPageOptions = [];
    options.jumpToPage = false;
  }

  const columns = [
    {
      name: "id",
      label: "ID",
      options: {
        filter: false,
        sort: false,
        display: false,
        download: false,
      },
    },
    {
      name: "created",
      label: "Date",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value) => (
          <DateTooltip date={value} shouldLocalize />
        ),
      },
    },
    {
      name: "name",
      label: "Name",
      options: {
        filter: false,
        sort: false,
        display: true,
        download: false,
      },
    },
    {
      name: "email",
      label: "Email",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "access",
      label: "Access Requested",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (val) => (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-start",
              gap: 3,
            }}
          >
            <ExplanationTooltip val={val} />
            <span style={{ fontSize: 13, fontWeight: "400" }}>
              {val === "C"
                ? "Admin"
                : val === "M"
                ? "Supervisor"
                : "Team Member"}
            </span>
            <span
              style={{ fontSize: 12, fontWeight: "400", color: "darkgrey" }}
            >
              {val === "C"
                ? "(Dashboard)"
                : val === "M"
                ? "(Dashboard)"
                : "(App)"}
            </span>
          </div>
        ),
      },
    },
    {
      name: "approve",
      label: "Approve",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (_, tableMeta) => {
          return (
            <div style={{ width: "fit-content" }}>
              <ActionButtonForTables
                title="Approve"
                Icon={CheckCircle}
                handleClick={() =>
                  setAcceptInfo({
                    id: tableMeta.rowData[0],
                    name: tableMeta.rowData[2],
                    email: tableMeta.rowData[3],
                    access: tableMeta.rowData[4],
                    initialLocation: [tableMeta.rowData[7]] || null,
                  })
                }
              />
            </div>
          );
        },
      },
    },
    {
      name: "approve",
      label: "Decline",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (_, tableMeta) => {
          return (
            <ActionButtonForTables
              title="Decline"
              Icon={XCircle}
              handleClick={() =>
                setDeclineInfo({
                  id: tableMeta.rowData[0],
                  name: tableMeta.rowData[2],
                  email: tableMeta.rowData[3],
                  access: tableMeta.rowData[4],
                })
              }
            />
          );
        },
      },
    },
    {
      name: "initialLocationId",
      lable: " ",
      options: {
        display: false,
        download: false,
      },
    },
  ];

  const allRequests = [
    ...adminRequests.access_requests,
    ...managerRequests.access_requests,
    ...formatTeamRequests(teamRequests),
  ];

  const mutateDar = async () => {
    await mutateAdminRequests();
    await mutateManagerRequests();
  };

  const tableData = allRequests.map((req, i) => ({
    id: req.id,
    name: `${req.first_name} ${req.last_name}`,
    email: req.email,
    access: req.job,
    created: req.created,
    initialLocationId: req?.requested_locations?.[0]?.id || null,
  }));

  const handleDecline = async () => {
    try {
      // decline an access request
      if (["C", "M"].includes(declineInfo?.access)) {
        await API.delete("/dashboard/dashboard-access-request/details/", {
          data: {
            id: declineInfo?.id,
          },
        });
        await mutateDar();
      }

      // decline a pending team member
      if (declineInfo?.access === "A") {
        await API.delete("/dashboard/teams/pending/", {
          data: {
            id: declineInfo?.id,
          },
        });
        await mutateTeamMemberRequests();
      }

      const config = await getConfig();
      setSnackbarText(
        `Access request from ${declineInfo?.name} declined warning`
      );
      dispatch({ type: ACTIONS.UPDATE_CONFIG, payload: config });
      setDeclineInfo(null);
    } catch (error) {
      setSnackbarText("Failed to process request error");
    }
  };

  const handleApproveTeamMemberReq = async () => {
    try {
      await API.put("/dashboard/teams/pending/", {
        id: acceptInfo?.id,
      });
      await mutateTeamMemberRequests();
      const config = await getConfig();
      setSnackbarText(`Accepted team member ${acceptInfo?.name} success`);
      dispatch({ type: ACTIONS.UPDATE_CONFIG, payload: config });
      setAcceptInfo(null);
    } catch (error) {
      setSnackbarText("Failed to process request error");
    }
  };

  return (
    <>
      <LowerWrapper>
        <Alert
          icon={
            <AlertCircle
              height={18}
              width={18}
              color="#22597f"
              style={{ marginTop: 2 }}
            />
          }
          style={{ backgroundColor: "#eeeeee" }}
          severity="info"
        >
          <p style={{ fontWeight: "600", fontSize: 13 }}>
            {allRequests?.length}{" "}
            {allRequests?.length >= 2 ? "people have" : "person has"} requested
            to join your team.
          </p>
          <span style={{ fontSize: 13, marginLeft: 2 }}>
            Please review these requests when you have time.
          </span>
        </Alert>
        <CustomDatatableV2
          columns={columns}
          options={options}
          data={tableData}
          components={{
            TableToolbar: () => <span />,
          }}
        />
      </LowerWrapper>
      <Modal open={Boolean(acceptInfo)} onClose={() => setAcceptInfo(null)}>
        {["C", "M"].includes(acceptInfo?.access) ? (
          <AcceptDarModal
            name={acceptInfo?.name}
            id={acceptInfo?.id}
            accessLevel={acceptInfo?.access === "M" ? "Supervisor" : "Admin"}
            initialLocation={acceptInfo?.initialLocation}
            setSnackbarText={setSnackbarText}
            handleClose={() => setAcceptInfo(null)}
            mutate={mutateDar}
          />
        ) : (
          <ConfirmModalSmall
            name={acceptInfo?.name}
            title="Approve Team Member Request"
            handleConfirm={handleApproveTeamMemberReq}
            confirmAccept
          />
        )}
      </Modal>
      <Modal open={Boolean(declineInfo)} onClose={() => setDeclineInfo(null)}>
        <div>
          <ConfirmModalSmall
            title="Decline Access Request"
            name={declineInfo?.name}
            handleConfirm={handleDecline}
          />
        </div>
      </Modal>
    </>
  );
};

export default Lower;
