/* eslint-disable */
import useSWR from "swr";
import API from "../../../axios/instances/API";
import { getClientTimezone } from "../../../storage/helpers";

const { timezoneQueryStr } = getClientTimezone();

const BASE_URL = `/dashboard/home/${timezoneQueryStr}`;

const fetcher = async (url) => {
  const { data } = await API.get(url);
  return data;
};

const useHome = () => {
  const { data, error, mutate } = useSWR(BASE_URL, fetcher, {
    revalidateOnFocus: false,
    revalidateIfStale: false,
    revalidateOnReconnect: false,
  });

  return {
    data,
    isLoading: !error && !data,
    isError: error,
    mutate,
  };
};

export default useHome;
