/* eslint-disable */
import React from "react";
import { Tooltip } from "@material-ui/core";
import styled from "styled-components/macro";
import { makeStyles } from "@material-ui/core/styles";
import { List } from "react-feather";
import useManagerDetail from "../../../swr/hooks/Managers/useManagerDetail";

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: fit-content;
  padding: 1px 4px;
  border-radius: 4px;

  &:hover {
    cursor: pointer;
    background-color: #eeeeee;
  }
`;

const InnerContainer = styled.div`
  width: 100%;
  background-color: white;
  border-radius: 4px;
  min-height: 40px;
  display: flex;
  padding: 4px;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
  border: 1px solid slategrey;
`;

const useStyles = makeStyles(() => ({
  tooltip: {
    maxWidth: "none",
    minWidth: 300,
    maxWidth: 400,
    minHeight: "none",
    maxHeight: "none",
    borderRadius: 8,
    padding: 0,
    // TODO -> used in many components
  },
}));

const Inner = ({ id }) => {
  const { isLoading, details: data } = useManagerDetail(id);

  if (isLoading) {
    return (
      <InnerContainer>
        <span>loading...</span>
      </InnerContainer>
    );
  }

  return (
    <InnerContainer>
      <div
        style={{
          padding: 2,
          display: "flex",
          alignItems: "flex-start",
          flexDirection: "column",
          justifyContent: "flex-start",
          gap: 6,

          width: "100%",
        }}
      >
        {data?.locations?.map((l) => (
          <div
            style={{
              display: "flex",
              alignItems: "flex-start",
              justifyContent: "flex-start",
              flexDirection: "column",
              width: "100%",
            }}
          >
            <p style={{ color: "black", fontSize: 10, fontWeight: "500" }}>
              {l.name}
            </p>
            <p
              style={{
                color: "black",
                fontSize: 10,
                fontWeight: "400",
                color: "darkgray",
              }}
            >
              {l?.address?.line1}, {l?.address?.state?.name},{" "}
              {l?.address?.country?.name}
            </p>
          </div>
        ))}
      </div>
    </InnerContainer>
  );
};

const LocationsTooltip = ({ value, id }) => {
  const classes = useStyles();

  return (
    <div
      style={{
        display: "flex",

        alignItems: "center",
        justifyContent: "flex-start",
        gap: 4,
      }}
    >
      <span style={{ fontWeight: "500", fontSize: 11 }}>{value}</span>
      <Tooltip
        classes={{ tooltip: classes.tooltip }}
        title={<Inner id={id} />}
        arrow
        placement="top"
      >
        <Container>
          {" "}
          <List height={14} width={14} color="darkgrey" />{" "}
        </Container>
      </Tooltip>
    </div>
  );
};

export default LocationsTooltip;
