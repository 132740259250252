/* eslint-disable */
/* eslint-disable camelcase */
import { Avatar, Paper } from "@material-ui/core";
import PropTypes from "prop-types";
import React, { useState } from "react";
import ReactCountryFlag from "react-country-flag";
import { BarChart2, Briefcase, Percent, PieChart } from "react-feather";
import styled from "styled-components/macro";
import LoaderWrapper from "../../../../../components/Loaders/LoaderWrapper";
import PrivateHeader from "../../../../../components/PrivateHeader";
import SmallBlockWide from "../../../../../components/SmallBlockWide";
import ErrorBlockWithPermissions from "../../../../../components/UI/ErrorBlockWithPermissions";
import {
  ENGAGEMENT_TYPES,
  returnDefinitionByEngagementType,
} from "../../../../../storage/constants";
import useSingleUserBreakdown from "../../../../../swr/hooks/VendorReports/useSingleUserBreakdown";
import { StyledLink } from "../../../styles";
import EngagementTypeSelect from "../../reuseableComponents/EngagementTypeSelect";
import MembersInMultipleCompaniesWarning from "../../reuseableComponents/MembersInMultipleCompaniesWarning";
import {
  retCompsByEngagementType,
  retSubsByEngagementType,
} from "../../utils/utils";
import SubmissionsTable from "./SubmissionsTable";
import UserProgress from "./UserProgress";

const Container = styled.div`
  display: flex;
  width: 100%;
  padding: 10px 0px;
  align-items: center;
  justify-content: flex-start;
  gap: 12px;
`;

const StyledAvatar = styled(Avatar)`
  height: 60px;
  width: 60px;
  border-radius: 4px;
`;

const SingleUserBreakdown = ({ match }) => {
  const { id } = match.params;
  const [engagementType, setEngagementType] = useState(ENGAGEMENT_TYPES.ALL);
  const { data, isError, isLoading } = useSingleUserBreakdown(id);

  if (isLoading) {
    return (
      <>
        <PrivateHeader header="User Report" secondary={true} />
        <LoaderWrapper text="User Report" />
      </>
    );
  }

  if (isError) {
    return (
      <>
        <PrivateHeader header="User Report" secondary={true} />
        <ErrorBlockWithPermissions errorObj={isError} />
      </>
    );
  }

  const { all_submissions, user_info, totals } = data;

  const subs = retSubsByEngagementType(totals, engagementType);
  const comps = retCompsByEngagementType(totals, engagementType);

  const percentage = Math.floor(
    ((totals.available_marketplace_quizzes -
      totals.outstanding_marketplace_quizzes) /
      totals.available_marketplace_quizzes) *
      100
  );

  return (
    <>
      <PrivateHeader
        header="User Report"
        secondary={`${data?.user_info?.first_name} ${data?.user_info?.last_initial}`}
      />
      {data?.users_with_team_members_in_multiple_locations >= 1 && (
        <>
          <MembersInMultipleCompaniesWarning
            num={data.users_with_team_members_in_multiple_locations}
          />
          <div style={{ height: 12 }} />
        </>
      )}
      <Paper
        style={{
          borderRadius: 4,
          width: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          padding: 10,
          backgroundColor: "white",
        }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-start",
            width: "100%",
          }}
        >
          <StyledAvatar src={null} />
          <div
            style={{
              paddingLeft: 12,
              display: "flex",
              flexDirection: "column",
              width: "100%",
              alignItems: "flex-start",
              justifyContent: "center",
            }}
          >
            <div
              style={{
                paddingLeft: 2,
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-start",
              }}
            >
              <span style={{ fontSize: 20, fontWeight: 600 }}>
                {user_info.first_name} {user_info.last_initial}
              </span>
              <span
                style={{ fontSize: 12, color: "darkgray", padding: "0px 10px" }}
              >
                |
              </span>
              <ReactCountryFlag
                countryCode={data.user_info.country_code}
                style={{ fontSize: 14, marginTop: 2 }}
              />
              {data.user_info.state && (
                <span
                  style={{ fontSize: 10, color: "darkgrey", marginLeft: 6 }}
                >
                  {data.user_info.state}
                </span>
              )}
              <span
                style={{ fontSize: 12, color: "darkgray", padding: "0px 10px" }}
              >
                |
              </span>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <div
                  style={{
                    height: 8,
                    width: 8,
                    borderRadius: 4,
                    backgroundColor: data.user_info.is_follower
                      ? "mediumseagreen"
                      : "tomato",
                    marginRight: 6,
                  }}
                />
                <span style={{ fontSize: 10, color: "darkgray" }}>
                  {data.user_info.is_follower ? "Follower" : "Not A Follower"}
                </span>
              </div>
            </div>
            <div
              style={{
                width: "100%",
                display: "flex",
                alignItems: "flex-start",
                justifyContent: "flex-start",
                maxHeight: 100,
                flexWrap: "wrap",
                overflowX: "scroll",
              }}
            >
              {data.user_info.active_teams.map((t) => (
                <div
                  key={t.company_name}
                  style={{
                    marginRight: 14,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <StyledLink
                    to={`/vendor-reports/company/${t.company_id}`}
                    value={t.company_name}
                    CustomIcon={Briefcase}
                    small
                  />
                  <span
                    style={{
                      marginLeft: 4,
                      fontSize: 10,
                      color: "darkgray",
                      fontWeight: 300,
                    }}
                  >
                    | {t?.location_name || ""}
                  </span>
                </div>
              ))}
            </div>
          </div>
        </div>
        <EngagementTypeSelect
          engagementType={engagementType}
          setEngagementType={setEngagementType}
          isVendor
        />
      </Paper>
      <Container>
        <SmallBlockWide
          title="Completions"
          Icon={PieChart}
          stat={comps}
          engagementType={engagementType}
          definition={returnDefinitionByEngagementType(
            "completions",
            engagementType
          )}
        />
        <SmallBlockWide
          title="Submissions"
          Icon={BarChart2}
          stat={subs}
          engagementType={engagementType}
          definition={returnDefinitionByEngagementType(
            "submissions",
            engagementType
          )}
        />
        <SmallBlockWide
          title="Progress"
          Icon={Percent}
          stat={Number.isNaN(percentage) ? 100 : `${percentage}%`}
          definition="The percentage of live modules available to this user that have been completed."
        />
      </Container>
      <UserProgress totals={data.totals} modules={data.quizzes_table} />
      <div style={{ height: 12 }} />
      <SubmissionsTable
        submissions={all_submissions}
        engagementType={engagementType}
        name={`${user_info.first_name} ${user_info.last_initial}`}
      />
    </>
  );
};

export default SingleUserBreakdown;

SingleUserBreakdown.propTypes = {
  match: PropTypes.object.isRequired,
};
