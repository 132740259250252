/* eslint-disable */
import React, { useEffect, useState } from "react";
import API from "../../../../axios/instances/API";
import styled from "styled-components/macro";
import { Button, TextField } from "@material-ui/core";
import ACTIONS from "../state/actions";
import { Check } from "react-feather";

const Wrapper = styled.div`
  width: 320px;
  height: 400px;
  background: white;
  display: flex;
  padding: 10px;
  flex-direction: column;
  border: 1px solid lightgray;
  border-radius: 4px;
`;

const Item = styled.div`
  width: 100%;
  padding: 6px;
  border-radius: 3px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #eeeeee;
  cursor: pointer;

  span {
    font-size: 12px;
    // font-weight: 500;
  }

  &:hover {
    background: #eeeeee88;
  }
`;

const CompanyFilter = ({ state, dispatch, handleClose }) => {
  const { countryCodeString, vendorSearchInfo } = state;
  const [companies, setCompanies] = useState([]);
  const [selected, setSelected] = useState(
    Boolean(vendorSearchInfo) ? vendorSearchInfo : null
  );
  const [loading, setLoading] = useState(true);
  const [searchString, setSearchString] = useState("");

  useEffect(() => {
    const getCompanies = async () => {
      try {
        const { data } = await API.get(
          `dashboard/marketplace/quizzes/vendors/?country_code=${countryCodeString}`
        );
        setCompanies(data.companies);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        setCompanies([]);
      }
    };
    getCompanies();
  }, []);

  if (loading) {
    return (
      <Wrapper>
        <div
          style={{
            height: "100%",
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <span>loading...</span>
        </div>
      </Wrapper>
    );
  }

  const filteredCompanies = companies.filter((company) =>
    company.name.toLowerCase().includes(searchString.toLowerCase())
  );

  const filterActive = searchString.length > 2;

  const handleCompanyFilter = () => {
    if (state?.vendorSearchInfo?.id === selected.id) return handleClose();

    dispatch({ type: ACTIONS.SEARCH_BY_VENDOR, payload: selected });
    return handleClose();
  };

  return (
    <Wrapper>
      <span style={{ fontSize: 13, color: "black", fontWeight: "500" }}>
        Filter Modules By Company
      </span>
      <div style={{ height: 8 }} />
      <div style={{ padding: 2 }}>
        <TextField
          variant="standard"
          fullWidth
          autoFocus
          size="small"
          color="secondary"
          placeholder="Search..."
          value={searchString}
          onChange={(e) => setSearchString(e.target.value)}
        />
      </div>
      <div style={{ height: 8 }} />

      <div
        style={{
          width: "100%",
          height: 280,
          padding: "8px 2px",
          overflowY: "scroll",
          gap: 2,
          display: "flex",
          alignItems: "flex-start",
          justifyContent: "flex-start",
          flexDirection: "column",
        }}
      >
        {filterActive && filteredCompanies.length === 0 && (
          <div
            style={{
              height: "100%",
              width: "100%",

              background: "#eeeeee44",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <span style={{ color: "darkgrey", fontSize: 10 }}>
              No results for this search
            </span>
          </div>
        )}
        {(filterActive ? filteredCompanies : companies).map((company) => (
          <Item
            style={{
              borderBottom: "1px solid #eeeeee85",
            }}
            key={company.id}
            onClick={() => setSelected(company)}
          >
            <span style={{ fontWeight: "400" }}>{company.name}</span>
            <div
              style={{
                borderRadius: 3,
                width: 12,
                height: 12,
                background:
                  selected?.id === company?.id ? "#337ab7" : "lightgray",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              {selected?.id === company?.id && (
                <Check height={8} width={8} color="white" />
              )}
            </div>
          </Item>
        ))}
      </div>
      <div
        style={{
          marginTop: "auto",
          width: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        {state?.vendorSearchInfo ? (
          <Button
            variant="outlined"
            // color="secondary"
            size="small"
            onClick={() => {
              dispatch({ type: ACTIONS.CLEAR_COMPANY_FILTER });
              return handleClose();
            }}
          >
            Clear
          </Button>
        ) : (
          <div />
        )}
        <Button
          variant="contained"
          color="secondary"
          disabled={!selected}
          size="small"
          onClick={handleCompanyFilter}
        >
          Filter
        </Button>
      </div>
    </Wrapper>
  );
};

export default CompanyFilter;
