/* eslint-disable */
import React from 'react';
import { Tooltip, Button } from '@material-ui/core';
import styled from 'styled-components/macro';

const StyledButton = styled(Button)`
  height: 38px;
  width: fit-content;
  min-height: 38px;
  max-height: 38px;
  background-color: #eeeeee;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 0px 12px;
  transition: background-color 200ms ease-in;

  svg {
    color: black;
    margin-right: 6px;
  }

  span {
    white-space: nowrap;
    color: black;
  }

  &:hover {
    background-color: lightgray;

    span {
      color: black;
    }

    svg {
      color: black;
    }
  }

  &:disabled {
    svg {
      color: lightgray;
    }

    span {
      color: lightgray;
    }
  }
`;

const StyledButtonPrimary = styled(Button)`
  height: 38px;
  width: fit-content;
  min-height: 38px;
  max-height: 38px;
  min-width: 38px;
  background-color: #337ab7;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 0px 12px;
  transition: background-color 200ms ease-in;

  svg {
    color: white;
    margin-right: 6px;
  }

  span {
    color: white;
    white-space: nowrap;
  }

  &:hover {
    background-color: #134f83;

    svg {
      color: #eeeeee;
    }

    span {
      color: white;
    }
  }

  &:disabled {
    background-color: #eeeeee;
  }
`;

const AttActionButtonWithText = ({
  handleClick,
  disabled = false,
  title = 'title',
  Icon,
  primary = false,
}) => {
  return (
    <Tooltip
      arrow
      placement="bottom"
      title={title}
    >
      <div>
        {/* ^ this div allows tooltip to still show if button is disabled --- dont remove */}
        {primary ? (
          <StyledButtonPrimary
            onClick={handleClick}
            disabled={disabled}
          >
            <Icon
              height={14}
              width={14}
            />
            <span style={{ fontSize: 12 }}>{title}</span>
          </StyledButtonPrimary>
        ) : (
          <StyledButton
            onClick={handleClick}
            disabled={disabled}
          >
            <Icon
              height={14}
              width={14}
            />
            <span style={{ fontSize: 12 }}>{title}</span>
          </StyledButton>
        )}
      </div>
    </Tooltip>
  );
};

export default AttActionButtonWithText;
