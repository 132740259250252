/* eslint-disable */
import React, { useState } from "react";
import useLocations from "../../../../swr/hooks/Locations/useLocations";
import useRoles from "../../../../swr/hooks/Roles/useRoles";
import API from "../../../../axios/instances/API";
import {
  FormControl,
  Select,
  MenuItem,
  Button,
  Paper,
} from "@material-ui/core";
import styled from "styled-components/macro";
import { Send } from "react-feather";
import FunLoader from "../../../../components/Loaders/FunLoader";

const Wrapper = styled(Paper)`
  padding: 20px 20px 14px 20px;
  width: 380px;

  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);

  border: 2px solid black;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
`;

const getCleanErrorMsg = (error, name) => {
  const errorObj = error?.response?.data?.error;

  if (errorObj?.context?.errors.length > 0) {
    return `${errorObj?.context?.errors.join(". ")} error`;
  }

  if (errorObj?.details) {
    return `${errorObj.details} error`;
  }

  return `Failed to re-invite ${name} error`;
};

const SelectInviteOptionsModal = ({
  selectLocationModalData,
  setSelectLocationModalData,
  mutate,
  setSnackbarText,
}) => {
  const initialLocationId = selectLocationModalData?.locations?.[0]?.id || null;

  const [isUpdating, setIsUpdating] = useState(false);
  const [selected, setSelected] = useState(initialLocationId);
  const [selectedRoles, setSelectedRoles] = useState([]);

  const { locations, isLoading } = useLocations();
  const { roles, isLoading: rolesLoading } = useRoles();

  if (isLoading || rolesLoading)
    return (
      <Wrapper>
        <div
          style={{
            height: 220,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            width: "100%",
          }}
        >
          <FunLoader />
        </div>
      </Wrapper>
    );

  const handleClick = async () => {
    try {
      setIsUpdating(true);
      await API.post("dashboard/teams/invitations/", {
        email: selectLocationModalData?.username,
        first_name: selectLocationModalData?.first_name,
        last_name: selectLocationModalData?.last_name,
        locations: [selected],
        roles: selectedRoles,
      });
      await mutate();
      setSnackbarText(
        `Team member ${selectLocationModalData?.full_name} re-invited to team success`
      );
      setIsUpdating(false);
      setSelectLocationModalData(null);
    } catch (error) {
      const errorMsg = getCleanErrorMsg(
        error,
        selectLocationModalData?.full_name
      );
      setIsUpdating(false);
      setSnackbarText(errorMsg);
    }
  };

  return (
    <Wrapper>
      <span style={{ fontSize: 15, fontWeight: "600" }}>Reinvite User</span>
      <div style={{ height: 6 }} />

      <span style={{ color: "darkgrey", fontSize: 12 }}>User</span>
      <span style={{ marginTop: -1 }}>
        {selectLocationModalData?.full_name}
      </span>
      <div style={{ height: 10 }} />
      <FormControl size="small" fullWidth>
        <span style={{ color: "darkgrey", fontSize: 12 }}>
          Select User Location
        </span>
        <Select
          variant="outlined"
          fullWidth
          value={selected}
          renderValue={() => (
            <span>
              {
                locations.locations.filter((loc) => loc.id === selected)[0]
                  ?.name
              }
            </span>
          )}
          onChange={(e) => setSelected(e.target.value)}
        >
          {locations.locations.map((loc) => (
            <MenuItem key={loc.id} value={loc.id}>
              {loc?.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <div style={{ height: 12 }} />
      <FormControl size="small" fullWidth>
        <span style={{ color: "darkgrey", fontSize: 12 }}>
          Select User Roles
        </span>

        <Select
          variant="outlined"
          fullWidth
          multiple
          value={selectedRoles}
          onChange={(e) => setSelectedRoles(e.target.value)}
        >
          {roles?.map((role) => (
            <MenuItem key={role.id} value={role.id}>
              {role?.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <div style={{ height: 14 }} />
      <Button
        style={{ marginLeft: "auto", marginTop: "auto" }}
        variant="contained"
        color="secondary"
        startIcon={<Send color="white" height={12} width={12} />}
        disabled={!selected || isUpdating}
        onClick={handleClick}
      >
        Reinvite
      </Button>
    </Wrapper>
  );
};

export default SelectInviteOptionsModal;
