/* eslint-disable */
import React, { useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import { Paper, MenuItem } from '@material-ui/core';
import { Line } from 'react-chartjs-2';
import { format } from 'date-fns';
import { Typography, TextField } from '../../styles';
import { getSubmissionsByDayStats } from '../utils';
import SubmissionTypeChip from '../retail/SubmissionTypeChip';
import { Activity } from 'react-feather';
import Last14Chip from '../../../../components/Table/Last14Chip';
import { ENGAGEMENT_TYPES } from '../../../../storage/constants';
const StyledTextField = styled(TextField)`
  width: 160px;

  @media (max-width: 600px) {
    display: none;
  }
`;

const Wrapper = styled(Paper)`
  padding: 20px;
  border-radius: 6px;

  @media (max-width: 600px) {
    padding: 10px;
  }
`;

const UpperWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 8px;
`;

const ChartWrapper = styled.div`
  width: 100%;
  height: 250px;
  background: white;
`;

const SUBTYPES = {
  SUBMISSIONS: 'SUBMISSIONS',
  COMPLETIONS: 'COMPLETIONS',
};

const SUBCATEGORIES = {
  ASSIGNED: 'ASSIGNED',
  FOLLOWER: 'FOLLOWER',
  ALL: 'ALL',
};

const returnDataset = (chartMetric, dataSets) => {
  if (chartMetric === SUBTYPES.SUBMISSIONS) {
    return [dataSets[0]];
  }
  if (chartMetric === SUBTYPES.COMPLETIONS) {
    return [dataSets[1]];
  }

  return [dataSets[0]];
};

const HomeEngagementChart = ({ submissions, engagementType, isVendor = false }) => {
  const [chartMetric, setChartMetric] = useState(SUBTYPES.SUBMISSIONS);
  const [chartSubmissionType, setChartSubmissionType] = useState(SUBCATEGORIES.ALL);
  // eslint-disable-next-line max-len
  const daySubmissions = useMemo(
    () => getSubmissionsByDayStats(submissions, chartSubmissionType),
    [submissions, chartSubmissionType]
  );
  const dataOne = Object.values(daySubmissions).map(obj => obj.subs);
  const dataTwo = Object.values(daySubmissions).map(obj => obj.pass);
  const chartLabels = Object.keys(daySubmissions).map(val => format(new Date(val), 'MMM-dd'));

  const returnTooltipText = () => {
    if (chartMetric === SUBTYPES.SUBMISSIONS) {
      return 'Submissions';
    }
    if (chartMetric === SUBTYPES.COMPLETIONS) {
      return 'Completions';
    }
    return null;
  };

  let highestVal = 0;
  dataOne.forEach(val => {
    if (val > highestVal) {
      highestVal = val;
    }
  });

  const options = {
    maintainAspectRatio: false,
    responsive: true,
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        callbacks: {
          label: () => '',
          beforeBody: context => `${context[0].raw} ${returnTooltipText()} `,
        },
      },
    },
    scales: {
      y: {
        beginAtZero: true,
        suggestedMax: highestVal + 2,
        grid: {
          color: 'transparent',
          // color: 'rgba(0,0,0,0.03)',
        },
        ticks: {
          precision: 0,
        },
      },
      x: {
        grid: {
          // color: 'rgba(0,0,0,0.03)',
          color: 'transparent',
        },
      },
    },
  };

  const chartData = canvas => {
    const ctx = canvas.getContext('2d');
    const gradient = ctx.createLinearGradient(0, 0, 0, 160);
    // gradient.addColorStop(0, "lightgray");
    // gradient.addColorStop(1, "#eeeeee");
    gradient.addColorStop(0, 'rgba(158,158,158,0.3)');
    gradient.addColorStop(1, 'rgba(158,158,158,0.08)');

    const dataSets = [
      {
        data: dataOne,
        fill: {
          target: 'origin',
          above: gradient,
        },
        borderColor: 'rgba(158,158,158,.05)',
        pointBorderColor: 'slategrey',
        pointBorderWidth: 0,
        pointBackgroundColor: 'black',
        // borderColor: '#eeeeee',
        borderWidth: 0,
        tension: 0.4,
        // pointBackgroundColor: 'black',
      },
      {
        data: dataTwo,
        fill: {
          target: 'origin',
          above: gradient,
        },
        borderColor: 'rgba(158,158,158,.05)',
        pointBorderColor: 'black',
        pointBorderWidth: 0,
        tension: 0.4,
        pointBackgroundColor: 'black',
      },
    ];

    return {
      labels: chartLabels,
      type: 'line',
      datasets: returnDataset(chartMetric, dataSets),
    };
  };

  return (
    <Wrapper>
      <UpperWrapper>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-start',
            gap: 6,
          }}
        >
          {engagementType === ENGAGEMENT_TYPES.ALL ? (
            <Activity
              height={14}
              width={14}
              color="#337ab7"
              style={{ marginRight: 2 }}
            />
          ) : (
            <SubmissionTypeChip
              small
              submissionType={engagementType}
              isVendor={isVendor}
            />
          )}
          {/* <div style={{ width: 2 }} /> */}
          <Typography style={{ fontSize: 16, fontWeight: '600' }}>Pulse Check </Typography>
          <Last14Chip />
          {/* <span
            style={{
              fontSize: 13,
              color: 'darkgrey',
              fontWeight: '500',
            }}
          >
            (Last 14 Days)
          </span> */}
        </div>
        <StyledTextField
          select
          size="small"
          variant="outlined"
          color="secondary"
          value={chartMetric}
          style={{ width: 160 }}
          onChange={e => setChartMetric(e.target.value)}
        >
          <MenuItem value={SUBTYPES.SUBMISSIONS}>Submissons</MenuItem>
          <MenuItem value={SUBTYPES.COMPLETIONS}>Completions</MenuItem>
        </StyledTextField>
      </UpperWrapper>
      <div style={{ height: 6 }} />
      <ChartWrapper>
        <Line
          data={chartData}
          options={options}
        />
      </ChartWrapper>
    </Wrapper>
  );
};

export default HomeEngagementChart;

HomeEngagementChart.propTypes = {
  submissions: PropTypes.array.isRequired,
  isVendor: PropTypes.bool,
};

HomeEngagementChart.defaultProps = {
  isVendor: false,
};
