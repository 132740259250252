/* eslint-disable */
/* eslint-disable no-shadow */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Paper, Avatar, IconButton, Tooltip } from '@material-ui/core';
import styled from 'styled-components/macro';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { Trash2 } from 'react-feather';
import { Typography, BlueShuffle, ShowState, CustomChip, MarketplaceChip } from '../../styles';
import API from '../../../../axios/instances/API';
// import VendorStream from './VendorStream';
import { get400ErrorMsg } from '../../../../storage/helpers';

const CustomIconButton = styled(IconButton)`
  margin-left: auto;
  background-color: #eee;

  svg {
    height: 14px;
    width: 14px;
    cursor: pointer;
  }
`;

const CustomWrapper = styled(Paper)`
  width: min(100%, 700px);
  min-height: 340px;
  margin: auto;
  margin-top: 16px;
  padding: 20px;
`;

const DragWrapper = styled.div`
  width: 100%;
  min-height: 340px;
  margin: auto;
`;

const CustomDragItem = styled.div`
  width: 100%;
  height: 44px;
  border-radius: 4px;
  display: flex;
  padding: 4px 12px;
  align-items: center;
  margin-top: 8px;
  cursor: pointer;
  // border: 1px solid slategrey;
  background-color: white;
  box-shadow: 1px 1px 4px 1px rgba(0, 0, 0, 0.2);
`;

const CustomAvatar = styled(Avatar)`
  background-color: #eeeeee44;
  border: 1px solid #eeeeee;
  height: 28px;
  width: 28px;
  border-radius: 6px;
  margin-right: 16px;
  font-size: 16px;
  font-weight: 700;
  color: black;
`;

const Centered = styled.div`
  width: 100%;
  height: 100%;
  min-height: 400px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: 2px solid #eeeeee;
  border-radius: 8px;
`;

const FlexDiv = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;

  span {
    text-transform: uppercase;
    font-size: 11px;
    color: ${props => props.theme.palette.grey[500]};
  }
`;

const FlexApart = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-right: 6px;
  padding-right: 2px;
`;

const RearrangeQuizzesList = ({ details, mutate, setSnackbarText, readOnly }) => {
  const [listQuizzes, setListQuizzes] = useState([]);
  const { quizzes } = details;

  useEffect(() => {
    setListQuizzes(quizzes);
  }, [quizzes]);

  const handleReorder = async items => {
    if (readOnly) return setSnackbarText('You are not authorized to reorder stream error');
    try {
      await API.put('dashboard/streams/quizzes/', {
        id: details.stream.id,
        quiz_ids: items.map(quiz => quiz.id),
      });
      mutate();
      return setSnackbarText('Successfully reordered stream success');
    } catch (error) {
      // mutate in error, so it resets the order in the App to align with database //
      mutate();
      const errorMsg = get400ErrorMsg(error, 'Failed to reorder stream error');
      return setSnackbarText(errorMsg);
    }
  };

  const handleDragEnd = result => {
    if (!result.destination) return;
    const items = Array.from(listQuizzes);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);
    setListQuizzes(items);
    handleReorder(items);
  };

  const handleDelete = async deleteId => {
    const filteredQuizIds = listQuizzes.filter(quiz => quiz.id !== deleteId).map(quiz => quiz.id);
    try {
      await API.put('dashboard/streams/quizzes/', {
        id: details.stream.id,
        quiz_ids: filteredQuizIds,
      });
      await mutate();
      return setSnackbarText('Removed module from stream warning');
    } catch (error) {
      const errorMsg = get400ErrorMsg(error, 'Failed to remove module from stream error');
      return setSnackbarText(errorMsg);
    }
  };

  if (details.quizzes.length === 0) {
    return (
      <CustomWrapper>
        <Typography variant="h3">
          Reorder Modules
          <BlueShuffle />
        </Typography>
        <Typography
          variant="subtitle1"
          mb={6}
        >
          Drag and drop modules to change the order they appear in the stream
        </Typography>
        <DragWrapper>
          <Centered>
            <Typography variant="h3">No Modules in this Stream yet</Typography>
            <Typography
              variant="subtitle1"
              color="secondary"
            >
              Add Modules from the Available Modules Dropdown
            </Typography>
          </Centered>
        </DragWrapper>
      </CustomWrapper>
    );
  }

  return (
    <CustomWrapper>
      <Typography variant="h3">
        Reorder Modules
        <BlueShuffle />
      </Typography>
      <Typography
        variant="subtitle1"
        mb={4}
      >
        Drag and drop modules to change the order they appear in the Spiffy App
      </Typography>
      <DragDropContext onDragEnd={handleDragEnd}>
        <Droppable droppableId="streamDroppable">
          {provided => (
            <DragWrapper
              className="streamDroppable"
              {...provided.droppableProps}
              ref={provided.innerRef}
            >
              {listQuizzes.map((quiz, index) => (
                <Draggable
                  key={quiz.id}
                  draggableId={String(quiz.id)}
                  index={index}
                >
                  {provided => (
                    <CustomDragItem
                      key={quiz.id}
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                    >
                      <CustomAvatar>
                        <span style={{ color: 'black' }}>{index + 1}</span>
                      </CustomAvatar>
                      <FlexApart>
                        <FlexDiv>
                          <Typography variant="subtitle2">{quiz.name}</Typography>
                          {quiz.is_marketplace ? (
                            <span>By: {quiz.vendor}</span>
                          ) : (
                            <span>By: {details.stream.company}</span>
                          )}
                        </FlexDiv>
                        <div
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                          }}
                        >
                          <ShowState
                            state={quiz.state}
                            style={{ marginLeft: 8 }}
                          >
                            <span>{quiz.state}</span>
                          </ShowState>
                        </div>
                      </FlexApart>
                      <Tooltip
                        title="Remove"
                        placement="right"
                      >
                        <CustomIconButton
                          size="small"
                          aria-label="delete"
                          disabled={readOnly}
                          onClick={() => handleDelete(quiz.id)}
                        >
                          <Trash2 />
                        </CustomIconButton>
                      </Tooltip>
                    </CustomDragItem>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </DragWrapper>
          )}
        </Droppable>
      </DragDropContext>
      {/* {!isRetail && (
        <VendorStream
          id={details.stream.id}
          details={details}
          mutate={mutate}
          setSnackbarText={setSnackbarText}
          readOnly={readOnly}
        />
      )} */}
    </CustomWrapper>
  );
};

export default RearrangeQuizzesList;

RearrangeQuizzesList.propTypes = {
  details: PropTypes.object.isRequired,
  setSnackbarText: PropTypes.func.isRequired,
  mutate: PropTypes.func.isRequired,
  readOnly: PropTypes.bool.isRequired,
  isRetail: PropTypes.bool.isRequired,
};
