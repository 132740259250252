/* eslint-disable */
import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Autocomplete } from "@material-ui/lab";
import styled from "styled-components/macro";
import { CircularProgress, Paper } from "@material-ui/core";
import API from "../../../../axios/instances/API";
import { Typography, TextField, Button } from "../../styles";

const Wrapper = styled(Paper)`
  min-width: 420px;
  flex: 0.5;
  flex-grow: 1;
  padding: 20px;
  min-height: 200px;
`;

const AddTeamMembers = ({
  currentTeamMembers,
  readOnly,
  setSnackbarText,
  roleId,
  mutate,
}) => {
  const [comboValue, setComboValue] = useState([]);
  const [resetKey, setResetKey] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [autocompleteOptions, setAutocompleteOptions] = useState([]);
  const [allTeamMembers, setAllTeamMembers] = useState([]);
  const getTeamMembers = async () => {
    try {
      const { data } = await API.get("/dashboard/roles/team/");
      setAllTeamMembers(data.teamMembers);
    } catch (error) {
      setAllTeamMembers("ERROR");
    }
  };

  useEffect(() => {
    getTeamMembers();
  }, []);

  useEffect(() => {
    if (allTeamMembers === "ERROR") return;
    const currentTeamIds = currentTeamMembers.map((member) => member.id);
    const availToAdd = allTeamMembers.filter(
      (member) => !currentTeamIds.includes(member.id)
    );
    setAutocompleteOptions(availToAdd);
  }, [currentTeamMembers, allTeamMembers]);

  const handleSubmit = async () => {
    setIsSubmitting(true);
    const addIds = comboValue.map((val) => val.id);
    try {
      await API.post("/dashboard/roles/team/", {
        role_id: roleId * 1,
        team_member_ids: addIds,
      });
      await mutate();
      setResetKey((p) => !p);
      setComboValue([]);
      setIsSubmitting(false);
      setSnackbarText("Team members successfully added to role success");
    } catch (error) {
      setIsSubmitting(false);
      setSnackbarText("Failed to update team members with role error");
    }
  };

  return (
    <Wrapper>
      <Typography variant="h3">Add Team Members To Role</Typography>
      <Typography variant="subtitle1" mb={10}>
        Team members available to add to this role
      </Typography>
      <Autocomplete
        key={resetKey}
        multiple
        size="small"
        options={autocompleteOptions}
        noOptionsText="No Team Members Available"
        getOptionLabel={(option) => `${option.firstName} ${option.lastName}`}
        onChange={(_, newValue) => setComboValue(newValue)}
        getOptionSelected={(option, value) => option.id === value.id}
        renderInput={(params) => (
          <TextField
            {...params}
            label="Add Team Members"
            variant="outlined"
            size="small"
          />
        )}
      />
      <Button
        mt={4}
        variant="contained"
        color="secondary"
        fullWidth
        disabled={comboValue.length === 0 || readOnly || isSubmitting}
        onClick={handleSubmit}
        startIcon={isSubmitting ? <CircularProgress size={20} /> : null}
      >
        {isSubmitting ? "Adding To Role" : "Add To Role"}
      </Button>
    </Wrapper>
  );
};

export default AddTeamMembers;

AddTeamMembers.propTypes = {
  currentTeamMembers: PropTypes.array.isRequired,
  roleId: PropTypes.string.isRequired,
  setSnackbarText: PropTypes.func.isRequired,
  readOnly: PropTypes.bool.isRequired,
  mutate: PropTypes.func.isRequired,
};
