import useSWR from 'swr';
import API from '../../../axios/instances/API';

const fetcher = async (url) => {
  const { data } = await API.get(url);
  return data;
};

const useVendorModuleReport = (id) => {
  const { data, error, mutate } = useSWR(`dashboard/analytics/report/?report=vendor_quiz_breakdown&quiz_id=${id}`, fetcher);

  return {
    data,
    isLoading: !error && !data,
    isError: error,
    mutate,
  };
};

export default useVendorModuleReport;
