/* eslint-disable */
import React from "react";
import { AlertTriangle } from "react-feather";
import styled from "styled-components/macro";

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 40px 0px;
`;

const DisplayError = ({ errorMsg }) => {
  return (
    <Wrapper>
      <div
        style={{
          height: 200,
          width: 300,
          background: "white",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          flexDirection: "column",
          borderRadius: 6,
          border: "1px solid #eeeeee",
        }}
      >
        <AlertTriangle color="firebrick" />
        <p style={{ fontSize: 16, fontWeight: "600" }}>ERROR</p>
        <span style={{ color: "darkgray" }}>{errorMsg} </span>
      </div>
    </Wrapper>
  );
};

export default DisplayError;
