/* eslint-disable */
import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components/macro";
import AnswerDistribution from "./AnswerDistribution";

const QuestionBreakdownContainer = styled.div`
  flex: 1;
  flex-grow: 1;
  min-width: min(100%, 800px);
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-wrap: wrap;
`;

const SubmissionsBreakdownWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  margin-top: 16px;
`;

const QuestionBreakdown = ({ selectedQuiz }) => (
  <>
    <SubmissionsBreakdownWrapper>
      {selectedQuiz.questions.length > 0 && (
        <>
          <QuestionBreakdownContainer>
            {selectedQuiz.questions.map((question, index) => (
              <AnswerDistribution
                question={question}
                key={question.id}
                index={index}
              />
            ))}
          </QuestionBreakdownContainer>
        </>
      )}
    </SubmissionsBreakdownWrapper>
  </>
);

export default QuestionBreakdown;

QuestionBreakdown.propTypes = {
  selectedQuiz: PropTypes.object.isRequired,
};
