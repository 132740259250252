/* eslint-disable */
import React from "react";
import { Modal } from "@material-ui/core";
import AddAudienceAndDatesToLaunchV3 from "../../../components/audience/AddAudienceAndDatesToLaunchV3";
import EditAudienceModalV2 from "../../../components/audience/EditAudienceModalV2";
import EditEndDateModal from "./components/EditEndDateModal";
import OrganizationCopyModal from "./components/OrganizationCopyModal";
import LaunchScheduledModModal from "./components/LaunchScheduledModModal";
import ConfirmModalSmall from "../../../components/Modals/ConfirmModalSmall";
import ReviewModal from "../marketplace/review";
import ActivityLogModal from "./components/ActivityLogModal";
import API from "../../../axios/instances/API";
import { LIBRARY_MODAL_KEYS } from "../../../storage/constants";

const LibraryModals = ({
  modalState = {},
  setModalState,
  mutate,
  setSnackbarText,
}) => {
  const handleClose = () => setModalState(null);

  const handleCloseModule = async () => {
    try {
      await API.delete("/dashboard/quiz/", {
        data: { id: modalState.id },
      });
      await mutate();
      setSnackbarText("Module closed success");
      handleClose();
    } catch (error) {
      setSnackbarText("Failed to close module error");
      handleClose();
    }
  };

  const handleDeleteModule = async () => {
    try {
      await API.delete("/dashboard/quiz/", {
        data: { id: modalState.id },
      });
      await mutate();
      setSnackbarText("Module deleted success");
      handleClose();
    } catch (error) {
      setSnackbarText("Error deleting this request error");
      handleClose();
    }
  };

  return (
    <>
      {modalState?.key === LIBRARY_MODAL_KEYS.ADD_AUDIENCE_AND_LAUNCH && (
        <Modal
          open
          onClose={handleClose}
          aria-labelledby="Add Audience to a vendor module"
          aria-describedby="Add Audience to a vendor module"
        >
          <div>
            <AddAudienceAndDatesToLaunchV3
              moduleId={modalState?.id}
              modalState={modalState}
              handleClose={async () => {
                await mutate();
                setSnackbarText("Module launched successfully success");
                handleClose();
              }}
            />
          </div>
        </Modal>
      )}
      {modalState?.key === LIBRARY_MODAL_KEYS.EDIT_AUDIENCE && (
        <Modal
          open
          aria-labelledby="Change module end date"
          aria-describedby="Modal to change module end date"
          onClose={() => {
            handleClose();
            mutate();
          }}
        >
          <div>
            <EditAudienceModalV2
              id={modalState?.id}
              moduleName={modalState?.moduleName}
              handleClose={handleClose}
              setSnackbarText={setSnackbarText}
            />
          </div>
        </Modal>
      )}
      {modalState?.key === LIBRARY_MODAL_KEYS.EDIT_END_DATE && (
        <Modal
          open
          aria-labelledby="Change module end date"
          aria-describedby="Modal to change module end date"
          onClose={handleClose}
        >
          <div>
            <EditEndDateModal
              id={modalState?.id}
              setSnackbarText={setSnackbarText}
              handleClose={handleClose}
            />
          </div>
        </Modal>
      )}
      {modalState?.key === LIBRARY_MODAL_KEYS.ACTIVITY && (
        <Modal
          open
          onClose={handleClose}
          aria-labelledby="Modal to view log of module changes"
          aria-describedby="Modal to view log of module changes"
        >
          <div>
            <ActivityLogModal
              changeLogInfo={modalState}
              handleClose={handleClose}
              setSnackbarText={setSnackbarText}
            />
          </div>
        </Modal>
      )}
      {modalState?.key === LIBRARY_MODAL_KEYS.PREVIEW && (
        <Modal
          open
          onClose={handleClose}
          aria-labelledby="Review a closed module"
          aria-describedby="Review a closed module"
        >
          <div>
            <ReviewModal
              reviewModalInfo={modalState}
              handleClose={handleClose}
              setSnackbarText={setSnackbarText}
              isViewClosed
            />
          </div>
        </Modal>
      )}
      {modalState?.key === LIBRARY_MODAL_KEYS.CLOSE && (
        <Modal
          open
          onClose={handleClose}
          aria-labelledby="Close a Module"
          aria-describedby="Modal to close a module"
        >
          <ConfirmModalSmall
            title="Close this module?"
            name={modalState?.name}
            handleConfirm={handleCloseModule}
          />
        </Modal>
      )}
      {modalState?.key === LIBRARY_MODAL_KEYS.DELETE && (
        <Modal
          open
          onClose={handleClose}
          aria-labelledby="Delete a module"
          aria-describedby="Permanently delete a module"
        >
          <div>
            <ConfirmModalSmall
              title="Delete this module"
              name={modalState?.name}
              handleConfirm={handleDeleteModule}
            />
          </div>
        </Modal>
      )}
      {modalState?.key === LIBRARY_MODAL_KEYS.ORG_SHARE && (
        <Modal
          open
          onClose={handleClose}
          aria-labelledby="Share module with other companies in organization"
          aria-describedby="Modal used to share modules with other companies in the organization"
        >
          <div>
            <OrganizationCopyModal
              id={modalState?.id}
              setSnackbarText={setSnackbarText}
              handleClose={handleClose}
            />
          </div>
        </Modal>
      )}
      {modalState.key === LIBRARY_MODAL_KEYS.LAUNCH_PENDING && (
        <Modal open onClose={handleClose}>
          <div>
            <LaunchScheduledModModal
              handleClose={async () => {
                await mutate();
                handleClose();
              }}
              id={modalState?.id}
              setSnackbarText={setSnackbarText}
            />
          </div>
        </Modal>
      )}
    </>
  );
};

export default LibraryModals;
