/* eslint-disable */
import React, { useState } from "react";
import { Document, Page, pdfjs } from "react-pdf/dist/esm/entry.webpack";
import styled from "styled-components/macro";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const PdfDocumentHolder = styled(Document)`
  position: relative;
  height 100%;
`;

const Wrapper = styled.div`
  height: 520px;
  width: 260px;
  overflowy: scroll;
`;

const DisplayPdf = ({ data }) => {
  const [isLoading, setisLoading] = useState(true);

  return (
    <Wrapper>
      {isLoading && (
        <div
          style={{
            height: 516,
            width: 260,
            position: "absolute",
            top: 80,

            background: "white",
            zIndex: 100,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <span style={{ color: "darkgrey" }}>loading pdf...</span>
        </div>
      )}
      <PdfDocumentHolder
        onLoadSuccess={() => setisLoading(false)}
        file={data?.media}
        error={() => (
          <span style={{ color: "firebrick" }}>error in document holder</span>
        )}
        loading={() => (
          <div
            style={{
              height: 520,
              width: 260,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              borderRadius: 4,
              background: "white",
            }}
          >
            <span style={{ color: "darkgrey", fontSize: 10 }}>
              loading pdf...
            </span>
          </div>
        )}
      >
        <Page pageNumber={1} height={520} width={260} wrap={true} />
      </PdfDocumentHolder>
    </Wrapper>
  );
};

export default DisplayPdf;
