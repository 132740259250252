/* eslint-disable */
import React, { useState } from "react";
import PropTypes from "prop-types";
import styled from "styled-components/macro";
import { Paper } from "@material-ui/core";
import { DeleteWrapper, DeleteButton, Alert, Typography } from "../../styles";
import API from "../../../../axios/instances/API";
import { FEEDBACK_MESSAGES } from "../../../../storage/constants";
import useTeamMemberGeneralInfo from "../../../../swr/hooks/RetailReports/singleTeamMemberV2/useTeamMemberGeneralInfo";

const LoadingWrapper = styled(Paper)`
  height: 180px;
  width: 460px;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  border: 2px solid tomato;
  border-radius: 8px;
`;

const RemoveRoleModal = ({
  deleteInfo,
  setDeleteInfo,
  setSnackbarText,
  roleId,
  mutate,
}) => {
  const { data, isLoading } = useTeamMemberGeneralInfo(deleteInfo.teamMemberId);
  const [isDeleting, setIsDeleting] = useState(false);
  const [errorAlert, setErrorAlert] = useState(false);

  if (isLoading) {
    return (
      <LoadingWrapper>
        <span style={{ color: "darkgrey" }}>loading...</span>
      </LoadingWrapper>
    );
  }

  const handleDelete = async () => {
    setIsDeleting(true);
    setErrorAlert(false);
    const oldRoles = data?.team_member?.roles.map((role) => role.id);
    const newRolesArr = oldRoles.filter((role) => role !== Number(roleId));
    if (newRolesArr.length === 0) {
      setIsDeleting(false);
      setSnackbarText(
        "Unable To remove role. Every team member must have at least one role error"
      );
      setDeleteInfo(null);
      return;
    }

    try {
      await API.put("dashboard/teams/roles/", {
        id: deleteInfo.teamMemberId,
        roles: newRolesArr,
      });
      setIsDeleting(false);
      mutate();
      setSnackbarText(
        `Role ${deleteInfo.roleName} removed for ${deleteInfo.name} warning`
      );
      setDeleteInfo(null);
    } catch (error) {
      setSnackbarText(
        `Failed to remove role ${deleteInfo.roleName} for ${deleteInfo.name} warning`
      );
      setIsDeleting(false);
      setDeleteInfo(null);
    }
  };

  return (
    <DeleteWrapper style={{ width: 460, border: "2px solid tomato" }}>
      <Typography variant="h5" component="h5">
        Remove team member from role?
      </Typography>
      <Typography mb={8} variant="subtitle1">
        {deleteInfo.name}
      </Typography>
      <DeleteButton
        fullWidth
        p={2}
        onClick={handleDelete}
        disabled={isDeleting}
      >
        {" "}
        {isDeleting ? "..." : "Confirm"}
      </DeleteButton>
      {errorAlert && (
        <Alert mt={4} severity="error" onClose={() => setErrorAlert(false)}>
          {FEEDBACK_MESSAGES.GENERIC_ERROR}
        </Alert>
      )}
    </DeleteWrapper>
  );
};

export default RemoveRoleModal;

RemoveRoleModal.propTypes = {
  deleteInfo: PropTypes.oneOfType([PropTypes.bool, PropTypes.object])
    .isRequired,
  setDeleteInfo: PropTypes.func.isRequired,
  setSnackbarText: PropTypes.func.isRequired,
  mutate: PropTypes.func.isRequired,
  roleId: PropTypes.string.isRequired,
};
