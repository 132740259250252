/* eslint-disable */

import React, { useState } from "react";
import PropTypes from "prop-types";
import { Modal, useMediaQuery } from "@material-ui/core";
import { CheckCircle, Feather } from "react-feather";
import TableLoader from "../../../components/Loaders/TableLoader";
import PendingModal from "./PendingModal";
import SnackbarWrapper from "../../../components/SnackbarWrapper";
import { CustomDatatableV2 } from "../styles";
import { makeDefaultTableOptions } from "../../../storage/helpers";
import usePendingTeamInvitations from "../../../swr/hooks/Team/usePendingTeamInvitations";
import DeleteButtonForTables from "../../../components/Table/DeleteButtonForTables";
import ActionButtonForTables from "../../../components/Table/ActionButtonForTables";
import TableTitle from "../../../components/Table/TableTitle";
import CustomDataTableIcons from "../../../components/Table/CustomDataTableIcons";
import DateTooltip from "../../../components/Table/DateTooltip";

const PendingTable = ({ readOnly }) => {
  const [snackbarText, setSnackbarText] = useState("");
  const [pendingData, setPendingData] = useState(null);
  const isUnder600Px = useMediaQuery((theme) => theme.breakpoints.down("xs"));

  const {
    isLoading,
    pending,
    mutate: pendingMutate,
  } = usePendingTeamInvitations();

  if (isLoading) return <TableLoader />;

  const options = makeDefaultTableOptions("team-members-awaiting-approval");
  options.textLabels = {
    body: {
      noMatch:
        "No new users are waiting for approval to join your team. You can invite new team members using the “Invite Team Member” button above!",
    },
  };
  options.sortOrder = {
    name: "created",
    direction: "desc",
  };
  options.rowsPerPage = 100;

  if (isUnder600Px) {
    options.rowsPerPageOptions = [];
    options.jumpToPage = false;
  }

  const tableData = pending.map((person) => ({
    id: person.id,
    name: `${person.user.firstName} ${person.user.lastName}`,
    created: new Date(person.created).toISOString(),
    email: person.user.email,
    location: person.location.name,
  }));

  const columns = [
    {
      name: "id",
      label: "ID",
      options: {
        filter: false,
        sort: false,
        display: false,
        download: false,
      },
    },
    {
      name: "name",
      label: "Name",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "created",
      label: "Request Date",
      options: {
        filter: false,
        sort: true,
        display: true,
        print: false,
        download: false,
        customBodyRender: (value) => (
          <DateTooltip date={value} shouldLocalize displayYear />
        ),
      },
    },
    {
      name: "email",
      label: "Email",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "location",
      label: "Location",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "approve",
      label: "Approve",
      options: {
        filter: false,
        sort: false,
        empty: true,
        print: false,
        download: false,
        customBodyRender: (_, tableMeta) => (
          <ActionButtonForTables
            title="approve team member"
            handleClick={(e) => {
              e.stopPropagation();
              const { rowData } = tableMeta;
              setPendingData({ id: rowData[0], name: rowData[1], action: "A" });
            }}
            Icon={CheckCircle}
            disabled={readOnly}
          />
        ),
      },
    },
    {
      name: "delete",
      label: "Decline",
      options: {
        sort: false,
        filter: false,
        empty: true,
        customBodyRender: (_, tableMeta) => (
          <DeleteButtonForTables
            disabled={readOnly}
            handleClick={(e) => {
              e.stopPropagation();
              const { rowData } = tableMeta;
              setPendingData({ id: rowData[0], name: rowData[1], action: "D" });
            }}
          />
        ),
      },
    },
  ];
  return (
    <>
      <CustomDatatableV2
        title={
          <TableTitle Icon={Feather} title="Team member access requests" />
        }
        data={tableData}
        columns={columns}
        options={options}
        components={{
          icons: CustomDataTableIcons,
        }}
      />
      <Modal
        open={Boolean(pendingData)}
        onClose={() => setPendingData(null)}
        aria-labelledby="Approve/Decline Team Member"
        aria-describedby="Approve/Decline Team Member"
      >
        <div>
          <PendingModal
            pendingData={pendingData}
            setSnackbarText={setSnackbarText}
            setPendingData={setPendingData}
            mutate={pendingMutate}
          />
        </div>
      </Modal>
      <SnackbarWrapper
        snackbarText={snackbarText}
        setSnackbarText={setSnackbarText}
      />
    </>
  );
};

export default PendingTable;

PendingTable.propTypes = {
  readOnly: PropTypes.bool.isRequired,
};

PendingTable.defaultProps = {
  pending: null,
  isLoading: undefined,
};
