const ACTIONS = {
  ADD_CONFIG: 'ADD_CONFIG',
  LOGOUT: 'LOGOUT',
  REFRESH_CONFIG: 'REFRESH_CONFIG',
  ADD_TRANSCODE: 'ADD_TRANSCODE',
  REMOVE_TRANSCODE: 'REMOVE_TRANSCODE',
  SET_TRANSCODE: 'SET_TRANSCODE',
  UPDATE_CONFIG: 'UPDATE_CONFIG',
  UPDATE_NAV_BADGES: 'UPDATE_NAV_BADGES',
};

export default ACTIONS;
