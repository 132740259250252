/* eslint-disable */
import React, { useState, useMemo } from "react";
import PropTypes from "prop-types";
import styled from "styled-components/macro";
import { subDays, startOfDay } from "date-fns";
import { MenuItem } from "@material-ui/core";
import { makeDefaultTableOptions } from "../../../../storage/helpers";
import SubmissionTypeChip from "./SubmissionTypeChip";
import { TextField, StyledLink, CustomDatatableV2 } from "../../styles";
import { ENGAGEMENT_TYPES } from "../../../../storage/constants";
import CustomDataTableIcons from "../../../../components/Table/CustomDataTableIcons";
import TableTitle from "../../../../components/Table/TableTitle";
import { Award } from "react-feather";
import { useMediaQuery } from "@material-ui/core";

const StyledTextfield = styled(TextField)`
  @media (max-width: 600px) {
    display: none;
  }
`;

const TODAY = new Date();

const TIMEPERIODS = {
  TODAY: "Today",
  WEEK: "Last Week",
  TWOWEEK: "Last 14 Days",
};

const BREAKDOWNS = {
  TEAMMEMBER: "Team Member",
  LOCATION: "Location",
  MODULE: "Module",
};

const getTimePeriod = (timestring) => {
  if (timestring === TIMEPERIODS.TODAY) return 1;
  if (timestring === TIMEPERIODS.WEEK) return 7;
  if (timestring === TIMEPERIODS.TWOWEEK) return 15;
};

const getBreakdownName = (submission, breakdown) => {
  if (breakdown === BREAKDOWNS.TEAMMEMBER)
    return `${submission.first_name} ${submission.last_name}`;
  if (breakdown === BREAKDOWNS.MODULE) return submission.quiz_name;
  if (breakdown === BREAKDOWNS.LOCATION) {
    const { active_team_members } = submission || {};
    if (!submission?.active_team_members || active_team_members.length === 0) {
      return "Unknown";
    }
    if (active_team_members.length === 1)
      return submission.active_team_members[0].location_name;
    return active_team_members;
  }
};

const getLink = (submission, breakdown, isManager) => {
  if (breakdown === BREAKDOWNS.TEAMMEMBER) {
    if (
      !submission?.active_team_members ||
      submission.active_team_members?.length === 0
    ) {
      return null;
    }
    return `/teammembers/detail/${submission.active_team_members[0].id}`;
  }
  if (breakdown === BREAKDOWNS.MODULE) {
    return null;
    /// removed because of bug
    // if (isManager) return null;
    // if (!submission.assigned) {
    //   return null;
    // }
    // return `/modules/progress/${submission.quiz_id}`;
  }
  if (breakdown === BREAKDOWNS.LOCATION || isManager) {
    const { active_team_members } = submission;

    if (active_team_members.length === 0) return null;
    if (active_team_members.length === 1)
      return `/locations/detail/${submission.active_team_members[0].location_id}`;
    if (active_team_members.length > 1) {
      return active_team_members.map(
        (member) => `/locations/detail/${member.location_id}`
      );
    }
  }
  return null;
};

const getSubBreakdown = (submissions, breakdown, timePeriod, isManager) => {
  if (submissions.length === 0) return null;
  const numDays = getTimePeriod(timePeriod);
  const timeAgo =
    timePeriod === TIMEPERIODS.TODAY
      ? startOfDay(TODAY)
      : subDays(TODAY, numDays);
  const submissionsLastTimePeriod = submissions.filter(
    (submission) => new Date(submission.created) > timeAgo
  );
  const answerObj = {};
  submissionsLastTimePeriod.forEach((submission) => {
    const name = getBreakdownName(submission, breakdown);

    const linkString = getLink(submission, breakdown, isManager);

    const multipleLocations = Array.isArray(name);

    /// This was changed to handle multiple locations //
    if (multipleLocations) {
      name.forEach((obj, i) =>
        adjustAnswerObjectByName(
          obj.location_name,
          submission,
          answerObj,
          linkString[i]
        )
      );
    } else {
      adjustAnswerObjectByName(name, submission, answerObj, linkString);
    }
  });
  return answerObj;
};

const adjustAnswerObjectByName = (name, submission, answerObj, linkString) => {
  if (!answerObj.hasOwnProperty(name)) {
    if (submission.passed) {
      answerObj[name] = {
        subs: 1,
        pass: 1,
        name,
        link: linkString,
        locationName: submission?.active_team_members
          ? submission.active_team_members[0]?.location_name
          : " ",
      };
    } else {
      answerObj[name] = {
        subs: 1,
        pass: 0,
        name,
        link: linkString,
        locationName: submission?.active_team_members
          ? submission.active_team_members[0]?.location_name
          : " ",
      };
    }
  } else {
    if (submission.passed) {
      answerObj[name].pass += 1;
      answerObj[name].subs += 1;
    } else {
      answerObj[name].subs += 1;
    }
  }
};

const SubmissionBreakdownTable = ({
  submissions,
  submissionsType,
  isManager = false,
}) => {
  const [timePeriod, setTimePeriod] = useState(TIMEPERIODS.TWOWEEK);
  const [breakdown, setBreakdown] = useState(BREAKDOWNS.TEAMMEMBER);
  const isUnder600Px = useMediaQuery((theme) => theme.breakpoints.down("xs"));

  const options = makeDefaultTableOptions("submission-breakdown");
  options.search = false;
  options.jumpToPage = false;
  options.rowsPerPage = 10;
  options.filterType = "multiselect";
  options.sortOrder = {
    name: "submissions",
    direction: "desc",
  };
  options.textLabels = {
    body: {
      noMatch: "No new submissions in the last 14 days",
    },
  };

  if (isUnder600Px) {
    options.rowsPerPageOptions = [];
    options.jumpToPage = false;
  }

  const allSubmissions =
    submissionsType === ENGAGEMENT_TYPES.EXTRACREDIT
      ? submissions.filter((sub) => !sub.assigned)
      : submissionsType === ENGAGEMENT_TYPES.ASSIGNED
      ? submissions.filter((sub) => sub.assigned)
      : submissions;

  const columns = [
    {
      name: "name",
      label: `${
        breakdown === BREAKDOWNS.TEAMMEMBER
          ? "Team member"
          : breakdown === BREAKDOWNS.MODULE
          ? "Module"
          : "Location"
      }`,
      options: {
        filter: false,
        sort: true,
        customBodyRender: (value, tableMeta) => {
          const link = tableMeta.rowData[5];
          if (link) {
            return <StyledLink to={link} value={value} />;
          }
          return <span>{value}</span>;
        },
      },
    },
    {
      name: "submissions",
      label: "Submissions",
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: "passed",
      label: "Completions",
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: "passRate",
      label: "Pass Rate",
      options: {
        filter: false,
        sort: true,
        customBodyRender: (value) => <span>{`${value}%`}</span>,
      },
    },
    {
      name: "locationName",
      label: "Location",
      options: {
        filter: breakdown === BREAKDOWNS.TEAMMEMBER,
        sort: true,
        display: breakdown === BREAKDOWNS.TEAMMEMBER && !isManager,
        print: breakdown === BREAKDOWNS.TEAMMEMBER,
        download: breakdown === BREAKDOWNS.TEAMMEMBER,
      },
    },
    {
      name: "linkString",
      label: "",
      options: {
        display: false,
        print: false,
        download: false,
      },
    },
  ];

  const subBreakdown = useMemo(
    () => getSubBreakdown(allSubmissions, breakdown, timePeriod, isManager),
    [breakdown, timePeriod, isManager, allSubmissions]
  );

  const tableData = !subBreakdown
    ? []
    : Object.values(subBreakdown).map((entry) => ({
        name: entry.name,
        submissions: entry.subs,
        passed: entry.pass,
        passRate:
          entry.pass > 0 ? Math.floor((entry.pass / entry.subs) * 100) : 0,
        locationName: entry.locationName,
        linkString: entry.link,
      }));

  return (
    <>
      <div>
        <CustomDatatableV2
          title={
            <div
              style={{
                display: "flex",
                width: "100%",
                alignItems: "center",
                justifyContent: "flex-start",
                gap: 8,
              }}
            >
              <TableTitle
                Icon={Award}
                title="Top Performers"
                submissionType={submissionsType}
              />

              <div style={{ marginLeft: "auto" }} />
              <TextField
                select
                variant="outlined"
                size="small"
                mr={2}
                value={breakdown}
                onChange={(e) => setBreakdown(e.target.value)}
                style={{
                  backgroundColor: "white",
                  width: 160,
                  borderRadius: 4,
                }}
              >
                {!isManager && (
                  <MenuItem value={BREAKDOWNS.LOCATION}>By Location</MenuItem>
                )}
                <MenuItem value={BREAKDOWNS.TEAMMEMBER}>
                  By Team Member
                </MenuItem>
                <MenuItem value={BREAKDOWNS.MODULE}>By Module</MenuItem>
              </TextField>
              <StyledTextfield
                select
                variant="outlined"
                size="small"
                mr={2}
                value={timePeriod}
                onChange={(e) => setTimePeriod(e.target.value)}
                style={{
                  backgroundColor: "white",
                  width: 140,
                  borderRadius: 4,
                }}
              >
                <MenuItem value={TIMEPERIODS.TODAY}>Today</MenuItem>
                <MenuItem value={TIMEPERIODS.WEEK}>Last 7 Days</MenuItem>
                <MenuItem value={TIMEPERIODS.TWOWEEK}>Last 14 Days</MenuItem>
              </StyledTextfield>
              <div style={{ width: 8 }} />
            </div>
          }
          columns={columns}
          options={options}
          data={tableData}
          components={{
            icons: CustomDataTableIcons,
          }}
        />
        {/* <AbsoluteContainer>
          <TabBar>
            <Tab
              isActive={breakdown === BREAKDOWNS.TEAMMEMBER ? "yes" : "no"}
              onClick={() => setBreakdown(BREAKDOWNS.TEAMMEMBER)}
            >
              <span>Team Member</span>
            </Tab>
            <Tab
              isActive={breakdown === BREAKDOWNS.MODULE ? "yes" : "no"}
              onClick={() => setBreakdown(BREAKDOWNS.MODULE)}
            >
              <span>Module</span>
            </Tab>
            {!isManager && (
              <Tab
                isActive={breakdown === BREAKDOWNS.LOCATION ? "yes" : "no"}
                onClick={() => setBreakdown(BREAKDOWNS.LOCATION)}
              >
                <span>Location</span>
              </Tab>
            )}
          </TabBar>
        </AbsoluteContainer> */}
      </div>
    </>
  );
};

export default SubmissionBreakdownTable;

SubmissionBreakdownTable.propTypes = {
  submissions: PropTypes.array.isRequired,
  submissionsType: PropTypes.string.isRequired,
};
