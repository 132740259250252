/* eslint-disable */
import React from 'react';
import DisplayPdf from './DisplayPdf';
import DisplayVideo from './DisplayVideo';
import { Activity } from 'react-feather';

const Content = ({ data, contentIndex, hasMultipleContentPeices }) => {
  const hasContent = Boolean(data?.media || data?.video);
  const conversionInProgress = data.state === 'Content Conversion In Progress';

  if (!hasContent) {
    return (
      <div
        style={{
          flex: 1,
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <span style={{ color: 'darkgrey' }}>No content added yet</span>
      </div>
    );
  }

  if (conversionInProgress) {
    return (
      <div
        style={{
          flex: 1,
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          flexDirection: 'column',
        }}
      >
        <div
          style={{
            height: 24,
            width: 24,
            background: 'lightgray',
            borderRadius: 4,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Activity
            height={14}
            width={14}
            color="slategrey"
          />
        </div>
        <div style={{ height: 8 }} />
        <span style={{ color: 'slategrey', fontSize: 12 }}>Video transcoding</span>
      </div>
    );
  }

  if (hasMultipleContentPeices) {
    return contentIndex === 0 ? <DisplayPdf data={data} /> : <DisplayVideo data={data} />;
  }

  if (data?.media) {
    return <DisplayPdf data={data} />;
  }

  if (data?.video) {
    return <DisplayVideo data={data} />;
  }

  return (
    <div
      style={{
        flex: 1,
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        border: '1px solid red',
      }}
    >
      <span>error</span>
    </div>
  );
};

export default Content;
