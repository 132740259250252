/* eslint-disable */
import styled, { keyframes } from "styled-components/macro";
import { Paper } from "@material-ui/core";
import { Link } from "react-router-dom";
import { Typography } from "../../styles";
import { ENGAGEMENT_TYPES } from "../../../../storage/constants";

// Info Blocks

export const InfoWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 16px;
  width: 100%;

  @media (max-width: 800px) {
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, 1fr);
  }
`;

export const InfoPaper = styled(Paper)`
  padding: 20px;
  flex: 0.5;
  minHeight: 150px;
  // max-width: 240px;
  text-align: center;
  display: flex;
  border-radius 6px;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  box-shadow: ${(props) => {
    if (props.subtype === ENGAGEMENT_TYPES.ASSIGNED) {
      return "0px 0px 2px 2px rgba(0, 0, 250, .4)";
    }
    if (props.subtype === ENGAGEMENT_TYPES.EXTRACREDIT) {
      return "0px 0px 1px 2px gold";
    }
  }};

  svg {
    height: 26; 
    width: 26;
    color: black;
  

  }
  // This looks pretty good imo
`;

export const DescriptorText = styled(Typography)`
  font-size: 14px;
  font-weight: 500;
  color: darkgrey;
  // margin-bottom: 10px;
`;

export const OverSizedText = styled(Typography)`
  font-size: 40px;
  font-weight: 800;
  line-height: 1.1;
  // color: #337ab7;
`;

// HomeHero Components

export const OverSizedSmaller = styled.span`
  font-weight: 400;
  font-size: 14px;
  color: black;
  text-transform: capitalize;
  margin-bottom: 2px;
`;

// Still use this
export const ShowDetail = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: -4px;

  span {
    color: ${(props) => props.theme.palette.grey[800]};
    font-size: 12px;
    text-transform: capitalize;
  }

  svg {
    color: ${(props) => props.theme.palette.primary.light};
    height: 14px;
    width: 14px;
    margin-right: 2px;
    margin-left: -3px;
  }
`;

// Still use this
export const AddCompanyImage = styled(Link)`
  height: 100%;
  width: 100%;
  border-radius: 10px;
  border: 3px dashed #eeeeee;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  transition: border 200ms ease-in;
  text-decoration: none;

  &:hover {
    cursor: pointer;
    border: 3px dashed ${(props) => props.theme.palette.info.light};

    span {
      color: ${(props) => props.theme.palette.info.light};
    }

    svg {
      color: ${(props) => props.theme.palette.info.light};
    }
  }

  span {
    color: ${(props) => props.theme.palette.grey[400]};
    font-size: 18px;
    margin-top: 6px;
    transition: color 200ms ease-in;
  }

  svg {
    height: 64px;
    width: 64px;
    transition: color 200ms ease-in;
    color: ${(props) => props.theme.palette.grey[400]};
  }
`;

// We use this still
export const LastSubContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 4px 16px;
  margin-bottom: 8px;
  margin-top: -4px;
  border-radius: 4px;
  text-align: center;

  p {
    color: ${(props) => props.theme.palette.info[300]};
    text-transform: capitalize;
  }

  span {
    color: ${(props) => props.theme.palette.info[300]};
  }
`;

export const TabBar = styled.div`
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 2px;
  padding: 2px;
  background: #eeeeee;
`;

export const Tab = styled.div`
  padding: 2px 6px;
  height: 100%;
  min-width: 120px;
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${(props) => {
    if (props.isActive === "yes") {
      return "white";
    }
    return "#EEEEEE22";
  }};

  &:before {
    content: " ";
    height: 8px;
    width: 8px;
    // height: ${(props) => (props.isActive === "yes" ? 8 : 0)}px;
    // width: ${(props) => (props.isActive === "yes" ? 8 : 0)}px;
    margin-left: -2px;
    border-radius: 4px;
    margin-right: 4px;

    background-color: ${(props) =>
      props.isActive === "yes" ? "#337AB7" : "#EEEEEE34"};
  }

  span {
    font-size: 13px;
    font-weight: 500;

    color: ${(props) => {
      if (props.isActive === "yes") {
        return "#337AB7";
      }
      return "darkgrey";
    }};
  }

  &:hover {
    cursor: pointer;
  }
`;
