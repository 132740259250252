/* eslint-disable */
import { lightFormat } from "date-fns";

const ACCESS_LEVELS = {
  SUPERVISOR: "Supervisor",
  ADMIN: "Admin",
};

export const REGISTER_TYPES = {
  ADMIN: "ADMIN_REGISTER",
  MANAGER: "MANAGER",
  RETAIL_ADMIN_NEW_COMPANY: "ADMIN_NEW_COMPANY",
  VENDOR_ADMIN_NEW_COMPANY: "VENDOR_ADMIN_NEW_COMPANY",
  VENDOR_ADMIN_JOIN_COMPANY: "VENDOR_ADMIN_JOIN_COMPANY",
};

const JOBS = {
  ADMIN: "C",
  MANAGER: "M",
};

export const getRegisterInfo = (signupState) => {
  try {
    const { companyInfo, registerInfo, cantFindCompany, createCompanyInfo } =
      signupState;

    let sendObj = {};

    sendObj.email = registerInfo.email;
    sendObj.password = registerInfo.password;
    sendObj.first_name = registerInfo.firstName;
    sendObj.last_name = registerInfo.lastName;
    sendObj.birth_date = lightFormat(registerInfo.dob, "yyyy-MM-dd");
    sendObj.referral_code = registerInfo.ref;
    sendObj.country = registerInfo.country;
    sendObj.state = registerInfo.state;

    const isAdminSignupExistingCompany =
      !cantFindCompany && companyInfo?.accessLevel === ACCESS_LEVELS.ADMIN;

    const isManagerSignupExistingCompany =
      !cantFindCompany &&
      companyInfo?.is_retail &&
      companyInfo?.accessLevel === ACCESS_LEVELS.SUPERVISOR;

    const isRetailAdminSignupNewCompany =
      cantFindCompany && createCompanyInfo?.type === "RETAIL";

    const isVendorAdminSignupNewCompany =
      cantFindCompany && createCompanyInfo?.type === "VENDOR";

    if (isAdminSignupExistingCompany) {
      sendObj.existing_company = true;
      sendObj.job = JOBS.ADMIN;
      sendObj.company = companyInfo?.id;

      return {
        sendObj,
        registerType: REGISTER_TYPES.ADMIN,
      };
    }

    if (isManagerSignupExistingCompany) {
      sendObj.job = JOBS.MANAGER;
      sendObj.company = companyInfo?.id;
      sendObj.locations = companyInfo?.location?.map((l) => l.id);

      return {
        sendObj,
        registerType: REGISTER_TYPES.MANAGER,
      };
    }

    if (isRetailAdminSignupNewCompany) {
      sendObj.existing_company = false;
      sendObj.job = JOBS.ADMIN;
      sendObj.company = {
        name: createCompanyInfo.companyName,
        vertical: createCompanyInfo?.companyVertical?.id,
        country: createCompanyInfo?.country?.code,
        type: "retail",
      };

      return {
        sendObj,
        registerType: REGISTER_TYPES.RETAIL_ADMIN_NEW_COMPANY,
      };
    }

    if (isVendorAdminSignupNewCompany) {
      sendObj.existing_company = false;
      sendObj.job = JOBS.ADMIN;
      sendObj.company = {
        name: createCompanyInfo.companyName,
        vertical: createCompanyInfo?.companyVertical?.id,
        country: createCompanyInfo?.country?.code,
        type: "vendor",
      };

      return {
        sendObj,
        registerType: REGISTER_TYPES.VENDOR_ADMIN_NEW_COMPANY,
      };
    }

    return {
      sendObj,
      registerType: "",
    };
  } catch (error) {
    return {
      sendObj: null,
      registerType: "error",
    };
  }
};

export const getRegisterErrorMessage = (error) => {
  return "Error registering you, please try again.";
};
