/* eslint-disable */
import React, { useEffect, useState } from "react";
import styled from "styled-components/macro";
import {
  MenuItem,
  Paper,
  Select,
  FormControl,
  TextField,
  Button,
  CircularProgress,
  Modal,
  IconButton,
} from "@material-ui/core";
import useInactiveTeamMembers from "../../../../swr/hooks/Team/useInactiveTeamMembers";
import {
  AlertTriangle,
  ChevronDown,
  ChevronLeft,
  ChevronRight,
  Send,
  X,
} from "react-feather";
import API from "../../../../axios/instances/API";
import { useDebounce } from "use-debounce";
import SelectInviteOptionsModal from "./SelectInviteOptionsModal";

const ModalWrapper = styled(Paper)`
  padding: 20px 20px 14px 20px;
  width: calc(min(980px, 96vw));
  max-height: 90vh;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  overflow-y: scroll;
  border: 2px solid black;
`;

const Item = styled.div`
  height: 30px;
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  border-bottom: 1px solid #eeeeee90;

  div {
    span {
      font-size: 12px;
      text-align: center;
    }
  }
`;

const SortButton = styled(Button)`
  display: flex;
  justify-content: center;

  align-items: center;
  height: 18px;
  max-height: 18px;
  min-height: 18px;
  max-width: 18px;
  min-width: 18px;
  width: 18px;
  background: ${(props) => (props.isactive === "Y" ? "white" : "gainsboro")};
  border-radius: 3px;
  padding: 0px;

  &:hover {
    background: aliceblue;
  }

  svg {
    height: 13px;
    width: 13px;
    color: black;

    margin-right: 2px;
  }
`;

const CellLarge = styled.div`
  flex: 0.3;
  height: 100%;
  flex-grow: 0.3;
  overflow-x: hidden;
  border-right: 1px solid #eeeeee90;
  display: flex;
  align-items: center;
  justify-content: center;
  text-overflow: ellipsis;

  span {
    width: 100%;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow-x: hidden;
    padding-left: 2px;
  }
`;

const CellSmall = styled.div`
  flex: 0.1;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-basis: 120px;
`;

const CustomButton = styled(Button)`
  height: 28px;
  min-height: 28px;
  max-height: 28px;
  width: 28px;
  min-width: 28px;
  max-width: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #eeeeee;
  border: none;
  border-radius: 4px;
  padding: 3px;

  svg {
    height: 16px;
    width: 16px;
    color: black;
  }

  &:disabled {
    svg {
      color: lightgray;
    }
  }
`;

const BottomBar = styled.div`
  height: 40px;
  width: 100%;

  display: flex;
  align-items: center;
  justify-content: flex-start;
`;

const TableWrapper = styled.div`
  width: 100%;
  min-height: 342px;
  overflow-y: scroll;
  height: 342px;
  max-height: 342px;
  border: 1px solid lightgray;
  border-radius: 4px;
  margin-bottom: 8px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  position: relative;
  flex-direction: column;
`;

const TableHead = styled.div`
  width: 100%;
  height: 40px;
  min-height: 40px;
  background: #eeeeee;
  z-index: 2;
  display: flex;
  alignitems: center;
  justify-content: flex-start;
  position: sticky;
  top: 0px;
  border-bottom: 1px solid lightgray;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
`;

const getCleanErrorMsg = (error, name) => {
  const errorObj = error?.response?.data?.error;

  if (errorObj?.context?.errors.length > 0) {
    return `${errorObj?.context?.errors.join(". ")} error`;
  }

  if (errorObj?.details) {
    return `${errorObj.details} error`;
  }

  return `Failed to re-invite ${name} error`;
};

const ORDER_BY_OPTIONS = {
  FULL_NAME: "full_name",
  USERNAME: "username",
};

const LoadingUi = () =>
  [0, 1, 2, 3, 4, 5, 6, 7, 8, 9].map((num) => (
    <Item
      style={{ background: num % 2 === 0 ? "white" : "#eeeeee50" }}
      key={num}
    >
      <CellLarge></CellLarge>
      <CellLarge></CellLarge>
      <CellLarge></CellLarge>
      <CellSmall></CellSmall>
    </Item>
  ));

const InactiveTeamMembers = ({ setSnackbarText }) => {
  const [page, setPage] = useState(1);
  const [orderBy, setOrderBy] = useState(ORDER_BY_OPTIONS.USERNAME);
  const [search, setSearch] = useState("");
  const [pageSize, setPageSize] = useState(10);
  const [totalNumRows, setTotalNumRows] = useState(1);
  const [updatingId, setUpdatingId] = useState(null);
  const [selectLocationModalData, setSelectLocationModalData] = useState(null);

  const [searchText] = useDebounce(search, 400);

  const { data, isLoading, isError, mutate } = useInactiveTeamMembers(
    page,
    pageSize,
    orderBy,
    searchText
  );

  useEffect(() => {
    if (!data?.count) return undefined;

    setTotalNumRows(data?.count);
  }, [data?.count]);

  useEffect(() => {
    if (!search) return undefined;

    if (search.length > 2 && page !== 1) {
      setPage(1);
    }
  }, [searchText]);

  const numPagesTotal = Math.ceil(totalNumRows / pageSize);

  const handleInviteUser = async (user) => {
    return setSelectLocationModalData(user);

    if (user.locations.length === 0) {
      return setSelectLocationModalData(user);
    }
    try {
      setUpdatingId(user?.user_id);
      await API.post("dashboard/teams/invitations/", {
        email: user?.username,
        first_name: user?.first_name,
        last_name: user?.last_name,
        locations: user?.locations?.map((loc) => loc.id),
        roles: [],
      });
      await mutate();
      setUpdatingId(null);
      setSnackbarText(
        `Team member ${user?.full_name} re-invited to team success`
      );
    } catch (error) {
      const errorMsg = getCleanErrorMsg(error, user?.full_name);
      setSnackbarText(errorMsg);
      setUpdatingId(null);
    }
  };

  if (isError) {
    return (
      <ModalWrapper>
        <div
          style={{
            height: 300,
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column",
            gap: 8,
          }}
        >
          <AlertTriangle height={26} width={26} color="firebrick" />
          <span style={{ fontWeight: "500" }}>
            Error loading inactive team members.
          </span>
        </div>
      </ModalWrapper>
    );
  }

  return (
    <ModalWrapper>
      <span style={{ fontSize: 16, fontWeight: "600" }}>
        Inactive Team Members
      </span>
      <div
        style={{
          width: "100%",
          height: 40,
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-start",
          marginTop: 10,
        }}
      >
        <TextField
          style={{ width: 260 }}
          variant="outlined"
          size="small"
          label="Search"
          value={search}
          onChange={(e) => setSearch(e.target.value)}
        />
        {search.length > 2 && (
          <IconButton
            size="small"
            style={{ marginLeft: 6, padding: 6 }}
            onClick={() => setSearch("")}
          >
            <X height={18} width={18} color="slategrey" />
          </IconButton>
        )}
      </div>
      <div style={{ height: 14 }} />
      <TableWrapper>
        <TableHead>
          <CellLarge style={{ borderRight: "1px solid lightgray" }}>
            <div
              style={{
                width: "100%",
                height: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                gap: 8,
              }}
            >
              <p style={{ fontWeight: "600" }}>Email</p>
              <SortButton
                isactive={orderBy === ORDER_BY_OPTIONS.USERNAME ? "Y" : "N"}
                onClick={() => setOrderBy(ORDER_BY_OPTIONS.USERNAME)}
              >
                <ChevronDown />
              </SortButton>
            </div>
          </CellLarge>
          <CellLarge style={{ borderRight: "1px solid lightgray" }}>
            <div
              style={{
                width: "100%",
                height: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                gap: 8,
              }}
            >
              <p style={{ fontWeight: "600" }}>Name</p>
              <SortButton
                isactive={orderBy === ORDER_BY_OPTIONS.FULL_NAME ? "Y" : "N"}
                onClick={() => setOrderBy(ORDER_BY_OPTIONS.FULL_NAME)}
              >
                <ChevronDown />
              </SortButton>
            </div>
          </CellLarge>
          <CellLarge style={{ borderRight: "1px solid lightgray" }}>
            <p style={{ fontWeight: "600" }}>Location</p>
          </CellLarge>
          <CellSmall style={{}}>
            <p style={{ fontWeight: "600" }}>Activate</p>
          </CellSmall>
        </TableHead>
        {isLoading && <LoadingUi />}
        {(!isLoading && !data?.results) || data?.results?.length === 0 ? (
          <div
            style={{
              height: 342,
              width: "100%",
              background: "#eeeeee50",
              padding: 12,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <span style={{ color: "darkgrey", fontSize: isLoading ? 12 : 13 }}>
              {search.length > 2
                ? "No Results For This Search"
                : "No Inactive Team Members"}
            </span>
          </div>
        ) : (
          data?.results?.map((result, i) => (
            <Item
              style={{ background: i % 2 === 0 ? "white" : "#eeeeee50" }}
              key={result?.user_id}
            >
              <CellLarge>
                <span>{result?.username}</span>
              </CellLarge>
              <CellLarge>
                <span>{result?.full_name}</span>
              </CellLarge>
              <CellLarge>
                {result?.locations.length === 0 ? (
                  <span style={{ color: "darkgrey" }}>---/---</span>
                ) : result?.locations?.length === 1 ? (
                  <span>{result?.locations?.[0]?.name}</span>
                ) : (
                  <span style={{ fontWeight: "500" }}>*Multiple Locations</span>
                )}
              </CellLarge>
              <CellSmall>
                {result?.invitation_exists ? (
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      gap: 4,
                      // background: "lightgreen",
                      padding: 2,
                      borderRadius: 3,
                    }}
                  >
                    <Send height={10} width={10} color="mediumseagreen" />
                    <span style={{ color: "slategrey", fontWeight: "500" }}>
                      Invitation Sent
                    </span>
                  </div>
                ) : (
                  <Button
                    startIcon={
                      updatingId === result?.user_id ? (
                        <CircularProgress size={12} color="primary" />
                      ) : (
                        <Send height={10} width={10} color="#337ab7" />
                      )
                    }
                    size="small"
                    variant="text"
                    color="inherit"
                    disabled={updatingId === result?.user_id}
                    onClick={() => handleInviteUser(result)}
                  >
                    Reinvite
                  </Button>
                )}
              </CellSmall>
            </Item>
          ))
        )}
      </TableWrapper>
      <BottomBar>
        <span style={{ fontSize: 12, color: "darkgrey" }}>
          Total: {data?.count ?? "---"} inactive users
        </span>
        <div style={{ marginLeft: "auto", width: 2 }} />
        <FormControl size="small" variant="outlined" style={{ height: 28 }}>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              gap: 6,
            }}
          >
            <span style={{ fontSize: 11, color: "darkgrey" }}>
              Rows Per Page:
            </span>
            <Select
              style={{ height: 28 }}
              value={pageSize}
              onChange={(e) => {
                setPage(1);
                setPageSize(e.target.value);
              }}
            >
              <MenuItem size="small" style={{ height: 28 }} value={10}>
                10
              </MenuItem>
              <MenuItem size="small" style={{ height: 28 }} value={20}>
                20
              </MenuItem>
              <MenuItem size="small" style={{ height: 28 }} value={50}>
                50
              </MenuItem>
              <MenuItem size="small" style={{ height: 28 }} value={100}>
                100
              </MenuItem>
            </Select>
          </div>
        </FormControl>
        <div
          style={{
            height: 30,
            width: 1,
            background: "#eeeeee",
            marginLeft: 16,
            marginRight: 16,
          }}
        />
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",

            gap: 4,
          }}
        >
          <CustomButton
            size="small"
            onClick={() => setPage((p) => p - 1)}
            disabled={page === 1}
          >
            <ChevronLeft />
          </CustomButton>
          <div
            style={{
              width: 80,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <span style={{ fontSize: 11, color: "darkgrey" }}>
              Page {page} of {numPagesTotal}
            </span>
          </div>
          <CustomButton
            size="small"
            onClick={() => setPage((p) => p + 1)}
            disabled={page === numPagesTotal}
          >
            <ChevronRight />
          </CustomButton>
        </div>
      </BottomBar>

      <Modal
        open={Boolean(selectLocationModalData)}
        onClose={() => setSelectLocationModalData(null)}
      >
        <div>
          <SelectInviteOptionsModal
            selectLocationModalData={selectLocationModalData}
            setSelectLocationModalData={setSelectLocationModalData}
            mutate={mutate}
            setSnackbarText={setSnackbarText}
          />
        </div>
      </Modal>
    </ModalWrapper>
  );
};

export default InactiveTeamMembers;
