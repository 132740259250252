/* eslint-disable */
import React from "react";
import { Modal } from "@material-ui/core";
import LocationSelectionModal from "./LocationSelectionModal";
import RoleSelectionModal from "./RoleSelectionModal";

const RoleAndLocationSelectionModals = ({
  locations,
  roles,
  handleApplyRoles,
  handleApplyLocations,
  handleRolesClose,
  handleLocationsClose,
  locationModalOpen,
  roleModalOpen,
  setRoleModalOpen,
  setLocationModalOpen,
}) => {
  return (
    <>
      <Modal
        open={locationModalOpen}
        onClose={() => setLocationModalOpen(false)}
      >
        <div>
          <LocationSelectionModal
            handleApply={handleApplyLocations}
            locations={locations}
            handleClose={handleLocationsClose}
          />
        </div>
      </Modal>
      <Modal open={roleModalOpen} onClose={() => setRoleModalOpen(false)}>
        <div>
          <RoleSelectionModal
            handleApply={handleApplyRoles}
            roles={roles}
            handleClose={handleRolesClose}
          />
        </div>
      </Modal>
    </>
  );
};

export default RoleAndLocationSelectionModals;
