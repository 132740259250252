/* eslint-disable */
import React from "react";
import { Button, Tooltip } from "@material-ui/core";
import styled from "styled-components/macro";

const StyledButton = styled(Button)`
  height: 34px;
  width: 34px;
  min-height: 34px;
  max-height: 34px;
  max-width: 34px;
  min-width: 34px;
  background-color: #eeeeee;
  display: flex;
  border-radius: 6px;
  align-items: center;
  justify-content: center;
  padding: 0px;
  position: relative;
  transition: background-color 200ms ease-in;
  border: none;

  svg {
    color: black;
    height: 14px;
    width: 14px;
  }

  &:hover {
    background-color: lightgray;
    border: none;
  }

  &:disabled {
    background-color: #eeeeee;

    svg {
      color: darkgrey;
    }
  }
`;
const SecondaryButton = ({ title, disabled = false, Icon, handleClick }) => {
  return (
    <Tooltip arrow title={title}>
      <span>
        <StyledButton disabled={disabled} onClick={handleClick}>
          <Icon />
        </StyledButton>
      </span>
    </Tooltip>
  );
};

export default SecondaryButton;
