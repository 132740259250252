/* eslint-disable */
import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components/macro";
import { CircularProgress } from "@material-ui/core";
import useLocationProgress from "../../../../swr/hooks/Locations/useLocationProgress";
import {
  Typography,
  CustomLinearProgressMain,
  WrapperLessPaddingTop,
} from "../../styles";

const TopWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 8px;

  span {
    color: black;
    font-weight: 800;
    font-size: 18px;
  }
`;

const AttemptsSpan = styled.span`
  margin-top: 4px;
  font-size: 12px;
  color: ${(props) => props.theme.palette.grey[500]};
  font-weight: 600;
  display: block;
`;

const Centered = styled.div`
  display: flex;
  min-height: 10px;
  align-items: flex-start;
`;

const LocationProgress = ({ locationId }) => {
  const { progress, isLoading, isError } = useLocationProgress();
  if (isLoading) {
    return (
      <WrapperLessPaddingTop>
        <Centered>
          <CircularProgress size={30} />
        </Centered>
      </WrapperLessPaddingTop>
    );
  }

  if (isError) {
    return (
      <WrapperLessPaddingTop>
        <Centered>
          <Typography variant="subtitle2">Error loading progress..</Typography>
        </Centered>
      </WrapperLessPaddingTop>
    );
  }

  const locationProgress = progress[locationId];

  if (locationProgress === undefined) return null;

  const value = Math.floor(
    (locationProgress.complete / locationProgress.total) * 100
  );

  return (
    <WrapperLessPaddingTop>
      <TopWrapper>
        <Typography style={{ fontSize: 16, fontWeight: "600" }}>
          Location Progress
        </Typography>
        <span>{value}%</span>
      </TopWrapper>
      <div style={{ height: 6 }} />
      <CustomLinearProgressMain variant="determinate" value={value} />
      <AttemptsSpan>
        {`${locationProgress.complete} modules completed, ${locationProgress.total} modules outstanding`}
      </AttemptsSpan>
    </WrapperLessPaddingTop>
  );
};

export default LocationProgress;

LocationProgress.propTypes = {
  locationId: PropTypes.string.isRequired,
};
