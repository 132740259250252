/* eslint-disable */
import React from "react";
import styled, { createGlobalStyle } from "styled-components/macro";
import { CssBaseline, AppBar, Toolbar, Button } from "@material-ui/core";
import EmailIcon from "@material-ui/icons/Email";
import LockOpenIcon from "@material-ui/icons/LockOpen";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
// import SpiffyLogo from "../assets/images/newspiffy.png";
import SpiffyLogo from "../assets/images/newspiffy.avif";

const GlobalStyle = createGlobalStyle`
  html,
  body,
  #root {
    height: 100%;
  }
  body {
    background: ${(props) => props.theme.palette.background.default};
  }
`;

const Root = styled.div`
  max-width: 520px;
  margin: auto;
  justify-content: center;
  align-items: center;
  display: flex;
  min-height: 100vh;
  flex-direction: column;
  padding-top: 100px;
  padding-bottom: 100px;
`;

const AuthAppBar = styled(AppBar)`
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: space-evenly;
  padding-left: ${(props) => props.theme.spacing(5)}px;
  padding-right: ${(props) => props.theme.spacing(5)}px;
  background-color: ${(props) => props.theme.sidebar.background};
`;

const LogoImage = styled.img`
  height: 36px;
  width: auto;

  @media (max-width: 500px) {
    height: 26px;
  }
`;

const LinkBox = styled.div`
  margin-left: auto;
  width: 260px;
  display: flex;
  justify-content: space-between;

  @media (max-width: 500px) {
    width: 180px;
  }
`;

const AppBarLink = styled(Button)`
  text-decoration: none;
  color: ${(props) => props.theme.palette.primary.contrastText};
  white-space: nowrap;
  margin-left: 20px;
  font-size: 16px;
  background-color: transparent;

  @media (max-width: 500px) {
    font-size: 12px;
    margin-left: 8px;
  }

  &:hover {
    color: ${(props) => props.theme.palette.primary.light};
  }
`;

const AuthLayout = ({ children }) => (
  <Root>
    <CssBaseline />
    <GlobalStyle />
    <AuthAppBar elevation={0}>
      <Toolbar>
        <LogoImage src={SpiffyLogo} alt="" />
        <LinkBox>
          <AppBarLink component={Link} to="/" startIcon={<LockOpenIcon />}>
            Log In
          </AppBarLink>
          <AppBarLink component={Link} to="/contact" startIcon={<EmailIcon />}>
            Contact Us
          </AppBarLink>
        </LinkBox>
      </Toolbar>
    </AuthAppBar>
    {children}
  </Root>
);

export default AuthLayout;

AuthLayout.propTypes = {
  children: PropTypes.node.isRequired,
};
