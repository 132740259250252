/* eslint-disable */
import React from 'react';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { DatePicker } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { InputAdornment } from '@material-ui/core';
import { Calendar } from 'react-feather';
import { subYears } from 'date-fns';

const DATE_FORMAT = 'dd/MM/yyyy'


const DobField = ({
  setFieldValue, 
  values,
}) => {

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <DatePicker
        autoOk
        disableToolbar
        label="Date of Birth"
        emptyLabel=""
        value={values.dob}
        name="dob"
        size="small"
        fullWidth
        inputVariant="outlined"
        disableFuture
        format={DATE_FORMAT}
        openTo="year"
        views={["year", "month", "date"]}
        onChange={(date) => {
          setFieldValue('dob', date)
        }}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <Calendar style={{ height: 14, width: 14, color: 'darkgrey' }} />
            </InputAdornment>
          ),          
        }}

      />
  </MuiPickersUtilsProvider>
  )
}

export default DobField;
