/* eslint-disable */
import React from "react";

const Notes = () => (
  <div
    style={{
      background: "#eeeeee60",
      border: "1px solid lightgray",
      padding: "8px 10px",
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-start",
      justifyContent: "flex-start",
      borderRadius: 4,
    }}
  >
    <p
      style={{
        fontWeight: "600",
        fontSize: 13,
        marginLeft: 4,
        color: "#233044",
        marginTop: -1,
      }}
    >
      Notes
    </p>

    <ul style={{ padding: "0px 16px" }}>
      <li style={{ color: "#233044", fontSize: 12, fontWeight: "500" }}>
        Maximum 250 rows per sheet.
      </li>
      <li style={{ color: "#233044", fontSize: 12, fontWeight: "500" }}>
        Do not change column names or add columns.
      </li>

      <li style={{ color: "#233044", fontSize: 12, fontWeight: "500" }}>
        Address line 2 field is optional.
      </li>
    </ul>
  </div>
);

export default Notes;
