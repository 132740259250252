/* eslint-disable */
import React from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components/macro';
import {
  Button as MuiButton,
  Typography,
  Paper,
} from '@material-ui/core';
import {
  spacing,
} from '@material-ui/system';
import { Helmet } from 'react-helmet-async';
import LOGO from '../assets/images/blueSmaller.png';

const Wrapper = styled(Paper)`
  padding: ${(props) => props.theme.spacing(8)}px;
  text-align: center;
  background: #FFF;
  width : 400px;
`;

const Root = styled.div`
  width: 100vw;
  height: 100vh;
  background-color: ${props => props.theme.palette.background.default};
  display: flex;
  align-items: center;
  justify-content: center;
`;

const LogoHolder = styled.img`
  padding: 10px;
  object-fit: contain;
  width: 100%;
  height: 100px;
  border-radius: 4px;
`;

const Button = styled(MuiButton)(spacing);

const Nomatch = () => {
  const history = useHistory();

  const returnToLast = () => {
    history.goBack();
  };

  return (
    <Root>
      <Helmet title="Spiffy | 404" />
      <Wrapper>
        <LogoHolder
          src={LOGO}
          alt="spiffy logo"
        />
        <Typography
          variant="h1"
        >
          404
        </Typography>
        <Typography
          variant="subtitle1"
          color="textPrimary"
        >
          Page Not Found
        </Typography>
        <Button
          variant="contained"
          color="secondary"
          mt={2}
          fullWidth
          onClick={returnToLast}
        >
          Go Back
        </Button>
      </Wrapper>
    </Root>
  );
};

export default Nomatch;
