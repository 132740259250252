/* eslint-disable */
import React from "react";
import IndividualModule from "./IndividualModule";

const CloseList = ({ modules, handleItemClick, config, modIds }) => {
  const closeList = modules
    .filter((mod) => ["0", "1", "3", "5"].includes(mod.state))
    .reverse();

  return (
    <>
      {closeList.map((mod) => (
        <IndividualModule
          key={mod.id}
          handleItemClick={handleItemClick}
          mod={mod}
          config={config}
          checked={modIds.includes(mod.id)}
        />
      ))}
    </>
  );
};

export default CloseList;
