/* eslint-disable */
import React, { useState, useContext } from "react";
import { Helmet } from "react-helmet-async";
import PropTypes from "prop-types";
import { Grid } from "@material-ui/core";
import useRoleDetails from "../../../../swr/hooks/Roles/useRoleDetails";
import RoleDetailTable from "./RoleDetailTable";
import PrivateHeader from "../../../../components/PrivateHeader";
import LoaderWrapper from "../../../../components/Loaders/LoaderWrapper";
import SnackbarWrapper from "../../../../components/SnackbarWrapper";
import { GlobalState } from "../../../../store/GlobalState";
import OnLoadError from "../../../../components/UI/OnLoadError";
import AddTeamMembers from "./AddTeamMembers";
import EditRole from "./EditRole";
import RoleModulesTable from "./RoleModulesTable";

const RoleDetail = ({ match }) => {
  const { id: roleId } = match.params;
  const [snackbarText, setSnackbarText] = useState("");
  const { details, isLoading, mutate, isError } = useRoleDetails(roleId);

  const { state } = useContext(GlobalState);
  const { read_only: readOnly } = state.config.user;

  if (isLoading) return <LoaderWrapper text="Role Details" />;

  if (isError) {
    return <OnLoadError />;
  }
  return (
    <>
      <Helmet title="Spiffy | Role Details" />
      <PrivateHeader header="Role" secondary={details.role.name} />
      <Grid container spacing={4}>
        <Grid item xs={12} md={6}>
          <AddTeamMembers
            currentTeamMembers={details.role.teamMembers}
            readOnly={readOnly}
            setSnackbarText={setSnackbarText}
            roleId={roleId}
            mutate={mutate}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <EditRole
            setSnackbarText={setSnackbarText}
            mutate={mutate}
            details={details.role}
            readOnly={readOnly}
          />
        </Grid>
      </Grid>
      <Grid container spacing={4} style={{ marginTop: 8 }}>
        <Grid item xs={12} md={6}>
          <RoleDetailTable
            roleId={roleId}
            readOnly={readOnly}
            teamMembers={details.role.teamMembers}
            mutate={mutate}
            name={details.role.name}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <RoleModulesTable
            assignedQuizzes={details.assigned_quizzes}
            timeRoleCreated={details?.role?.created}
          />
        </Grid>
      </Grid>
      <SnackbarWrapper
        snackbarText={snackbarText}
        setSnackbarText={setSnackbarText}
      />
    </>
  );
};

export default RoleDetail;

RoleDetail.propTypes = {
  match: PropTypes.object.isRequired,
};
