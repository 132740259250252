/* eslint-disable */
import React from "react";
import styled from "styled-components/macro";
import Preview from "../../Preview";

const StateAndPreviewWrapper = styled.div`
  margin-left: auto;
  display: flex;
  align-items: center;
  justify-content: center;

  @media (max-width: 700px) {
    display: none;
  }
`;

const ToolbarStateAndPreview = ({
  details,
  isModuleConvertingNextStateDev,
  isModuleConvertingNextStateLive,
  isModuleConvertingNextStatePending,
  isModuleConvertingNextStateReview,
}) => (
  <StateAndPreviewWrapper>
    <div
      style={{
        width: 1,
        height: 26,
        marginLeft: 12,
        marginRight: 12,
        background: "lightgray",
      }}
    />
    <div style={{ display: "flex", alignItems: "flex-end", gap: 4 }}>
      <span
        style={{
          fontWeight: "500",
          color: "slategrey",
          whiteSpace: "nowrap",
        }}
      >
        Module State:
      </span>

      <span
        style={{
          fontSize: 12,
          color: "darkgrey",
          marginRight: 11,
          fontWeight: "500",
          whiteSpace: "nowrap",
        }}
      >
        {isModuleConvertingNextStateDev
          ? "In Development (Video Transcoding)"
          : isModuleConvertingNextStateLive
          ? "Live (Pending Video Transcode)"
          : isModuleConvertingNextStatePending
          ? "Pending Release (Pending Video Transcode)"
          : isModuleConvertingNextStateReview
          ? "Spiffy Review (Pending Video Transcode)"
          : details.state}
      </span>
    </div>
    <Preview id={details.id} />
  </StateAndPreviewWrapper>
);

export default ToolbarStateAndPreview;
