/* eslint-disable */
/* redeploy */
import React, { useState } from "react";
import PropTypes from "prop-types";
import { Modal, useMediaQuery } from "@material-ui/core";
import SnackbarWrapper from "../../../components/SnackbarWrapper";
import { CustomDatatableV2 } from "../styles";
import DeleteAdminModal from "./DeleteAdminModal";
import { Copy, List, Edit2, Settings } from "react-feather";
import API from "../../../axios/instances/API";
import ModifyAdminTypeModal from "./ModifyAdminTypeModal";
import DeleteButtonForTables from "../../../components/Table/DeleteButtonForTables";
import ActionButtonForTables from "../../../components/Table/ActionButtonForTables";
import TableTitle from "../../../components/Table/TableTitle";
import CustomDataTableIcons from "../../../components/Table/CustomDataTableIcons";
import CustomSearchBarForTables from "../../../components/Table/CustomSearchBarForTables";

const HINT_STRING =
  "This represents the total number of brands followed within your organization via referral from this admin. Ex: If you have 2 brands and a user scans your teams referral code, the user count would go up by 1 and the brands followed would go up by 2 because they followed 2 brands.";
const HINT_STIRNG_2 =
  "This represents the total number of people this user has successfully referred to view your content on the app.";

const getAdminStatus = (user) => {
  if (user.read_only && user.organization_access)
    return "Read-Only Organization Admin";
  if (user.read_only) return "Read-Only";
  if (user.organization_access) return "Organization Access";
  return "Admin";
};

const AdminTable = ({
  readOnly,
  organizationAccess,
  isRetail,
  mutate,
  admins,
  isLoading,
  usersCompanyHasOrganization,
  userId,
}) => {
  const [deleteInfo, setDeleteInfo] = useState(null);
  const [modifyAdminInfo, setModifyAdminInfo] = useState(null);
  const [snackbarText, setSnackbarText] = useState("");
  const isUnder600Px = useMediaQuery((theme) => theme.breakpoints.down("xs"));

  if (isLoading) return null;

  const options = {
    filterType: "textField",
    filter: false,
    print: true,
    viewColumns: false,
    elevation: 1,
    selectableRows: "none",
    rowsPerPage: 50,
    jumpToPage: true,
    rowsPerPageOptions: [5, 10, 20, 50, 100],
    responsive: "standard",
    downloadOptions: {
      filename: "admin-list.csv",
    },
  };

  options.customSearchRender = (searchText, onSearch, onHide) => (
    <CustomSearchBarForTables
      searchText={searchText}
      onSearch={onSearch}
      onHide={onHide}
    />
  );

  if (isUnder600Px) {
    options.rowsPerPageOptions = [];
    options.jumpToPage = false;
  }

  /// If company is in org, only org access can edit others, else anyone without read only can
  // You cannot edit your own accesss
  const userCanEditOtherAdminsAccess = usersCompanyHasOrganization
    ? organizationAccess && !readOnly
    : !readOnly;

  const handleCopyToClipboard = async (id) => {
    const { data } = await API.get(`/dashboard/admin/details/?id=${id}`);
    navigator.clipboard.writeText(data.referral);
    return setSnackbarText("Referral code copied to clipboard info");
  };

  const tableData = admins.users.map((user) => ({
    id: user.id,
    name: `${user.first_name} ${user.last_name}`,
    email: user.email,
    invitesSent: user.referral_data.unique_users_followed,
    overallFollowings: user.referral_data?.overall_followings || 0,
    privilege: getAdminStatus(user),
    userOrgAccess: user.organization_access,
    activeTeamMembers: user?.companies_of_active_team_members,
  }));

  const columns = [
    {
      name: "id",
      label: "ID",
      options: {
        filter: false,
        sort: false,
        display: false,
        download: false,
      },
    },
    {
      name: "name",
      label: "Name",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "email",
      label: "Email",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "privilege",
      label: "Permission Level",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta) => {
          const idOfAdmin = tableMeta.rowData[0];
          const isUserThisAdmin = idOfAdmin === userId;
          return (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-start",
              }}
            >
              {userCanEditOtherAdminsAccess &&
                !(!organizationAccess && value === "Organization Access") && (
                  <ActionButtonForTables
                    Icon={Settings}
                    title="Change permission level"
                    disabled={isUserThisAdmin}
                    handleClick={() =>
                      setModifyAdminInfo({
                        id: idOfAdmin,
                        name: tableMeta.rowData[1],
                        currentPrivelege: value,
                      })
                    }
                  >
                    <Edit2 />
                  </ActionButtonForTables>
                )}
              <span style={{ marginLeft: 8 }}>{value}</span>
            </div>
          );
        },
      },
    },
    {
      name: "invitesSent",
      label: "Users Referred",
      options: {
        filter: false,
        sort: true,
        hint: HINT_STIRNG_2,
        display: !isRetail,
      },
    },
    {
      name: "overallFollowings",
      label: "Brand follows generated",
      options: {
        filter: false,
        hint: HINT_STRING,
        sort: true,
        display: !isRetail,
      },
    },

    {
      name: "userOrgAccess",
      label: "",
      options: {
        filter: false,
        sort: true,
        display: false,
      },
    },
    {
      name: "activeTeamMembers",
      label: "",
      options: {
        filter: false,
        sort: true,
        display: false,
        print: false,
      },
    },
    {
      name: "copy",
      label: "Referral Code",
      options: {
        sort: false,
        filter: false,
        display: !isRetail,
        download: false,
        customBodyRender: (_, tableMeta) => (
          <ActionButtonForTables
            Icon={Copy}
            title="Copy referral code"
            handleClick={() => handleCopyToClipboard(tableMeta.rowData[0])}
          >
            <Copy />
          </ActionButtonForTables>
        ),
      },
    },
    {
      name: "delete",
      label: "Delete",
      options: {
        sort: false,
        filter: false,
        empty: true,
        download: false,
        customBodyRender: (_, tableMeta) => {
          const idOfAdmin = tableMeta.rowData[0];
          const isUserThisAdmin = idOfAdmin === userId;
          const isButtonDisabled =
            readOnly ||
            (tableMeta.rowData[6] && !organizationAccess) ||
            isUserThisAdmin;
          return (
            <DeleteButtonForTables
              disabled={isButtonDisabled}
              handleClick={() => {
                const { rowData } = tableMeta;
                setDeleteInfo({
                  id: rowData[0],
                  name: rowData[1],
                  description: "Are you sure you want to delete admin",
                  header: "Delete This Admin",
                  activeTeamMembers: rowData[7],
                  snack: `Deleted Admin ${rowData[1]} warning`,
                });
              }}
            />
          );
        },
      },
    },
  ];

  return (
    <>
      <CustomDatatableV2
        title={<TableTitle title="Admin List" Icon={List} />}
        options={options}
        columns={columns}
        data={tableData}
        components={{
          icons: CustomDataTableIcons,
        }}
      />
      <Modal
        open={Boolean(deleteInfo)}
        onClose={() => setDeleteInfo(null)}
        aria-labelledby="Delete a Admin User"
        aria-describedby="Delete a Pending Admin User"
      >
        <div>
          <DeleteAdminModal
            deleteInfo={deleteInfo}
            setSnackbarText={setSnackbarText}
            setDeleteInfo={setDeleteInfo}
            mutate={mutate}
          />
        </div>
      </Modal>
      <Modal
        open={Boolean(modifyAdminInfo)}
        onClose={() => setModifyAdminInfo(null)}
        aria-labelledby="Change an Admins access"
        aria-describedby="Modal to change an admins access"
      >
        <div>
          <ModifyAdminTypeModal
            modifyAdminInfo={modifyAdminInfo}
            setModifyAdminInfo={setModifyAdminInfo}
            setSnackbarText={setSnackbarText}
            mutate={mutate}
            userHasOrgAccess={organizationAccess}
          />
        </div>
      </Modal>
      <SnackbarWrapper
        snackbarText={snackbarText}
        setSnackbarText={setSnackbarText}
      />
    </>
  );
};

export default AdminTable;

AdminTable.propTypes = {
  readOnly: PropTypes.bool.isRequired,
  organizationAccess: PropTypes.bool.isRequired,
  isRetail: PropTypes.bool.isRequired,

  mutate: PropTypes.func.isRequired,
  admins: PropTypes.object.isRequired,
  isLoading: PropTypes.bool.isRequired,
};
