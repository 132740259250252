/* eslint-disable */
import React, { useRef, useEffect, useState } from "react";
import styled from "styled-components/macro";
import Vimeo from "@vimeo/player";

const Wrapper = styled.div`
  height: 100%;
  width: 100%;
`;

const DisplayVideo = ({ data }) => {
  const videoRef = useRef(null);
  const [isLoading, setIsLoading] = useState(true);

  const videoUrl = data.video;

  useEffect(() => {
    // Video Player
    if (!videoUrl) return;
    if (!videoRef.current) return;

    const videoId = videoUrl.split("/")[1] * 1;

    const vimeoOptions = {
      url: `https://vimeo.com/${videoId}`,
      autoplay: true,
      loop: false,
      // responsive: true,

      width: 260,
      playsinline: true,
      fullscreen: false,
    };

    // eslint-disable-next-line no-unused-vars
    const player = new Vimeo(videoRef.current, vimeoOptions);
    player.on("error", (e) => {});
    player.on("loaded", () => setIsLoading(false));
  }, [videoUrl]);

  return (
    <Wrapper>
      <div
        style={{
          width: 260,
          height: 524,
          background: "white",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",

          position: "relative",
        }}
      >
        {isLoading && (
          <div
            style={{
              width: 260,
              height: 520,
              background: "white",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              position: "absolute",
            }}
          >
            <div style={{ color: "darkgrey" }}>loading video...</div>
          </div>
        )}
        <div
          style={{
            width: "100%",
          }}
          ref={videoRef}
          id="video-player-lib-v2"
        />
      </div>
    </Wrapper>
  );
};

export default DisplayVideo;
