/* eslint-disable */
import React from 'react';
import styled from 'styled-components/macro';

const Wrapper = styled.div`
  flex-basis: 0.75;
  flex-grow: 1;
  height: calc(100vh - 64px);
  padding: 20px;
  overflow-y: scroll;

  padding-bottom: 100px;

  ::-webkit-scrollbar {
    width: 0px;
    background: transparent;
  }

  -ms-overflow-style: none;
  scrollbar-width: none;

  @media (max-width: 1000px) {
    padding: 20px;
  }

  @media (max-width: 600px) {
    padding: 12px 4px;
  }
`;

const HeroLayout = ({ children }) => {
  return <Wrapper>{children}</Wrapper>;
};

export default HeroLayout;
