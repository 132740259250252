/* eslint-disable */
import useSWR from "swr";
import API from "../../../../axios/instances/API";

const BASE_ENDPOINT =
  "/dashboard/analytics/retail-reports/users-activity-breakdown/?team_member_id=";

const fetcher = async (url) => {
  const { data } = await API.get(url);
  return data;
};

const useTeamMemberBreakdownInfo = (id) => {
  const { data, error, mutate } = useSWR(`${BASE_ENDPOINT}${id}`, fetcher, {
    revalidateOnFocus: false,
    errorRetryCount: 1,
  });

  return {
    data,
    isLoading: !error && !data,
    isError: error,
    mutate,
  };
};

export default useTeamMemberBreakdownInfo;
