/* eslint-disable */
import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components/macro";
import { NavLink } from "react-router-dom";
import { rgba, darken } from "polished";
import { ListItem, ListItemText, Chip } from "@material-ui/core";

const Link = styled(ListItem)`
  padding-left: ${(props) => props.theme.spacing(17.5)}px;
  padding-top: ${(props) => props.theme.spacing(2)}px;
  padding-bottom: ${(props) => props.theme.spacing(2)}px;

  span {
    color: ${(props) => rgba(props.theme.sidebar.color, 0.7)};
  }

  &:hover span {
    color: ${(props) => rgba(props.theme.sidebar.color, 0.9)};
  }

  &:hover {
    background-color: ${(props) =>
      darken(0.02, props.theme.sidebar.background)};

    svg {
      color: #337ab7;
    }
  }

  &.${(props) => props.activeClassName} {
    background-color: ${(props) =>
      darken(0.03, props.theme.sidebar.background)};

    span {
      // font-weight: 800;
      color: ${(props) => props.theme.sidebar.color};
    }
  }
`;

const LinkText = styled(ListItemText)`
  color: ${(props) => props.theme.sidebar.color};
  span {
    font-size: ${(props) => props.theme.typography.body1.fontSize}px;
  }
  margin-top: 0;
  margin-bottom: 0;
  border: 1px solid white;
`;

const LinkBadge = styled(Chip)`
  font-size: 11px;
  font-weight: ${(props) => props.theme.typography.fontWeightBold};
  height: 20px;
  position: absolute;
  right: 28px;
  top: 8px;
  background: ${(props) => props.theme.sidebar.badge.background};

  span.MuiChip-label,
  span.MuiChip-label:hover {
    cursor: pointer;
    color: ${(props) => props.theme.sidebar.badge.color};
    padding-left: ${(props) => props.theme.spacing(2)}px;
    padding-right: ${(props) => props.theme.spacing(2)}px;
  }
`;

const SidebarLink = ({ name, to, badge }) => (
  <Link button dense component={NavLink} exact to={to} activeClassName="active">
    <LinkText>{name}</LinkText>
    {badge && badge > 0 ? <LinkBadge label={badge} /> : ""}
  </Link>
);
export default SidebarLink;

SidebarLink.propTypes = {
  name: PropTypes.string.isRequired,
  to: PropTypes.string.isRequired,
  badge: PropTypes.node,
};

SidebarLink.defaultProps = {
  badge: null,
};
