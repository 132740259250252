/* eslint-disable */
import React from 'react'
import { User } from 'react-feather'
import {
  InputAdornment
} from '@material-ui/core';
import {
  TextField,
} from '../../private/styles';


const FirstNameField = ({
  touched, 
  errors, 
  values, 
  handleChange, 
  handleBlur,
}) => (
  <TextField
    type="text"
    name="firstName"
    variant="outlined"
    label="First Name"
    size="small"
    fullWidth
    value={values.firstName}
    onChange={handleChange}
    onBlur={handleBlur}
    error={Boolean(touched.firstName && errors.firstName)}
    helperText={touched.firstName && errors.firstName}
    InputProps={{
      startAdornment: (
        <InputAdornment position="start">
          <User style={{ height: 14, width: 14, color: 'darkgrey' }} />
        </InputAdornment>
      ),
      autoComplete: 'new-password',
      form: {
        autoComplete: 'off',
      },
    }}
  />
)

export default FirstNameField;