/* eslint-disable */
import { CssBaseline, Hidden, withWidth } from "@material-ui/core";
import PropTypes from "prop-types";
import React, { useContext, useState } from "react";
import { useLocation } from "react-router-dom";
import styled, { createGlobalStyle } from "styled-components/macro";
import Header from "../components/Header/AppBar";
import Sidebar from "../components/Sidebar";
import { GlobalState } from "../store/GlobalState";

const drawerWidth = 258;

const Root = styled.div`
  display: flex;
  min-height: 100vh;
  overflow: hidden;
`;

const Drawer = styled.div`
  // Initially was MD
  ${(props) => props.theme.breakpoints.up("lg")} {
    width: ${drawerWidth}px;
    flex-shrink: 0;
  }
`;

const AppContent = styled.div`
  flex: 1;
  min-height: 100vh;
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
  max-width: 100%;
  overflow: hidden;
`;

const MainContent = styled.div`
  background: ${(props) => props.theme.palette.background.default};
  height: calc(100vh - 64px);
  overflow-y: scroll;

  padding: ${(props) => (props.isretailhomepage === "Y" ? 0 : 60)}px;

  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    flex: none;
  }

  .MuiPaper-root .MuiPaper-root {
    box-shadow: none;
  }

  @media (max-width: 800px) {
    padding: ${(props) => (props.isretailhomepage === "Y" ? 0 : 40)}px;
  }

  @media (max-width: 600px) {
    padding: ${(props) => (props.isretailhomepage === "Y" ? 0 : 20)}px;
  }

  @media (max-width: 500px) {
    padding: ${(props) =>
      props.isretailhomepage === "Y" ? "0px" : "40px 12px 100px 12px"};
  }
`;

const GlobalStyle = createGlobalStyle`
  html,
  body,
  #root {
    height: 100%;
  } 

  body {
    background: ${(props) => props.theme.palette.background.default};
    overflow: hidden;
    height: 100%;
  }

  .MuiCardHeader-action .MuiIconButton-root {
    padding: 4px;
    width: 28px;
    height: 28px;
  }
`;

const Dashboard = ({ children, routes }) => {
  const [mobileOpen, setMobileOpen] = useState(false);
  const location = useLocation();
  const { state } = useContext(GlobalState);

  const { isRetail } = state;
  const isRetailHomePage = location.pathname === "/home/" && isRetail;

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  return (
    <Root>
      <CssBaseline />
      <GlobalStyle />
      <Drawer>
        {/* initially was mdUp */}
        <Hidden lgUp implementation="js">
          <Sidebar
            routes={routes}
            PaperProps={{ style: { width: drawerWidth } }}
            variant="temporary"
            open={mobileOpen}
            onClose={handleDrawerToggle}
          />
        </Hidden>
        {/* initially was smDown */}
        <Hidden mdDown implementation="css">
          <Sidebar
            routes={routes}
            PaperProps={{ style: { width: drawerWidth } }}
          />
        </Hidden>
      </Drawer>
      <AppContent>
        <Header onDrawerToggle={handleDrawerToggle} />
        <MainContent
          isretailhomepage={isRetailHomePage ? "Y" : "N"}
          // style={{ padding: !isRetailHomePage ? 60 : 0 }}
        >
          {children}
        </MainContent>
      </AppContent>
    </Root>
  );
};

export default withWidth()(Dashboard);

Dashboard.propTypes = {
  children: PropTypes.node,
  routes: PropTypes.node,
  width: PropTypes.string.isRequired,
};

Dashboard.defaultProps = {
  children: null,
  routes: null,
};
