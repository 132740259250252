/* eslint-disable */
import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import { BDown, CustomDetails, CustomAccordion, CustomAccordionSummary } from './styles';
import { Typography, DeleteIcon } from '../../pages/private/styles';

import { Cast, Copy, Edit, Send } from 'react-feather';

const StyledAction = styled.div`
  flex: 0.45;
  display: flex;
  align-items: center;
  justify-content: flex-start;

  svg {
    width: 16px;
    height: 16px;
    margin-right: 12px;
  }
`;

const Wrapper = styled.div`
  padding: 10px 0px;
  width: 100%;
  flex: 0.5;
  flex-grow: 1;
`;

const ExplanationHolder = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  border-bottom: 1px solid #eeeeee;
  padding: 10px 0px;
`;

const DescriptionHolder = styled.p`
  flex: 0.6;
  flex-grow: 1;
  padding: 12px 4px;
  color: ${props => props.theme.palette.grey[700]};
  font-size: 12px;
`;

const ExplainActions = ({ isRetail }) => (
  <Wrapper>
    <CustomAccordion>
      <CustomAccordionSummary expandIcon={<BDown />}>
        <Typography variant="subtitle1">
          <strong>Breakdown of Module Actions</strong>
        </Typography>
      </CustomAccordionSummary>
      <CustomDetails>
        <ExplanationHolder>
          <StyledAction>
            <Edit />
            Edit Module
          </StyledAction>
          <DescriptionHolder>
            You can edit everything in this module. This includes the module name, description,
            start/end times, module image, content, questions and audience.
          </DescriptionHolder>
        </ExplanationHolder>
        <ExplanationHolder>
          <StyledAction>
            <Edit />
            Modify Live Module
          </StyledAction>
          <DescriptionHolder>
            Allows you to edit certain aspects of a custom Module. You cannot edit the module start
            date, the score to pass, or the answer key.
          </DescriptionHolder>
        </ExplanationHolder>
        {isRetail && (
          <ExplanationHolder>
            <StyledAction>
              <Cast />
              Add Audience And Launch
            </StyledAction>
            <DescriptionHolder>
              After adding a Module to your Library from the marketplace, you must add an audience
              and a start date to it before it becomes live.
            </DescriptionHolder>
          </ExplanationHolder>
        )}
        <ExplanationHolder>
          <StyledAction>
            <Copy />
            Clone Module
          </StyledAction>
          <DescriptionHolder>
            Creates a copy of the module and adds it into your Custom Modules table. This module
            will now be in development and can be modified.
          </DescriptionHolder>
        </ExplanationHolder>
        {isRetail && (
          <ExplanationHolder>
            <StyledAction>
              <Send />
              Send Completion Reminder
            </StyledAction>
            <DescriptionHolder>
              Sends a reminder to team members that this module still needs to be completed
            </DescriptionHolder>
          </ExplanationHolder>
        )}
        {isRetail && (
          <ExplanationHolder>
            <StyledAction>
              <Edit />
              Edit Dates And Audience
            </StyledAction>
            <DescriptionHolder>
              Will show up for live vendor modules you have lauched. Once they are launched, you
              will still have the ability to edit the end date as well as the Audience.
            </DescriptionHolder>
          </ExplanationHolder>
        )}
        <ExplanationHolder>
          <StyledAction>
            <DeleteIcon />
            Close Module
          </StyledAction>
          <DescriptionHolder>
            Action closes this module, and removed it from the App. Module cannot be reopened.
          </DescriptionHolder>
        </ExplanationHolder>
      </CustomDetails>
    </CustomAccordion>
  </Wrapper>
);

export default ExplainActions;

ExplainActions.propTypes = {
  isRetail: PropTypes.bool.isRequired,
};
