/* eslint-disable */
/* eslint-disable block-spacing */
import React, { useState } from "react";
import PropTypes from "prop-types";
import { Modal, useMediaQuery } from "@material-ui/core";
import { Cast, List } from "react-feather";
import { StyledLink, CustomDatatableV2 } from "../styles";
import {
  makeDefaultTableOptions,
  fixIncomingDate,
} from "../../../storage/helpers";
import styled from "styled-components/macro";
import ConfirmModalSmall from "../../../components/Modals/ConfirmModalSmall";
import DeleteButtonForTables from "../../../components/Table/DeleteButtonForTables";
import API from "../../../axios/instances/API";
import TableTitle from "../../../components/Table/TableTitle";
import CustomDataTableIcons from "../../../components/Table/CustomDataTableIcons";
import DateTooltip from "../../../components/Table/DateTooltip";

const StyledCast = styled(Cast)`
  height: 14px;
  width: 14px;
  color: black;
  padding: 2px;
  background-color: #eeeeee;
  border-radius: 2px;
`;

const AudienceTable = ({
  mutate,
  setSnackbarText,
  audienceList,
  readOnly,
  isRetail,
}) => {
  const [deleteInfo, setDeleteInfo] = useState(null);
  const isUnder600Px = useMediaQuery((theme) => theme.breakpoints.down("xs"));

  const handleDelete = async () => {
    try {
      await API.delete("dashboard/audiences/details/", {
        data: {
          id: deleteInfo.id,
        },
      });
      await mutate();
      setSnackbarText("Audience deleted success");
      setDeleteInfo(null);
    } catch (error) {
      setSnackbarText(
        "Failed to delete audience. You cannot delete an audience if there are modules assigned to it. error"
      );
      setDeleteInfo(null);
    }
  };

  const options = makeDefaultTableOptions("audience-templates");
  options.textLabels = {
    body: {
      noMatch:
        'No custom audience(s) created for your team yet. Click on "Create New Audience" to get started.',
    },
  };
  options.rowsPerPage = 20;
  options.sortOrder = {
    name: "created",
    direction: "desc",
  };

  if (isUnder600Px) {
    options.rowsPerPageOptions = [];
    options.jumpToPage = false;
  }

  const tableData = audienceList.map((audience) => ({
    id: audience.id,
    name: audience.name,
    created: new Date(audience.created).toISOString(),
  }));

  const columns = [
    {
      name: "id",
      label: "ID",
      options: {
        filter: false,
        sort: false,
        display: false,
      },
    },
    {
      name: "name",
      label: isRetail ? "Template Name" : "Audience Name",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta) => {
          if (readOnly) {
            return <span>{value}</span>;
          }
          return (
            <StyledLink
              to={`/audience/detail/${tableMeta.rowData[0]}`}
              value={value}
              CustomIcon={StyledCast}
            />
          );
        },
      },
    },
    {
      name: "created",
      label: "Created",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value) => (
          <DateTooltip date={value} shouldLocalize={isRetail} />
        ),
      },
    },
    {
      name: "delete",
      label: "Delete",
      options: {
        sort: false,
        filter: false,
        empty: true,
        customBodyRender: (_, tableMeta) => (
          <DeleteButtonForTables
            disabled={readOnly}
            handleClick={(e) => {
              e.stopPropagation();
              const { rowData } = tableMeta;
              setDeleteInfo({
                id: rowData[0],
                name: rowData[1],
              });
            }}
          />
        ),
      },
    },
  ];

  return (
    <>
      <CustomDatatableV2
        title={
          <TableTitle
            title={isRetail ? "Template List" : "Audience List"}
            Icon={List}
          />
        }
        data={tableData}
        options={options}
        columns={columns}
        components={{
          icons: CustomDataTableIcons,
        }}
      />
      <Modal
        open={Boolean(deleteInfo)}
        onClose={() => setDeleteInfo(null)}
        aria-labelledby="Delete Audience Modal"
        aria-describedby="Modal to Delete Audience"
      >
        <div>
          <ConfirmModalSmall
            title="Delete Audience?"
            name={deleteInfo?.name}
            handleConfirm={handleDelete}
            confirmAccept={false}
          />
        </div>
      </Modal>
    </>
  );
};

export default AudienceTable;

AudienceTable.propTypes = {
  audienceList: PropTypes.array.isRequired,
  setSnackbarText: PropTypes.func.isRequired,
  mutate: PropTypes.func.isRequired,
  readOnly: PropTypes.bool.isRequired,
};
