/* eslint-disable */
import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import { InfoWrapper, InfoPaper, DescriptorText } from '../home/styles';
import { addCommas } from '../../../storage/helpers';
import ExtraInfo from '../../../components/ExtraInfo';
import { DEFINITIONS, returnDefinitionByEngagementType } from '../../../storage/constants';
import { retCompsByEngagementType, retSubsByEngagementType } from '../reports/utils/utils';
import BigBlockByEngagementType from '../reports/reuseableComponents/BigBlockByEngagementType';

const OverSizedText = styled.span`
  font-size: 40px;
  font-weight: 800;
  color: black;
`;

const Wrapper = styled.div`
  margin-bottom: 16px;
`;

const UsersBlocks = ({ followersData, engagementType }) => {
  const { followers, new_followers_since_last_week, totals } = followersData;

  const comps = retCompsByEngagementType(totals, engagementType);
  const subs = retSubsByEngagementType(totals, engagementType);

  return (
    <Wrapper>
      <InfoWrapper>
        <InfoPaper>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-start',
              gap: 6,
              textAlign: 'left',
            }}
          >
            <DescriptorText>Current Followers</DescriptorText>
            <ExtraInfo text={DEFINITIONS.currentFollowers} />
          </div>

          <OverSizedText>{addCommas(followers)}</OverSizedText>
        </InfoPaper>
        <InfoPaper>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-start',
              gap: 6,
              textAlign: 'left',
            }}
          >
            <DescriptorText>New Followers Last 7 Days</DescriptorText>
            <ExtraInfo text={DEFINITIONS.newFollowersLast7} />
          </div>

          <OverSizedText>{addCommas(new_followers_since_last_week)}</OverSizedText>
        </InfoPaper>
        <BigBlockByEngagementType
          title="Completions All Time"
          definition={returnDefinitionByEngagementType('completions', engagementType)}
          number={addCommas(comps)}
          engagementType={engagementType}
        />
        <BigBlockByEngagementType
          title="Submissions All Time"
          definition={returnDefinitionByEngagementType('submissions', engagementType)}
          number={addCommas(subs)}
          engagementType={engagementType}
        />
      </InfoWrapper>
    </Wrapper>
  );
};

export default UsersBlocks;

UsersBlocks.propTypes = {
  followers: PropTypes.object.isRequired,
};
