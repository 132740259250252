/* eslint-disable */
import React from "react";
import useSWR from "swr";
import API from "../../../../axios/instances/API";
import { Alert } from "@material-ui/lab";
import { CircularProgress } from "@material-ui/core";

const URL = "/dashboard/locations/bulk-create/";

const fetcher = async (url) => {
  const { data } = await API.get(url);
  return data;
};

const useLocationUploadProgress = () => {
  const { data, error, mutate } = useSWR(URL, fetcher, {
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
    revalideIfStale: true,
    refreshInterval: 300,
  });

  return {
    data,
    isLoading: !error && !data,
    isError: error,
    mutate,
  };
};

/// use this to query result progress.... maybe just display on locations page.
const CheckUploadProgress = () => {
  const { data, isLoading } = useLocationUploadProgress();

  if (!data || isLoading) return null;

  if (data?.scheduled_location_creations === 0) {
    return (
      <Alert severity="success" style={{ width: "100%" }}>
        <p style={{ fontWeight: "600", color: "mediumnseagreen" }}>
          Proccess Finished
        </p>
        <p style={{ fontWeight: "500" }}>
          All locations from CSV have been uploaded.
        </p>
      </Alert>
    );
  }

  return (
    <Alert
      icon={<CircularProgress size={20} />}
      severity="info"
      style={{ width: "100%" }}
    >
      <p style={{ fontWeight: "600" }}>Locations being processed...</p>
      <p>
        Number of locations left to create:{" "}
        <b>{data?.scheduled_location_creations}</b>
      </p>
    </Alert>
  );
};

export default CheckUploadProgress;
