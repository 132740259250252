/* eslint-disable */
import React, { useState } from "react";
import styled from "styled-components/macro";
import * as Yup from "yup";
import { Formik } from "formik";
import { CircularProgress } from "@material-ui/core";
import { spacing } from "@material-ui/system";
import { Helmet } from "react-helmet-async";
import AuthLayout from "../../../layouts/Auth";
import { Alert, TextField, Button, Typography } from "../../private/styles";
import { Wrapper } from "./styles";
import API from "../../../axios/instances/API";

const Box = styled.div`
  ${spacing}
`;

const initialValues = {
  email: "",
  submit: false,
};

const validationSchema = Yup.object().shape({
  email: Yup.string()
    .email("Must be a valid email")
    .max(255)
    .required("Email is required"),
});

const RecoverPassword = () => {
  const [alertText, setAlertText] = useState(false);
  const [successText, setSuccessText] = useState(false);

  const handleFormSubmit = async (values, { setSubmitting, resetForm }) => {
    setSubmitting(true);
    setAlertText(false);
    setSuccessText(false);
    try {
      await API.post("dashboard/auth/forgot-password/", {
        email: values.email.trim(),
      });
      setSuccessText("Recovery link sent. Check your email");
      setSubmitting(false);
      resetForm();
    } catch (error) {
      setSubmitting(false);
      setAlertText("Hmm.. that email is not in our records");
      resetForm();
    }
  };

  return (
    <AuthLayout>
      <Helmet title="Spiffy | Recover Password" />
      <Wrapper>
        <Typography variant="h2" component="h2" align="center">
          Recover Password
        </Typography>
        <Typography
          component="h5"
          variant="subtitle2"
          align="center"
          color="textSecondary"
        >
          Enter your email to receive recovery link
        </Typography>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleFormSubmit}
        >
          {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            isSubmitting,
            touched,
            values,
          }) => (
            <form noValidate onSubmit={handleSubmit}>
              {errors.submit && (
                <Alert mt={2} mb={1} severity="warning">
                  {errors.submit}
                </Alert>
              )}
              <TextField
                type="email"
                name="email"
                label="Email Address"
                value={values.email}
                error={Boolean(touched.email && errors.email)}
                fullWidth
                helperText={touched.email && errors.email}
                onBlur={handleBlur}
                onChange={handleChange}
                my={3}
              />
              <Box mt={2}>
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  color="secondary"
                  disabled={isSubmitting}
                  startIcon={isSubmitting && <CircularProgress size={20} />}
                >
                  {isSubmitting ? "Sending" : "Recover Password"}
                </Button>
              </Box>
              {successText && (
                <Box mt={2}>
                  <Alert type="success" onClose={() => setSuccessText(false)}>
                    {successText}
                  </Alert>
                </Box>
              )}
              {alertText && (
                <Alert
                  severity="error"
                  onClose={() => setAlertText(false)}
                  mt={2}
                >
                  {alertText}
                </Alert>
              )}
            </form>
          )}
        </Formik>
      </Wrapper>
    </AuthLayout>
  );
};

export default RecoverPassword;
