/* eslint-disable */
import React from "react";
import styled from "styled-components/macro";
import SubmissionTypeChip from "../../pages/private/home/retail/SubmissionTypeChip";
import { ENGAGEMENT_TYPES } from "../../storage/constants";

const TitleText = styled.p`
  font-size: 16px;
  color: black;
  font-weight: 600;

  @media (max-width: 600px) {
    font-size: 13px;
  }
`;

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;

  @media (max-width: 600px) {
    margin-top: 10px;
  }
`;

const TableTitle = ({
  title = "title",
  Icon,
  submissionType = ENGAGEMENT_TYPES.ALL,
  isVendor = false,
}) => {
  return (
    <Wrapper>
      <div style={{ width: 4 }} />
      {submissionType === ENGAGEMENT_TYPES.ALL ? (
        <Icon
          height={18}
          width={18}
          style={{
            borderRadius: 4,
            padding: 3,
            color: "#337ab7",
          }}
        />
      ) : (
        <SubmissionTypeChip
          small
          submissionType={submissionType}
          isVendor={isVendor}
        />
      )}
      <div style={{ width: 8 }} />
      <TitleText>{title}</TitleText>
    </Wrapper>
  );
};

export default TableTitle;
