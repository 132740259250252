/* eslint-disable */
import React from "react";
import PropTypes from "prop-types";
import { MapPin } from "react-feather";
import {
  CenterWithColor,
  StyledLink,
  RoundedDataTable,
  CustomDatatableV2,
} from "../../../styles";
import { makeDefaultTableOptions } from "../../../../../storage/helpers";
import {
  DEFINITIONS,
  returnDefinitionByEngagementType,
} from "../../../../../storage/constants";
import {
  retCompsByEngagementType,
  retSubsByEngagementType,
  retPassRateByEngagementType,
} from "../../utils/utils";
import TableTitle from "../../../../../components/Table/TableTitle";
import SubmissionTypeChip from "../../../home/retail/SubmissionTypeChip";
import CustomDataTableIcons from "../../../../../components/Table/CustomDataTableIcons";
import { useMediaQuery } from "@material-ui/core";

const LocationsTable = ({ locations, engagementType }) => {
  const isUnder600Px = useMediaQuery((theme) => theme.breakpoints.down("xs"));

  const options = makeDefaultTableOptions("show-locations");
  options.sortOrder = {
    name: "completions",
    direction: "desc",
  };
  options.rowsPerPage = 20;

  if (isUnder600Px) {
    options.rowsPerPageOptions = [];
    options.jumpToPage = false;
  }

  const columns = [
    {
      name: "id",
      label: "ID",
      options: {
        filter: false,
        sort: false,
        display: false,
      },
    },
    {
      name: "name",
      label: "Name",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta) => (
          <StyledLink
            to={`/vendor-reports/location/${tableMeta.rowData[0]}`}
            value={value}
            CustomIcon={MapPin}
          />
        ),
      },
    },
    {
      name: "address",
      label: "Address",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "state",
      label: "State",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "teamMembers",
      label: "Team Members",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "submissions",
      label: "Submissions",
      options: {
        filter: true,
        sort: true,
        hint: returnDefinitionByEngagementType("submissions", engagementType),
        customBodyRender: (val) => (
          <CenterWithColor engagementType={engagementType}>
            <span>{val}</span>
          </CenterWithColor>
        ),
      },
    },
    {
      name: "completions",
      label: "Completions",
      options: {
        filter: true,
        sort: true,
        hint: returnDefinitionByEngagementType("completions", engagementType),
        customBodyRender: (val) => (
          <CenterWithColor engagementType={engagementType}>
            <span>{val}</span>
          </CenterWithColor>
        ),
      },
    },
    {
      name: "passRate",
      label: "Pass Rate",
      options: {
        filter: true,
        sort: true,
        hint: DEFINITIONS.passRate,
        customBodyRender: (val) => (
          <CenterWithColor engagementType={engagementType}>
            <span>{val}%</span>
          </CenterWithColor>
        ),
      },
    },
    {
      name: "logo",
      label: " ",
      options: {
        display: false,
        sort: false,
        print: false,
        download: false,
      },
    },
  ];

  const tableData = locations.map((location) => ({
    id: location.id,
    name: location.location_name,
    address: `${location.address_line_1}, ${location.city_name}`,
    state: location.state_name,
    teamMembers: location.active_employees,
    completions: retCompsByEngagementType(location, engagementType),
    submissions: retSubsByEngagementType(location, engagementType),
    passRate: retPassRateByEngagementType(location, engagementType),
  }));

  return (
    <CustomDatatableV2
      title={
        <TableTitle
          Icon={MapPin}
          title="Locations At This Company"
          isVendor
          submissionType={engagementType}
        />
      }
      options={options}
      columns={columns}
      data={tableData}
      components={{
        icons: CustomDataTableIcons,
      }}
    />
  );
};

export default LocationsTable;

LocationsTable.propTypes = {
  locations: PropTypes.array.isRequired,
  engagementType: PropTypes.string.isRequired,
};
