/* eslint-disable */
import { Tooltip, Button } from "@material-ui/core";
import { XCircle } from "react-feather";
import styled from "styled-components/macro";

const StyledButton = styled(Button)`
  height: 22px;
  width: 22px;
  min-height: 22px;
  max-height: 22px;
  max-width: 22px;
  min-width: 22px;
  background-color: #eeeeee;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0px;
  transition: background-color 200ms ease-in;

  svg {
    color: slategrey;
  }
  &:hover {
    background-color: firebrick;

    svg {
      color: #eeeeee;
    }
  }
`;

const DeleteButtonForTables = ({ handleClick, disabled = false }) => {
  return (
    <>
      <Tooltip arrow placement="right" title="delete">
        <span>
          <StyledButton onClick={handleClick} disabled={disabled}>
            <XCircle height={14} width={14} />
          </StyledButton>
        </span>
      </Tooltip>
    </>
  );
};

export default DeleteButtonForTables;
