/* eslint-disable */
import useSWR from "swr";
import API from "../../../axios/instances/API";

const fetcher = async (url) => {
  const { data } = await API.get(url);
  return data;
};

const useQuizReview = (id) => {
  const { data, error, mutate, isValidating } = useSWR(
    !id ? null : `/dashboard/quiz/review/?id=${id}`,
    fetcher,
    {
      dedupingInterval: 2500,
      revalidateOnFocus: false,
    }
  );

  return {
    details: data,
    isLoading: !error && !data,
    isError: error,
    mutate,
    isValidating,
  };
};

export default useQuizReview;
