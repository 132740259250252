/* eslint-disable */
/* eslint-disable react/no-unescaped-entities */
/* eslint-disable no-nested-ternary */
import React, { useState } from "react";
import PropTypes from "prop-types";
import { RadioGroup, FormControlLabel, Radio } from "@material-ui/core";
import { DeleteWrapper, DeleteButton, Alert, Typography } from "../styles";
import { FEEDBACK_MESSAGES } from "../../../storage/constants";
import API from "../../../axios/instances/API";

const DeleteStreamModal = ({
  setSnackbarText,
  mutate,
  deleteInfo,
  setDeleteInfo,
  isRetail,
}) => {
  const [errorAlert, setErrorAlert] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [warning, setWarning] = useState(false);
  const [radioValue, setRadioValue] = useState(null);

  const allModulesAreClosed = deleteInfo.modules.every(
    (mod) => mod.state === "Closed"
  );

  const handleDelete = async () => {
    setWarning(false);
    setIsDeleting(true);
    setErrorAlert(false);
    try {
      await API.delete("/dashboard/streams/details/", {
        data: {
          id: deleteInfo.id,
          close_quizzes: radioValue === "yes",
        },
      });
      await mutate();
      setIsDeleting(false);
      setSnackbarText("Stream successfully deleted warning");
      return setDeleteInfo(null);
    } catch (error) {
      setErrorAlert(true);
      setSnackbarText("Failed to close this stream error");
      return setIsDeleting(false);
    }
  };

  return (
    <DeleteWrapper style={{ border: "1px solid tomato", width: 460 }}>
      <Typography variant="h5" component="h5">
        Delete Stream?
      </Typography>

      <Typography
        mb={!allModulesAreClosed ? 6 : 0}
        mt={1}
        style={{ fontWeight: "400", fontSize: 14 }}
      >
        {deleteInfo.name}
      </Typography>
      {!allModulesAreClosed ? (
        <Alert severity="warning" style={{ border: "1px solid darkorange" }}>
          <p style={{ fontSize: 13, fontWeight: "500" }}>
            Close the modules in this stream?
          </p>
          <RadioGroup
            aria-labelledby="select-delete-modules"
            value={radioValue}
            onChange={(e) => setRadioValue(e.target.value)}
            row
          >
            <FormControlLabel
              value="yes"
              control={<Radio size="small" color="black" />}
              label="Yes"
            />
            <FormControlLabel
              value="no"
              control={<Radio size="small" color="black" />}
              label="No"
            />
          </RadioGroup>
          {!isRetail && radioValue === "yes" ? (
            <span style={{ fontSize: 11, color: "orange" }}>
              *All retailer versions of these modules will be closed as well
            </span>
          ) : (
            <div style={{ height: "1px" }} />
          )}
        </Alert>
      ) : (
        <div style={{ height: 26 }} />
      )}
      <DeleteButton
        type="submit"
        form="deletingForm"
        fullWidth
        disabled={
          isDeleting || warning || (!allModulesAreClosed && !radioValue)
        }
        p={2}
        mt={2}
        onClick={handleDelete}
      >
        {isDeleting ? "..." : "Confirm"}
      </DeleteButton>
      {errorAlert && (
        <Alert mt={2} severity="error" onClose={() => setErrorAlert(false)}>
          {FEEDBACK_MESSAGES.GENERIC_ERROR}
        </Alert>
      )}
    </DeleteWrapper>
  );
};

export default DeleteStreamModal;

DeleteStreamModal.propTypes = {
  setSnackbarText: PropTypes.func.isRequired,
  mutate: PropTypes.func.isRequired,
  deleteInfo: PropTypes.object,
  setDeleteInfo: PropTypes.func.isRequired,
  isRetail: PropTypes.bool.isRequired,
};

DeleteStreamModal.defaultProps = {
  deleteInfo: null,
};
