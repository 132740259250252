/* eslint-disable */
import React, { useEffect, useRef } from "react";
import PropTypes from "prop-types";
import Vimeo from "@vimeo/player";
import styled from "styled-components/macro";
import { Typography, Button } from "../../styles";
import PdfDocument from "../../moduleBuilder/content/PdfDocument";
import ACTIONS from "../state/actions";
import { NoContentWrapper } from "../../moduleBuilder/styles";

const PdfHolder = styled.div`
  width: fit-content;
  height: 100%;
  text-align: center;
`;

const ContentWrapper = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  max-width: 1400px;
  min-height: 320px;
  gap: 16px;
  padding: 6px;
`;

const ContentBoxOne = styled.div`
  flex: 0.6;
  margin-bottom: 16px;
  margin-right: 16px;
`;

const QuestionText = styled(Typography)`
  color: darkgrey;
  font-size: 13px;
  font-weight: 400;
`;

const ContentBoxTwo = styled.div`
  flex: 0.4;
`;

const Content = ({ details, quizContext, isMarketplace = false }) => {
  const videoRef = useRef(null);

  useEffect(() => {
    if (!details.video) return;
    if (!videoRef.current) return;

    // eslint-disable-next-line no-unused-vars

    const vimeoOptions = {
      url: `https://vimeo.com${details.video}`,
      width: 400,
      height: "auto",
    };
    const player = new Vimeo(videoRef.current, vimeoOptions);
    player.on("error", () => {});
  }, [details]);

  return (
    <>
      <ContentWrapper>
        {details.video && (
          <ContentBoxOne>
            <QuestionText mb={3}>Video</QuestionText>
            {!details.video && (
              <NoContentWrapper>
                <Typography variant="subtitle2">No Video Uploaded</Typography>
              </NoContentWrapper>
            )}
            <>
              <div ref={videoRef} id="video-player-review" />
            </>
          </ContentBoxOne>
        )}
        {details.media && (
          <ContentBoxTwo>
            <QuestionText mb={3}>PDF</QuestionText>
            {details.media === null ? (
              <NoContentWrapper>
                <Typography variant="subtitle2">No PDF Uploaded</Typography>
              </NoContentWrapper>
            ) : (
              <PdfHolder>
                <PdfDocument media={{ content: details.media }} />
              </PdfHolder>
            )}
          </ContentBoxTwo>
        )}
      </ContentWrapper>
      {!isMarketplace && (
        <Button
          color="secondary"
          variant="outlined"
          mt={2}
          onClick={() =>
            quizContext.dispatch({ type: ACTIONS.UPDATE_INDEX, payload: 1 })
          }
        >
          Go To Edit Content
        </Button>
      )}
    </>
  );
};

export default Content;

Content.propTypes = {
  details: PropTypes.object.isRequired,
  quizContext: PropTypes.object,
  isMarketplace: PropTypes.bool,
};

Content.defaultProps = {
  quizContext: null,
  isMarketplace: undefined,
};
