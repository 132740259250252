/* eslint-disable */
/* redeploy */
import { Modal } from "@material-ui/core";
import PropTypes from "prop-types";
import React, { useContext, useState } from "react";
import { Edit2 } from "react-feather";
import { Helmet } from "react-helmet-async";
import styled from "styled-components/macro";
import LoaderWrapper from "../../../../components/Loaders/LoaderWrapper";
import PrivateHeader from "../../../../components/PrivateHeader";
import SnackbarWrapper from "../../../../components/SnackbarWrapper";
import ErrorBlockWithPermissions from "../../../../components/UI/ErrorBlockWithPermissions";
import SecondaryButton from "../../../../components/UI/SecondaryButton";
import { ENGAGEMENT_TYPES } from "../../../../storage/constants";
import { GlobalState } from "../../../../store/GlobalState";
import useLocationDetailV2 from "../../../../swr/hooks/Locations/useLocationDetailV2";
import EngagementTypeSelect from "../../reports/reuseableComponents/EngagementTypeSelect";
import EditLocationDetails from "./EditLocationDetails";
import LocationReport from "./LocationReport";
import ToggleManagerDeploy from "./ToggleManagerDeploy";

const TopWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media (max-width: 600px) {
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 12px;
  }
`;

const LocationDetail = ({ match }) => {
  const { id: locationId } = match.params;
  const { data, isLoading, isError, mutate } = useLocationDetailV2(locationId);
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [snackbarText, setSnackbarText] = useState("");
  const { state } = useContext(GlobalState);
  const { config, job } = state;
  const { read_only: readOnly } = config?.user;
  const isAdmin = job === "C";
  const isManager = job === "M";
  const [engagementType, setEngagementType] = useState(ENGAGEMENT_TYPES.ALL);

  if (isLoading) {
    return (
      <>
        <PrivateHeader header="Location" secondary={true} />
        <LoaderWrapper text="Location Details" />
      </>
    );
  }

  if (isError) {
    return (
      <>
        <PrivateHeader header="Location" secondary={true} />
        <ErrorBlockWithPermissions errorObj={isError} />
      </>
    );
  }

  return (
    <>
      <Helmet title="Spiffy | Location Detail" />
      <PrivateHeader header="Location" secondary={data?.location?.name}>
        <SecondaryButton
          handleClick={() => setEditModalOpen(true)}
          title="Edit Location"
          Icon={Edit2}
          disabled={readOnly || !isAdmin}
        />
      </PrivateHeader>
      <TopWrapper>
        <EngagementTypeSelect
          engagementType={engagementType}
          setEngagementType={setEngagementType}
        />
        <ToggleManagerDeploy
          canDeploy={data?.location?.managers_can_deploy_content}
          mutate={mutate}
          readOnly={readOnly}
          id={locationId}
          isManager={isManager}
          setSnackbarText={setSnackbarText}
        />
      </TopWrapper>
      <div style={{ height: 12 }} />
      <LocationReport
        data={data}
        locationId={locationId}
        setSnackbarText={setSnackbarText}
        mutate={mutate}
        engagementType={engagementType}
      />
      <Modal
        open={editModalOpen}
        onClose={() => setEditModalOpen(false)}
        aria-labelledby="Edit This Location"
        aria-describedby="Modal To Edit This Location"
      >
        <div>
          <EditLocationDetails
            readOnly={readOnly}
            isAdmin={isAdmin}
            data={data}
            setSnackbarText={setSnackbarText}
            handleClose={() => setEditModalOpen(false)}
          />
        </div>
      </Modal>
      <SnackbarWrapper
        snackbarText={snackbarText}
        setSnackbarText={setSnackbarText}
      />
    </>
  );
};

export default LocationDetail;

LocationDetail.propTypes = {
  match: PropTypes.object.isRequired,
};
