/* eslint-disable */
import React, { useState, useContext, useEffect } from "react";
import { Helmet } from "react-helmet-async";
import { Grid, Modal, Tab } from "@material-ui/core";
import { TableTabs, TableTabBar } from "../styles";
import TabPanel from "../../../components/TabPanel";
import LoaderWrapper from "../../../components/Loaders/LoaderWrapper";
import SupervisorsDatatable from "./SupervisorsDatatable";
import SupervisorInvitesTable from "./SupervisorInvitesTable";
import AccessRequestsTable from "./AccessRequestsTable";
import useManagers from "../../../swr/hooks/Managers/useManagers";
import usePendingManager from "../../../swr/hooks/Managers/useManagerInvitations";
import PrivateHeader from "../../../components/PrivateHeader";
import SnackbarWrapper from "../../../components/SnackbarWrapper";
import OnLoadError from "../../../components/UI/OnLoadError";
import InviteSupervisor from "./InviteSupervisor";
import { GlobalState } from "../../../store/GlobalState";
import BadgeLabel from "../../../components/BadgeLabel";
import { Monitor } from "react-feather";
import CreateButton from "../../../components/UI/CreateButton";

const Managers = ({ location }) => {
  const { isLoading, isError } = useManagers();

  const {
    isLoading: pendingLoading,
    pendingManagers,
    mutate,
    isError: isPendingManagerError,
  } = usePendingManager(!isLoading);

  const [snackbarText, setSnackbarText] = useState("");
  const [tabValue, setTabValue] = useState(0);
  const [createManagerOpen, setCreateManagerOpen] = useState(false);
  const { state } = useContext(GlobalState);

  const { config, job } = state;

  const { read_only: readOnly } = config.user;

  const pendingSupervisors =
    config?.current_company?.pending_access_requests?.managers;

  useEffect(() => {
    if (location && location.state && location.state.supervisorDeleted) {
      return setSnackbarText(
        `Supervisor access removed for ${location.state.supervisorName} success`
      );
    }
  }, []);

  const isAdmin = job === "C";
  const isManager = job === "M";

  if (isError || isPendingManagerError) {
    return <OnLoadError />;
  }

  const handleTabChange = (_, newValue) => {
    setTabValue(newValue);
  };

  return (
    <>
      <Helmet title="Spiffy | Supervisors" />
      <PrivateHeader header="Supervisors" Icon={Monitor} />
      {isLoading ? (
        <LoaderWrapper text="Supervisors" />
      ) : (
        <>
          <Grid container>
            <Grid item xs={12}>
              <div style={{ width: "fit-content" }}>
                <CreateButton
                  disabled={readOnly}
                  handleClick={() => setCreateManagerOpen(true)}
                  title="Invite Supervisor"
                />
              </div>

              <div style={{ height: 18 }} />
              <TableTabBar position="relative" elevation={0}>
                <TableTabs
                  value={tabValue}
                  onChange={handleTabChange}
                  scrollButtons="off"
                >
                  <Tab label="All Supervisors" />
                  <Tab label="Outstanding Invitations" />
                  <Tab
                    label={
                      <BadgeLabel
                        num={!pendingSupervisors ? null : pendingSupervisors}
                        text="Access Requests"
                      />
                    }
                  />
                </TableTabs>
              </TableTabBar>
            </Grid>
            <Grid item xs={12}>
              <TabPanel value={tabValue} index={0}>
                <SupervisorsDatatable
                  readOnly={readOnly}
                  isAdmin={isAdmin}
                  isManager={isManager}
                />
              </TabPanel>
              <TabPanel value={tabValue} index={1}>
                <SupervisorInvitesTable
                  pendingLoading={pendingLoading}
                  pendingManagers={pendingManagers}
                  mutate={mutate}
                  readOnly={readOnly}
                />
              </TabPanel>
              <TabPanel value={tabValue} index={2}>
                <AccessRequestsTable
                  setSnackbarText={setSnackbarText}
                  mutateOutstanding={mutate}
                />
              </TabPanel>
            </Grid>
          </Grid>
          <Modal
            open={createManagerOpen}
            onClose={() => setCreateManagerOpen(false)}
            aria-labelledby="Invite a Supervisor"
            aria-describedby="Modal to invite a Supervisor To Dashboard"
          >
            <div>
              <InviteSupervisor
                setSnackbarText={setSnackbarText}
                setCreateManagerOpen={setCreateManagerOpen}
              />
            </div>
          </Modal>
        </>
      )}
      <SnackbarWrapper
        snackbarText={snackbarText}
        setSnackbarText={setSnackbarText}
      />
    </>
  );
};

export default Managers;
