/* eslint-disable */
import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components/macro";
import { IconButton, Tooltip } from "@material-ui/core";
import { CheckCircleOutline } from "@material-ui/icons";
import { HelpCircle } from "react-feather";

const CheckSaved = styled(CheckCircleOutline)`
  color: ${(props) => props.theme.palette.success.light};
  font-size: 16px;
  cursor: pointer;
`;

const ExtraInfo = ({
  text,
  saved = false,
  savedText = null,
  overrideBlack = false,
}) => (
  <>
    <Tooltip arrow title={saved ? savedText : text}>
      <IconButton size="small">
        {saved ? (
          <CheckSaved />
        ) : (
          <HelpCircle
            height={13}
            width={13}
            style={{ color: overrideBlack ? "black" : "#22597f" }}
          />
        )}
      </IconButton>
    </Tooltip>
  </>
);

export default ExtraInfo;

ExtraInfo.propTypes = {
  text: PropTypes.string.isRequired,
  saved: PropTypes.bool,
  savedText: PropTypes.string,
};

ExtraInfo.defaultProps = {
  saved: false,
  savedText: null,
};
