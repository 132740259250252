/* eslint-disable */
import React from "react";
import { PlusCircle } from "react-feather";
import useAudienceToolStore from "../../../../store/useAudienceToolStore";
import { TABLE_STATES } from "../helpers";
import AttActionButton from "./AttActionButton";

const SelectAllButton = () => {
  const handleSelectAll = useAudienceToolStore(
    (state) => state.handleSelectAll
  );
  const hasAtLeast1LocationAsOption = useAudienceToolStore(
    (state) => state.hasAtLeast1LocationAsOption
  );
  const hasAtLeast1RoleAsOption = useAudienceToolStore(
    (state) => state.hasAtLeast1RoleAsOption
  );
  const initialLoad = useAudienceToolStore((state) => state.initialLoad);
  const isLocked = useAudienceToolStore((state) =>
    [TABLE_STATES.SAVING, TABLE_STATES.LOCKED].includes(state.tableState)
  );

  const isDisabled =
    !hasAtLeast1LocationAsOption ||
    !hasAtLeast1RoleAsOption ||
    isLocked ||
    initialLoad;

  return (
    <AttActionButton
      title="Select All"
      handleClick={handleSelectAll}
      Icon={PlusCircle}
      disabled={isDisabled}
    />
  );
};

export default SelectAllButton;
