import React from 'react';
import styled from 'styled-components/macro';
import { Typography } from '../../../pages/private/styles';
import { IntroText } from '../styles';

const Wrapper = styled.div`
  margin-left: 12px;
`;

const Content = () => (
  <Wrapper>
    <Typography
      variant="subtitle2"
    >
      What does transcoding mean?
    </Typography>
    <IntroText
      mt={1}
      mb={6}
    >
      Once your video has finished uploading,
      it must be transcoded. What this means is
      your video file will be converted to a
      standardized video file type that can be used everywhere.
      This process can take a little while,
      so feel free to move on to other parts of
      the module builder while you wait.
    </IntroText>
    <Typography
      variant="subtitle2"
    >
      How do i replace a video or pdf?
    </Typography>
    <IntroText
      mt={1}
    >
      You can replace the existing video or pdf by simply uploading a new one.
    </IntroText>
  </Wrapper>
);

export default Content;
