/* eslint-disable */
import { create } from "zustand";
import { AUDIENCETYPES, USER_TYPES } from "../storage/constants";
import API from "../axios/instances/API";

import {
  TABLE_STATES,
  handleFormatAudience,
  formatCreatedTemplateForApi,
  formatSelectedAudeinceForApi,
  createTemplateMatrix,
  initialState,
  areRowsAndCurrentAudienceEqual,
  checkIfSavedCustomIsOtherAudienceType,
} from "../components/audience/AudienceTool/helpers";

const useAudienceToolStore = create((set, get) => ({
  /// VARIABLES
  ...initialState,

  // ACTIONS
  initialize: async (
    moduleId,
    userType,

    isTemplate = false,
    templateId = null,
    templateAudience = null,

    isDisplayOnly = false,
    allowReset = false
  ) => {
    const loadOptions = get().loadOptions;
    const loadAudience = get().loadAudience;
    const handleError = get().handleError;
    const getTemplateName = get().getTemplateName;

    const userIsManager = [
      USER_TYPES.RETAIL_MANAGER_CANT_DEPLOY,
      USER_TYPES.RETAIL_MANAGER_CAN_DEPLOY,
    ].includes(userType);

    try {
      const { locations, roles, savedAudiences } = await loadOptions();
      const {
        audience,
        audience_type: audienceType,
        audience_modifiable_by_managers: canManagersModify,
      } = isTemplate ? templateAudience : await loadAudience(moduleId);

      const initialRows = createTemplateMatrix(roles, locations, audience);

      const hasAudience = Object.keys(audience).length > 0;

      const templateName = !isTemplate
        ? null
        : await getTemplateName(templateId);

      set({
        isInitialized: true,
        initialLoad: false,
        displayTableInline: userIsManager || isTemplate ? true : false,
        displayTableModal: false,
        currentAudienceType: hasAudience
          ? audienceType.raw
          : AUDIENCETYPES.NONE,
        currentAudience: audience,
        rows: initialRows,
        isDisplayOnly,
        isTemplate,
        moduleId,
        allowReset,
        displayAudienceSelect: userIsManager || isTemplate ? false : true,
        roles,
        locations,
        hasAtLeast1LocationAsOption: locations.length > 0,
        hasAtLeast1RoleAsOption: roles.length > 0,
        templates: savedAudiences,
        canManagersModify,
        templateName,
        tableState: userIsManager ? TABLE_STATES.LOCKED : TABLE_STATES.UNLOCKED,
        templateId,
        userType,
      });
    } catch (error) {
      handleError(error);
    }
  },

  handleSaveTable: async () => {
    const handleError = get().handleError;
    const isTemplate = get().isTemplate;
    const handleSaveAsTemplate = get().handleSaveAsTemplate;
    const handleRefreshAudience = get().handleRefreshAudience;

    if (isTemplate) {
      return handleSaveAsTemplate();
    }

    try {
      const updateTableState = get().updateTableState;
      updateTableState(TABLE_STATES.SAVING);
      set({ exitModalOpen: false });
      const rows = get().rows;
      const moduleId = get().moduleId;
      const locations = get().locations;
      const sendObj = {
        quiz_id: moduleId,
        audience: formatSelectedAudeinceForApi(rows, locations),
      };

      await API.put("dashboard/quiz/audience/", sendObj);
      await handleRefreshAudience();
    } catch (error) {
      handleError(error);
    }
  },

  handleRefreshAudience: async (overRideMsg = null) => {
    const handleError = get().handleError;
    const userType = get().userType;

    const isManager = [
      USER_TYPES.RETAIL_MANAGER_CANT_DEPLOY,
      USER_TYPES.RETAIL_MANAGER_CAN_DEPLOY,
    ].includes(userType);

    try {
      const updateTableState = get().updateTableState;
      updateTableState(TABLE_STATES.SAVING);
      const moduleId = get().moduleId;
      const { data } = await API.get(
        `dashboard/quiz/audience/?quiz_id=${moduleId}`
      );
      set({
        snackText: overRideMsg || "Audience updated successfully success",
        currentAudience: data?.audience,
        currentAudienceType: data?.audience_type?.raw,
        tableState: isManager ? TABLE_STATES.LOCKED : TABLE_STATES.UNLOCKED,
        canManagersModify: data?.audience_modifiable_by_managers,
      });
    } catch (error) {
      handleError(error);
    }
  },

  handleSaveFromModal: async () => {
    const rows = get().rows;
    const moduleId = get().moduleId;
    const locations = get().locations;
    const roles = get().roles;
    const userType = get().userType;

    const handleRefreshAudience = get().handleRefreshAudience;

    const sendObj = {
      quiz_id: moduleId,
      audience: formatSelectedAudeinceForApi(rows, locations),
    };
    set({
      tableState: TABLE_STATES.SAVING,
      displayTableModal: false,
    });
    await API.put("dashboard/quiz/audience/", sendObj);

    const isOtherAudienceTypeMsg = checkIfSavedCustomIsOtherAudienceType(
      rows,
      roles.length,
      userType
    );

    await handleRefreshAudience(isOtherAudienceTypeMsg || null);
  },

  getTemplateName: async (id) => {
    try {
      const { data } = await API.get(`dashboard/audiences/details/?id=${id}`);

      return data?.audience?.name;
    } catch (error) {}
  },

  toggleColumnCheck: (e, index) => {
    const { checked } = e.target;
    set((state) => {
      const allLocationsIds = state.locations.map((l) => l.id);
      const copy = { ...state.rows };

      allLocationsIds.forEach(
        (locId) => (copy[locId][index].checked = checked)
      );

      return { rows: copy };
    });
  },

  toggleCellCheck: (e, locationId, index) => {
    const { checked } = e.target;
    set((state) => {
      const copy = { ...state.rows };
      copy[locationId][index].checked = checked;

      return { rows: copy };
    });
  },

  handleSelectAll: () => {
    const handleError = get().handleError;

    try {
      set((state) => {
        const copy = { ...state.rows };
        const keys = Object.keys(copy);
        const retObj = {};

        keys.forEach((key) => {
          retObj[key] = copy[key].map((obj) => ({
            ...obj,
            checked: true,
          }));
        });

        return {
          rows: retObj,
        };
      });
    } catch (error) {
      handleError(error);
    }
  },

  handleSelectAllAndSave: () => {
    const handleError = get().handleError;
    const handleSaveTable = get().handleSaveTable;
    const toggleConfirmAllTeamOpen = get().toggleConfirmAllTeamOpen;
    try {
      set((state) => {
        const copy = { ...state.rows };
        const keys = Object.keys(copy);
        const retObj = {};

        keys.forEach((key) => {
          retObj[key] = copy[key].map((obj) => ({
            ...obj,
            checked: true,
          }));
        });

        return {
          rows: retObj,
        };
      });
      toggleConfirmAllTeamOpen();
      handleSaveTable();
    } catch (error) {
      handleError(error);
    }
  },

  handleResetTable: () => {
    const handleError = get().handleError;

    try {
      set((state) => {
        const copy = { ...state.rows };
        const keys = Object.keys(copy);
        const retObj = {};

        keys.forEach((key) => {
          retObj[key] = copy[key].map((obj) => ({
            ...obj,
            checked: false,
          }));
        });

        return { rows: retObj };
      });
    } catch (error) {
      handleError(error);
    }
  },

  closeExitModal: () => set({ exitModalOpen: false }),

  handleApplyRolesFromModal: (selectedRoles) => {
    const handleSaveTable = get().handleSaveTable;
    const handleError = get().hadleError;
    const displayTableModal = get().displayTableModal;
    try {
      set((state) => {
        const copy = { ...state.rows };
        const retObj = {};
        Object.keys(copy).forEach((id) => {
          retObj[id] = copy[id].map((cell) => ({
            ...cell,
            checked: Boolean(selectedRoles.includes(cell.roleId)),
          }));
        });

        return {
          rows: retObj,
        };
      });
      if (!displayTableModal) {
        handleSaveTable();
      }
    } catch (error) {
      handleError(error);
    }
  },

  handleApplyLocationsFromModal: (selectedLocations) => {
    const handleSaveTable = get().handleSaveTable;
    const handleError = get().hadleError;
    try {
      set((state) => {
        const copy = { ...state.rows };
        const retObj = {};

        Object.keys(copy).map((id) => {
          retObj[id] = copy[id].map((cell) => ({
            ...cell,
            checked: Boolean(selectedLocations.includes(cell.locationId)),
          }));
        });

        return {
          rows: retObj,
        };
      });

      handleSaveTable();
    } catch (error) {
      handleError(error);
    }
  },

  handleError: (error) => {
    let title = "";
    let body = "";

    const isApiReponseError = Boolean(error?.response?.data?.error);

    if (!isApiReponseError) {
      title = "Error applying this action (F)";
      body = error?.message || "Sorry for the inconvenience";
    } else {
      title = `${error?.response?.data?.error?.title} (B)`;
      body = error?.response?.data?.error?.details;
    }

    set({
      error: { title, body },
      tableState: TABLE_STATES.ERROR,
    });
  },

  loadAudience: async (id) => {
    const handleError = get().handleError;
    try {
      const { data } = await API.get(`dashboard/quiz/audience/?quiz_id=${id}`);
      return data;
    } catch (error) {
      handleError(error);
    }
  },

  loadOptions: async () => {
    const handleError = get().handleError;
    try {
      const { data } = await API.get("/dashboard/audiences/options/");
      return data;
    } catch (error) {
      handleError(error);
    }
  },

  handleSaveAsTemplate: async (newName = null) => {
    const updateTableState = get().updateTableState;
    updateTableState(TABLE_STATES.SAVING);
    const handleError = get().handleError;
    const templateName = get().templateName;
    const templateId = get().templateId;
    const rows = get().rows;
    const handleRefreshTemplateAudience = get().handleRefreshTemplateAudience;

    try {
      const sendObjForTemplate = {
        id: templateId,
        name: newName || templateName,
        mapping: {
          select_all: false,
          role_override: [],
          locations: formatCreatedTemplateForApi(rows),
        },
      };

      if (newName) {
        set({
          editTemplateNameModalOpen: false,
          templateName: newName,
        });
      }
      await API.put("dashboard/audiences/details/", sendObjForTemplate);
      await handleRefreshTemplateAudience();
    } catch (error) {
      handleError(error);
    }
  },

  handleUpdateTemplateName: (newName) => {
    const handleSaveAsTemplate = get().handleSaveAsTemplate;

    handleSaveAsTemplate(newName);
  },

  handleRefreshTemplateAudience: async () => {
    const handleError = get().handleError;
    try {
      const templateId = get().templateId;

      const { data } = await API.get(
        `dashboard/audiences/details/?id=${templateId}`
      );

      const formatted = handleFormatAudience(data?.audience);
      set({
        templateName: data?.audience?.name,
        currentAudience: formatted.audience,
        currentAudienceType: formatted?.audience_type?.raw,
        tableState: TABLE_STATES.LOCKED,
        canManagersModify: formatted?.audience_modifiable_by_managers,
        snackText: "Template saved success",
      });
    } catch (error) {
      handleError(error);
    }
  },

  toggleManagerCanModify: () => {
    set((state) => ({
      canManagersModify: !state.canManagersModify,
    }));
  },

  handleToggleAudienceLock: async () => {
    const moduleId = get().moduleId;
    const handleError = get().handleError;
    const toggleManagerCanModify = get().toggleManagerCanModify;
    const setSnackText = get().setSnackText;

    try {
      await API.put("dashboard/quiz/toggle-audience-lock/", {
        quiz_id: moduleId,
      });
      setSnackText("Supervisor lock saved success");
      toggleManagerCanModify();
    } catch (error) {
      handleError(error);
    }
  },

  handleApplyTemplateFromModal: async (id) => {
    const handleError = get().handleError;
    const updateTableState = get().updateTableState;

    updateTableState(TABLE_STATES.SAVING);
    try {
      const { data } = await API.get(`dashboard/audiences/details/?id=${id}`);
      const mapping = data?.audience?.mapping[0];

      if (!mapping)
        throw new Error(
          "The template selected is empty and therefore cannot be used"
        );

      const { locations } = mapping;

      if (locations.length === 0) {
        throw new Error(
          "The template selected has no locations, and therefore cannot be used"
        );
      }

      const formattedLocations = {};

      locations.forEach((loc) => {
        formattedLocations[loc.id] = loc.roles.map((r) => r.id);
      });

      set((state) => {
        const copy = { ...state.rows };
        const retObj = {};

        Object.keys(copy).forEach((id) => {
          retObj[id] = copy[id].map((obj) => ({
            ...obj,
            checked: Boolean(formattedLocations[id]?.includes(obj.roleId)),
          }));
        });

        return { rows: retObj, tableState: TABLE_STATES.UNLOCKED };
      });
      const setSnackText = get().setSnackText;
      setSnackText("Template applied to table info");
    } catch (error) {
      handleError(error);
    }
  },

  handleDiscardChanges: (showMessage = true) => {
    const currentAudience = get().currentAudience;
    const roles = get().roles;
    const locations = get().locations;
    const newMatrix = createTemplateMatrix(roles, locations, currentAudience);
    set({
      snackText: showMessage ? "Discarded changes warning" : "",
      rows: newMatrix,
      tableState: TABLE_STATES.UNLOCKED,
    });
  },

  setSnackText: (text = "") => {
    set({ snackText: text });
  },

  handleClearError: () =>
    set({ error: null, tableState: TABLE_STATES.UNLOCKED }),

  updateTableState: (str) => set({ tableState: str }),

  viewCurrentAudienceInModal: () =>
    set({ isDisplayOnly: true, displayTableModal: true }),

  resetStateToInitial: () => set(initialState),

  openTableInModal: () => set({ displayTableModal: true }),

  closeTableModal: () => {
    const handleDiscardChanges = get().handleDiscardChanges;
    handleDiscardChanges(false);
    set({ displayTableModal: false, isDisplayOnly: false });
  },

  toggleRoleSelector: () =>
    set((state) => ({ roleSelectorOpen: !state.roleSelectorOpen })),

  toggleLocationSelector: () =>
    set((state) => ({ locationSelectorOpen: !state.locationSelectorOpen })),

  toggleTemplateSelector: () =>
    set((state) => ({ templateSelectorOpen: !state.templateSelectorOpen })),

  toggleRowCheck: (e, id) => {
    const { checked } = e.target;
    set((state) => {
      const updatedRow = state.rows[id].map((cell) => ({
        ...cell,
        checked: checked,
      }));
      const copy = { ...state.rows };
      copy[id] = updatedRow;

      return { rows: copy };
    });
  },

  openEditTemplateName: () => set({ editTemplateNameModalOpen: true }),

  closeEditTemplateName: () => set({ editTemplateNameModalOpen: false }),

  toggleConfirmAllTeamOpen: () =>
    set((state) => ({
      confirmAllTeamMembersOpen: !state.confirmAllTeamMembersOpen,
    })),
}));

export default useAudienceToolStore;
