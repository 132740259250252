/* eslint-disable */
import React from "react";
import TableWrapper from "./TableWrapper";
import TableHead from "./TableHead";
import TableBody from "./TableBody";
import useAudienceToolStore from "../../../store/useAudienceToolStore";
import { Paper, Typography } from "@material-ui/core";
import styled from "styled-components/macro";
import TableBottomActions from "./TableBottomActions";
import SavedAudienceBox from "./SavedAudienceBox";
import TableAudienceButtonsV2 from "./TableAudienceButtonsV2";
import { X } from "react-feather";
import ErrorScreen from "./ErrorScreen";
import AttActionButton from "./Buttons/AttActionButton";

const ModalWrapper = styled(Paper)`
  padding: 20px;
  width: calc(min(1100px, 96vw));
  max-height: 90vh;
  min-height: 540px;
  position: absolute;
  left: 50%;
  overflow-y: scroll;
  border: 2px solid black;
  top: 50%;
  transform: translate(-50%, -50%);
`;

const HeaderWrapper = styled.div`
  height: 46px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 8px;
  margin-bottom: 8px;
  margin-top: -8px;
`;

const ModalDisplayHeader = ({ isDisplayOnly, closeTableModal }) => {
  return (
    <HeaderWrapper>
      <Typography style={{ fontWeight: "600", fontSize: 16, color: "black" }}>
        {isDisplayOnly ? "Saved Audience Matrix" : "Audience Matrix"}
      </Typography>
      {isDisplayOnly && (
        <span style={{ color: "darkgrey" }}>(Not Editable)</span>
      )}
      <div style={{ marginLeft: "auto" }} />
      <AttActionButton
        title="Close"
        style={{
          marginLeft: "auto",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          height: 32,
          width: 32,
          maxWidth: 32,
          minWidth: 32,
          background: "black",
          borderRadius: 8,
        }}
        handleClick={closeTableModal}
        Icon={X}
      />
    </HeaderWrapper>
  );
};

const ATT = () => {
  const displayTableModal = useAudienceToolStore(
    (state) => state.displayTableModal
  );
  const isDisplayOnly = useAudienceToolStore((state) => state.isDisplayOnly);
  const closeTableModal = useAudienceToolStore(
    (state) => state.closeTableModal
  );

  if (displayTableModal) {
    return (
      <ModalWrapper>
        <ModalDisplayHeader
          isDisplayOnly={isDisplayOnly}
          closeTableModal={closeTableModal}
        />
        <div style={{ position: "relative", minHeight: 460 }}>
          <TableAudienceButtonsV2 />
          <TableWrapper>
            <table>
              <TableHead />
              <TableBody />
            </table>
          </TableWrapper>
          <div style={{ height: 12 }} />
          <TableBottomActions />
        </div>
        <ErrorScreen />
      </ModalWrapper>
    );
  }

  return (
    <div
      style={{
        position: "relative",
        minHeight: 420,
        padding: 6,
        maxWidth: "100%",
      }}
    >
      <TableAudienceButtonsV2 />
      <TableWrapper>
        <table>
          <TableHead />
          <TableBody />
        </table>
      </TableWrapper>
      <div style={{ height: 12 }} />
      <TableBottomActions />
      <SavedAudienceBox />
      <ErrorScreen />
    </div>
  );
};

export default ATT;
