/* eslint-disable */
import React, { useState } from "react";
import styled from "styled-components/macro";
import { Paper, Typography, Modal } from "@material-ui/core";
import CountryAndStateFields from "./CountryAndStateFields";
import UploadTemplate from "./UploadTemplate";
import ShowResults from "./ShowResults";
import Notes from "./Notes";

const Wrapper = styled(Paper)`
  padding: 20px;
  min-height: 300px;
  width: min(500px, 90vw);
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  text-align: left;
  border: 2px solid black;
  max-height: 96vh;
  overflow-y: scroll;
`;

const Subtitle = styled.p`
  fontsize: 12px;
  font-weight: 600;
  color: darkgrey;
`;

const Number = styled.span`
  font-size: 12px;
  font-weight: 800;
  color: black;
`;

const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
`;

const CreateLocationsViaCsv = ({ setCsvModalOpen, mutate }) => {
  const [inviteResults, setInviteResults] = useState(null);

  return (
    <Wrapper>
      <Typography style={{ fontSize: 16, fontWeight: "600" }}>
        Create locations via CSV
      </Typography>
      <div style={{ height: 12 }} />
      <TitleContainer>
        <Number>Step 1:</Number>
        <Subtitle>Select the country and state of locations.</Subtitle>
      </TitleContainer>
      <div>
        <CountryAndStateFields />
      </div>
      <div style={{ height: 12 }} />

      <TitleContainer>
        <Number>Step 2:</Number>
        <Subtitle>
          Fill out the downloaded template in excel and save as a CSV.
        </Subtitle>
      </TitleContainer>
      <div style={{ height: 6 }} />
      <Notes />
      <div style={{ height: 8 }} />
      <TitleContainer>
        <Number>Step 3:</Number>
        <Subtitle>Upload CSV file here.</Subtitle>
      </TitleContainer>
      <div style={{ height: 4 }} />
      <UploadTemplate setInviteResults={setInviteResults} />
      <Modal
        onClose={() => setInviteResults(null)}
        open={Boolean(inviteResults)}
      >
        <ShowResults
          handleExit={() => {
            setInviteResults(null);
            mutate();
            setCsvModalOpen(false);
          }}
          handleUploadMore={() => {
            mutate();
            setInviteResults(null);
          }}
          results={inviteResults}
        />
      </Modal>
    </Wrapper>
  );
};

export default CreateLocationsViaCsv;
