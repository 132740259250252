/* eslint-disable */
import React from "react";
import useAudienceToolStore from "../../../store/useAudienceToolStore";
import ColumnHeader from "./ColumnHeader";
import { useShallow } from "zustand/react/shallow";

const TableHead = () => {
  const isTemplate = useAudienceToolStore((state) => state.isTemplate);
  const isDisplayOnly = useAudienceToolStore((state) => state.isDisplayOnly);

  const roleIds = useAudienceToolStore(
    useShallow((state) => state.roles.map((r) => r.id))
  );

  return (
    <thead>
      <tr>
        <th
          style={{
            width: 160,
            maxWidth: 160,
            minWidth: 160,
            backgroundColor: "#eeeeee",
            zIndex: 10,
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              width: "100%",
              minHeight: 50,
            }}
          >
            {!isDisplayOnly && (
              <span style={{ color: "black" }}>
                {isTemplate ? "Template Builder" : "Select Audience"}
              </span>
            )}
          </div>
        </th>
        {roleIds.map((id, i) => (
          <ColumnHeader roleId={id} index={i} key={id} />
        ))}
      </tr>
    </thead>
  );
};

export default TableHead;
