/* eslint-disable */
import React from "react";
import PropTypes from "prop-types";
import { CustomDatatableV2 } from "../../../styles";
import { makeDefaultTableOptions } from "../../../../../storage/helpers";
import TableTitle from "../../../../../components/Table/TableTitle";
import { Book } from "react-feather";
import CustomDataTableIcons from "../../../../../components/Table/CustomDataTableIcons";
import { useMediaQuery } from "@material-ui/core";

const UserProgress = ({ modules }) => {
  const isUnder600Px = useMediaQuery((theme) => theme.breakpoints.down("xs"));

  const options = makeDefaultTableOptions("user-modules-completed");
  options.rowsPerPage = 10;

  if (isUnder600Px) {
    options.rowsPerPageOptions = [];
    options.jumpToPage = false;
  }

  const columns = [
    {
      name: "name",
      label: "Module Name",
    },
    {
      name: "submissions",
      label: "Times Submitted",
    },
    {
      name: "completed",
      label: "Completed",
    },
  ];

  const tableData = modules.map((mod) => ({
    name: mod.name,
    submissions: mod.submissions,
    completed: mod.completions > 0 ? "Yes" : "No",
  }));

  return (
    <CustomDatatableV2
      title={
        <div style={{ display: "flex", gap: 6 }}>
          <TableTitle Icon={Book} title="Modules" />
        </div>
      }
      options={options}
      columns={columns}
      data={tableData}
      components={{
        icons: CustomDataTableIcons,
      }}
    />
  );
};

export default UserProgress;

UserProgress.propTypes = {
  modules: PropTypes.array.isRequired,
};
